import React, { useEffect } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Input, Row } from "antd";
import { LOGO_XPRESS } from "../../Constants/SvgConstants";
import { validateEmail } from "../../Util";
import SvgLoader from "../../Util/SvgLoader";
import {
  Login,
  errorLogin,
  generateToken,
  handleChangeLogInOTP,
  handleChangeLoginUserEmail,
  loginBack,
  resetErrorMsg,
  setError,
  setLoginUserToken,
  validateUserForLogin,
} from "../../actions/CommonActions";
import BannerV2 from "../BannerV2/BannerV2";
import FooterV2 from "../FooterV2/FooterV2";
import styles from "./LoginV2.module.scss";

const LoginV2 = () => {
  const dispatch = useDispatch();
  const {
    generateTokenRequest,
    loginMessage,
    loginAuthenicating,
    loginUserEmail,
    tokenSendSuccess,
    alreadyHaveToken,
    loginOtpError,
    loginOtp,
  } = useSelector((state) => state.CommonReducer);

  useEffect(() => {
    const qs = require("query-string");
    const token = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const cookies = new Cookies();
    if (token["email"] !== undefined) {
      cookies.set("xpressEmail", token["email"]);
      dispatch(handleChangeLoginUserEmail(token["email"]));
    }
    if (token["token"] !== undefined) {
      dispatch(setLoginUserToken(token["token"]));
    }
    if (token["email"] !== undefined && token["token"] !== undefined) {
      dispatch(Login());
    } else {
      dispatch(setLoginUserToken(""));
    }
  }, []);

  const validateForm = () => {
    let formValid = true;
    if (loginUserEmail.trim() == "" || !validateEmail(loginUserEmail)) {
      dispatch(setError("loginUserEmailError", true));
      formValid = false;
    } else {
      dispatch(setError("loginUserEmailError", false));
      formValid = true;
    }
    if ((loginOtp == undefined || loginOtp.trim() == "") && (tokenSendSuccess || alreadyHaveToken)) {
      dispatch(setError("loginOtpError", true));
      formValid = false;
    } else {
      dispatch(setError("loginOtpError", false));
      formValid = true;
    }
    return formValid;
  };

  const tokenGenerate = (e) => {
    e.preventDefault();
    let formValid = validateForm();
    if (dispatch(validateUserForLogin(loginUserEmail))) {
      if (formValid && !loginMessage) {
        dispatch(generateToken({ email: loginUserEmail }));
      }
    }
  };

  const loginWithOtp = () => {
    let formValid = validateForm();
    if (formValid) {
      dispatch(Login(loginOtp));
    } else {
      dispatch(errorLogin());
    }
  };

  const validateUserInputForEmail = (value) => {
    dispatch(handleChangeLoginUserEmail(value));
    if (loginUserEmail.trim() == "" || !validateEmail(loginUserEmail)) {
      dispatch(setError("loginUserEmailError", true));
    } else {
      dispatch(setError("loginUserEmailError", false));
    }
  };

  return (
    <Col span={24} className={styles["container"]}>
      <Col xl={15} md={12} className="banner_Visibility">
        <BannerV2 />
      </Col>
      <Col xl={9} md={12} xs={24} className="auth_card_wrap">
        <Row className="auth_card">
          <Col span={24} className={"validate-form"}>
            <SvgLoader iconName={LOGO_XPRESS} width={112} height={33} />
            <div className={styles["sign_In_Text"]}>Sign in</div>
            <React.Fragment>
              {tokenSendSuccess || alreadyHaveToken ? (
                [
                  <>
                    <div className={styles["custom-input-wrapper"]}>
                      <div className={`${styles["custom-input-label"]} `}>Token</div>
                      <Input
                        className={`${styles["modal-input"]} ${loginOtpError && styles["error"]}`}
                        type="text"
                        name="Enter Token"
                        placeholder="Token"
                        onChange={(e) => {
                          dispatch(handleChangeLogInOTP(e.target.value));
                        }}
                        autoComplete="off"
                        autoFocus
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && loginOtp) {
                            loginWithOtp(e);
                          }
                        }}
                      />
                    </div>
                    <Col>
                      <div className={styles["resend_Token_Text"]} onClick={() => dispatch(loginBack())}>
                        Resend Token
                      </div>
                    </Col>
                  </>,
                ]
              ) : (
                <div className={styles["custom-input-wrapper"]}>
                  <div className={`${styles["custom-input-label"]} `}>Email Address</div>
                  <Input
                    className={`${styles["modal-input"]} ${
                      (loginMessage || (loginUserEmail && !validateEmail(loginUserEmail))) && styles["error"]
                    }`}
                    type="text"
                    name="UserEmail"
                    value={loginUserEmail}
                    onChange={(e) => {
                      dispatch(resetErrorMsg());
                      validateUserInputForEmail(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && validateEmail(loginUserEmail)) {
                        tokenGenerate(e);
                      }
                    }}
                    placeholder="Enter Email Address"
                    autoFocus
                  />
                  {loginMessage && <div className="error-message">{loginMessage}</div>}
                </div>
              )}
              <div>
                <Button
                  type="primary"
                  size="large"
                  disabled={tokenSendSuccess || alreadyHaveToken ? !loginOtp : !validateEmail(loginUserEmail)}
                  block
                  loading={generateTokenRequest || loginAuthenicating}
                  className={styles["btn_login_Or_Token"]}
                  onClick={(e) => (tokenSendSuccess || alreadyHaveToken ? loginWithOtp(e) : tokenGenerate(e))}
                >
                  {tokenSendSuccess || alreadyHaveToken ? "Login" : "Generate Token"}
                </Button>
              </div>
              {(tokenSendSuccess || alreadyHaveToken) && (
                <div
                  className={styles["back_Button"]}
                  onClick={() => {
                    dispatch(loginBack());
                  }}
                >
                  Back
                </div>
              )}
            </React.Fragment>
            {/* <div className={styles["create_Account_Text"]}>
              New to Xpress?<Link to={"/SignUp"}> Create an account </Link>
            </div> */}
          </Col>
        </Row>
        <FooterV2 />
      </Col>
    </Col>
  );
};

export default LoginV2;
