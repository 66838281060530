import React, { useState } from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, DatePicker, Select, TimePicker } from "antd";
import moment from "moment-timezone";
import { disablePastDate, getPastHours, getPastMinutes, getPastSeconds, isSameDate } from "../../../../Util";
import { useDispatch } from "react-redux";
import { changeTestJobScheduleData } from "../../../../actions/TestJobActions";
import { SearchOutlined } from "@ant-design/icons";

const OneTimeSchedule = ({ testJobScheduleDetail, timeZoneList }) => {
  const dispatch = useDispatch();
  const [onFocus, setOnFocus] = useState(false);
  const handleSelectDate = (e) => {
    let date = e;
    let _testJobScheduleDetail = { ...testJobScheduleDetail, scheduleDate: date };
    if (!isSameDate(date)) {
      _testJobScheduleDetail = { ..._testJobScheduleDetail, isValidTime: false };
    }
    dispatch(changeTestJobScheduleData(_testJobScheduleDetail));
  };

  function handleSelectTime(timeString) {
    let _testJobScheduleDetail = { ...testJobScheduleDetail, scheduleTime: timeString, scheduleDateTime: timeString };
    if (isSameDate(_testJobScheduleDetail.scheduleDate) && timeString < moment()) {
      _testJobScheduleDetail = { ..._testJobScheduleDetail, isValidTime: true };
    } else {
      _testJobScheduleDetail = { ..._testJobScheduleDetail, isValidTime: false };
    }
    dispatch(changeTestJobScheduleData(_testJobScheduleDetail));
  }

  return (
    <div>
      <Col span={24} className="font-size-14 mb-20">
        <div className="mb-5 font-blueDark font-weight-600">Date</div>
        <div>
          <DatePicker
            className="cursor-pointer w-40 mr-10 datePicker"
            placeholder="Select Date"
            format={"DD-MM-YYYY"}
            value={testJobScheduleDetail.scheduleDate}
            onChange={handleSelectDate}
          />
        </div>
      </Col>
      <Col span={24} className="font-size-14 mb-20">
        <div className="mb-5 font-blueDark font-weight-600">Time</div>
        <div className="display-flex ">
          <div className="overflow-auto cursor-pointer">
            <TimePicker
              className="border-radius-4"
              placeholder="Set up a Time"
              format="hh:mm:ss A"
              use12Hours
              disabled={testJobScheduleDetail.scheduleDate === undefined}
              value={testJobScheduleDetail.scheduleTime}
              onChange={handleSelectTime}
            />
          </div>
          <div className="ml-20 mt-5 font-blueDark font-weight-400">
            {testJobScheduleDetail.scheduleTime && testJobScheduleDetail.scheduleDate
              ? "UTC Date & Time: " +
                moment(new Date(testJobScheduleDetail.scheduleDateTime))
                  .utcOffset(testJobScheduleDetail.timeZone ? testJobScheduleDetail.timeZone : "00:00")
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss")
              : ""}
          </div>
        </div>
        {testJobScheduleDetail.isValidTime && <Col className="ERROR">*Time must not be in the past.</Col>}
      </Col>
      <Col span={24} className="font-size-14 mb-20">
        <Col className="mb-5 font-blueDark font-weight-600">Timezone</Col>
        <Select
          showSearch
          onFocus={() => setOnFocus(true)}
          onBlur={() => setOnFocus(false)}
          suffixIcon={onFocus && <SearchOutlined className="rotate-180" />}
          className="mb-10 w-40 ant-collapse mr-10"
          placeholder="Select TimeZone"
          value={testJobScheduleDetail.timeZoneName || undefined}
          onChange={(e) => {
            setOnFocus(false);
            dispatch(changeTestJobScheduleData({ ...testJobScheduleDetail, timeZoneName: e }));
          }}>
          {timeZoneList}
        </Select>
      </Col>
    </div>
  );
};

export default OneTimeSchedule;
