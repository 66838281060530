import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import DeviceStringsListV2 from "./DeviceStringsListV2";
import EditOrAddDeviceStringsV2 from "./EditOrAddDeviceStringsV2";

const DeviceStringsV2 = (props) => {
  const { visibleAddDeviceStringForm } = useSelector((state) => state.RecTestStepCommonViewReducer);

  return (
    <Row>
      <Col span={24}>
        {visibleAddDeviceStringForm ? (
          <EditOrAddDeviceStringsV2 projectId={props.projectId} />
        ) : (
          <DeviceStringsListV2 projectId={props.projectId} />
        )}
      </Col>
    </Row>
  );
};

export default DeviceStringsV2;
