import { default as React } from "react";
import { useSelector } from "react-redux";
import { Button, Divider, Dropdown, Menu } from "antd";
import { LOGOUT_ICON, PROFILE_ICON } from "../../CdnImagePath";
import { USER_ICON } from "../../Constants/SvgConstants";
import SvgLoader from "../../Util/SvgLoader";

const UserIconOptions = (props) => {
  const { isPreviewRunning } = props;

  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);
  const menu = (
    <Menu>
      <div className="dd-profile-width">
        <div key={"PROFILE_ICON"} className="dd-profile-header">
          <img src={PROFILE_ICON} alt="down" />
        </div>
        <div className="px-10">
          <div key={"userName"} className={"user-tag-username"}>
            {loggedInUserDetails ? loggedInUserDetails["userName"] : ""}
          </div>

          <div key={"userEmail"} className={"user-tag-email"}>
            {loggedInUserDetails ? loggedInUserDetails["userEmail"] : ""}
          </div>
          <Divider className="m-0" />
        </div>

        <div key={"handleLogout"} className={"logout-button"}>
          <Button type="text" onClick={(e) => props.handleLogout(e)} block>
            <img src={LOGOUT_ICON} alt="down" className="mr-10" />
            {"Logout"}
          </Button>
        </div>
      </div>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      className={isPreviewRunning ? "cursor-not-allowed userprofile-popover pe" : "userprofile-popover pe"}
      placement="bottomRight"
      disabled={isPreviewRunning}
    >
      <div>
        <SvgLoader iconName={USER_ICON} disabled={isPreviewRunning} />
      </div>
    </Dropdown>
  );
};

export default UserIconOptions;
