import { Checkbox, Col, Input, Popover, Row, Spin, Tooltip } from "antd";
import { default as React, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ELEMENT_ACTION, STEP, TOOLTIP_BG_COLOR } from "../../../../Constants";
import {
  getRecElement,
  isActionNeedToSelectElement,
  isNotNullAndNotEmptyAndNotUndefined,
  validForNLP,
} from "../../../../Util";
import { findRecTestStepByTag } from "../../../../reducer/RecTestStepReducer";
import { disableStepSaveButton } from "../../../RecTestStep/ValidateStepMandatoryProperty";
import ActionsVerificationItemsV2 from "../ActionsV2/ActionsVerificationItemsV2/ActionsVerificationItemsV2";
import LaunchAppListV2 from "../ActionsV2/LaunchAppV2/LaunchAppListV2";
import QuickActionsV2 from "../ActionsV2/QuickActionsV2/QuickActionsV2";
import RecTestStepPostActionSettingsV2 from "../RecTestStepPostActionSettingsV2/RecTestStepPostActionSettingsV2";
import RecTestStepElementListV2 from "./RecTestStepElementListV2/RecTestStepElementListV2";
import RecTestStepAIDetail from "./RecTestStepAIDetail/RecTestStepAIDetail";
import { changeExecuteBeforeAiStep, changeIsAIStep, changeUseManualElementSelection, updateAiStepObjective, visibleGeneralSettingsDrawer } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./RecTestStepDetailV2.module.scss";
import SvgLoader from "../../../../Util/SvgLoader";
import { BLACK_COLOR, DISABLE_ICON_COLOR, GENERAL_SETTINGS_ICON, ICON_AI, INFO_ICON_NEW } from "../../../../Constants/SvgConstants";
import ReactJson from "react-json-view";
import { INFO_ICON } from "../../../../CdnImagePath";
import RecStepGeneralSetting from "../../../RecTestStep/StepInnerComponents/RecStepGeneralSetting/RecStepGeneralSetting";

const getActionMapping = (recTestStepProperty) => {
  const mapping = {
    actions: ActionsVerificationItemsV2,
    tap: ActionsVerificationItemsV2,
    longPress: ActionsVerificationItemsV2,
    tapByIndex: ActionsVerificationItemsV2,
    tapByCoordinates: ActionsVerificationItemsV2,
    sendKeys: ActionsVerificationItemsV2,
    clearText: ActionsVerificationItemsV2,
    clear: ActionsVerificationItemsV2,
    swipeOverElement: ActionsVerificationItemsV2,
    launchApp: LaunchAppListV2,
    verifications: ActionsVerificationItemsV2,
    step: ActionsVerificationItemsV2,
    Swipe: QuickActionsV2,
    BACK: QuickActionsV2,
    HOME: QuickActionsV2,
    ENTER: QuickActionsV2,
    deviceInteraction: QuickActionsV2,
    pause: QuickActionsV2,
    insertValueToOutputTestData: QuickActionsV2,
    setAppLocale: QuickActionsV2,
    captureScreenshot: QuickActionsV2,
  };
  return mapping[recTestStepProperty?.action];
};

const RecTestStepDetailV2 = (props) => {
  const dispatch = useDispatch();
  const {
    data,
    disabled,
    projectId,
    isDeviceLoaded,
    isSkipStep,
    recTestSteps,
    isTestBlock,
    selectedRecTestStepDisabled,
    stepId,
  } = props;
  const { findElementProcessing } = useSelector((state) => state.RecordingReducer);
  const { isPreviewRunning, selectedScenarioPlatform, recTestStepList, testBlockStep } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { unSavedRecStepTag, stepType, unSavedRecElementGuid } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  //Destructure of data object
  const { action, isStepHasPrecondition, recStepVerifyElementProperties, isLoopStep, recElements } =
    data && data?.recTestStepProperty ? data?.recTestStepProperty : {};

  const { recTestStepProperty, id, tag } = data ? data : {};

  const unSavedRecTestStep = useMemo(
    () => findRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep),
    [recTestStepList, unSavedRecStepTag, testBlockStep]
  );
  const unSavedRecElement = useMemo(
    () => getRecElement(recElements, unSavedRecElementGuid),
    [recElements, unSavedRecElementGuid]
  );
  let isSameStepForEdit = useMemo(() => unSavedRecStepTag === tag, [unSavedRecStepTag, tag]);
  const saveButtonDisable = useMemo(
    () => !isSameStepForEdit || disableStepSaveButton(unSavedRecTestStep, unSavedRecElement),
    [isSameStepForEdit, unSavedRecTestStep, unSavedRecElement]
  );
  const ActionItem = useMemo(
    () => (data ? getActionMapping(recTestStepProperty) : undefined),
    [data, recTestStepProperty]
  );

  const showPostActionOrSettings = ![ELEMENT_ACTION?.INSERT_VALUE_TO_OUTPUT_TEST_DATA, ELEMENT_ACTION?.PAUSE].includes(
    action
  );

  return (
    <>
      <div>
        <div className={styles["actionSelection"]}>
          <Col span={18}>
            <Spin spinning={id === undefined && findElementProcessing}>
              {ActionItem && (
                <ActionItem
                  recTestSteps={recTestSteps}
                  data={data}
                  disabled={
                    disabled ||
                    isSkipStep ||
                    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== tag) ||
                    selectedRecTestStepDisabled
                  }
                  projectId={projectId}
                  isDeviceLoaded={isDeviceLoaded}
                  isSkipStep={isSkipStep}
                  isPreviewRunning={isPreviewRunning}
                  isTestBlock={isTestBlock}
                  stepType={stepType}
                  platform={selectedScenarioPlatform}
                  showPostActionOrSettings={showPostActionOrSettings}
                  stepId={stepId}
                />
              )}
            </Spin>
          </Col>
          <Col span={4}>
          {data.recTestStepProperty.aiGeneratedScreenshot && data.recTestStepProperty.isAIStep==1 && 
                <Popover
                    content={<img src={data.recTestStepProperty.aiGeneratedScreenshot} height={450} weight={225} alt="Screenshot" />}
                    placement="left"
                    title="Element Image"
                    trigger="hover"
                >
              <img className={styles.elementImage} src={data.recTestStepProperty.aiGeneratedScreenshot} height={50} weight={50} alt="Screenshot" />
              </Popover>
          }
          {data.recTestStepProperty.aiGeneratedPagesource && 
            <a href={data.recTestStepProperty.aiGeneratedPagesource} target="_blank">
              Pagesource
            </a>
          }
          </Col>
          {data.recTestStepProperty.isAIStep==0 && data.recTestStepProperty.aiGeneratedStep && 
            <Col span={2}>
              <Popover overlayStyle={{ width: "60%" }} content={
                <div>
                  <ReactJson src={JSON.parse(data.recTestStepProperty.aiGeneratedStep)} />
                </div>
                } title="AI Generated Step">
                <span>
                    <SvgLoader
                    iconName={INFO_ICON_NEW}
                    infoIcon={true}
                  />
                </span>
                </Popover>
            </Col>
          }
        </div>
        {validForNLP(data.recTestStepProperty.action) && (
          <>
              <Row>
                <Col>
                  <Checkbox
                    disabled={disabled}
                    checked={data.recTestStepProperty.isAIStep}
                    onChange={(e) => {
                      dispatch(changeIsAIStep(e.target.checked, data));
                    }}
                    className="selection-checkBox"
                  />
                  <span className={styles["checkbox-Text"]}>Use NLP (Natural Language Processing)</span>
                </Col>
                <Tooltip title={"Settings"} color={TOOLTIP_BG_COLOR}>
                <Col className={styles.settingsIcon}>
                  <SvgLoader
                    iconName={GENERAL_SETTINGS_ICON}
                    iconColor={isPreviewRunning ? DISABLE_ICON_COLOR : BLACK_COLOR}
                    handleClick={() =>
                      !isPreviewRunning &&
                      dispatch(visibleGeneralSettingsDrawer(true, data.recTestStepProperty?.action, data?.tag))
                    }
                    disabled={isPreviewRunning}
                  />
                </Col>
              </Tooltip>
            </Row>
            {data.recTestStepProperty.isAIStep == 1 ? <Row>
              <Col span="15">
              <span className={styles["checkbox-Text"]}>Step Objective:</span>
              <Input
                  type="text"
                  autoFocus
                  disabled={disabled || data.recTestStepProperty.isAIStep==0}
                  className="w-400 input-text-box"
                  value={data.recTestStepProperty?.aiStepObjective}
                  onChange={(e) => {
                    dispatch(updateAiStepObjective(e.target.value,data?.tag));
                  }}
                  placeholder={"Step Objective"}
                />
              </Col>
            </Row>:""
            }
            <Row>
              <Col span="15">
            {data.recTestStepProperty.isAIStep == 1 ?
              <>
                <RecTestStepAIDetail {...props} saveButtonDisable={saveButtonDisable} />
                <span>or will execute by element selector</span>
              </>
            :""
            }
            </Col>
            </Row>
        </>
        )}
        {(isActionNeedToSelectElement(action) ||
          (action === STEP &&
            ((isStepHasPrecondition === 1 &&
              recStepVerifyElementProperties?.some((i) => i?.type === "VerifyExistence")) ||
              isLoopStep === 1))) &&
              <>
              <Row>
                <Col>
                  <Checkbox
                    disabled={disabled}
                    checked={data.recTestStepProperty.useManualElementSelection}
                    onChange={(e) => {
                      dispatch(changeUseManualElementSelection(e.target.checked, data?.tag));
                    }}
                    className="selection-checkBox"
                  />
                  <span className={styles["checkbox-Text"]}>Use Manual Element Selection</span>
                </Col>
                <Col className="ml-10">
                {data.recTestStepProperty.useManualElementSelection==1 && 
                    <>
                      <Checkbox
                        disabled={disabled || data.recTestStepProperty.isAIStep==0}
                        checked={data.recTestStepProperty.executeBeforeAiStep}
                        onChange={(e) => {
                          dispatch(changeExecuteBeforeAiStep(e.target.checked, data?.tag));
                        }}
                        className="selection-checkBox"
                      />
                      <span className={styles["checkbox-Text"]}>Execute before AI</span>
                    </>
                }
                </Col>
              </Row>
              {data.recTestStepProperty.useManualElementSelection==1 && 
                <>  
                  <div className={styles["selectorOrSettings"]}>Element Selector</div>
                  <RecTestStepElementListV2 {...props} saveButtonDisable={saveButtonDisable} />
                </>
              }
              </>
          }

        {action !== ELEMENT_ACTION.PAUSE && (
          <RecTestStepPostActionSettingsV2
            data={props.data}
            disabled={disabled}
            projectId={projectId}
            isSkipStep={isSkipStep}
          />
        )}
      </div>
      <RecStepGeneralSetting
        data={data}
        disabled={disabled}
        disableMaxNoOfSwipes={disabled || isSkipStep || isPreviewRunning}
        isPreviewRunning={isPreviewRunning}
        isSkipStep={isSkipStep}
        saveButtonDisable={saveButtonDisable}
      />
    </>
  );
};

export default RecTestStepDetailV2;
