import { Tooltip } from "antd";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TEST_SCENARIOS, TOOLTIP_BG_COLOR } from "../../../Constants";
import { ACTION_ICON_COLOR, BUILD_DATA_SET_ICON, DISABLE_ICON_COLOR } from "../../../Constants/SvgConstants";
import { BUILD_TEST_DATA_SET } from "../../../Constants/TooltipConstants";
import SvgLoader from "../../../Util/SvgLoader";
import { setTestDataSetAssigneeDetail } from "../../../actions/TestDataSet/TestDataSetCommonAction";
import { fetchTestDataSetByProfileId } from "../../../actions/TestDataSet/TestDataSetRedirectAction";
import styles from "./EditTestHeaderOptionsV2.module.scss";

const TestDataProfileButtonV2 = ({ disabled, previewRunOrBuildTestDataSetDisable, addOrImportDisabled }) => {
  const dispatch = useDispatch();
  const { selectedRecTestScenarioName, selectedRecTestScenarioId, isPreviewRunning } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { testBlockStep } = useSelector((state) => state.TestBlockReducer);
  const { applicationStringAssigneeDetail, testDataSetAssigneeDetail } = useSelector(
    (state) => state.TestDataSetReducer
  );

  useEffect(() => {
    if (testDataSetAssigneeDetail?.id) {
      dispatch(fetchTestDataSetByProfileId(testDataSetAssigneeDetail?.id));
    }
  }, []);

  const handleTestDataSetAssigneeDetail = useCallback(() => {
    if (stepType === TEST_SCENARIOS) {
      if (!previewRunOrBuildTestDataSetDisable) {
        dispatch(setTestDataSetAssigneeDetail({ id: selectedRecTestScenarioId, name: selectedRecTestScenarioName }));
      }
    } else {
      if (!testDataSetAssigneeDetail && !isPreviewRunning && !applicationStringAssigneeDetail && !addOrImportDisabled) {
        dispatch(setTestDataSetAssigneeDetail(testBlockStep));
      }
    }
  }, [
    dispatch,
    stepType,
    previewRunOrBuildTestDataSetDisable,
    selectedRecTestScenarioId,
    selectedRecTestScenarioName,
    testBlockStep,
    testDataSetAssigneeDetail,
    isPreviewRunning,
    applicationStringAssigneeDetail,
    addOrImportDisabled,
  ]);

  return (
    <>
      <Tooltip title={BUILD_TEST_DATA_SET} color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
        <div className={styles["buttonForActionStep"]}>
          <div onClick={handleTestDataSetAssigneeDetail}>
            <SvgLoader
              iconName={BUILD_DATA_SET_ICON}
              iconColor={disabled ? DISABLE_ICON_COLOR : ACTION_ICON_COLOR}
              width="1.87rem"
              height="1.87rem"
              disabled={previewRunOrBuildTestDataSetDisable}
            />
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default TestDataProfileButtonV2;
