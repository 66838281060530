import React from "react";
import { useDispatch } from "react-redux";
import { Popover } from "antd";
import isEmpty from "lodash/isEmpty";
import { defaultDateTimeFormat } from "../../../../Constants";
import { displayUTCDateFormatWithToolTip, openTestPlanResultDetails } from "../../../../Util";
import ShowTooltipV2 from "../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import styles from "./TestPlanDetailsColumnsV2.module.scss";

const JobRunningThisPlanColumnV2 = ({ row, projectId, orgId }) => {
  const dispatch = useDispatch();

  const showJobName = (job) => {
    return <ShowTooltipV2 text={job} className={styles["job_Name"]} />;
  };

  const showListOfJobs = (jobs) => {
    const firstJob = jobs[0];
    const remainingJobs = jobs.slice(1);
    if (jobs?.length <= 1) {
      return <span className={styles.data}>{!isEmpty(jobs) ? showJobName(firstJob.name) : "NA"}</span>;
    } else {
      return (
        <div className={styles["popover_Name"]}>
          {showJobName(firstJob.name)}
          {remainingJobs.length > 0 && (
            <Popover
              content={remainingJobs?.map((job, index) => (
                <div
                  className={`${styles["popover-contents"]} ${
                    index !== remainingJobs.length - 1 && styles["border-bottom"]
                  }`}
                  key={job.id}
                >
                  <span className={styles["jobsInPopover"]}>{job.name}</span>
                  <div className={styles["jobIdOrName"]}>
                    <span>Job : </span>
                    <span className={styles["jobId"]}>{job.id}</span>
                  </div>
                </div>
              ))}
              title="Jobs"
              placement="bottom"
            >
              <div className={styles["popover-details"]}>
                {remainingJobs.length === 1 ? `+${remainingJobs.length} other` : `+${remainingJobs.length} others`}
              </div>
            </Popover>
          )}
        </div>
      );
    }
  };

  return (
    <div>
      <div className="display__flex">
        <div>
          <span className={styles["label-name"]}>Total: </span>
          <span className={styles["step-And-Test-Plan-Details"]}> {row?.listOfJobs?.length}</span>
          <div className={styles["step-And-Test-Plan-Details"]}>
            {row?.listOfJobs && showListOfJobs(row?.listOfJobs)}
          </div>
        </div>
      </div>
      <div className={`${styles["step-And-Test-Plan-Details"]} ${styles["black_Color"]}`}>Most Recent Execution</div>
      <div>
        <span className={styles["label-name"]}>Job Name: </span>

        {row?.lastExecutedJob !== null ? (
          <span className={styles["step-And-Test-Plan-Details"]}>{showJobName(row?.lastExecutedJob)}</span>
        ) : (
          "NA"
        )}
      </div>
      <div>
        <span className={styles["label-name"]}>Last Job Execution: </span>
        <span
          className={`${styles["step-And-Test-Plan-Details"]} ${
            row.runNumber ? styles["last-Job-Execution-Text"] : ""
          }`}
          onClick={() => row.runNumber && openTestPlanResultDetails(dispatch, orgId, projectId, row.runNumber)}
        >
          {row.runNumber ? `#${row.runNumber}` : "N/A"}
        </span>
      </div>
      <div>
        <span className={styles["label-name"]}>Executed On: </span>

        {row?.lastExecuted != null ? (
          <span className={styles["step-And-Test-Plan-Details"]}>
            {displayUTCDateFormatWithToolTip(row?.lastExecuted, defaultDateTimeFormat)}{" "}
          </span>
        ) : (
          "NA"
        )}
      </div>
    </div>
  );
};

export default JobRunningThisPlanColumnV2;
