import { default as React, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MY_PROJECTS,
  PRE_SIGNED_URL_EXPIRATION_DAYS,
  PROJECT,
  TEAM_PROJECTS,
  xpressComponentType,
} from "../../../Constants";
import { isNullOrUndefinedOrEmpty, showNotification } from "../../../Util";
import * as commonActions from "../../../actions/CommonActions";
import { getInvitationList } from "../../../actions/InvitationAction";
import { myProjects, saveProject, visibleCreateProjectModal } from "../../../actions/ProjectsAction";
import { getMyOrgUsers } from "../../../actions/TeamsAction";
import CommonModalV2 from "../../CommonComponents/CommonModalV2/CommonModalV2";
import HeaderButtonsGroupV2 from "../../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import VerticalLine from "../../CommonComponents/VerticalLine";
import HeaderComponent from "../../Header/Header";
import CreateProjectV2 from "./CreateProjectV2/CreateProjectV2";
import ProjectCardsV2 from "./ProjectCardsV2";
import ProjectsHeaderV2 from "./ProjectsHeaderV2";
import styles from "./ProjectDetailsV2.module.scss";

const filterProjects = [
  { id: MY_PROJECTS, value: MY_PROJECTS },
  { id: TEAM_PROJECTS, value: TEAM_PROJECTS },
];
const initialState = {
  name: "",
  appName: "",
  description: "",
  teamMembers: [],
  orgMembers: [],
  teamId: null,
  platformTypeId: null,
};

export const MyProjectsPageV2 = (props) => {
  const dispatch = useDispatch();

  const { resendInvitation, deleteInvitation } = useSelector((state) => state.InvitationReducer);
  const { inviteMembers, createProjectModalVisible } = useSelector(
    (state) => state.ProjectsReducer
  );

  const [projectData, setProjectData] = useState(initialState);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentOptions, setCurrentOptions] = useState({ id: MY_PROJECTS, value: MY_PROJECTS });
  const [filter, setFilter] = useState("");

  useEffect(() => {
    dispatch(getInvitationList(orgId));
    if (props.route && props.route.componentId) {
      dispatch(
        commonActions.getChildComponentByTypeAndParentId({
          componentType: xpressComponentType.rights,
          parentId: props.route.componentId,
        })
      );
    }
  }, [inviteMembers, resendInvitation, deleteInvitation, dispatch]);

  useEffect(() => {
    if (orgId) {
      dispatch(myProjects(orgId, "", searchKeyword, filter));
      dispatch(getMyOrgUsers(orgId));
    }
  }, [filter]);

  const orgId = props.match.params && props.match.params.organizationId;

  const handleOnChange = useCallback(
    (value) => {
      setCurrentOptions(value);
      setFilter(value?.value === TEAM_PROJECTS ? "team" : "");
    },
    [currentOptions, setCurrentOptions]
  );
  
  const isDisable = useMemo(() => {
    return (
      isNullOrUndefinedOrEmpty(projectData?.appName) ||
      isNullOrUndefinedOrEmpty(projectData?.name) ||
      isNullOrUndefinedOrEmpty(projectData?.platformTypeId)
    );
  }, [projectData]);

  const handleSaveButton = useCallback(() => {
    if (projectData?.name.length > 0) {
      let preparedObject = {
        ...{ ...projectData, 
          description: projectData?.description !== "" ? projectData?.description : null },
          orgId: orgId,
          preSignedUrlExpirationDays: PRE_SIGNED_URL_EXPIRATION_DAYS,
      };
      dispatch(saveProject(preparedObject));
    } else {
      showNotification("error", "Please Insert Project Name.", "");
    }
    setProjectData(initialState);
  }, [projectData, orgId, dispatch, showNotification, visibleCreateProjectModal, initialState]);

  return (
    <>
      <HeaderComponent showHumbergerMenu={true} buttonState={PROJECT} organizationId={orgId} />
      <>
        <ProjectsHeaderV2
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          orgId={orgId}
          filter={filter}
        />
        <div className={styles["projects-details"]}>
          <>
            <div className={styles["header-buttons"]}>
              <HeaderButtonsGroupV2
                data={filterProjects}
                onClick={(e) => handleOnChange(e)}
                activeButton={currentOptions?.id}
              />
            </div>
            <VerticalLine className={styles["border-bottom"]} />
            <div>
              <ProjectCardsV2 orgId={orgId} filter={filter} />
            </div>
          </>
        </div>
      </>
      <CommonModalV2
        modalVisible={createProjectModalVisible}
        modalClose={() => {
          setProjectData(initialState);
          dispatch(visibleCreateProjectModal(false));
        }}
        cancelText="Cancel"
        saveBtnDisabled={isDisable}
        handleSubmit={() => handleSaveButton()}
        children={<CreateProjectV2 orgId={orgId} projectData={projectData} setProjectData={setProjectData} />}
      />
    </>
  );
};

export default MyProjectsPageV2;
