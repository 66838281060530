import React from "react";
import { Table } from "antd";
import { CLOSE_COLLAPSE_ICON_FOR_TABLE, OPEN_COLLAPSE_ICON_FOR_TABLE } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";

const TableComponentV2 = (props) => {
  const {
    data,
    columns,
    handleSelectRow,
    selected,
    selectedRowId,
    testDataSetModal,
    className,
    rowExpandable,
    handleExpand,
  } = props;

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        expandable={
          rowExpandable && {
            expandedRowRender: (record) =>
              rowExpandable && (
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  {record.description}
                </p>
              ),

            expandIcon: ({ expanded, onExpand, record }) => (
              <SvgLoader
                iconName={expanded ? OPEN_COLLAPSE_ICON_FOR_TABLE : CLOSE_COLLAPSE_ICON_FOR_TABLE}
                handleClick={(e) => {
                  onExpand(record, e);
                  if (!expanded) {
                    handleExpand(record);
                  }
                }}
              />
            ),
          }
        }
        onRow={handleSelectRow}
        pagination={false}
        rowClassName={(record) =>
          selected?.includes(record?.key) || selectedRowId?.includes(record?.key) ? "activeRow" : ""
        }
        scroll={{
          x: testDataSetModal && 1000,
          y: "60vh",
        }}
        className={className}
      />
    </>
  );
};

export default TableComponentV2;
