import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Checkbox, Col, Row, Select, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { APPLICATION_STRING_TAB, SUCCESS_TYPE, TOOLTIP_BG_COLOR } from "../../../Constants";
import { CREATE_ICON, SILVER_GRAY, TRASH_ICON, WHITE_COLOR } from "../../../Constants/SvgConstants";
import { showNotification } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import {
  addEmptyLocaleOptionForImport,
  changeRemoveAppStringFromLocale,
  importApplicationStringsFormVisible,
  removeEmptyLocaleOptionForImport,
  selectApplicationStringFileForImport,
  selectApplicationStringLocaleForImport,
  uploadApplicationStringsXml,
} from "../../../actions/ApplicationStrings";
import { getListOfLocalesForApplicationStrings } from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import CommonSelectBoxV2 from "../../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import CustomButtonV2 from "../../CommonComponents/CustomButtonV2/CustomButtonV2";
import UploadFilesV2 from "../../CommonComponents/UploadFilesV2/UploadFilesV2";
import SaveResetButtonV2 from "../../CoreComponents/SaveResetButtonV2/SaveResetButtonV2";
import styles from "./ApplicationStringsV2.module.scss";

const ImportApplicationStringsV2 = (props) => {
  const { projectId,setActiveFilter } = props;
  const dispatch = useDispatch();

  const { getListOfLocales } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { importApplicationStringsData, removePreviousDataFromLocale } = useSelector(
    (state) => state.ApplicationStringReducer
  );

  const onBackClick = () => {
    dispatch(importApplicationStringsFormVisible(false));
  };
  const [fileList, setFileList] = useState([]);
  const [addNewBuild, setAddNewBuild] = useState(false);
  const [selectStringsIndex, setSelectStringsIndex] = useState([]);

  const addFileIntoList = (file, guid) => {
    dispatch(selectApplicationStringFileForImport(file, guid));
    setFileList((prev) => {
      return {
        ...prev,
        [guid]: file,
      };
    });
  };

  useEffect(() => {
    if (!isEmpty(importApplicationStringsData) && importApplicationStringsData.every((i) => i?.status === "done")) {
      const intervalId = setInterval(() => {
        onBackClick();
        showNotification(SUCCESS_TYPE, "Strings have been uploaded successfully", true);
        setActiveFilter({ id: APPLICATION_STRING_TAB.ALL, value: "All" })
      }, 500);
      return () => clearInterval(intervalId);
    }
  }, [importApplicationStringsData]);

  useEffect(() => {
    Object.keys(fileList).forEach((key) => {
      if (importApplicationStringsData.filter((i) => i.guid === key).length === 0) {
        delete fileList[key];
      }
    });
    importApplicationStringsData.forEach((item) => {
      if (fileList[item?.guid]) {
        fileList[item?.guid][0].status = item?.status;
      }
    });
  }, [importApplicationStringsData, fileList]);

  useEffect(() => {
    dispatch(getListOfLocalesForApplicationStrings());
  }, []);

  const uploadApplicationStringLoader =
    importApplicationStringsData.filter((i) => i?.status === "uploading").length > 0;
  const uploadIsDone =
    importApplicationStringsData.filter((i) => i?.status === "done").length === importApplicationStringsData.length;

  return (
    <>
      <Row justify="space-between" align="middle" className={styles["main_Header"]}>
        <Col className={styles["main-Header-Text"]}>Import Application Strings</Col>
        <Col>
          <SaveResetButtonV2
            submitLabel="Upload"
            resetLabel={uploadIsDone ? "Close" : "Cancel"}
            submitDisable={
              uploadIsDone ||
              uploadApplicationStringLoader ||
              importApplicationStringsData.some((i) => i?.appFile === undefined)
            }
            resetDisable={uploadApplicationStringLoader}
            handleSubmit={() => dispatch(uploadApplicationStringsXml())}
            handleReset={onBackClick}
          />
        </Col>
      </Row>

      <Row className={styles["import_Application_Strings"]} justify="center">
        <Col span={24}>
          <div className={styles["checkbox_And_Text"]}>
            <Checkbox
              className="selection-checkBox"
              checked={removePreviousDataFromLocale === 1}
              onChange={(e) => dispatch(changeRemoveAppStringFromLocale(e.target.checked === true ? 1 : 0))}
            />
            Remove Previous Value for Locale
          </div>
          <div className={styles["add_Application_String_Scroll"]}>
            <Row className={styles["import_XML_Files"]}>
              <Col span={8}>
                <span></span>
              </Col>
              <Col span={11}>
                <span>XML File</span>
              </Col>
              <Col span={4}>
                <span>Action</span>
              </Col>
            </Row>
            {importApplicationStringsData?.map((row, index) => {
              return (
                <Row key={row?.guid} className={styles["import_XML_Files"]}>
                  <Col span={8}>
                    <CommonSelectBoxV2
                      label="Locale"
                      labelBackgroundClass={"label_Background"}
                      options={
                        getListOfLocales &&
                        getListOfLocales?.map((i) => (
                          <Select.Option key={i?.locales} value={i?.locales}>
                            {i?.locales}
                          </Select.Option>
                        ))
                      }
                      value={row?.locale}
                      placeholder="Select Application String Locale"
                      handleChange={(e) => {
                        dispatch(selectApplicationStringLocaleForImport(e, row?.guid));
                      }}
                    />
                  </Col>
                  <Col span={11}>
                    <UploadFilesV2
                      addNewBuild={addNewBuild}
                      setAddNewBuild={setAddNewBuild}
                      existingBuildName={
                        fileList[row?.guid] && fileList[row?.guid].length > 0 ? fileList[row?.guid][0].name : undefined
                      }
                      setFileList={(file) => {
                        addFileIntoList(file, row?.guid);
                      }}
                      fileList={fileList[row?.guid]}
                      placeholder={"Upload XML File"}
                      tooltip="Upload XML File"
                      acceptFileTypes=".xml"
                      setSelectStringsIndex={setSelectStringsIndex}
                      index={index}
                      selectStringsIndex={selectStringsIndex}
                    />
                  </Col>
                  <Col span={4}>
                    {row.status === undefined ? (
                      <Tooltip title="Remove" color={TOOLTIP_BG_COLOR}>
                        <div className={styles["action_Icon"]}>
                          <SvgLoader
                            iconName={TRASH_ICON}
                            iconColor={SILVER_GRAY}
                            handleClick={() => dispatch(removeEmptyLocaleOptionForImport(row?.guid))}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <span className={row.status === "done" ? styles["uploaded_Text"] : styles["uploading_Text"]}>
                        <Badge className={row.status === "done" ? styles["uploaded_Badge"] : ""} />
                        {row.status === "done" ? "Uploaded" : "Uploading.."}
                      </span>
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>

          <CustomButtonV2
            iconColor={WHITE_COLOR}
            buttonIcon={CREATE_ICON}
            buttonLabel="Add Locale"
            handleClick={() => dispatch(addEmptyLocaleOptionForImport(projectId))}
            className="common_Green_Button"
          />
        </Col>
      </Row>
    </>
  );
};

export default ImportApplicationStringsV2;
