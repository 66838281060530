import React from "react";
import styles from "./CommonSelectBoxV2.module.scss";
import { Select } from "antd";

const CommonSelectBoxV2 = (props) => {
  const {
    label,
    labelBackgroundClass,
    required,
    options,
    value,
    placeholder,
    handleChange,
    mode = "",
    tokenSeparators = "",
    disabled = false,
    defaultValue = null,
  } = props;
  return (
    <div className={styles["custom_Input_Wrapper"]}>
      <div
        className={`${styles["custom_Input_Label"]} ${labelBackgroundClass ? labelBackgroundClass : ""} 
        `}
      >
        {label}
        {required && <span className={styles["field_Required"]}>*</span>}
      </div>

      <Select
        className={`${styles["modal-select-box"]} ${mode ? "select_Selection_Overflow" : ""} table_SelectBox `}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        mode={mode}
        tokenSeparators={tokenSeparators}
        disabled={disabled}
        defaultValue={defaultValue}
      >
        {options}
      </Select>
    </div>
  );
};

export default CommonSelectBoxV2;
