import { Button, Col, Row, Select, Space, Spin } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_EXECUTION_FARM } from "../../Constants";
import { disableValidateCredential, isResetDisableForCloudTesting } from "../../Util";
import {
  getExternalFarmConfig,
  getExternalFarmDataCenterList,
  handleDisableButton,
  setExternalFarmValidation,
  updateExternalFarmDetail,
  validateExternalFarmCredentials,
} from "../../actions/ProjectSettingsActions";
import CommonInputFieldV2 from "../CommonComponents/CommonInputFieldV2/CommonInputFieldV2";
import CommonSelectBoxV2 from "../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import ErrorComponent from "../CoreComponents/ErrorComponent/ErrorComponent";
import SaveResetButtonV2 from "../CoreComponents/SaveResetButtonV2/SaveResetButtonV2";
import styles from "./ProjectSettingsV2.module.scss";

const initialLambdaTestDetails = {
  userName: "",
  accessKey: "",
  dataCenter: "",
};

const { Option } = Select;

const LambdaTestV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId } = props;
  const {
    externalFarmDataCenterList,
    externalFarmDetails,
    isDisabled,
    isValidateExternalFarmCredentials,
    projectLoading,
    externalFarmLoading,
    externalFarmName,
  } = useSelector((state) => state.ProjectSettingsReducer);

  const [lambdaTestDetails, setLambdaTestDetails] = useState(initialLambdaTestDetails);
  const [error, setError] = useState(false);

  //Destructure of objects
  const { userName, accessKey, dataCenter } = lambdaTestDetails || {};
  const {
    userName: externalUserName,
    accessKey: externalAccessKey,
    dataCenter: externalDataCenter,
  } = externalFarmDetails || {};

  useEffect(() => {
    dispatch(getExternalFarmDataCenterList(DEVICE_EXECUTION_FARM.LAMBDA_TEST));
    dispatch(getExternalFarmConfig(projectId, DEVICE_EXECUTION_FARM.LAMBDA_TEST));
  }, [projectId]);

  useEffect(() => {
    if (!isEmpty(externalFarmDetails) && externalFarmName === DEVICE_EXECUTION_FARM.LAMBDA_TEST) {
      setLambdaTestDetails({
        userName: externalUserName,
        accessKey: externalAccessKey,
        dataCenter: externalDataCenter,
      });
      dispatch(handleDisableButton(true));
    }
  }, [externalFarmDetails]);

  useEffect(() => {
    setLambdaTestDetails(initialLambdaTestDetails);
  }, [projectId]);

  useEffect(() => {
    if (isValidateExternalFarmCredentials) {
      dispatch(handleDisableButton(false));
    } else {
      dispatch(handleDisableButton(true));
    }
  }, [isValidateExternalFarmCredentials]);

  useEffect(() => {
    if (projectLoading) {
      dispatch(handleDisableButton(true));
    }
  }, [projectLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLambdaTestDetails({ ...lambdaTestDetails, [name]: value });
    dispatch(setExternalFarmValidation(false));
    setError(false);
  };

  const handleDataCenterChange = (e) => {
    setLambdaTestDetails({ ...lambdaTestDetails, dataCenter: e });
    setError(false);
  };

  const saveLambdaTestDetail = () => {
    if (lambdaTestDetails && isValidateExternalFarmCredentials) {
      dispatch(
        updateExternalFarmDetail(
          {
            userName,
            accessKey,
            dataCenter,
            id: externalFarmDetails ? externalFarmDetails?.id : 0,
            project: { id: parseInt(projectId) },
            farmName: DEVICE_EXECUTION_FARM.LAMBDA_TEST,
          },
          DEVICE_EXECUTION_FARM.LAMBDA_TEST
        )
      );
      dispatch(handleDisableButton(true));
    }
  };

  const resetLambdaTestDetails = () => {
    if (!isEmpty(externalFarmDetails)) {
      setLambdaTestDetails({
        userName: externalUserName,
        accessKey: externalAccessKey,
        dataCenter: externalDataCenter,
      });
    } else {
      setLambdaTestDetails(initialLambdaTestDetails);
    }
    dispatch(setExternalFarmValidation(false));
    setError(false);
    dispatch(handleDisableButton(true));
  };

  let dataCenterList = [];
  if (!isEmpty(externalFarmDataCenterList)) {
    externalFarmDataCenterList?.map((data) => {
      dataCenterList.push(<Option key={data?.key}>{data?.key}</Option>);
    });
  }

  const inputFields = [
    {
      label: "Username",
      name: "userName",
      placeholder: "Please Enter Username",
      value: userName,
      autoFocus: true,
    },
    {
      label: "Access Key",
      name: "accessKey",
      placeholder: "Please Enter Access Key",
      value: accessKey,
    },
  ];

  return (
    <Spin spinning={projectLoading || externalFarmLoading} tip="Loading">
      <Row justify="space-between" align="middle" className={styles["main_Header"]}>
        <Col>
          <div className={styles["main-Header-Text"]}>LambdaTest</div>
        </Col>
        <Col>
          <Space>
            <SaveResetButtonV2
              submitLabel={isEmpty(externalFarmDetails) ? "Save" : "Update"}
              resetLabel={"Reset"}
              submitDisable={isDisabled}
              resetDisable={isResetDisableForCloudTesting(lambdaTestDetails, externalFarmDetails)}
              handleSubmit={saveLambdaTestDetail}
              handleReset={resetLambdaTestDetails}
            />
          </Space>
        </Col>
      </Row>
      <Row justify="center" className={styles["page_Scroll"]}>
        <Col xs={{ span: 24 }} lg={{ span: 14 }} className={styles["project_Details"]}>
          <Row gutter={50} justify="center">
            <Col span={20}>
              <div className={styles["header_Title"]}>Basic Details</div>
              {inputFields.map((field, index) => (
                <CommonInputFieldV2
                  key={index}
                  label={field.label}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={field.value}
                  handleChange={handleChange}
                  labelBackgroundClass="label_Background"
                  required={true}
                  autoFocus={field.autoFocus}
                />
              ))}
              <CommonSelectBoxV2
                label="Data Center"
                labelBackgroundClass={"label_Background"}
                options={dataCenterList}
                value={dataCenter || undefined}
                placeholder="Select Data Center"
                handleChange={(e) => handleDataCenterChange(e)}
                required={true}
              />
              <Button
                className={styles["Search_And_validate_Btn"]}
                onClick={() =>
                  dispatch(
                    validateExternalFarmCredentials(
                      userName,
                      accessKey,
                      dataCenter,
                      DEVICE_EXECUTION_FARM.LAMBDA_TEST,
                      setError
                    )
                  )
                }
                disabled={disableValidateCredential(lambdaTestDetails, externalFarmDetails)}
              >
                Validate credentials
              </Button>
              {error && (
                <div className="mt-10">
                  <ErrorComponent errorMessage="Please check credentials are invalid." />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default LambdaTestV2;
