import React from "react";
import { useDispatch } from "react-redux";
import { Button, Tooltip } from "antd";
import { TOOLTIP_BG_COLOR } from "../../../../../../../Constants";
import { MANAGE_KEYS } from "../../../../../../../Constants/TooltipConstants";
import { resetManageKeysData } from "../../../../../../../actions/TestDataSetAction";
import styles from "../AllTestDataSetV2.module.scss";

const TestDataSetsColumn = ({ testDataSet, setManageKeys }) => {
  const { id, count, uniqueTestDataCount } = testDataSet || {};
  const dispatch = useDispatch();

  return (
    <div className={styles["test-data-set-List"]}>
      {id ? (
        <div>
          <span className={styles["label"]}>Steps with Keys: </span>
          <span className={styles["label-name"]}>{count}</span>
        </div>
      ) : (
        <div>
          <span className={styles["label"]}>Key/Value Pairs: </span>
          <span className={styles["label-name"]}>{uniqueTestDataCount}</span>
        </div>
      )}
      {!id && (
        <span className={styles['margin-5']}>
          <Tooltip color={TOOLTIP_BG_COLOR} title={MANAGE_KEYS}>
            <Button
              onClick={() => {
                setManageKeys(true);
                dispatch(resetManageKeysData());
              }}
            >
              Manage Keys
            </Button>
          </Tooltip>
        </span>
      )}
    </div>
  );
};

export default TestDataSetsColumn;