import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { ACTION_VIEW, CUSTOM, PRIVATE, PUBLIC, TOOLTIP_BG_COLOR } from "../../../../Constants";
import { ICON_EDIT_NEW, SILVER_GRAY, TRASH_ICON, UPLOAD_BUILD_ICON } from "../../../../Constants/SvgConstants";
import SvgLoader from "../../../../Util/SvgLoader";
import { showInstallBuildOnDevicesModal } from "../../../../actions/Recording";
import ActionComponentV2 from "../../../CoreComponents/ActionComponentV2/ActionComponentV2";
import styles from "./AppBuildListColumnsV2.module.scss";

const AppBuildListActionColumnV2 = (props) => {
  const dispatch = useDispatch();
  const { row, projectId, fetchAppBuildDataById, deleteConfirm, handleActionChange } = props;
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  const handleAction = (row) => {
    return [
      {
        title: "Permissions",
        name: "permissions",
        children: [
          ((row?.isPrivate !== 1 && loggedInUserDetails?.userEmail === row?.uploadedByEmail) ||
            (row?.isPrivate === 1 &&
              !isEmpty(row?.buildDetailRights?.data) &&
              loggedInUserDetails?.userEmail === row?.uploadedByEmail)) && {
            title: "Make Private",
            name: PRIVATE,
          },
          row?.isPrivate !== 0 && {
            title: "Make Public",
            name: PUBLIC,
          },
          {
            title: "Custom",
            name: CUSTOM,
          },
        ],
      },
    ];
  };

  return (
    <div className={` ${row?.uploadStatus === "In-Progress" ? styles["in_Progress_Loader"] : styles["action-icon"]}`}>
      {row?.uploadStatus === "In-Progress" ? (
        <Spin spinning={true}></Spin>
      ) : (
        <>
          <Tooltip color={TOOLTIP_BG_COLOR} title="Install Build" mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div className={styles["app_Build__Buttons"]}>
              <SvgLoader
                iconName={UPLOAD_BUILD_ICON}
                handleClick={() =>
                  row?.uploadStatus === "Done" && dispatch(showInstallBuildOnDevicesModal(projectId, row))
                }
              />
            </div>
          </Tooltip>{" "}
          {row?.allowEdit === ACTION_VIEW && (
            <Tooltip color={TOOLTIP_BG_COLOR} title="Edit" mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div className={styles["app_Build__Buttons"]}>
                <SvgLoader
                  iconName={ICON_EDIT_NEW}
                  handleClick={() => row?.uploadStatus === "Done" && fetchAppBuildDataById(row?.id)}
                />
              </div>
            </Tooltip>
          )}
          {row?.allowDelete === ACTION_VIEW && (
            <Tooltip color={TOOLTIP_BG_COLOR} title="Delete" mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div className={styles["app_Build__Buttons"]}>
                <SvgLoader
                  iconName={TRASH_ICON}
                  iconColor={SILVER_GRAY}
                  handleClick={(e) =>
                    row?.uploadStatus === "Done" &&
                    deleteConfirm(row?.displayName, [
                      {
                        id: row?.id,
                        folderPath: row?.folderPath,
                        fileKey: row?.fileKey,
                        awsDeviceFarmProjectId: row?.awsDeviceProjectId,
                      },
                    ])
                  }
                />
              </div>
            </Tooltip>
          )}
          {(loggedInUserDetails?.userEmail === row?.uploadedByEmail || row?.allowAdmin === ACTION_VIEW) && (
            <ActionComponentV2
              actions={handleAction(row)}
              data={row}
              handleActionChange={handleActionChange}
              disabled={row?.uploadStatus !== "Done"}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AppBuildListActionColumnV2;
