import React, { useState } from "react";
import { useSelector } from "react-redux";
import { APPLICATION_STRING_TAB } from "../../../Constants";
import ApplicationStringsListV2 from "./ApplicationStringsListV2";
import EditOrAddApplicationStringsV2 from "./EditOrAddApplicationStringsV2";
import ImportApplicationStringsV2 from "./ImportApplicationStringsV2";

const ApplicationStringsV2 = (props) => {
  const { visibleAddApplicationStringForm } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { visibleImportApplicationStringForm } = useSelector((state) => state.ApplicationStringReducer);
  const [activeFilter, setActiveFilter] = useState({ id: APPLICATION_STRING_TAB.ALL, value: "All" });

  const getVisibleComponent = () => {
    if (visibleAddApplicationStringForm) {
      return <EditOrAddApplicationStringsV2 projectId={props.projectId} />;
    }
    if (visibleImportApplicationStringForm) {
      return <ImportApplicationStringsV2 projectId={props.projectId} setActiveFilter={setActiveFilter} />;
    }
    return (
      <ApplicationStringsListV2
        projectId={props.projectId}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    );
  };

  return <>{getVisibleComponent()}</>;
};

export default ApplicationStringsV2;
