import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "antd/lib/mentions";
import { getOrgMembersByOrgId } from "../../../../actions/OrganizationAction";
import { getPlatformDevice, getTeamMembersByTeamId, myTeams } from "../../../../actions/TeamsAction";
import CommonInputFieldV2 from "../../../CommonComponents/CommonInputFieldV2/CommonInputFieldV2";
import CommonSelectBoxV2 from "../../../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import styles from "./CreateProjectV2.module.scss";

const CreateProjectV2 = ({ orgId, projectData, setProjectData, teamNameOfProject }) => {
  const dispatch = useDispatch();
  const nameInput = useRef(null);
  const { teamIdOfProject } = useSelector((state) => state.ProjectsReducer);
  const { platformDataList, listOfTeamMembers } = useSelector((state) => state.TeamsReducer);
  const { myTeamsList } = useSelector((state) => state.TeamsReducer);
  const { listOfOrgMembers } = useSelector((state) => state.OrganizationReducer);

  useEffect(() => {
    dispatch(getPlatformDevice());
    dispatch(myTeams(orgId));
  }, []);

  const orgMemberList = useMemo(() => {
    return (listOfOrgMembers || []).map((element) => (
      <Option key={element.email} value={element.email}>
        {element.email}
      </Option>
    ));
  }, [listOfOrgMembers]);

  const teamMemberList = useMemo(() => {
    return (listOfTeamMembers || []).map((element) => (
      <Option key={element.email} value={element.email}>
        {element.email}
      </Option>
    ));
  }, [listOfTeamMembers]);

  const defaultTeamMembers = listOfTeamMembers.map((member) => member.email);

  const childrenTeam = useMemo(() => {
    return (myTeamsList || []).map((element) => (
      <Option key={element.teamId} value={element.teamId}>
        {element.teamName}
      </Option>
    ));
  }, [myTeamsList]);

  const platformList = useMemo(() => {
    return (platformDataList || []).map((element) => (
      <Option key={element.id} value={element.id}>
        {element.name}
      </Option>
    ));
  }, [platformDataList]);

  useEffect(() => {
    if (projectData?.teamId) {
      dispatch(getTeamMembersByTeamId(projectData?.teamId));
      dispatch(getOrgMembersByOrgId(orgId, projectData.teamId));
    }
  }, [projectData?.teamId]);

  useEffect(() => {
    if (nameInput && nameInput.current) {
      setTimeout(() => {
        nameInput?.current?.focus();
      }, 1000);
    }
  }, [nameInput]);

  useEffect(() => {
    setProjectData((prevData) => ({ ...prevData, teamMembers: defaultTeamMembers }));
  }, [teamMemberList]);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const handleSelectChange = useCallback((name, value) => {
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const inputFields = [
    {
      label: "Project Name",
      name: "name",
      placeholder: "For Example: Development Or QA",
      value: projectData?.name,
      required: true,
      type: "input",
      autoFocus: true,
    },
    {
      label: "App Name",
      name: "appName",
      placeholder: "Please Enter App name",
      value: projectData?.appName,
      required: true,
      type: "input",
    },
    {
      label: "Project Description",
      name: "description",
      placeholder: "Insert Project Description.",
      value: projectData?.description,
      required: true,
      type: "textarea",
    },
  ];

  const selectFields = [
    {
      label: "Team",
      required: true,
      options: childrenTeam,
      value: teamNameOfProject || projectData?.teamId,
      placeholder: "Select Team",
      defaultValue: teamIdOfProject,
      onChangeKey: "teamId",
    },
    {
      label: "Team Members",
      options: teamMemberList,
      value: projectData?.teamMembers,
      placeholder: "Select Team Members",
      mode: "multiple",
      onChangeKey: "teamMembers",
      defaultValue: teamMemberList,
    },
    {
      label: "Additional Members",
      options: orgMemberList,
      value: projectData?.orgMembers,
      placeholder: "Add Members who are not part of the Team",
      mode: "multiple",
      onChangeKey: "orgMembers",
    },
  ];

  return (
    <>
      <div className={styles["create-project-title"]}>Create New Project</div>
      <div className={styles["scroll-content"]}>
        <div className={styles["details-title"]}>Project Details</div>

        {inputFields.map((field, index) => (
          <CommonInputFieldV2
            key={index}
            label={field.label}
            name={field.name}
            placeholder={field.placeholder}
            value={field.value}
            required={field.required}
            handleChange={handleInputChange}
            type={field.type}
            autoFocus={field.autoFocus || false}
          />
        ))}
        <CommonSelectBoxV2
          label="Platform"
          required={true}
          options={platformList}
          value={projectData?.selectedPlatform}
          placeholder="Select Platform"
          handleChange={(e) => {
            handleSelectChange("platformTypeId", e);
          }}
        />
        <div className={styles["details-title"]}>Team Details</div>
        {selectFields.map((field, index) => (
          <CommonSelectBoxV2
            key={index}
            label={field.label}
            required={field.required}
            options={field.options}
            value={field.value}
            placeholder={field.placeholder}
            handleChange={(e) => handleSelectChange(field.onChangeKey, e)}
            mode={field.mode}
            defaultValue={field.defaultValue}
          />
        ))}
      </div>
    </>
  );
};

export default CreateProjectV2;
