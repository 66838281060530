import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { updateStepType } from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { getTestDataSetById } from "../../../actions/TestDataSet/TestDataSetRedirectAction";
import {
    cloneSystemKeyByTestDataSetId,
    deleteTestDataSet,
    fetchSystemKeyByProjectId,
} from "../../../actions/TestDataSetAction";
import { SYSTEM_KEYS, TOOLTIP_BG_COLOR } from "../../../Constants";
import { ICON_COPY, ICON_EDIT_NEW, ICON_VIEW, SILVER_GRAY, TRASH_ICON } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import PaginationComponentV2 from "../../CoreComponents/PaginationComponent/PaginationComponentV2";
import CommonSearchV2 from "../../CoreComponents/SearchComponent/CommonSearchV2";
import TableComponentV2 from "../../CoreComponents/TableComponent/TableComponentV2";
import EditTestDataSetV2 from "../../TestPlan/TestPlanManager/TestPlanTabs/BuildTestDataSet/EditTestDataSetV2/EditTestDataSetV2";
import ViewDefaultTestDataSetV2 from "../../TestPlan/TestPlanManager/TestPlanTabs/BuildTestDataSet/ViewDefaultTestDataSetV2/ViewDefaultTestDataSetV2";
import styles from "../TestDataSetManagementV2.module.scss";

const SystemKeyTestDataSetListV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId } = props;
  const { testDataSetAssigneeDetails } = useSelector((state) => state.TestDataSetReducer);
  const {
    testDataSetList,
    fetchingTestDataSetList,
    totalPageCount,
    totalCounts,
    currentPage,
    testDataSetForEdit,
    pageItems,
  } = useSelector((state) => state.TestDataSetReducer);

  const [isEditTestDataSet, setIsEditTestDataSet] = useState(false);
  const [isViewTestData, setIsViewTestData] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [confirmModal, setConfirmModal] = useState(null);

  useEffect(() => {
    dispatch(fetchSystemKeyByProjectId(searchKeyword, currentPage));
  }, [testDataSetAssigneeDetails, projectId]);

  const columns = [
    {
      title: "Test Data Set Details",
      dataIndex: "testDataDetails",
    },
    {
      title: "Data Sets",
      dataIndex: "dataSets",
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "end",
    },
  ];

  const renderLabel = (label, value, dataSetName) =>
    value && (
      <div>
        <span className={styles["label"]}>{label}: </span>
        <span className={dataSetName ? styles["data_Set_Title"] : styles["label-name"]}>{value}</span>
      </div>
    );

  const TestDataSetActions = (testDataSet) => {
    return (
      <div className={styles["action_Icon"]}>
        {testDataSet.id && (
          <Tooltip color={TOOLTIP_BG_COLOR} title="Edit Test Data set" mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div className={styles["system_Key_Action__Buttons"]}>
              <SvgLoader
                iconName={ICON_EDIT_NEW}
                handleClick={() => {
                  dispatch(getTestDataSetById(testDataSet?.id));
                  setIsEditTestDataSet(true);
                }}
              />
            </div>
          </Tooltip>
        )}
        <Tooltip color={TOOLTIP_BG_COLOR} title="Clone" mouseEnterDelay={0} mouseLeaveDelay={0}>
          <div className={styles["system_Key_Action__Buttons"]}>
            <SvgLoader
              iconName={ICON_COPY}
              handleClick={() => setConfirmModal({ id: testDataSet?.id, type: "duplicate" })}
            />
          </div>
        </Tooltip>
        {(testDataSet.id && (
          <Tooltip color={TOOLTIP_BG_COLOR} title="Delete" mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div className={styles["system_Key_Action__Buttons"]}>
              <SvgLoader
                iconName={TRASH_ICON}
                iconColor={SILVER_GRAY}
                handleClick={() => setConfirmModal({ id: testDataSet?.id, type: "delete" })}
              />
            </div>
          </Tooltip>
        )) || (
          <Tooltip color={TOOLTIP_BG_COLOR} title="View" mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div className={styles["system_Key_Action__Buttons"]}>
              <SvgLoader
                iconName={ICON_VIEW}
                handleClick={() => {
                  setIsViewTestData(true);
                  dispatch(updateStepType(SYSTEM_KEYS));
                  dispatch(fetchSystemKeyByProjectId(searchKeyword, currentPage));
                }}
              />
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  //confirmation modal
  const handleModalOk = () => {
    if (confirmModal?.type === "delete") {
      dispatch(deleteTestDataSet(confirmModal?.id));
    } else if (confirmModal?.type === "duplicate") {
      dispatch(cloneSystemKeyByTestDataSetId(confirmModal?.id));
    }
    setConfirmModal(null);
  };

  const getModalContent = () => {
    if (confirmModal?.type === "delete") {
      return {
        title: "Confirm Deletion",
        content: "Are you sure you want to delete this item? This action cannot be undone.",
        okText: "Delete",
      };
    } else if (confirmModal?.type === "duplicate") {
      return {
        title: "Confirm Duplication",
        content: "Are you sure you want to duplicate this item?",
        okText: "Duplicate",
      };
    }
    return {};
  };

  const { title, content, okText } = getModalContent();

  const hasValidDataSet =
    testDataSetList?.length > 1 ||
    (testDataSetList?.length === 1 && testDataSetList[0].name !== "Default") ||
    searchKeyword === "";

  const testDataSetDetail =
    testDataSetList &&
    hasValidDataSet &&
    testDataSetList.map((row) => ({
      key: row.id,
      testDataDetails: (
        <div>
          {renderLabel("Data Set Name", row.name, true)}
          {renderLabel("Created By", row.createdBy)}
          {renderLabel("Updated By", row.updatedBy)}
        </div>
      ),
      dataSets: renderLabel("Count of Key/Value Pairs", row.count),
      action: TestDataSetActions(row),
    }));

  return (
    <div className={styles["system_Key_Page_Scroll"]}>
      {!isEditTestDataSet && !isViewTestData && (
        <>
          <div className={styles["search_Box"]}>
            <CommonSearchV2
              placeHolder="Search by Data Set name"
              searchDetails={(searchText) => dispatch(fetchSystemKeyByProjectId(searchText))}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </div>
          <div className="table_Details__wrapper">
            <Spin spinning={fetchingTestDataSetList} tip="Loading" className="spinner-center">
              <TableComponentV2 className="table__wrapper" data={testDataSetDetail} columns={columns} />
              {!isEmpty(testDataSetList) &&
                (testDataSetList.length > 1 ||
                  (testDataSetList.length === 1 && testDataSetList[0].name !== "Default")) && (
                  <div className="pagination_Section">
                    <PaginationComponentV2
                      currentPageItems={pageItems}
                      totalItems={totalCounts}
                      currentPage={currentPage}
                      totalPage={totalPageCount}
                      callPaginationAPI={(pageNumber) => dispatch(fetchSystemKeyByProjectId(searchKeyword, pageNumber))}
                    />
                  </div>
                )}
            </Spin>
          </div>
        </>
      )}
      {isEditTestDataSet && (
        <Spin spinning={fetchingTestDataSetList} className="mt-per-50">
          {testDataSetForEdit && (
            <div>
              <EditTestDataSetV2 setIsEditTestDataSet={setIsEditTestDataSet} isSystemKeyView={true} />
            </div>
          )}
        </Spin>
      )}
      {isViewTestData && (
        <div>
          <ViewDefaultTestDataSetV2
            title={"System Key Test Data Profile (Base-Profile)"}
            setIsViewTestData={setIsViewTestData}
          />
        </div>
      )}
      {confirmModal && (
        <CommonConfirmationModalV2
          handleOk={handleModalOk}
          handleCancel={() => setConfirmModal(null)}
          modalOpen={!!confirmModal}
          modalContent={content}
          modalTitle={title}
          okText={okText}
          isDeleteModal={confirmModal?.type === "delete" ? true : false}
        />
      )}
    </div>
  );
};

export default SystemKeyTestDataSetListV2;
