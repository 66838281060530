import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Grid } from "antd";
import { ALL_TEST_SCENARIOS, TEST_SCENARIOS } from "../../Constants";
import { hideTestBuildDataSetTab } from "../../actions/CommonActions";
import {
  updateStepType,
} from "../../actions/RecTestStep/RecTestStepRedirectAction";
import { disableKeyboard } from "../../actions/Recording";
import RecTestScenarioHeaderV2 from "../RecTestStepV2/TestBlockAndScenarioHeaderV2/RecTestScenarioHeaderV2";
import ApplicationStringTab from "../ApplicationString/ApplicationStringTab";
import DeviceStringTab from "../DeviceString/DeviceStringTab";
import RecTestScenarioPageV2 from "./RecTestScenarioPageV2";
import StepDetailsV2 from "../RecTestStepV2/StepDetailsV2/StepDetailsV2";
import RecScenarioListModal from "../RecTestScenario/RecScenarioListModal";
import UnSavedRecTestStepModal from "../RecTestStep/SingleColumnView/UnSavedRecTestStepModal";
import ImportRecTestStepActionModal from "../RecTestStep/SingleColumnView/ImportRecTestStepActionModal/ImportRecTestStepActionModal";
import RecTestStepActionElementPropertiesDrawer from "../RecTestStep/SingleColumnView/RecTestStepActionElementPropertiesDrawer/RecTestStepActionElementPropertiesDrawer";
import AIGenerateHeaderV2 from "./AIGenerateHeaderV2";
import RecElementDeleteModalV2 from "../RecTestStepV2/StepInnerComponents/RecStepsConfirmationModalV2/RecElementDeleteModalV2";
import RecTestStepDeleteModalV2 from "../RecTestStepV2/StepInnerComponents/RecStepsConfirmationModalV2/RecTestStepDeleteModalV2";
import RecTestStepPreConditionDeleteModalV2 from "../RecTestStepV2/StepInnerComponents/RecStepsConfirmationModalV2/RecTestStepPreConditionDeleteModalV2";
import RecTestStepLoopConditionDeleteModalV2 from "../RecTestStepV2/StepInnerComponents/RecStepsConfirmationModalV2/RecTestStepLoopConditionDeleteModalV2";
import TestDataSetTabsV2 from "../CommonComponents/TestStepsDataV2/TestDataSetTabsV2";


const RecTestScenarioV2 = (props) => {
  const { projectId, organizationId } = props;
  const [currentOptions, setCurrentOptions] = useState(ALL_TEST_SCENARIOS);
  const { stepType, isShowAIGenerateStepsRow } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const {
    testDataSetAssigneeDetail,
    applicationStringAssigneeDetail,
    deviceStringAssigneeDetail,
  } = useSelector((state) => state.TestDataSetReducer);
  const { selectedScenario } = useSelector(
    (state) => state.RecTestScenarioReducer
  );
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    setCurrentOptions(ALL_TEST_SCENARIOS);
  }, [projectId]);

  const handleOnChange = (value) => {
    setCurrentOptions(value);
  };
  useEffect(() => {
    dispatch(updateStepType(TEST_SCENARIOS));
  }, []);

  useEffect(() => {
    dispatch(hideTestBuildDataSetTab());
  }, [stepType]);

  return (
    <>
      <Col span={24} onFocus={() => dispatch(disableKeyboard())}>
        {currentOptions !== ALL_TEST_SCENARIOS && (
          <RecTestScenarioHeaderV2
            projectId={props.projectId}
            currentOptions={currentOptions}
            setCurrentOptions={setCurrentOptions}
            orgId={organizationId}
          />
        )}
        {isShowAIGenerateStepsRow && currentOptions !== ALL_TEST_SCENARIOS && <AIGenerateHeaderV2 />}
        {testDataSetAssigneeDetail && currentOptions !== ALL_TEST_SCENARIOS ? (
          <div className="mt-10">
            <TestDataSetTabsV2 projectId={projectId} selectedStepId={selectedScenario?.id} />
          </div>
        ) : applicationStringAssigneeDetail &&
          currentOptions !== ALL_TEST_SCENARIOS ? (
          <div className="mt-10">
            <ApplicationStringTab
              projectId={props.projectId}
              selectedStepId={applicationStringAssigneeDetail?.id}
            />
          </div>
        ) : deviceStringAssigneeDetail &&
          currentOptions !== ALL_TEST_SCENARIOS ? (
          <div className="mt-10">
            <DeviceStringTab
              projectId={props.projectId}
              selectedStepId={deviceStringAssigneeDetail?.id}
            />
          </div>
        ) : currentOptions === ALL_TEST_SCENARIOS ? (
          <RecTestScenarioPageV2
            projectId={projectId}
            navigateToEditBuildScenario={handleOnChange}
          />
        ) : (
          <StepDetailsV2
            projectId={props.projectId}
            organizationId={props.organizationId}
            stepType={stepType}
          />
        )}
      </Col>
      <RecElementDeleteModalV2 />
      <RecTestStepDeleteModalV2 />
      <RecTestStepPreConditionDeleteModalV2 />
      <RecTestStepLoopConditionDeleteModalV2 />
      <RecScenarioListModal projectId={props.projectId} />
      <UnSavedRecTestStepModal />
      <ImportRecTestStepActionModal projectId={props.projectId} />
      <RecTestStepActionElementPropertiesDrawer />
    </>
  );
};

export default RecTestScenarioV2;
