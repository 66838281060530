import { Spin, Tooltip } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import { CREATE_ICON, ICON_EDIT_NEW, SILVER_GRAY, TRASH_ICON, WHITE_COLOR } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import {
  addDeviceStringsFormVisible,
  deleteDeviceString,
  getDeviceStrings,
  loadEditDeviceStringDetail,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import CustomButtonV2 from "../../CommonComponents/CustomButtonV2/CustomButtonV2";
import PaginationComponentV2 from "../../CoreComponents/PaginationComponent/PaginationComponentV2";
import CommonSearchV2 from "../../CoreComponents/SearchComponent/CommonSearchV2";
import TableComponentV2 from "../../CoreComponents/TableComponent/TableComponentV2";
import styles from "./DeviceStringsV2.module.scss";

const DeviceStringsListV2 = (props) => {
  const { projectId } = props;
  const { getDeviceString, deviceStringLoader, getDeviceStringPageCount, getDeviceStringCurrentCount } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filter, setFilter] = useState("ALL");
  const [openConfirmModal, setOpenConfirmModal] = useState(null);

  const pageState =
    getDeviceStringPageCount instanceof Array && getDeviceStringPageCount.length === 2
      ? parseInt(getDeviceStringPageCount[1]) - parseInt(getDeviceStringPageCount[0]) > 0
        ? getDeviceStringCurrentCount
        : getDeviceStringCurrentCount > 1
        ? getDeviceStringCurrentCount - 1
        : 1
      : 1;

  useEffect(() => {
    dispatch(getDeviceStrings(projectId, "", "", ""));
    setFilter("ALL");
  }, [projectId, dispatch]);

  const columns = [
    {
      title: "Key",
      dataIndex: "name",
      width: 500,
    },
    {
      title: "Values",
      dataIndex: "value",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "right",
    },
  ];

  const deviceStringsData = getDeviceString?.data?.map((row) => ({
    key: row?.id,
    name: row?.key,
    value: (
      <Tooltip
        color={TOOLTIP_BG_COLOR}
        title={row?.values.map((data) => {
          return <div>{data.locale + " : " + data.value}</div>;
        })}
        mouseEnterDelay={0}
        mouseLeaveDelay={0}
      >
        {`${row?.locale} ${row?.locale === 1 ? " Locale" : " Locales"}`}
      </Tooltip>
    ),
    action: (
      <div className={styles["action_Icons"]}>
        <Tooltip title="Edit" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
          <div>
            <SvgLoader
              iconName={ICON_EDIT_NEW}
              handleClick={() => dispatch(loadEditDeviceStringDetail(projectId, row?.key, row?.isImport))}
            />
          </div>
        </Tooltip>
        <Tooltip title="Delete" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
          <div>
            <SvgLoader iconName={TRASH_ICON} iconColor={SILVER_GRAY} handleClick={() => setOpenConfirmModal(row?.key)} />
          </div>
        </Tooltip>
      </div>
    ),
  }));

  return (
    <Spin spinning={deviceStringLoader} tip="Loading">
      <div className={styles["page_Scroll"]}>
        <div className={styles["sub_Header"]}>
          <CommonSearchV2
            placeHolder="Search by Device string name"
            searchDetails={(searchText) =>
              dispatch(getDeviceStrings(projectId, searchText, "", "", "", "", "", filter))
            }
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
          />
          <CustomButtonV2
            iconColor={WHITE_COLOR}
            buttonIcon={CREATE_ICON}
            buttonLabel="Device Strings"
            className="common_Green_Button"
            handleClick={(e) => dispatch(addDeviceStringsFormVisible(true))}
          />
        </div>
        <div className="table_Details__wrapper">
          <TableComponentV2 data={deviceStringsData} columns={columns} className="table__wrapper" />
          <div className="pagination_Section">
            {!isEmpty(getDeviceString?.data) && (
              <PaginationComponentV2
                currentPageItems={getDeviceString?.pageItems}
                totalItems={getDeviceString?.totalItems}
                currentPage={getDeviceString?.currentPage}
                totalPage={getDeviceString?.totalCount}
                callPaginationAPI={(pageNumber) =>
                  dispatch(getDeviceStrings(projectId, searchKeyword, "", pageNumber, "", "", "", filter))
                }
              />
            )}
          </div>
        </div>
        <CommonConfirmationModalV2
          handleOk={() => {
            dispatch(deleteDeviceString(openConfirmModal, filter, pageState));
            setOpenConfirmModal(null);
          }}
          handleCancel={() => setOpenConfirmModal(null)}
          modalOpen={!!openConfirmModal}
          modalTitle={"Confirm Deletion"}
          modalContent={"Are you sure you want to delete this item?"}
          okText={"Delete"}
          isDeleteModal={true}
          modalNote="Note: Device String will be removed from associated steps/elements."
        />
      </div>
    </Spin>
  );
};

export default DeviceStringsListV2;
