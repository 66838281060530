import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNewTestDataName,
  changeNewTestDataValue,
  hideAddTestDataForm,
  saveNewTestData,
} from "../../../actions/TestDataAction";
import { TEST_DATA_SCOPE } from "../../../Constants";
import EditOrAddProjectTestDataV2 from "../EditOrAddProjectTestDataV2";
import ProjectTestDataV2 from "../ProjectTestDataV2";

const SystemKeyTestDataV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId, setCurrentActionTab, isSkipStep } = props;
  const {
    addTestDataFormVisible,
    newTestDataId,
    newTestDataName,
    newTestDataValue,
    previous_newTestDataValue,
    requestSaveTestDataForm,
  } = useSelector((state) => state.TestDataReducer);

  const actionCreators = {
    changeName: changeNewTestDataName,
    changeValue: changeNewTestDataValue,
    hideForm: hideAddTestDataForm,
  };

  const formData = {
    id: newTestDataId,
    name: newTestDataName,
    value: newTestDataValue,
    requestSaveForm: requestSaveTestDataForm,
    previousValue: previous_newTestDataValue,
  };

  return (
    <Row>
      <Col span={24}>
        {addTestDataFormVisible ? (
          <>
            <EditOrAddProjectTestDataV2
              formType="Test Data"
              projectId={projectId}
              actionCreators={actionCreators}
              formData={formData}
              handleSubmit={() =>
                dispatch(
                  saveNewTestData(
                    newTestDataName,
                    newTestDataValue,
                    newTestDataId,
                    projectId,
                    TEST_DATA_SCOPE.PROJECT,
                    1
                  )
                )
              }
            />
          </>
        ) : (
          <ProjectTestDataV2 setCurrentActionTab={setCurrentActionTab} projectId={projectId} isSkipStep={isSkipStep} />
        )}
      </Col>
    </Row>
  );
};

export default SystemKeyTestDataV2;
