import React from "react";
import CommonConfirmationModalV2 from "../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRecTestStepPreCondition,
  hideDeleteRecTestStepPreConditionConfirmationModal,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";

const RecTestStepPreConditionDeleteModalV2 = () => {
  const dispatch = useDispatch();
  const { deleteRecTestStepPreconditionModalVisible } = useSelector((state) => state.RecTestStepCommonViewReducer);

  return (
    <CommonConfirmationModalV2
      handleOk={() => {
        dispatch(deleteRecTestStepPreCondition());
      }}
      handleCancel={() => dispatch(hideDeleteRecTestStepPreConditionConfirmationModal())}
      modalOpen={deleteRecTestStepPreconditionModalVisible}
      modalTitle={"Confirm Deletion"}
      modalContent={"Are you sure you want to delete this condition?"}
      okText={"Delete"}
      isDeleteModal={true}
    />
  );
};

export default RecTestStepPreConditionDeleteModalV2;
