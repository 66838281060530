import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spin } from "antd";
import isEmpty from "lodash/isEmpty";
import { TEST_PLAN } from "../../../../../../Constants";
import {
  cloneTestDataSetByTestPlanId,
  fetchTestDataSetByProfileId,
} from "../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import PaginationComponentV2 from "../../../../../CoreComponents/PaginationComponent/PaginationComponentV2";
import TableComponentV2 from "../../../../../CoreComponents/TableComponent/TableComponentV2";
import styles from "./AllTestDataSetV2.module.scss";

const columns = [
  {
    title: "Profile Details",
    dataIndex: "testDataDetails",
  },
  {
    title: "Test Data Keys",
    dataIndex: "dataSets",
  },
  {
    title: "Use For Execution",
    dataIndex: "isDefault",
  },
  {
    title: "Actions",
    dataIndex: "action",
    align: "end",
  },
];
const TestDataSetDetailsListV2 = ({ stepType, testDataSet }) => {
  const dispatch = useDispatch();

  const { totalCounts, currentPage, totalPageCount, pageItems } = useSelector((state) => state.TestDataSetReducer);
  const { testDataSetList, fetchingTestDataSetList } = useSelector((state) => state.TestDataSetReducer);

  return (
    <>
      <div className={`${stepType === TEST_PLAN ? styles[`page-Scroll`] : styles["page-Scroll-Of-TestData"]}`}>
        <div className={styles["test_Data_Details"]}>
          <div className={styles[`test-data-set-details-list`]}>
            <Button
              type="primary"
              size="large"
              className={styles["new-profile-btn"]}
              onClick={() => dispatch(cloneTestDataSetByTestPlanId(testDataSet.id, stepType))}
            >
              New Profile
            </Button>
          </div>
            <div className="table_Details__wrapper">
              <Spin spinning={fetchingTestDataSetList} tip="Loading" className="spinner-center">
                <TableComponentV2 data={testDataSet} columns={columns} className="table__wrapper" />
                {!isEmpty(testDataSetList) &&
                  (testDataSetList.length > 1 ||
                    (testDataSetList.length === 1 && testDataSetList[0].name !== "Default")) && (
                    <div className="pagination_Section">
                      <PaginationComponentV2
                        currentPageItems={pageItems}
                        totalItems={totalCounts}
                        currentPage={currentPage}
                        totalPage={totalPageCount}
                        callPaginationAPI={(pageNumber) => dispatch(fetchTestDataSetByProfileId("", pageNumber))}
                      />
                    </div>
                  )}
              </Spin>
            </div>
        </div>
      </div>
    </>
  );
};

export default TestDataSetDetailsListV2;