import { Button, Col, Collapse, Modal, Radio, Row, Spin, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TEST_SCENARIOS, TOOLTIP_BG_COLOR } from "../../../../../../Constants";
import { getRecTestStepListByRecTestScenarioId } from "../../../../../../actions/RecTestStepAction";
import { fetchTestData } from "../../../../../../actions/TestDataAction";
import { changeTestDataSetView } from "../../../../../../actions/TestDataSet/TestDataSetCommonAction";
import { getTestDataSetById } from "../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import {
  assignTestDataSet,
  getTestDataSetByTestPlanId,
  hideTestDataSetSelectionModal,
  saveAssignTestData,
} from "../../../../../../actions/TestDataSetAction";
import { changeTestJobData } from "../../../../../../actions/TestJobActions";
import EmptyRecords from "../../../../../CommonComponents/EmptyRecords/EmptyRecords";
import EditTestPlanTestDataSet from "../../../../../TestPlan/TestPlanManager/TestPlanTabs/BuildTestDataSet/EditTestPlanTestDataSet";
import styles from "./AssignTestDataSetModal.module.scss";
import ViewDefaultTestDataSet from "../../../../../TestPlan/TestPlanManager/TestPlanTabs/BuildTestDataSet/ViewDefaultTestDataSet";

const StyledTestDataCollapse = styled(Collapse)`
  border-top: 1px solid #d9d9d9 !important;
`;
const StyledRadioButton = styled(Radio)`
  vertical-align: middle;
  height: 17px;
  width: 18px;
  .ant-radio-checked .ant-radio-inner {
    border-color: #1890ff !important ;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #1890ff !important;
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: #1890ff !important;
  }
`;

const AssignTestDataSetModal = (props) => {
  const { testDataSetAssigneeDetail, projectId, stepType } = props;
  const {
    testDataSetList,
    requestTestDataSetList,
    assignTestDataSetModalVisible,
    selectedTestDataSet,
    testDataSetForEdit,
    fetchingTestDataSetList,
  } = useSelector((state) => state.TestDataSetReducer);
  const { selectedRecTestScenarioId, selectedRecTestScenarioName } = useSelector((state) => state.RecTestStepReducer);
  const { testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { testJobDetail } = useSelector((state) => state.TestJobReducer);

  const [selectedKey, setSelectedKey] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (testDataList === undefined) {
      dispatch(fetchTestData(projectId));
    }
    dispatch(changeTestDataSetView(stepType));
  }, []);

  const saveTestData = () => {
    dispatch(saveAssignTestData(selectedTestDataSet ? selectedTestDataSet.id : 0, stepType));
    dispatch(getRecTestStepListByRecTestScenarioId(selectedRecTestScenarioId, selectedRecTestScenarioName));
  };

  useEffect(() => {
    if (testDataSetAssigneeDetail?.id === undefined) {
      const findDefaultValue = !_.isEmpty(testDataSetList) && testDataSetList?.find((i) => i?.isDefault === 1);
      dispatch(
        changeTestJobData({
          ...testJobDetail,
          testDataSet: { id: findDefaultValue?.id, name: findDefaultValue?.name },
        })
      );
    }
  }, [assignTestDataSetModalVisible, testDataSetList]);

  return (
    <>
      <Modal
        width={950}
        className="assignTestDataModal"
        title="Assign Test Data Profiles"
        visible={assignTestDataSetModalVisible}
        onCancel={() => dispatch(hideTestDataSetSelectionModal())}
        footer={[
          !stepType && (
            <Button
              key="Reset"
              type="primary"
              onClick={() => {
                resetSelection(stepType, testDataSetAssigneeDetail, dispatch);
              }}
            >
              Reset
            </Button>
          ),
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              stepType === TEST_SCENARIOS ? saveTestData() : dispatch(hideTestDataSetSelectionModal());
            }}
          >
            Save
          </Button>,
        ]}
      >
        <Spin spinning={fetchingTestDataSetList} tip="Loading..." className={styles.spinning_center}>
          {
            assignTestDataSetModalVisible && !requestTestDataSetList && testDataSetList !== undefined ? (
              <>
                <Row className="p-10">
                  <Col span={12}> Name</Col>
                  <Col span={12}>Scope</Col>
                </Row>
                {(!_.isEmpty(testDataSetList) &&
                  testDataSetList.map((data, index) => {
                    return (
                      <StyledTestDataCollapse
                        key={"collapse-" + data.id ? data.id : "DEFAULT"}
                        activeKey={selectedKey}
                        onChange={() => {
                          setSelectedKey(data.id ? data.id : "DEFAULT");
                          if (data.id) dispatch(getTestDataSetById(data.id));
                        }}
                      >
                        <Collapse.Panel
                          header={
                            <Row>
                              <Col span={12}>
                                <Tooltip
                                  title={index === 0 && "Currently selected Execution Profile of Test Plan"}
                                  color={TOOLTIP_BG_COLOR}
                                >
                                  {data.name}
                                </Tooltip>
                              </Col>
                              <Col span={12}>
                                {data.createdFrom === "TEST_BLOCK"
                                  ? "Test Block"
                                  : data.createdFrom === "TEST_SCENARIO"
                                  ? "Test Scenario"
                                  : ""}
                              </Col>
                            </Row>
                          }
                          key={data.id ? data.id : "DEFAULT"}
                          extra={
                            <span className="mr-10">
                              <StyledRadioButton
                                type="radio"
                                name={data?.name}
                                checked={
                                  stepType === TEST_SCENARIOS
                                    ? selectedTestDataSet && selectedTestDataSet?.id
                                      ? selectedTestDataSet?.id === data?.id
                                      : 0 || data?.name === "Default"
                                    : (testDataSetAssigneeDetail.testDataSet &&
                                        testDataSetAssigneeDetail.testDataSet.id === undefined &&
                                        data.id === undefined) ||
                                      data.id === parseInt(testDataSetAssigneeDetail.testDataSet.id)
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  stepType === TEST_SCENARIOS
                                    ? dispatch(assignTestDataSet(data))
                                    : dispatch(
                                        changeTestJobData({
                                          ...testDataSetAssigneeDetail,
                                          testDataSet: { id: data.id, name: data.name },
                                        })
                                      );
                                }}
                              />
                            </span>
                          }
                        >
                          {data.id ? (
                            <EditTestPlanTestDataSet
                              title={"Test Plan Level Test Data"}
                              testDataSetForEdit={testDataSetForEdit}
                              isForView={true}
                            />
                          ) : (
                            <>
                              {data?.testScenarioTestDataSaveReq ? (
                                <ViewDefaultTestDataSet assignTestDataSetModal={true} />
                              ) : (
                                <div>
                                  <div className="test-data-row-key-header">Sr no</div>
                                  <div className="test-data-row-header">Scope</div>
                                  <div className="test-data-row-header">Key</div>
                                  <div className="test-data-row-header">Default Value</div>
                                  <div className="test-data-row-header">Custom /Reference Test Data </div>

                                  {data.testDataValues &&
                                    data.testDataValues.map((v, k) => (
                                      <div key={k}>
                                        <div className="test-data-row-key">{k + 1}</div>
                                        <div className="test-data-row">{v.scope}</div>
                                        <div className="test-data-row">{v.name}</div>
                                        <div className="test-data-row">{v.value}</div>
                                        <div className="test-data-row">
                                          {v.refTestDataName ? <i>{v.refTestDataName}</i> : v.testDataCustomValue}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </>
                          )}
                        </Collapse.Panel>
                      </StyledTestDataCollapse>
                    );
                  })) || <EmptyRecords description="No Data Found" />}
              </>
            ) : (
              !fetchingTestDataSetList && (
                <div className="p-50">
                  <EmptyRecords description="No Data Found" />
                </div>
              )
            )
            // : (
            //   <Col className="display-flex justify-center p-20">
            //     <Spin spinning={fetchingTestDataSetList} tip="Loading..."></Spin>
            //   </Col>
            // )
          }
        </Spin>
      </Modal>
    </>
  );
};
const resetSelection = (stepType, testJobDetail, dispatch) => {
  if (stepType === TEST_SCENARIOS) {
    dispatch(
      getTestDataSetByTestPlanId(
        testJobDetail?.id,
        "",
        TEST_SCENARIOS,
        testJobDetail?.recTestScenarioRecTestStepAssocId
      )
    );
  } else {
    dispatch(changeTestJobData({ ...testJobDetail, testDataSet: { name: "Default" } }));
  }
};

export default AssignTestDataSetModal;
