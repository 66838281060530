import { Col, Row, Select, Tooltip } from "antd";
import { isEmpty, debounce } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAwsDeviceFarmTabData,
  getAwsDeviceFarmPoolsLists,
  getAwsProjectsList,
} from "../../../actions/ProjectSettingsActions";
import { REMOVE_AWS_POOLS, TOOLTIP_BG_COLOR } from "../../../Constants";
import { SILVER_GRAY, TRASH_ICON } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import CommonSelectBoxV2 from "../../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import styles from "../ProjectSettingsV2.module.scss";

const { Option } = Select;

const AWSDeviceFarmProjectsV2 = () => {
  const dispatch = useDispatch();
  const { awsProjects, awsDeviceFarmTabDetails, awsPools, previous_awsDeviceFarmTabDetails } = useSelector(
    (state) => state.ProjectSettingsReducer
  );

  //Destructure objects
  const { accessKeyId, secretAccessKey, awsDeviceFarmProjects } = awsDeviceFarmTabDetails || {};

  const AwsProjectsList = awsProjects
    ? awsProjects.map((project) => (
        <Option key={project.arn} value={project.arn}>
          {project.name}
        </Option>
      ))
    : [];

  const debounceHandler = debounce(() => {
    dispatch(getAwsDeviceFarmPoolsLists());
  }, 1000);

  const handleChange = useCallback(
    (projectArn) => {
      let _awsDeviceFarmTabDetails = { ...awsDeviceFarmTabDetails };
      //Remove the Project
      _awsDeviceFarmTabDetails.awsDeviceFarmProjects = _awsDeviceFarmTabDetails.awsDeviceFarmProjects.filter(
        (awsDeviceFarmProject) => projectArn.includes(awsDeviceFarmProject.awsProjectArn)
      );
      // Add Project
      awsProjects.map((awsProject) => {
        if (
          projectArn.includes(awsProject.arn) &&
          _awsDeviceFarmTabDetails.awsDeviceFarmProjects.filter(
            (awsDeviceFarmProject) => awsProject.arn == awsDeviceFarmProject.awsProjectArn
          ).length === 0
        ) {
          _awsDeviceFarmTabDetails.awsDeviceFarmProjects.push({
            awsProjectArn: awsProject.arn,
            awsProjectName: awsProject.name,
            awsDevicePool: [],
          });
        }
      });
      dispatch(changeAwsDeviceFarmTabData(_awsDeviceFarmTabDetails));
      let awsDeviceFarmProject = {
        accessKeyId: _awsDeviceFarmTabDetails?.accessKeyId,
        secretAccessKey: _awsDeviceFarmTabDetails?.secretAccessKey,
        awsDeviceFarmProjects: _awsDeviceFarmTabDetails?.awsDeviceFarmProjects,
      };
      debounceHandler(awsDeviceFarmProject);
    },
    [awsProjects]
  );

  useEffect(() => {
    if (
      awsDeviceFarmTabDetails &&
      accessKeyId &&
      secretAccessKey &&
      awsDeviceFarmProjects &&
      previous_awsDeviceFarmTabDetails === undefined
    ) {
      dispatch(getAwsProjectsList());
      dispatch(getAwsDeviceFarmPoolsLists());
    }
  }, [
    dispatch,
    awsDeviceFarmTabDetails,
    accessKeyId,
    secretAccessKey,
    awsDeviceFarmProjects,
    previous_awsDeviceFarmTabDetails,
  ]);

  const handleSelectPool = (e, selectedProjectArn) => {
    let _awsDeviceFarmTabDetails = { ...awsDeviceFarmTabDetails };
    _awsDeviceFarmTabDetails.awsDeviceFarmProjects.forEach((element) => {
      if (element.awsProjectArn === selectedProjectArn) {
        if (e.length !== 0) {
          e.map((item) => {
            element.awsDevicePool = [];
            let awsDeviceProject = awsPools.filter((projects) => projects.awsProjectArn === selectedProjectArn);
            let finalAwsDevicePool = awsDeviceProject[0].awsDevicePool.filter((awsDevicePool) =>
              e.includes(awsDevicePool.arn)
            );
            element.awsDevicePool = [...finalAwsDevicePool];
          });
        } else {
          element.awsDevicePool = [];
        }
      }
    });
    dispatch(changeAwsDeviceFarmTabData(_awsDeviceFarmTabDetails));
  };

  let selectedProjects = [];
  let selectedAwsDevicePoolArn = {};
  if (awsDeviceFarmTabDetails.awsDeviceFarmProjects && awsDeviceFarmTabDetails.awsDeviceFarmProjects) {
    // Selected Project
    awsDeviceFarmTabDetails.awsDeviceFarmProjects.forEach((awsDeviceProject) => {
      selectedProjects.push(awsDeviceProject.awsProjectArn);
      // Selected Device pool
      selectedAwsDevicePoolArn[awsDeviceProject.awsProjectArn] = [];
      awsDeviceProject.awsDevicePool.forEach((devicePool) => {
        selectedAwsDevicePoolArn[awsDeviceProject.awsProjectArn].push(devicePool.arn);
      });
    });
  }

  const removeAWSDevicePool = (projectArn) => {
    let _awsDeviceFarmTabDetails = { ...awsDeviceFarmTabDetails };
    const removedDevicePool =
      _awsDeviceFarmTabDetails &&
      _awsDeviceFarmTabDetails?.awsDeviceFarmProjects?.filter((data, j) => data.awsProjectArn !== projectArn);
    dispatch(changeAwsDeviceFarmTabData({ ..._awsDeviceFarmTabDetails, awsDeviceFarmProjects: removedDevicePool }));
    const removeAwsPool = awsPools.filter((data, j) => data.awsProjectArn !== projectArn);
    dispatch({ type: REMOVE_AWS_POOLS, removeAwsPool });
  };

  return (
    awsDeviceFarmTabDetails &&
    secretAccessKey &&
    accessKeyId &&
    !isEmpty(awsProjects) && (
      <div className={styles["project_Details"]}>
        <Row justify="center">
          <Col span={20}>
            <div className={styles["header_Title"]}>Project & Device</div>
            <CommonSelectBoxV2
              label="Projects"
              labelBackgroundClass={"label_Background"}
              options={AwsProjectsList}
              value={selectedProjects}
              placeholder="Select Project"
              handleChange={(e) => handleChange(e)}
              mode="tags"
            />
            <div className={styles["aws_Project_Details"]}>
              {!isEmpty(awsPools) && (
                <>
                  {awsPools?.map((pools, i) => {
                    return (
                      <>
                        <Col span={24}>
                          <CommonSelectBoxV2
                            label={pools.awsProjectName}
                            labelBackgroundClass={"label_Background"}
                            options={
                              <>
                                {!isEmpty(pools.awsDevicePool) &&
                                  pools?.awsDevicePool?.map((poolsOption, index) => {
                                    return (
                                      <Option key={index} value={poolsOption.arn}>
                                        {poolsOption.name}
                                      </Option>
                                    );
                                  })}
                              </>
                            }
                            value={selectedAwsDevicePoolArn[pools.awsProjectArn]}
                            placeholder="Add Device Pool"
                            handleChange={(e) => handleSelectPool(e, pools.awsProjectArn)}
                            mode="tags"
                            tokenSeparators={[","]}
                          />
                        </Col>
                        <Col span={2}>
                          <Tooltip title="Delete" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
                            <div>
                              <SvgLoader
                                iconName={TRASH_ICON}
                                iconColor={SILVER_GRAY}
                                handleClick={() => removeAWSDevicePool(pools?.awsProjectArn)}
                              />
                            </div>
                          </Tooltip>
                        </Col>
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    )
  );
};

export default AWSDeviceFarmProjectsV2;
