import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Checkbox, Col, Input, Row } from "antd";
import { LOGO_XPRESS, SUCCESS_SIGN_UP_IMAGE } from "../../Constants/SvgConstants";
import { validateEmail } from "../../Util";
import SvgLoader from "../../Util/SvgLoader";
import {
  SignUp,
  handleChangeEmail,
  handleChangeFullName,
  resetSignUpErrorMsg,
  setError,
} from "../../actions/CommonActions";
import BannerV2 from "../BannerV2/BannerV2";
import FooterV2 from "../FooterV2/FooterV2";
import styles from "./SignUpV2.module.scss";

const SignUpV2 = () => {
  const dispatch = useDispatch();
  const {
    signUpStatus,
    signUpMessage,
    signupEmail,
    signupFullNameError,
    signupFirstName,
    singupRequested,
    signupFullName,
    showEmailExistsError,
  } = useSelector((state) => state.CommonReducer);

  const [isTermAndConditionChecked, setIsTermAndConditionChecked] = useState("");

  const validateForm = () => {
    let formValid = true;
    if (signupFullName.trim() == "") {
      dispatch(setError("signupFullNameError", true));
      formValid = false;
    } else {
      dispatch(setError("signupFullNameError", false));
    }
    if (signupEmail.trim() == "" || !showEmailExistsError || !validateEmail(signupEmail)) {
      dispatch(setError("signupEmailError", true));
      formValid = false;
    } else {
      dispatch(setError("signupEmailError", false));
    }
    return formValid;
  };

  const validateUserInputForEmail = (value) => {
    dispatch(handleChangeEmail(value));

    if (signupEmail.trim() == "" || !validateEmail(signupEmail)) {
      dispatch(setError("signupEmailError", true));
    } else {
      dispatch(setError("signupEmailError", false));
    }
  };

  const onCheckedTermsAndCondition = (e) => {
    setIsTermAndConditionChecked(e.target.checked);
  };

  const loginRequest = () => {
    let formValid = validateForm();
    if (formValid) {
      dispatch(SignUp());
    }
  };

  return (
    <Col span={24} className={styles["container"]}>
      <Col xl={15} md={12} className="banner_Visibility">
        <BannerV2 />
      </Col>
      <Col xl={9} md={12} xs={24} className="auth_card_wrap">
        {!signUpStatus ? (
          <Row className="auth_card">
            <>
              <Col span={24} className={"validate-form"}>
                <>
                  <SvgLoader iconName={LOGO_XPRESS} width={112} height={33} />
                  <div className={styles["sign_Up_Text"]}>Sign Up</div>
                  <>
                    <div className={styles["custom-input-wrapper"]}>
                      <div className={`${styles["custom-input-label"]} `}>Email</div>
                      <Input
                        className={`${styles["modal-input"]} ${
                          signUpMessage || (signupEmail && !validateEmail(signupEmail)) ? styles["error"] : ""
                        }`}
                        type="text"
                        name="email"
                        placeholder="Enter Email Id"
                        value={signupEmail}
                        onChange={(e) => {
                          dispatch(resetSignUpErrorMsg());
                          validateUserInputForEmail(e.target.value);
                        }}
                        autoFocus
                      />
                      {signupEmail && !validateEmail(signupEmail) && (
                        <div className={styles["error_message"]}>Invalid email</div>
                      )}
                      {signUpMessage && signUpMessage != "" ? (
                        <div className={styles["error_message"]}>{signUpMessage}</div>
                      ) : null}
                    </div>
                    <div className={styles["custom-input-wrapper"]}>
                      <div className={`${styles["custom-input-label"]} `}>Full Name</div>
                      <Input
                        className={`${styles["modal-input"]} ${signupFullNameError && styles["error"]}`}
                        type="text"
                        name="FullName"
                        placeholder="Enter Full Name"
                        value={signupFirstName}
                        onChange={(e) => dispatch(handleChangeFullName(e.target.value))}
                      />
                    </div>

                    <div className={styles["terms_Condition"]}>
                      <Checkbox
                        className={"selection-checkBox"}
                        onChange={(e) => {
                          onCheckedTermsAndCondition(e);
                        }}
                      />
                      <div className={styles["terms_Condition_Text"]}>
                        <span> I agree to the </span>
                        <a href="/TermsAndPrivacyPolicy" target="_blank">
                          Xpress Terms {"&"} Privacy Policy.
                        </a>
                      </div>
                    </div>

                    <div>
                      <Button
                        type="primary"
                        size="large"
                        disabled={!validateEmail(signupEmail) || !isTermAndConditionChecked}
                        loading={singupRequested}
                        block
                        onClick={loginRequest}
                        className={styles["btn_Sign_Up"]}
                      >
                        Sign Up
                      </Button>
                    </div>
                  </>
                  <div className={styles["already_Account_Text"]}>
                    Already on Xpress?<Link to={"/login"}> Sign In </Link>
                  </div>
                </>
              </Col>
            </>
          </Row>
        ) : (
          <div className={styles["success_Sign_Up_Wrap"]}>
            <SvgLoader iconName={LOGO_XPRESS} width={112} height={33} />
            <SvgLoader iconName={SUCCESS_SIGN_UP_IMAGE} />
            <div className={styles["verify_Email_Wrap"]}>
              <div className={styles["successfully_Text"]}>You have successfully signed up.</div>
              <div className={styles["verify_Email_Text"]}>Please check your email for verification.</div>
            </div>
          </div>
        )}
        <FooterV2 />
      </Col>
    </Col>
  );
};

export default SignUpV2;
