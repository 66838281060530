import { Collapse } from "antd";
import _ from "lodash";
import React from "react";
import ViewDefaultTestDataListViewV2 from "./ViewDefaultTestDataListViewV2";
import ViewTestBlockDefaultTestDataSetV2 from "./ViewTestBlockDefaultTestDataSetV2";


const ViewRecTestScenarioDefaultTestDataSetV2 = ({ defaultTestData, title, stepType }) => {
  let refTestDataList = _.cloneDeep(defaultTestData?.testDataValues);

  for (let index = 0; index < defaultTestData?.testBlockTestDataSaveReq?.length; index++) {
    Array.prototype.push.apply(
      refTestDataList,
      _.cloneDeep(defaultTestData?.testBlockTestDataSaveReq[index]?.testDataValues)
    );
  }

  return (
    <>
      {defaultTestData && (
        <>
        {!_.isEmpty(defaultTestData?.testDataValues) &&
            <ViewDefaultTestDataListViewV2
              testDataValues={defaultTestData.testDataValues}
              title={title}
              name={defaultTestData.name}
              stepType={stepType}
              refTestDataList={refTestDataList ? refTestDataList : []}
            />
        }
          <div className="m-10">
          {defaultTestData?.testBlockTestDataSaveReq && defaultTestData?.testBlockstepCount > 0 &&
            <Collapse defaultActiveKey={[]}>
              <Collapse.Panel 
                collapsible={true} 
                header={"Test Block: Steps using Test Data"
                      + " [Steps using Test Data: " + defaultTestData?.testBlockstepCount
                      + " Unique Test Data Keys: " + defaultTestData?.testBlockuniqueTestDataCount + "]"}
                key={"Default"}>
                {defaultTestData.testBlockTestDataSaveReq.map((testBlockTestData) => {
                      return (
                        <ViewTestBlockDefaultTestDataSetV2
                          defaultTestData={testBlockTestData}
                          title={"Test Block:" + testBlockTestData.name}
                          stepType={stepType}
                          refTestDataList={refTestDataList ? refTestDataList : []}
                          testBlockInTestScenario={true}
                        />
                      );
                    })}
              </Collapse.Panel>
            </Collapse>
          }
          </div>
        </>
      )}
    </>
  );
};

export default ViewRecTestScenarioDefaultTestDataSetV2;