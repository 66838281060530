import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Select, Space, Spin } from "antd";
import { isEmpty, isEqual, isNull } from "lodash";
import {
  getTestRailConfig,
  getTestRailProject,
  getTestRailProjectOnLoad,
  resetTestRailDetails,
  updateTestRailConfig,
} from "../../actions/ProjectSettingsActions";
import { ERROR_STATUS } from "../../Constants";
import {
  isArrayNotUndefinedNotNullNotEmpty,
  isNotNullAndNotEmptyAndNotUndefined,
  isNullOrUndefinedOrEmpty,
  showNotification,
} from "../../Util";
import CommonInputFieldV2 from "../CommonComponents/CommonInputFieldV2/CommonInputFieldV2";
import CommonSelectBoxV2 from "../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import ErrorComponent from "../CoreComponents/ErrorComponent/ErrorComponent";
import SaveResetButtonV2 from "../CoreComponents/SaveResetButtonV2/SaveResetButtonV2";
import styles from "./ProjectSettingsV2.module.scss";

const initialTestRailConfigData = {
  host: "",
  userName: "",
  password: "",
  apiKey: "",
  testRailProjectId: "",
  isTestSuiteAvailable: "",
};
const { Option } = Select;

const TestRailsConfigV2 = (props) => {
  const { projectId } = props;
  const dispatch = useDispatch();
  const { getTestRailConfigData, projectLoading, getTestRailConfigProjectList, fetchProjectError, testRailLoading } =
    useSelector((state) => state.ProjectSettingsReducer);
  const { getProjectByOrgId } = useSelector((state) => state.ProjectsReducer);

  const [testRailConfigData, setTestRailConfigData] = useState(initialTestRailConfigData);
  const [testRailProjectName, setTestRailProjectName] = useState(undefined);
  const [isError, setIsError] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  //Destructure of object
  const { host, userName, password, apiKey, testRailProjectId } = testRailConfigData || {};

  const {
    host: getHost,
    userName: getUserName,
    password: getPassword,
    apiKey: getApiKey,
    testRailProjectId: getTestRailProjectId,
  } = getTestRailConfigData || {};

  useEffect(() => {
    dispatch(getTestRailConfig(projectId));
  }, [projectId]);

  useEffect(() => {
    dispatch(resetTestRailDetails);
    setTestRailConfigData(initialTestRailConfigData);
    if (isNotNullAndNotEmptyAndNotUndefined(testRailConfigData)) {
      dispatch(getTestRailConfig(projectId));
    }
  }, [getProjectByOrgId]);

  useEffect(() => {
    if (!isEmpty(getTestRailConfigData)) {
      setTestRailConfigData({
        host: getHost,
        userName: getUserName,
        password: getPassword,
        apiKey: getApiKey,
        testRailProjectId: getTestRailProjectId,
      });
      dispatch(
        getTestRailProjectOnLoad({
          host: getHost,
          userName: getUserName,
          password: getPassword || "",
          apiKey: getApiKey || "",
        })
      );
    }
  }, [getTestRailConfigData]);

  useEffect(() => {
    if (getTestRailConfigProjectList) {
      let projectName =
        getTestRailConfigProjectList &&
        getTestRailConfigProjectList.find((i) => i.id === parseInt(getTestRailProjectId));
      setTestRailProjectName(projectName && projectName.name);
    }
  }, [getTestRailConfigProjectList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTestRailConfigData({ ...testRailConfigData, [name]: value });
    setIsError(false);
    setIsSearch(true);
    setTestRailProjectName(undefined);
    setIsDisabled(true);
  };

  const searchForTestRailProject = () => {
    if (isNotNullAndNotEmptyAndNotUndefined(host) && isNotNullAndNotEmptyAndNotUndefined(userName)) {
      if (isNotNullAndNotEmptyAndNotUndefined(password) || isNotNullAndNotEmptyAndNotUndefined(apiKey)) {
        let preObj = {
          host,
          userName,
          password: password || "",
          apiKey: apiKey || "",
        };

        dispatch(getTestRailProject(preObj));
        setIsError(false);
        setIsSearch(false);
      } else {
        showNotification("info", "Please Enter Password Or ApiKey.");
      }
    } else {
      showNotification("info", "Please Enter All Details.");
    }
    setTestRailProjectName(undefined);
  };
  let projectsList = [];
  if (isArrayNotUndefinedNotNullNotEmpty(getTestRailConfigProjectList)) {
    for (let i = 0; i < getTestRailConfigProjectList.length; i++) {
      projectsList.push(
        <Option key={getTestRailConfigProjectList[i].id}>{getTestRailConfigProjectList[i].name}</Option>
      );
    }
  }

  const handleProjectChange = (e) => {
    setTestRailConfigData({ ...testRailConfigData, testRailProjectId: parseInt(e) });
    let projectName = getTestRailConfigProjectList && getTestRailConfigProjectList.find((i) => i.id === parseInt(e));
    setTestRailProjectName(projectName && projectName.name);
    setIsDisabled(false);
  };

  const saveTestRailConfig = () => {
    if (testRailConfigData && testRailProjectId) {
      let suiteId =
        getTestRailConfigProjectList && getTestRailConfigProjectList.find((i) => i.id === testRailProjectId);
      dispatch(
        updateTestRailConfig({
          ...testRailConfigData,
          isTestSuiteAvailable: suiteId.suiteMode,
          projectId: parseInt(projectId),
        })
      );
    }
  };

  useEffect(() => {
    if (fetchProjectError && fetchProjectError.status === ERROR_STATUS) {
      setIsError(true);
    }
  }, [fetchProjectError]);

  useEffect(() => {
    if (projectLoading) {
      setIsDisabled(true);
    }
  }, [projectLoading]);

  const resetTestRailConfigData = () => {
    if (!isEmpty(getTestRailConfigData)) {
      setTestRailConfigData({
        host: getHost,
        userName: getUserName,
        password: getPassword,
        apiKey: getApiKey,
        testRailProjectId: getTestRailProjectId,
      });

      dispatch(
        getTestRailProjectOnLoad({
          host: getHost,
          userName: getUserName,
          password: getPassword || "",
          apiKey: getApiKey || "",
        })
      );

      setIsError(false);
      setIsDisabled(true);
      setIsSearch(false);
    } else {
      setTestRailConfigData(initialTestRailConfigData);
      setTestRailProjectName("");
    }
  };

  const isResetDisable = () => {
    if (!isEmpty(getTestRailConfigData)) {
      return (
        isEqual(host, getHost) &&
        isEqual(userName, getUserName) &&
        isEqual(password, getPassword) &&
        isEqual(apiKey, getApiKey) &&
        isEqual(testRailProjectId, getTestRailProjectId)
      );
    }
    return Object.values(testRailConfigData).every((i) => isNullOrUndefinedOrEmpty(i));
  };

  const disableValidateCredential = () => {
    return (
      (!isNull(getTestRailConfigData) &&
        isEqual(host, getHost) &&
        isEqual(userName, getUserName) &&
        isEqual(password, getPassword) &&
        isEqual(apiKey, getApiKey)) ||
      isNullOrUndefinedOrEmpty(host) ||
      isNullOrUndefinedOrEmpty(userName) ||
      (isNullOrUndefinedOrEmpty(password) && isNullOrUndefinedOrEmpty(apiKey))
    );
  };

  const inputFields = [
    {
      label: "Host",
      name: "host",
      placeholder: "Please Enter Host",
      value: host,
      autoFocus: true,
    },
    {
      label: "User Name",
      name: "userName",
      placeholder: "Please Enter User Name",
      value: userName,
    },
    {
      label: "Password",
      name: "password",
      placeholder: "Please Enter Password",
      value: password,
    },
    {
      label: "API Key",
      name: "apiKey",
      placeholder: "Please Enter API key",
      value: apiKey,
    },
  ];

  return (
    <Spin spinning={projectLoading || testRailLoading} tip="Loading">
      <Row justify="space-between" align="middle" className={styles["main_Header"]}>
        <Col>
          <div className={styles["main-Header-Text"]}>Testrail Configuration</div>
        </Col>
        <Col>
          <Space>
            <SaveResetButtonV2
              submitLabel={!isEmpty(getTestRailConfigData) ? "Update" : "Save"}
              resetLabel={"Reset"}
              submitDisable={isDisabled}
              resetDisable={isResetDisable()}
              handleSubmit={saveTestRailConfig}
              handleReset={resetTestRailConfigData}
            />
          </Space>
        </Col>
      </Row>
      <Row justify="center" className={styles["page_Scroll"]}>
        <Col xs={{ span: 24 }} lg={{ span: 14 }} className={styles["project_Details"]}>
          <Row gutter={50} justify="center">
            <Col span={20}>
              <div className={styles["header_Title"]}>Basic Details</div>
              {inputFields.map((field, index) => (
                <CommonInputFieldV2
                  key={index}
                  label={field.label}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={field.value}
                  handleChange={handleChange}
                  labelBackgroundClass="label_Background"
                  required={true}
                  autoFocus={field.autoFocus}
                />
              ))}
              <Button
                className={`${styles["Search_And_validate_Btn"]} mb-20`}
                onClick={searchForTestRailProject}
                disabled={disableValidateCredential()}
              >
                Validate credentials
              </Button>
              {isError && <ErrorComponent errorMessage="Please check credentials is invalid." />}
              {!isEmpty(getTestRailConfigProjectList) && !isSearch && !testRailLoading && (
                <CommonSelectBoxV2
                  label="Testrail Project"
                  labelBackgroundClass={"label_Background"}
                  options={projectsList}
                  value={testRailProjectName || undefined}
                  placeholder="Select Testrail Project"
                  handleChange={(e) => handleProjectChange(e)}
                  required={true}
                />
              )}

              {!isDisabled && !isEmpty(getTestRailConfigData) && (
                <ErrorComponent
                  errorMessage={"Note: Changing the project will remove attached test case's with Test scenario."}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default TestRailsConfigV2;
