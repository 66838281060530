import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Spin } from "antd";
import { isEmpty } from "lodash";
import { USAGE_DETAILS, defaultDateTimeFormat, SAUCELABS, LAMBDA_TEST } from "../../../Constants";
import { CLOSE_ICON_RED } from "../../../Constants/SvgConstants";
import { displayUTCDateFormatWithToolTip, getOrgId } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import { hideDeviceMoreDetailsModal } from "../../../actions/ServerListAction";
import HeaderButtonsGroupV2 from "../../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import VerticalLine from "../../CommonComponents/VerticalLine";
import DeviceDetailsCardV2 from "../DeviceDetailsCardV2/DeviceDetailsCardV2";
import styles from "./DeviceMoreDetailsModalV2.module.scss";

const DeviceMoreDetailsModalV2 = ({ data, typeForDeviceModals, deviceServiceFarm }) => {
  const dispatch = useDispatch();
  const { visibleMoreDetailOfDevice, udidForOpenModal, stepTypeForDeviceModal, isFetchDeviceProjectListLoading } =
    useSelector((state) => state.ServerListReducer);

  const orgId = getOrgId();
  const isSameOrgId = data?.deviceAcquireForOrgId?.toString() === orgId;

  // useEffect(() => {
  //   if (typeForDeviceModals !== LAMBDA_TEST && typeForDeviceModals !== SAUCELABS)
  //     dispatch(getDeviceProjectList(data?.device?.targetUniqueId));
  // }, []);

  // useEffect(() => {
  //   if ((runNumber !== undefined || data?.deviceAcquireBy !== null) && !data?.device?.isDeviceOffline && isSameOrgId) {
  //     setCurrentOptions({ id: USAGE_DETAILS, value: runNumber ? `Run #:${runNumber}` : USAGE_DETAILS });
  //   } else {
  //   setCurrentOptions({ id: SHARED_WITH_PROJECTS, value: SHARED_WITH_PROJECTS });
  // }
  // }, [visibleMoreDetailOfDevice, runNumber, data, isSameOrgId]);

  // const initializeFilterPermission = (runNumber, data) => {
  //   let filterPermission = [
  //     { id: SHARED_WITH_PROJECTS, value: SHARED_WITH_PROJECTS },
  //     { id: SHARED_WITH_USERS, value: SHARED_WITH_USERS },
  //   ];

  //   if ((runNumber !== undefined || data?.deviceAcquireBy !== null) && !data?.device?.isDeviceOffline && isSameOrgId) {
  //     filterPermission = [
  //       { id: USAGE_DETAILS, value: runNumber ? `Run #:${runNumber}` : USAGE_DETAILS },
  //       ...filterPermission,
  //     ];
  //   }

  //   return filterPermission;
  // };

  const sharedDetails = (label, details, propertyName) => {
    return (
      <Spin spinning={isFetchDeviceProjectListLoading} className={styles["spinner_Center"]}>
        <div className={styles["shared_With_Details_Label"]}>
          {label} ({details?.length})
        </div>
        <div className={details?.length > 5 && styles.scrollBar}>
          {details?.map((data, index) => (
            <div className={styles["shared_With_Details"]} key={index}>
              {index + 1}.<span>{data?.[propertyName]}</span>
            </div>
          ))}
        </div>
      </Spin>
    );
  };

  //Destructure object
  const { runNumber, scheduleTime, result, projectName, testJobName } =
    data && data?.deviceAcquiredForRunNumberDetails ? data?.deviceAcquiredForRunNumberDetails : {};

  const initializeCurrentOptions = (runNumber, data) => {
    if ((runNumber !== undefined || data?.deviceAcquireBy !== null) && !data?.device?.isDeviceOffline) {
      return { id: USAGE_DETAILS, value: runNumber ? `Run #:${runNumber}` : USAGE_DETAILS };
    }
  };

  const initializeFilterPermission = (runNumber, data) => {
    if ((runNumber !== undefined || data?.deviceAcquireBy) && !data?.device?.isDeviceOffline && isSameOrgId) {
      return [{ id: USAGE_DETAILS, value: runNumber ? `Run #:${runNumber}` : USAGE_DETAILS }];
    }
    return [];
  };

  const [currentOptions, setCurrentOptions] = useState(initializeCurrentOptions(runNumber, data));

  const filterPermission = initializeFilterPermission(runNumber, data);

  const handleOnChange = useCallback(
    (value) => {
      setCurrentOptions(value);
    },
    [setCurrentOptions]
  );

  return (
    <Modal
      centered
      visible={
        udidForOpenModal === data?.device?.targetUniqueId &&
        typeForDeviceModals === stepTypeForDeviceModal &&
        visibleMoreDetailOfDevice
      }
      onCancel={() => dispatch(hideDeviceMoreDetailsModal())}
      className={`device_More_Details_Modal ${
        (typeForDeviceModals === SAUCELABS || typeForDeviceModals === LAMBDA_TEST) && "saucelabs_Device_More_Details"
      }`}
      closeIcon={
        <SvgLoader
          iconName={CLOSE_ICON_RED}
          handleClick={() => dispatch(hideDeviceMoreDetailsModal())}
          width="1.6rem"
          height="1.6rem"
        />
      }
      footer={[]}
    >
      <div className={styles["modal_content_wrapper"]}>
        <DeviceDetailsCardV2
          deviceData={data}
          deviceMoreDetailsModal={true}
          deviceServiceFarm={deviceServiceFarm}
          isSameOrgId={isSameOrgId}
        />
        {typeForDeviceModals !== SAUCELABS &&
          typeForDeviceModals !== LAMBDA_TEST &&
          currentOptions?.id === USAGE_DETAILS &&
          isSameOrgId &&
          (runNumber !== undefined || data?.deviceAcquireBy !== null) && (
            <>
              <VerticalLine className={styles["border_Bottom"]} />
              <div className={styles["device_More_Details"]}>
                <HeaderButtonsGroupV2
                  data={filterPermission}
                  activeButton={currentOptions?.id}
                  onClick={(e) => handleOnChange(e)}
                  wrapperClass={styles["header_Buttons_Gap"]}
                />
                <div className={styles["device_Shared_Details"]}>
                  {currentOptions?.id === USAGE_DETAILS ? (
                    runNumber !== undefined ? (
                      <>
                        <div className={styles["usage_Details"]}>
                          Started On:{" "}
                          <span> {displayUTCDateFormatWithToolTip(scheduleTime, defaultDateTimeFormat) || "-"}</span>
                        </div>
                        <div className={styles["usage_Details"]}>
                          Result: <span>{result}</span>
                        </div>
                        <div className={styles["usage_Details"]}>
                          Project: <span>{projectName}</span>
                        </div>
                        <div className={styles["usage_Details"]}>
                          Test Job: <span>{testJobName}</span>
                        </div>
                      </>
                    ) : (
                      data?.deviceAcquireBy !== null && (
                        <>
                          <div className={styles["usage_Details"]}>
                            Acquired by:
                            <span> {data?.deviceAcquireBy}</span>
                          </div>
                          <div className={styles["usage_Details"]}>
                            Acquired Time: <span>{data?.deviceAcquireTime}</span>
                          </div>
                          {!isEmpty(data?.deviceLastInteractTime) && (
                            <div className={styles["usage_Details"]}>
                              Last interaction time: <span>{data?.deviceLastInteractTime}</span>
                            </div>
                          )}
                        </>
                      )
                    )
                  ) : (
                    ""
                  )}
                  {/* {currentOptions?.id === SHARED_WITH_PROJECTS &&
                  sharedDetails("Shared with Projects", deviceProjectList, "projectName")}
                {currentOptions?.id === SHARED_WITH_USERS &&
                  sharedDetails("Shared with Users", data?.miscDetails?.users, "name")} */}
                </div>
              </div>
            </>
          )}
      </div>
    </Modal>
  );
};

export default DeviceMoreDetailsModalV2;
