import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "antd";
import styles from "./TestDataSetManagementV2.module.scss";
import SaveResetButtonV2 from "../CoreComponents/SaveResetButtonV2/SaveResetButtonV2";
import CommonInputFieldV2 from "../CommonComponents/CommonInputFieldV2/CommonInputFieldV2";

const EditOrAddProjectTestDataV2 = ({ formType, actionCreators, initialStateSelector, handleSubmit, formData }) => {
  const dispatch = useDispatch();
  const { id, name, value, requestSaveForm, previousValue } = formData;

  const enableSaveButton = useMemo(() => {
    return !requestSaveForm && name?.trim() && value?.trim() && previousValue !== value;
  }, [requestSaveForm, name, value, previousValue]);

  const inputFields = [
    {
      label: "Name",
      name: "name",
      placeholder: "Test Data Name",
      value: name,
      handleChange: (e) => dispatch(actionCreators.changeName(e.target.value)),
      autoFocus: true,
      disabled: !!id,
    },
    {
      label: "Value",
      name: "value",
      placeholder: "Test Data Value",
      value: value,
      handleChange: (e) => dispatch(actionCreators.changeValue(e.target.value)),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle" className={styles["main_Header"]}>
        <Col className={styles["main-Header-Text"]}>{`${id ? "Edit" : "Add"} ${formType}`}</Col>
        <Col>
          <SaveResetButtonV2
            submitLabel={id ? "Update" : "Add"}
            resetLabel={"Cancel"}
            submitDisable={!enableSaveButton}
            resetDisable={false}
            handleSubmit={() => handleSubmit()}
            handleReset={() => dispatch(actionCreators.hideForm())}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={{ span: 24 }} lg={{ span: 14 }} className={styles["project_Details"]}>
          <Row gutter={50} justify="center">
            <Col span={20}>
              {inputFields.map((field, index) => (
                <CommonInputFieldV2
                  key={index}
                  label={field.label}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={field.value}
                  handleChange={field.handleChange}
                  labelBackgroundClass="label_Background"
                  autoFocus={field.autoFocus}
                  disabled={field.disabled}
                />
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EditOrAddProjectTestDataV2;
