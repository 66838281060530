import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Col, Modal, Row, Space } from "antd";
import {
  ALL_JOBS,
  ANDROID,
  APP_MANAGER,
  BUILD_URL,
  CONFIRM_MODAL_CONTENT,
  DEVICE_EXECUTION_FARM,
  DEVICE_FARM_USE,
  MY_PLANS,
  OWNED,
  RECORD_10_PER_PAGE,
  SCHEDULE_TEST_JOB,
  SERIAL,
} from "../../../../Constants";
import { ICON_CLOSE } from "../../../../Constants/SvgConstants";
import {
  checkListIsEmpty,
  checkNotUndefined,
  checkNull,
  checkUndefined,
  isNullOrUndefinedOrEmpty,
  showNotification,
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  changeExecutionManagerTabAndSubTab,
  changeSelectedTestJobCriteria,
  ediBuildJob,
  resetTestJobData,
  saveTestJob,
  updateTestJobErrorDetail,
} from "../../../../actions/TestJobActions";
import { getTestPlanDetailsList, setScheduleTestPlan } from "../../../../actions/TestPlanAction";
import CommonConfirmationModalV2 from "../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import SaveResetButtonV2 from "../../../CoreComponents/SaveResetButtonV2/SaveResetButtonV2";
import { BuildJobModal } from "../../../ExecutionManager/JobDesigner/BuildJobModal";
import JobCriteriaV2 from "./JobCriteriaV2/JobCriteriaV2";
import JobCriteriaOptionsV2 from "./JobCriteriaOptionsV2/JobCriteriaOptionsV2";
import styles from "./BuildJobV2.module.scss";

const { confirm } = Modal;

const BuildJobV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId, orgId, showDeviceView } = props;

  const { testJobDetail, selectedTestJobCriteria, testJobErrorDetail, previousTestJobDetail } = useSelector(
    (state) => state.TestJobReducer
  );
  const { testPlanDetailData } = useSelector((state) => state.TestPlanReducer);
  const { getSearchDeviceList } = useSelector((state) => state.ManageFarmReducer);
  const { platform } = useSelector((state) => state.ProjectsReducer);

  //Destructure object
  const { devices, executionType, name, id, isUseLatestBuild, buildType, testPlan, buildDetail, fileUrl, appActivity } =
    testJobDetail;

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const deviceDetails = useMemo(
    () =>
      (getSearchDeviceList &&
        getSearchDeviceList.length > 0 &&
        getSearchDeviceList.filter(
          (data) =>
            !data.device.isDeviceOffline &&
            [DEVICE_FARM_USE.SCENARIO_RECORDING, DEVICE_FARM_USE.BOTH].includes(
              data.miscDetails?.farmDetails?.deviceFarmUse
            )
        )) ||
      [],
    [getSearchDeviceList]
  );

  const allDeviceIncludesInDeviceList = devices?.every((id) =>
    deviceDetails?.some((item) => item?.miscDetails?.deviceDetail?.targetUniqueId.toString() === id)
  );

  const saveJob = () => {
    if (Object.keys(testJobErrorDetail).filter((i) => testJobErrorDetail[i] === true).length === 0) {
      if (devices?.length < 2 && executionType !== SERIAL) {
        confirm({
          title:
            "Only serial execution mode will be enabled, as you have selected one device. If you would like to execute in parallel, you have to select multiple devices",
          onOk() {
            dispatch(saveTestJob(projectId, { id: OWNED, value: MY_PLANS }), allDeviceIncludesInDeviceList);
          },
        });
      } else {
        dispatch(saveTestJob(projectId, { id: OWNED, value: MY_PLANS }, allDeviceIncludesInDeviceList));
      }
    } else {
      showNotification("error", "Error in saving the job.", "");
    }
  };

  useEffect(() => {
    dispatch(updateTestJobErrorDetail());
  }, [selectedTestJobCriteria, testJobDetail]);

  useEffect(() => {
    if (id === undefined) {
      dispatch(
        getTestPlanDetailsList({
          pageNumber: "",
          recordPerPage: RECORD_10_PER_PAGE,
          searchKeyword: "",
          projectId: props.projectId,
          rights: "",
        })
      );
    }
  }, []);

  const isDisableSave = () => {
    const fileUrlIsEmpty =
      buildType === BUILD_URL && (checkUndefined(fileUrl) || fileUrl === "https://" || checkNull(fileUrl));
    const checkBuildDetails =
      buildType === BUILD_URL &&
      testJobDetail?.deviceServiceFarm !== DEVICE_EXECUTION_FARM.XPRESS &&
      platform === ANDROID
        ? fileUrlIsEmpty || checkNull(appActivity) || appActivity === ""
        : fileUrlIsEmpty;
    let testJobObj = {
      devices: devices,
      name: name,
      testPlanName: testPlan?.name,
    };
    const editData =
      JSON.stringify(testJobDetail) === JSON.stringify(previousTestJobDetail) ||
      (buildType === APP_MANAGER && buildDetail?.id === 0 && isUseLatestBuild === 0) ||
      checkBuildDetails;
    if (devices.length === 0) {
      return true;
    }
    if (buildType === APP_MANAGER) {
      if (isUseLatestBuild === 0) {
        testJobObj = { ...testJobObj, buildDetail: buildDetail?.displayName };
      } else {
        testJobObj = { ...testJobObj, isUseLatestBuild: isUseLatestBuild };
      }
    }
    if (buildType === BUILD_URL) {
      if (testJobDetail?.deviceServiceFarm !== DEVICE_EXECUTION_FARM.XPRESS && platform === ANDROID) {
        testJobObj = { ...testJobObj, fileUrl: fileUrl, appActivity: appActivity };
      } else {
        testJobObj = { ...testJobObj, fileUrl: fileUrl };
      }
    }
    if (checkNotUndefined(id)) {
      if (
        testJobDetail?.deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS &&
        !allDeviceIncludesInDeviceList &&
        devices?.length > 1
      ) {
        return (
          !allDeviceIncludesInDeviceList &&
          testJobDetail?.deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS &&
          devices?.length > 1 &&
          ((buildType === APP_MANAGER && buildDetail?.id === 0 && isUseLatestBuild === 0) ||
            fileUrlIsEmpty ||
            name === "")
        );
      } else {
        return editData;
      }
      // Edit With no change
    } else {
      // Save with No change
      return (
        Object.values(testJobObj).some((i) => isNullOrUndefinedOrEmpty(i) || checkListIsEmpty(i)) || fileUrlIsEmpty
      );
    }
  };

  const disableReset = () => {
    const buildJob = new BuildJobModal({});
    const keysToIgnore = ["testPlan"];
    const keys1 = Object.keys(buildJob).filter((key) => !keysToIgnore.includes(key));
    const keys2 = Object.keys(testJobDetail).filter((key) => !keysToIgnore.includes(key));
    if (keys1.length !== keys2.length || !keys1.every((key) => keys2.includes(key))) {
      return false;
    }
    for (let key of keys1) {
      if (JSON.stringify(buildJob[key]) !== JSON.stringify(testJobDetail[key])) {
        return false;
      }
    }
    if (!isEmpty(testPlanDetailData) && testPlanDetailData !== undefined) {
      if (testJobDetail.testPlan?.id !== testPlanDetailData[0]?.id) {
        return false;
      }
    }
    return true;
  };

  const isDisableReset = useCallback(() => {
    // Edit With no change
    return (
      (checkNotUndefined(id) && JSON.stringify(testJobDetail) === JSON.stringify(previousTestJobDetail)) ||
      (checkUndefined(id) && disableReset())
    );
  }, [testJobDetail, previousTestJobDetail, id]);

  const onBackClick = () => {
    if (isDisableReset()) {
      dispatch(changeExecutionManagerTabAndSubTab(ALL_JOBS));
    } else {
      setOpenConfirmModal(true);
    }
    dispatch(setScheduleTestPlan(null));
  };

  useEffect(() => {
    if (isDisableReset()) {
      dispatch(ediBuildJob(false));
    } else {
      dispatch(ediBuildJob(true));
    }
  }, [isDisableReset, dispatch]);

  return (
    <>
      <Row justify="space-between" align="middle" className={styles["main_Header"]}>
        <Col>
          <div className={styles["main-Header-Text"]}>Build Job</div>
        </Col>
        <Col>
          <Space>
            <SaveResetButtonV2
              submitLabel={"Save"}
              resetLabel={"Reset"}
              submitDisable={isDisableSave()}
              resetDisable={isDisableReset()}
              handleSubmit={saveJob}
              handleReset={() => {
                dispatch(changeSelectedTestJobCriteria(SCHEDULE_TEST_JOB.STEP1));
                dispatch(resetTestJobData());
                dispatch(ediBuildJob(false));
              }}
            />
            <div className={styles["close_Icon"]}>
              <SvgLoader iconName={ICON_CLOSE} width={36} height={36} handleClick={onBackClick} />
            </div>
          </Space>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={24} className={styles["page_Scroll"]}>
          <Col span={6}>
            <JobCriteriaV2 />
          </Col>
          <Col span={18}>
            <JobCriteriaOptionsV2 orgId={orgId} projectId={projectId} showDeviceView={showDeviceView} />
          </Col>
        </Col>
      </Row>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(changeExecutionManagerTabAndSubTab(ALL_JOBS));
          dispatch(ediBuildJob(false));
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle={"Discard Changes"}
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default BuildJobV2;
