import { Pagination } from "antd";
import React from "react";
import styles from "./PaginationComponentV2.module.scss";

const PaginationComponentV2 = (props) => {
  const {
    currentPage,
    totalPage,
    callPaginationAPI,
    setPageSize,
    totalItems,
    pageSize,
    onShowSizeChange,
    pageSizeChanger,
  } = props;

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <div
          className={`${styles["text"]} ${currentPage === 1 ? styles["disabled-text"] : "cursor__pointer"}`}
          onClick={() => currentPage !== 1 && callPaginationAPI(currentPage - 1)}
        >
          Previous
        </div>
      );
    }
    if (type === "next") {
      return (
        <div
          className={`${styles["text"]} ${totalPage !== currentPage ? "cursor__pointer" : styles["disabled-text"]}`}
          onClick={() => totalPage !== currentPage && callPaginationAPI(currentPage + 1)}
        >
          Next
        </div>
      );
    }
    if (type === "page") {
      const pageNumber = originalElement;
      const pageClassName = `${styles["page-number"]} ${currentPage === pageNumber ? styles["current-page"] : ""}`;
      return (
        <div key={pageNumber} className={pageClassName} onClick={() => handlePageChange(currentPage)}>
          {pageNumber}
        </div>
      );
    }
    return originalElement;
  };

  const handlePageChange = (page) => {
    callPaginationAPI(page, pageSize);
  };

  const handleSizeChange = (current, size) => {
    setPageSize(size);
    onShowSizeChange(current, size);
  };

  return (
    <>
      <div className={styles["pagination"]}>
        <Pagination
          total={totalItems}
          current={currentPage}
          defaultPageSize={pageSize ? pageSize : 10}
          defaultCurrent={1}
          itemRender={itemRender}
          onChange={handlePageChange}
          onShowSizeChange={handleSizeChange}
          showSizeChanger={pageSizeChanger ? true : false}
          showQuickJumper={pageSizeChanger ? true : false}
          className="pagination"
        />
      </div>
    </>
  );
};

export default PaginationComponentV2;
