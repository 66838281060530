import React from "react";
import { Avatar } from "antd";
import { getRandomColor } from "../../../Util";
import styles from "./ProjectOrTeamMembersV2.module.scss";

const ProjectOrTeamMembersV2 = ({ members }) => {
  return (
    <Avatar.Group maxCount={4}>
      {members?.map((i) => {
        const membersName = i?.name ? i?.name : i?.userName;
        return (
          <Avatar
            key={i.id || i.userName}
            className={styles["avatar-font"]}
            style={{
              backgroundColor: getRandomColor(),
            }}
          >
            {membersName
              ?.split(" ")
              ?.map((i) => i[0])
              ?.join("")}
          </Avatar>
        );
      })}
    </Avatar.Group>
  );
};

export default ProjectOrTeamMembersV2;
