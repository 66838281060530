import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { Drawer, Row } from "antd";
import { CUSTOM, privacyOption } from "../../../../Constants";
import SaveResetButtonV2 from "../../SaveResetButtonV2/SaveResetButtonV2";
import MemberListV2 from "./MemberListV2";
import styles from "./PermissionDrawerV2.module.scss";

const PermissionDrawerV2 = (props) => {
  const {
    openPermissionDrawer,
    onClose,
    permissionData,
    savePermission,
    members,
    previousPermission,
    setPermissionData,
    notShowExecutionCheck,
    ownerEmailId,
    drawerTitle,
  } = props;
  const [applyFor, setApplyFor] = useState([]);
  const [removeFor, setRemoveFor] = useState([]);

  useEffect(() => {
    setPermissionData();
  }, [permissionData]);

  return (
    <>
      <Drawer
        title={
          <Row justify="space-between" align="middle">
            <div>
              <div className={styles["drawer_Header_Text"]}>
                <div className={styles["drawer_Title_Label"]}>{drawerTitle}: </div>
                <div className={styles["drawer-title-Text"]}>{permissionData?.name || permissionData?.displayName}</div>
              </div>
              <div className={styles["drawer_Header_Text"]}>
                <div className={styles["drawer_Title_Label"]}> Rights: </div>
                <div className={styles["drawer-title-Text"]}>
                  {permissionData?.isPrivate === 1 ? privacyOption[0] : privacyOption[1]}
                </div>
              </div>
            </div>

            <div>
              <SaveResetButtonV2
                submitLabel={"Save"}
                resetLabel={"Reset"}
                submitDisable={isEqual([...applyFor, ...removeFor], previousPermission)}
                resetDisable={isEqual([...applyFor, ...removeFor], previousPermission)}
                handleSubmit={() => savePermission(CUSTOM, permissionData, [...applyFor, ...removeFor])}
                handleReset={setPermissionData}
              />
            </div>
          </Row>
        }
        visible={openPermissionDrawer}
        onClose={onClose}
        placement="right"
        width={"736"}
      >
        <MemberListV2
          notShowExecutionCheck={notShowExecutionCheck}
          ownerEmailId={ownerEmailId}
          applyFor={applyFor}
          setApplyFor={setApplyFor}
          setRemoveFor={setRemoveFor}
          removeFor={removeFor}
          members={members}
          openPermissionDrawer={openPermissionDrawer}
        />
      </Drawer>
    </>
  );
};

export default PermissionDrawerV2;
