import React, { useCallback, useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { PLAY_TYPE, TEST_SCENARIOS, TOOLTIP_BG_COLOR } from "../../../Constants";
import { startPreview, stopPreview } from "../../../actions/RecTestStepAction";
import {
  CLEAR_RESULTS,
  PLAY_SCENARIO,
  PLAY_TEST_BLOCK,
  PREVIEW_SETTINGS,
  RESULTS,
  STOP,
} from "../../../Constants/TooltipConstants";
import {
  ACTION_ICON_COLOR,
  CLEAR_RESULT_ICON,
  DISABLE_ICON_COLOR,
  REC_TEST_PLAY_ICON,
  SETTING_ICON,
  STOP_ICON,
  TEST_PLAN_RESULT_ICON,
} from "../../../Constants/SvgConstants";
import ButtonActionsV2 from "./ButtonActionsV2";
import { clearPreview, openPreviewSettingDrawer } from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { Tooltip } from "antd";
import SvgLoader from "../../../Util/SvgLoader";
import styles from "./EditTestHeaderOptionsV2.module.scss";

const PlayActionsV2 = (props) => {
  const { orgId, projectId, disabled, requestingPerformRecTestStepAction, previewRunOrBuildTestDataSetDisable } = props;
  const dispatch = useDispatch();

  const {
    requestingToStopPreview,
    isPreviewRunning,
    requestingForPreview,
    previewRunNumber,
    recTestStepList,
    startPreviewRequestLoading,
  } = useSelector((state) => state.RecTestStepReducer);
  const { recordingRequest, startDeviceStreamingStatus } = useSelector((state) => state.RecordingReducer);
  const { stepType, requestingForClearingPreview, previewTestDataSetName, previewAppLocale } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const { applicationStringAssigneeDetail, testDataSetAssigneeDetail, deviceStringAssigneeDetail } = useSelector(
    (state) => state.TestDataSetReducer
  );

  const playSpinners = (requestingToPreview) => {
    return (
      requestingToPreview.includes(PLAY_TYPE.ALL_STEPS) ||
      requestingToPreview?.includes(PLAY_TYPE.PLAY_THIS_STEP) ||
      requestingToPreview?.includes(PLAY_TYPE.PLAY_TILL_HERE) ||
      requestingToPreview?.includes(PLAY_TYPE.PLAY_FROM_HERE)
    );
  };

  const showStopButton = useMemo(() => {
    return !startPreviewRequestLoading && playSpinners(requestingForPreview);
  }, [startPreviewRequestLoading, requestingForPreview]);

  const disablePlayScenarioButton = useMemo(() => {
    return (
      !startDeviceStreamingStatus ||
      disabled ||
      isEmpty(recTestStepList) ||
      testDataSetAssigneeDetail ||
      requestingPerformRecTestStepAction ||
      applicationStringAssigneeDetail ||
      deviceStringAssigneeDetail
    );
  }, [
    startDeviceStreamingStatus,
    disabled,
    recTestStepList,
    testDataSetAssigneeDetail,
    requestingPerformRecTestStepAction,
    deviceStringAssigneeDetail,
    applicationStringAssigneeDetail,
  ]);

  const disableClearOrReportIcon = useMemo(() => {
    return (
      !previewRunNumber ||
      isPreviewRunning ||
      testDataSetAssigneeDetail !== undefined ||
      disabled ||
      applicationStringAssigneeDetail ||
      deviceStringAssigneeDetail ||
      requestingPerformRecTestStepAction
    );
  }, [
    previewRunNumber,
    isPreviewRunning,
    testDataSetAssigneeDetail,
    disabled,
    applicationStringAssigneeDetail,
    deviceStringAssigneeDetail,
    requestingPerformRecTestStepAction,
  ]);

  const openTestPlanResultDetails = useCallback(() => {
    const url = `/${orgId}/project/${projectId}/TestPlanResultDetails/${previewRunNumber}`;
    const win = window.open(url, "_blank");
    win.focus();
  }, [orgId, projectId, previewRunNumber]);

  return (
    <>
      {showStopButton && (
        <ButtonActionsV2
          name="Stop Scenario"
          classForButton="buttonForStopPlaying"
          toolTipTitle={STOP}
          handleClick={() =>
            !requestingToStopPreview?.includes(PLAY_TYPE.ALL_STEPS) ? dispatch(stopPreview()) : undefined
          }
          spinner={playSpinners(requestingToStopPreview)}
          imageName={STOP_ICON}
        />
      )}
      {!showStopButton && (
        <ButtonActionsV2
          name="Play Scenario"
          classForButton="buttonForActionStep"
          toolTipTitle={stepType === TEST_SCENARIOS ? PLAY_SCENARIO : PLAY_TEST_BLOCK}
          handleClick={() =>
            !recordingRequest && !disablePlayScenarioButton ? dispatch(startPreview(projectId)) : undefined
          }
          disabled={disablePlayScenarioButton}
          spinner={playSpinners(requestingForPreview) && startPreviewRequestLoading}
          imageName={REC_TEST_PLAY_ICON}
          imageColor={"#00C361"}
        />
      )}
      {previewRunNumber && !isPreviewRunning && (
        <div className={styles["show_Results_Icon"]}>
          <ButtonActionsV2
            name="Result"
            classForButton="buttonForActionStep"
            toolTipTitle={RESULTS}
            handleClick={() => !disableClearOrReportIcon && openTestPlanResultDetails()}
            disabled={disableClearOrReportIcon}
            imageName={TEST_PLAN_RESULT_ICON}
          />
          <ButtonActionsV2
            name="Clear Result"
            classForButton="buttonForActionStep"
            toolTipTitle={CLEAR_RESULTS}
            handleClick={() => (!disableClearOrReportIcon ? dispatch(clearPreview()) : undefined)}
            disabled={disableClearOrReportIcon}
            spinner={requestingForClearingPreview}
            imageName={CLEAR_RESULT_ICON}
          />
        </div>
      )}
      <Tooltip
        title={
          <div>
            {previewTestDataSetName || previewAppLocale ? (
              <>
                <div>{previewTestDataSetName && "Test Data Profile : " + previewTestDataSetName}</div>
                <div>{previewAppLocale && "App Locale : " + previewAppLocale}</div>
              </>
            ) : (
              PREVIEW_SETTINGS
            )}
          </div>
        }
        color={TOOLTIP_BG_COLOR}
        mouseEnterDelay={0}
        mouseLeaveDelay={0}
      >
        <div className={styles["buttonForActionStep"]}>
          <div onClick={() => !previewRunOrBuildTestDataSetDisable && dispatch(openPreviewSettingDrawer())}>
            <SvgLoader
              iconName={SETTING_ICON}
              iconColor={previewRunOrBuildTestDataSetDisable ? DISABLE_ICON_COLOR : ACTION_ICON_COLOR}
              disabled={previewRunOrBuildTestDataSetDisable}
              width="1.88rem"
              height="1.88rem"
            />
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default PlayActionsV2;