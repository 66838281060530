import { Checkbox, Dropdown, Menu, Popover, Spin, Tooltip } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APPLICATION_STRING_TAB, TOOLTIP_BG_COLOR } from "../../../Constants";
import {
  CLEAR_STRINGS_ICON,
  CREATE_ICON,
  ICON_EDIT_NEW,
  SILVER_GRAY,
  TRASH_ICON,
  WHITE_COLOR,
} from "../../../Constants/SvgConstants";
import { removeSelectedKeys } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import { importApplicationStringsFormVisible } from "../../../actions/ApplicationStrings";
import {
  addApplicationStringsFormVisible,
  deleteApplicationString,
  getApplicationStrings,
  loadEditApplicationStringDetail,
  setApplicationStringCurrentTab,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import CustomButtonV2 from "../../CommonComponents/CustomButtonV2/CustomButtonV2";
import HeaderButtonsGroupV2 from "../../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import SelectAllBoxV2 from "../../CommonComponents/SelectAllBoxV2/SelectAllBoxV2";
import PaginationComponentV2 from "../../CoreComponents/PaginationComponent/PaginationComponentV2";
import CommonSearchV2 from "../../CoreComponents/SearchComponent/CommonSearchV2";
import TableComponentV2 from "../../CoreComponents/TableComponent/TableComponentV2";
import styles from "./ApplicationStringsV2.module.scss";

const filterPermission = [
  { id: APPLICATION_STRING_TAB.ALL, value: "All" },
  { id: APPLICATION_STRING_TAB.IMPORTED, value: "Imported" },
  { id: APPLICATION_STRING_TAB.CUSTOM, value: "Custom" },
];

const ApplicationStringsListV2 = (props) => {
  const { projectId, activeFilter, setActiveFilter } = props;
  const {
    getApplicationString,
    applicationStringLoader,
    getApplicationStringPageCount,
    getApplicationStringCurrentCount,
    getApplicationStringCurrentTab,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();

  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectImportCheckBox, setSelectImportCheckBox] = useState([]);
  const [selectAllCustomCheckBox, setSelectAllCustomCheckBox] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(null);

  const selectCustomStringsTab = getApplicationStringCurrentTab === APPLICATION_STRING_TAB.CUSTOM;

  useEffect(() => {
    dispatch(
      getApplicationStrings(
        projectId,
        searchKeyword,
        "",
        getApplicationStringCurrentCount,
        "",
        "",
        "",
        getApplicationStringCurrentTab
      )
    );
  }, [projectId, dispatch]);

  const confirmClear = (deleteAllStrings) => {
    const action = deleteAllStrings ? "delete all" : "delete the selected";
    const selectedApplicationStringLength = selectCustomStringsTab
      ? selectAllCustomCheckBox?.length
      : selectImportCheckBox?.length;
    const message = `Are you sure you want to ${action} ${selectedApplicationStringLength > 1 ? "items" : "item"}?`;
    setOpenConfirmModal({
      key: selectCustomStringsTab ? [...selectAllCustomCheckBox] : [...selectImportCheckBox],
      message,
    });
  };

  const isSelectedAllIStrings = (selectedIds, records) => {
    let selectedRecords = records && records?.filter((i) => selectedIds?.includes(i?.key));
    return records?.length && selectedRecords?.length === records?.length;
  };

  const clearAllApplicationStrings = () => {
    return (
      <Tooltip
        color={TOOLTIP_BG_COLOR}
        title={`Delete All ${selectCustomStringsTab ? "Custom" : "Imported"} Strings`}
        placement="bottomRight"
      >
        <div>
          <SvgLoader
            iconName={CLEAR_STRINGS_ICON}
            iconColor="#FF188F"
            handleClick={() => {
              confirmClear(true);
            }}
          />
        </div>
      </Tooltip>
    );
  };

  const applicationStringSelectionCheckboxType = (row) => {
    return (
      <span>
        <Checkbox
          className="selection-checkBox mr-10"
          checked={
            selectCustomStringsTab
              ? selectAllCustomCheckBox && selectAllCustomCheckBox?.includes(row?.key)
              : selectImportCheckBox && selectImportCheckBox?.includes(row?.key)
          }
          onChange={(e) => {
            if (e.target.checked) {
              selectCustomStringsTab
                ? setSelectAllCustomCheckBox([...selectAllCustomCheckBox, row?.key])
                : setSelectImportCheckBox([...selectImportCheckBox, row?.key]);
            } else {
              selectCustomStringsTab
                ? setSelectAllCustomCheckBox(
                    selectAllCustomCheckBox && selectAllCustomCheckBox?.filter((i) => i !== row?.key)
                  )
                : setSelectImportCheckBox(selectImportCheckBox && selectImportCheckBox?.filter((i) => i !== row?.key));
            }
          }}
        />
      </span>
    );
  };

  const handleCheckboxChange = (isChecked, selectedCheckBox, setSelectedCheckBox) => {
    if (isChecked) {
      setSelectedCheckBox([
        ...selectedCheckBox,
        ...(
          getApplicationString?.data &&
          getApplicationString?.data?.filter((i) => !selectedCheckBox.includes(i?.key) && i?.key)
        )?.map((i) => i?.key),
      ]);
    } else {
      setSelectedCheckBox(removeSelectedKeys(selectedCheckBox, getApplicationString?.data));
    }
  };

  const columns = [
    {
      title: (
        <div className={styles["checkBox_In_Header"]}>
          {(selectCustomStringsTab ? isEmpty(selectAllCustomCheckBox) : isEmpty(selectImportCheckBox)) &&
            getApplicationStringCurrentTab !== APPLICATION_STRING_TAB.ALL &&
            !isEmpty(getApplicationString?.data) && (
              <span>
                <Checkbox
                  className="selection-checkBox mr-10"
                  onChange={(e) => {
                    selectCustomStringsTab
                      ? handleCheckboxChange(e.target.checked, selectAllCustomCheckBox, setSelectAllCustomCheckBox)
                      : handleCheckboxChange(e.target.checked, selectImportCheckBox, setSelectImportCheckBox);
                  }}
                />
              </span>
            )}
          Key
        </div>
      ),
      dataIndex: "name",
      width: 500,
    },
    {
      title: "Values",
      dataIndex: "value",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "right",
    },
  ];

  const applicationStringsData =
    getApplicationString &&
    getApplicationString?.data?.map((row) => ({
      key: row?.id,
      name: (
        <div className={styles["checkBox_And_Key"]}>
          {getApplicationStringCurrentTab !== APPLICATION_STRING_TAB.ALL && applicationStringSelectionCheckboxType(row)}
          <div className={styles["application_String_Key"]}>{row?.key}</div>
        </div>
      ),
      value: (
        <Popover
          color={TOOLTIP_BG_COLOR}
          title="Locales"
          content={row?.values.map((data, i) => (
            <div key={data}>
              <span style={{ marginRight: 5 }}>{i + 1}</span>
              {data.locale + " : " + data.value}
              <br></br>
            </div>
          ))}
        >
          {`${row?.locale} ${row?.locale === 1 ? " Locale" : " Locales"}`}
        </Popover>
      ),
      action: (
        <div className={styles["action_Icons"]}>
          <Tooltip title="Edit" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div>
              <SvgLoader
                iconName={ICON_EDIT_NEW}
                handleClick={() => dispatch(loadEditApplicationStringDetail(projectId, row?.key, row?.isImport))}
              />
            </div>
          </Tooltip>
          <Tooltip title="Delete" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div>
              <SvgLoader iconName={TRASH_ICON} iconColor={SILVER_GRAY} handleClick={() => setOpenConfirmModal([row.key])} />
            </div>
          </Tooltip>
        </div>
      ),
    }));

  const selectAllStrings = (selectedStrings, setSelectedStrings) => {
    return (
      <div className={styles["select_All_Box"]}>
        <SelectAllBoxV2
          selectAllIds={selectedStrings}
          isChecked={isSelectedAllIStrings(selectedStrings, getApplicationString?.data)}
          handleChangeCheck={(isChecked) => {
            if (isChecked) {
              setSelectedStrings([
                ...selectedStrings,
                ...(
                  getApplicationString?.data &&
                  getApplicationString?.data?.filter((i) => !selectedStrings.includes(i?.key) && i?.key)
                )?.map((i) => i.key),
              ]);
            } else {
              setSelectedStrings(removeSelectedKeys(selectedStrings, getApplicationString?.data));
            }
          }}
          deleteConfirm={confirmClear}
          isDisable={isEmpty(selectedStrings)}
        />
      </div>
    );
  };

  const handleFilters = (e) => {
    setActiveFilter(e);
    dispatch(setApplicationStringCurrentTab(e?.id));
    dispatch(getApplicationStrings(projectId, searchKeyword, "", "", "", "", "", e?.id));
  };

  const selectedApplicationString =
    activeFilter?.id === APPLICATION_STRING_TAB.IMPORTED
      ? !isEmpty(selectImportCheckBox)
      : !isEmpty(selectAllCustomCheckBox);

  return (
    <Spin spinning={applicationStringLoader} tip="Loading">
      <div className={styles["page_Scroll"]}>
        <div className={styles["sub_Header"]}>
          <CommonSearchV2
            placeHolder="Search by Application string name"
            searchDetails={(searchText) =>
              dispatch(getApplicationStrings(projectId, searchText, "", "", "", "", "", getApplicationStringCurrentTab))
            }
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
          />
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => dispatch(importApplicationStringsFormVisible(true, projectId))}>
                  Import From XML
                </Menu.Item>
                <Menu.Item onClick={() => dispatch(addApplicationStringsFormVisible(true))}>Add Custom Value</Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            placement="bottomRight"
          >
            <div>
              <CustomButtonV2
                iconColor={WHITE_COLOR}
                buttonIcon={CREATE_ICON}
                buttonLabel="Application Strings"
                className={styles["add_Application_String_Btn"]}
                handleClick={(e) => e?.preventDefault()}
              />
            </div>
          </Dropdown>
        </div>
        <div className={styles["header_Button_Group"]}>
          <HeaderButtonsGroupV2
            data={filterPermission}
            activeButton={activeFilter?.id}
            onClick={(e) => handleFilters(e)}
          />
          {!isEmpty(getApplicationString?.data) &&
            (activeFilter?.id === APPLICATION_STRING_TAB.IMPORTED ||
              activeFilter?.id === APPLICATION_STRING_TAB.CUSTOM) && (
              <div className={styles["clear_All_String"]}>{clearAllApplicationStrings()}</div>
            )}
        </div>
          <div className="table_Details__wrapper">
            {selectedApplicationString && (
              <div className={styles["select--all-box"]}>
                {activeFilter?.id === APPLICATION_STRING_TAB.IMPORTED &&
                  !isEmpty(selectImportCheckBox) &&
                  selectAllStrings(selectImportCheckBox, setSelectImportCheckBox)}
                {activeFilter?.id === APPLICATION_STRING_TAB.CUSTOM &&
                  !isEmpty(selectAllCustomCheckBox) &&
                  selectAllStrings(selectAllCustomCheckBox, setSelectAllCustomCheckBox)}
              </div>
            )}
            <TableComponentV2
              columns={columns}
              data={applicationStringsData}
              selected={selectImportCheckBox || selectAllCustomCheckBox}
              className={`table__wrapper ${selectedApplicationString && " hide_column_name"}`}
            />
            <div className="pagination_Section">
              {!isEmpty(getApplicationString?.data) && (
                <PaginationComponentV2
                  currentPageItems={getApplicationString?.pageItems}
                  totalItems={getApplicationString?.totalItems}
                  currentPage={getApplicationString?.currentPage}
                  totalPage={getApplicationString?.totalCount}
                  pageSize={15}
                  callPaginationAPI={(pageNumber) =>
                    pageNumber !== getApplicationString?.currentPage &&
                    dispatch(
                      getApplicationStrings(
                        projectId,
                        searchKeyword,
                        "",
                        pageNumber,
                        "",
                        "",
                        "",
                        getApplicationStringCurrentTab
                      )
                    )
                  }
                />
              )}
            </div>
          </div>
        <CommonConfirmationModalV2
          handleOk={() => {
            dispatch(
              deleteApplicationString(
                openConfirmModal?.key ? openConfirmModal?.key : openConfirmModal,
                getApplicationStringCurrentTab,
                getApplicationStringPageCount
              )
            );
            if (selectCustomStringsTab) {
              setSelectAllCustomCheckBox([]);
            } else {
              setSelectImportCheckBox([]);
            }
            setOpenConfirmModal(null);
          }}
          handleCancel={() => setOpenConfirmModal(null)}
          modalOpen={!!openConfirmModal}
          modalTitle={"Confirm Deletion"}
          modalContent={
            openConfirmModal?.message ? openConfirmModal?.message : "Are you sure you want to delete this item?"
          }
          okText="Delete"
          isDeleteModal={true}
          modalNote="Note: Application String will be removed from associated steps/elements."
        />
      </div>
    </Spin>
  );
};

export default ApplicationStringsListV2;
