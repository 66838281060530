import { default as React, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Col, Divider, Row, Spin } from "antd";
import { TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../Constants";
import { onRecTestStepDrop } from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { onRecTestStepDragOver, onRecTestStepDragStart } from "../../../actions/RecTestStepAction";
import { disableKeyboard } from "../../../actions/Recording";
import RecTestStepListHeaderV2 from "../StepInnerComponents/RecTestStepListHeaderV2/RecTestStepListHeaderV2";
import RecTestStepConditionV2 from "./RecTestStepConditionV2/RecTestStepConditionV2";
import RecTestStepListV2 from "./RecTestStepListV2";
import styles from "./StepDetailsV2.module.scss";

const StepDetailsV2 = ({ projectId, organizationId }) => {
  const dispatch = useDispatch();

  const {
    isPreviewRunning,
    draggingRequest,
    fetchingRecTestStepList,
    selectedDeviceByUdid,
    recTestStepList,
    testBlockStep,
  } = useSelector((state) => state.RecTestStepReducer);
  const { requestingForNewScenario } = useSelector((state) => state.RecTestScenarioReducer);
  const { requestingPerformRecTestStepAction, stepType, isShowAIGenerateStepsRow } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  let disableAction = false;
  let unSavedStep = false;

  disableAction = useMemo(
    () => unSavedStep || requestingPerformRecTestStepAction,
    [unSavedStep, requestingPerformRecTestStepAction]
  );

  return (
    <>
      <Spin
        spinning={
          draggingRequest || requestingForNewScenario || fetchingRecTestStepList || requestingPerformRecTestStepAction
        }
        tip={fetchingRecTestStepList && "Loading data, please wait..."}
      >
        <Row onFocus={() => dispatch(disableKeyboard())}>
          {/* <Col span={24}>
            <RecTestStepListHeaderV2
              projectId={projectId}
              setNotShowStepList={setNotShowStepList}
              notShowActionList={notShowActionList}
              notShowStepList={notShowStepList}
              setNotShowActionList={setNotShowActionList}
              recTestStepList={recTestStepList}
              testBlockStep={testBlockStep}
              stepType={stepType}
              platform={selectedScenarioPlatform}
            />
            {!isEmpty(recTestStepList) && <Divider className={styles["divider"]} />}
          </Col> */}
          <Col
            span={24}
            className={`rec-Test-Step-List  ${
              isShowAIGenerateStepsRow && stepType === TEST_SCENARIOS && styles["show_AI_Step"]
            }`}
          >
            <Col span={24} className={styles["steps-Area"]}>
              {testBlockStep && stepType === TEST_STEP_BLOCKS && (
                <RecTestStepConditionV2
                  recTestSteps={recTestStepList}
                  isDeviceLoaded={selectedDeviceByUdid !== undefined}
                  recTestStep={testBlockStep}
                  disableAction={disableAction}
                  isSkipStep={testBlockStep.isSkipStep}
                  isTestBlock={true}
                  projectId={projectId}
                />
              )}
              <RecTestStepListV2
                recTestSteps={recTestStepList}
                selectedDeviceByUdid={selectedDeviceByUdid}
                projectId={projectId}
                organizationId={organizationId}
                disableAction={disableAction || isPreviewRunning}
                isDeviceLoaded={selectedDeviceByUdid !== undefined}
              />
              {recTestStepList && (
                <div
                  key={recTestStepList.length}
                  className={styles["empty-Area-of-Designer"]}
                  onDragStart={(event) => dispatch(onRecTestStepDragStart(event))}
                  onDragOver={(event) => dispatch(onRecTestStepDragOver(event))}
                  onDrop={(event) => dispatch(onRecTestStepDrop(event, undefined, undefined, recTestStepList.length))}
                />
              )}
            </Col>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default StepDetailsV2;
