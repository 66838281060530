import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { InfoCircleFilled } from "@ant-design/icons";
import { Divider, Tooltip } from "antd";
import { fetchProjectKeysData, fetchTestDataByProjectId } from "../../actions/TestDataAction";
import { PROJECT_KEYS, SYSTEM_KEY_WARNING_CONTENT, TEST_DATA, TOOLTIP_BG_COLOR } from "../../Constants";
import CommonConfirmationModalV2 from "../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import HeaderButtonsGroupV2 from "../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import ProjectKeysV2 from "./ProjectKeysV2/ProjectKeysV2";
import SystemKeysV2 from "./SystemKeysV2/SystemKeysV2";
import styles from "./TestDataSetManagementV2.module.scss";

const TestDataSetManagementV2 = (props) => {
  const { projectId } = props;
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [activeFilter, setActiveFilter] = useState({ id: PROJECT_KEYS, value: "Project Keys" });

  const filterPermission = [
    { id: PROJECT_KEYS, value: "Project Keys" },
    {
      id: TEST_DATA,
      value: (
        <span className={styles["tab_Name_And_Icon"]}>
          System Keys
          <Tooltip title="Learn more about System Keys" color={TOOLTIP_BG_COLOR}>
            <div className={styles["info_Icon"]}>
              <InfoCircleFilled onClick={() => setOpenConfirmModal(true)} />
            </div>
          </Tooltip>
        </span>
      ),
    },
  ];

  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    setActiveFilter(e);
    if (e?.id === TEST_DATA) {
      dispatch(fetchTestDataByProjectId(projectId));
    } else {
      dispatch(fetchProjectKeysData(projectId));
    }
  };
  return (
    <div className="deviceTab">
      <div className={styles["header_Button_Group"]}>
        <HeaderButtonsGroupV2
          data={filterPermission}
          activeButton={activeFilter?.id}
          onClick={(e) => handleOnChange(e)}
        />
      </div>
      <Divider className={styles["divider"]} />

      {activeFilter?.id === PROJECT_KEYS ? (
        <ProjectKeysV2 projectId={projectId} />
      ) : (
        <SystemKeysV2 projectId={projectId} />
      )}
      <CommonConfirmationModalV2
        handleOk={() => {
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={SYSTEM_KEY_WARNING_CONTENT}
        modalTitle="SYSTEM KEYS HAVE LIMITED USE"
        okText="Ok"
        hideCancelButton={true}
      />
    </div>
  );
};

export default TestDataSetManagementV2;
