import { Col, Row } from "antd";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BUILD_TEST_DATA_SET } from "../../../Constants";
import { setTestDataSetAssigneeDetail } from "../../../actions/TestDataSet/TestDataSetCommonAction";
import { changeTestDataSetTab } from "../../../actions/TestDataSet/TestDataSetRedirectAction";
import BackButton from "../../CoreComponents/BackButtonComponent/BackButton";
import AllTestDataSetV2 from "../../TestPlan/TestPlanManager/TestPlanTabs/BuildTestDataSet/AllTestDataSetV2/AllTestDataSetV2";
import styles from "./TestStepsDataV2.module.scss";

const TestDataSetTabsV2 = (props) => {
  const { projectId, selectedStepId } = props;
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTestDataSetTab(BUILD_TEST_DATA_SET));
  }, [stepType, dispatch]);

  const handleBackButton = useCallback(() => {
    dispatch(setTestDataSetAssigneeDetail(undefined));
  }, [dispatch]);

  return (
    <>
      <Row justify="space-between" align="middle" className={styles["main-header"]}>
        <Col>
          <div className={styles["main-Header-Title"]}>Manage Test Data Profiles</div>
        </Col>
        <Col>
          <div className="close-Icon">
            <BackButton handleBack={handleBackButton} isBlack={true} />
          </div>
        </Col>
      </Row>
      <div>
        <AllTestDataSetV2 projectId={projectId} stepType={stepType} selectedStepId={selectedStepId} />
      </div>
    </>
  );
};

export default TestDataSetTabsV2;
