import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNewProjectKeyName,
  changeNewProjectKeyValue,
  hideAddProjectKeysForm,
  saveNewTestData,
} from "../../../actions/TestDataAction";
import { TEST_DATA_SCOPE } from "../../../Constants";
import EditOrAddProjectTestDataV2 from "../EditOrAddProjectTestDataV2";
import ProjectTestDataV2 from "../ProjectTestDataV2";

const ProjectKeysV2 = (props) => {
  const { projectId } = props;
  const dispatch = useDispatch();
  const {
    addProjectKeyForm,
    requestSaveProjectKeyForm,
    newProjectKeyId,
    newProjectKeyName,
    newProjectKeyValue,
    previous_newProjectKeyValue,
  } = useSelector((state) => state.TestDataReducer);

  const actionCreators = {
    changeName: changeNewProjectKeyName,
    changeValue: changeNewProjectKeyValue,
    hideForm: hideAddProjectKeysForm,
  };

  const formData = {
    id: newProjectKeyId,
    name: newProjectKeyName,
    value: newProjectKeyValue,
    requestSaveForm: requestSaveProjectKeyForm,
    previousValue: previous_newProjectKeyValue,
  };

  return (
    <div>
      {addProjectKeyForm ? (
        <EditOrAddProjectTestDataV2
          formType="Project Keys"
          projectId={projectId}
          actionCreators={actionCreators}
          formData={formData}
          handleSubmit={() =>
            dispatch(
              saveNewTestData(
                newProjectKeyName,
                newProjectKeyValue,
                newProjectKeyId,
                projectId,
                TEST_DATA_SCOPE.PROJECT,
                0,
                undefined,
                TEST_DATA_SCOPE.PROJECT,
                true
              )
            )
          }
        />
      ) : (
        <ProjectTestDataV2 projectId={projectId} projectKeys={TEST_DATA_SCOPE.PROJECT} />
      )}
    </div>
  );
};

export default ProjectKeysV2;
