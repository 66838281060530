import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import { ACTION_VIEW, BUILD_TEST_SCENARIO, CUSTOM, PRIVATE, PUBLIC, TOOLTIP_BG_COLOR } from "../../../Constants";
import {
  ICON_APPLICATION_STRING_NEW,
  ICON_BUILD_TEST_DATA_SET_NEW,
  ICON_EDIT_NEW,
} from "../../../Constants/SvgConstants";
import { BUILD_TEST_DATA_SET } from "../../../Constants/TooltipConstants";
import SvgLoader from "../../../Util/SvgLoader";
import {
  setApplicationAssigneeDetail,
  setTestDataSetAssigneeDetail,
} from "../../../actions/TestDataSet/TestDataSetCommonAction";
import { editScenario } from "../../../actions/TestPlanAction";
import ActionComponentV2 from "../../CoreComponents/ActionComponentV2/ActionComponentV2";
import styles from "../RecTestScenarioV2.module.scss";

const RecTestScenarioActionsColumnV2 = (props) => {
  const dispatch = useDispatch();
  const { row, isScenarioLoadModel, navigateToEditBuildScenario, handleActionChange, projectId } = props;

  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  //Destructure of object
  const { allowDelete, id, createdByEmail, allowEdit, name, allowAdmin, isPrivate, recTestScenarioRights } = row
    ? row
    : {};

  const handleAction = () => {
    let arr = [];
    arr.push({
      title: "Device Strings",
      name: "deviceStrings",
    });
    arr.push({
      title: "Duplicate",
      name: "duplicate",
    });
    arr.push({
      title: "Preview History",
      name: "previewHistory",
    });

    if (allowDelete === ACTION_VIEW) {
      arr.push({
        title: "Delete",
        name: "delete",
      });
    }
    if (loggedInUserDetails?.userEmail === createdByEmail || allowAdmin === ACTION_VIEW) {
      arr.push({
        title: "Permissions",
        name: "permissions",
        children: [
          ((isPrivate !== 1 && loggedInUserDetails?.userEmail === createdByEmail) ||
            (isPrivate === 1 &&
              !isEmpty(recTestScenarioRights?.data) &&
              loggedInUserDetails?.userEmail === createdByEmail)) && {
            title: "Make Private",
            name: PRIVATE,
          },
          isPrivate !== 0 && {
            title: "Make Public",
            name: PUBLIC,
          },
          {
            title: "Custom",
            name: CUSTOM,
          },
        ],
      });
    }
    return arr;
  };

  return (
    <>
      {!isScenarioLoadModel && (
        <div className={styles["action-icon"]} id={id}>
          {allowEdit === ACTION_VIEW && (
            <Tooltip color={TOOLTIP_BG_COLOR} title="Edit" mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div className={styles["test--scenarios-action__buttons"]}>
                <SvgLoader
                  iconName={ICON_EDIT_NEW}
                  handleClick={() => {
                    navigateToEditBuildScenario(BUILD_TEST_SCENARIO);
                    dispatch(editScenario(row, projectId));
                  }}
                />
              </div>
            </Tooltip>
          )}
          <Tooltip title={BUILD_TEST_DATA_SET} color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div className={styles["test--scenarios-action__buttons"]}>
              <SvgLoader
                iconName={ICON_BUILD_TEST_DATA_SET_NEW}
                handleClick={() => {
                  navigateToEditBuildScenario(BUILD_TEST_SCENARIO);
                  dispatch(editScenario(row, projectId));
                  dispatch(setTestDataSetAssigneeDetail(row));
                }}
              />
            </div>
          </Tooltip>
          <Tooltip title={"Application Strings"} color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div className={styles["test--scenarios-action__buttons"]}>
              <SvgLoader
                iconName={ICON_APPLICATION_STRING_NEW}
                handleClick={() => {
                  dispatch(
                    setApplicationAssigneeDetail({
                      id: id,
                      name: name,
                    })
                  );
                  navigateToEditBuildScenario(BUILD_TEST_SCENARIO);
                  dispatch(editScenario(row, projectId));
                }}
              />
            </div>
          </Tooltip>

          <ActionComponentV2 actions={handleAction()} data={row} handleActionChange={handleActionChange} />
        </div>
      )}
    </>
  );
};
export default RecTestScenarioActionsColumnV2;
