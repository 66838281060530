import React, { useEffect, useMemo, useState } from "react";
import { Col, Spin } from "antd";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../../../../Constants";
import { getListOfBlocksByProjectId } from "../../../../../../actions/TestBlocksAction";
import {
  changeTestDataSetView,
  fetchTestDataSetByProfileId,
} from "../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import { fetchTestPlanList } from "../../../../../../actions/TestDataSetAction";
import TestDataSetDetailsListV2 from "./TestDataSetDetailsListV2";
import TestDataDetailsColumnV2 from "./AllTestDataSetTableDetails/TestDataDetailsColumnV2";
import TestDataSetsColumn from "./AllTestDataSetTableDetails/TestDataSetsColumn";
import DefaultSwitchColumnV2 from "./AllTestDataSetTableDetails/DefaultSwitchColumnV2";
import TestDataSetActionsColumnV2 from "./AllTestDataSetTableDetails/TestDataSetActionsColumnV2";
import EditTestDataSetV2 from "../EditTestDataSetV2/EditTestDataSetV2";
import ViewDefaultTestDataSetV2 from "../ViewDefaultTestDataSetV2/ViewDefaultTestDataSetV2";
import TestStepsManageDataV2 from "../../../../../CommonComponents/TestStepsDataV2/TestStepsManageDataV2";

const AllTestDataSetV2 = (props) => {
  const dispatch = useDispatch();

  const { testPlanIdNameList, testDataSetAssigneeDetail } = useSelector((state) => state.TestDataSetReducer);
  const { testDataSetList, fetchingTestDataSetList, testDataSetForEdit } = useSelector(
    (state) => state.TestDataSetReducer
  );
  const { projectId, selectedStepId, stepType } = props;
  const { listOfBlocksByProjectId } = useSelector((state) => state.RecTestStepReducer);
  const { recTestScenarioList } = useSelector((state) => state.RecTestScenarioReducer);

  const [isEditTestDataSet, setIsEditTestDataSet] = useState(false);
  const [isViewTestData, setIsViewTestData] = useState(false);
  const [isManageKeys, setManageKeys] = useState(false);

  useEffect(() => {
    dispatch(changeTestDataSetView(stepType));
    if (stepType === TEST_STEP_BLOCKS) {
      dispatch(getListOfBlocksByProjectId(props.projectId, 1, "", "", false, -1));
    } else {
      dispatch(fetchTestPlanList(projectId));
    }
  }, []);

  useEffect(() => {
    if (testDataSetAssigneeDetail && testDataSetAssigneeDetail?.id) {
      dispatch(fetchTestDataSetByProfileId(testDataSetAssigneeDetail?.id));
    }
  }, [testDataSetAssigneeDetail]);

  const selectTestDataSetValue = useMemo(() => {
    if (stepType === TEST_STEP_BLOCKS && listOfBlocksByProjectId?.data) {
      return listOfBlocksByProjectId.data;
    }
    if (stepType === TEST_SCENARIOS && recTestScenarioList) {
      return recTestScenarioList;
    }
    return testPlanIdNameList;
  }, [stepType, listOfBlocksByProjectId, recTestScenarioList, testPlanIdNameList]);

  const showTestDataSetDetailsList = useMemo(() => {
    return (
      !isEditTestDataSet &&
      !isViewTestData &&
      !isManageKeys &&
      testDataSetAssigneeDetail &&
      selectTestDataSetValue &&
      !isEmpty(selectTestDataSetValue)
    );
  }, [isEditTestDataSet, isViewTestData, isManageKeys, testDataSetAssigneeDetail, selectTestDataSetValue, isEmpty]);

  const testDataSet = testDataSetList?.map((row) => ({
    key: row?.id,
    testDataDetails: <TestDataDetailsColumnV2 testDataSet={row} />,
    dataSets: <TestDataSetsColumn testDataSet={row} setManageKeys={setManageKeys} />,
    isDefault: <DefaultSwitchColumnV2 testDataSet={row} />,
    action: (
      <TestDataSetActionsColumnV2
        testDataSet={row}
        stepType={stepType}
        setIsEditTestDataSet={setIsEditTestDataSet}
        setIsViewTestData={setIsViewTestData}
      />
    ),
  }));

  return (
    <>
      {showTestDataSetDetailsList && (
        <TestDataSetDetailsListV2
          stepType={stepType}
          selectTestDataSetValue={selectTestDataSetValue}
          testDataSet={testDataSet}
        />
      )}
      {isEditTestDataSet && (
        <Spin spinning={fetchingTestDataSetList} className="spinner-center">
          {testDataSetForEdit && (
            <Col span={24}>
              <EditTestDataSetV2
                testPlanId={testDataSetAssigneeDetail?.id}
                setIsEditTestDataSet={setIsEditTestDataSet}
                stepType={stepType}
              />
            </Col>
          )}
        </Spin>
      )}

      {isViewTestData && (
        <Col span={24}>
          <ViewDefaultTestDataSetV2 stepType={stepType} setIsViewTestData={setIsViewTestData} />
        </Col>
      )}

      {isManageKeys && (
        <Col span={24}>
          <TestStepsManageDataV2
            selectedStepId={selectedStepId}
            projectId={projectId}
            setManageKeys={setManageKeys}
            stepType={stepType}
          />
        </Col>
      )}
    </>
  );
};

export default AllTestDataSetV2;