import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Divider, Row, Select, Space, Spin } from "antd";
import { isEmpty, isEqual } from "lodash";
import {
  changeGeneralTabData,
  getProjectDetailsForSetting,
  updateGeneralSettings,
} from "../../actions/ProjectSettingsActions";
import { getMyOrgUsers, myTeams } from "../../actions/TeamsAction";
import { ADD_MEMBERS, CHANGE_GENERAL_TAB_DATA } from "../../Constants";
import {
  isArrayNotUndefinedNotNullNotEmpty,
  isNotNullAndNotEmptyAndNotUndefined,
  isNullOrUndefinedOrEmpty,
  showNotification,
} from "../../Util";
import CommonInputFieldV2 from "../CommonComponents/CommonInputFieldV2/CommonInputFieldV2";
import CommonSelectBoxV2 from "../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import SaveResetButtonV2 from "../CoreComponents/SaveResetButtonV2/SaveResetButtonV2";
import { BuildGeneralTabModal } from "../ProjectSettings/BuildProjectSettingModal";
import styles from "./ProjectSettingsV2.module.scss";

const { Option } = Select;

const GeneralTabV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId, orgId } = props;
  const { projectLoading, generalTabDetails, getProjectDetailsById } = useSelector(
    (state) => state.ProjectSettingsReducer
  );
  const { myOrgUsers, myTeamsList } = useSelector((state) => state.TeamsReducer);

  //Destructure of objects
  const { appName, projectName, description, teamId, members } = getProjectDetailsById || {};
  const {
    appName: generalAppName,
    projectName: generalProjectName,
    projectDes,
    selectedTeam,
    selectedMembers,
  } = generalTabDetails || {};

  useEffect(() => {
    dispatch(getProjectDetailsForSetting(projectId));
  }, [projectId]);

  useEffect(() => {
    dispatch(myTeams(orgId));
    dispatch(getMyOrgUsers(orgId));
  }, [orgId]);

  const membersOfChildren = myOrgUsers
    ? myOrgUsers.map((user) => (
        <Option key={user.email} value={user.email}>
          {user.email}
        </Option>
      ))
    : [];

  const teamOfMembersOfChildren = myTeamsList
    ? myTeamsList.map((team) => (
        <Option key={team.teamId} value={team.teamId}>
          {team.teamName}
        </Option>
      ))
    : [];

  const updateGeneralSettingsData = () => {
    if (
      isNotNullAndNotEmptyAndNotUndefined(generalProjectName) &&
      isNotNullAndNotEmptyAndNotUndefined(generalAppName) &&
      isArrayNotUndefinedNotNullNotEmpty(selectedMembers)
    ) {
      dispatch(updateGeneralSettings(projectId));
    } else {
      showNotification("info", "Please Enter All details.");
    }
  };

  const resetGeneralSettingsData = () => {
    dispatch({
      type: CHANGE_GENERAL_TAB_DATA,
      data: !isEmpty(getProjectDetailsById)
        ? new BuildGeneralTabModal(getProjectDetailsById)
        : new BuildGeneralTabModal({}),
    });
  };

  const isValid = () =>
    isEqual(appName, generalAppName) &&
    isEqual(projectName, generalProjectName) &&
    isEqual(description, projectDes) &&
    isEqual(teamId, selectedTeam) &&
    isEqual(
      members.map((i) => i.email),
      selectedMembers
    );

  const areGeneralTabDetailsEmpty = () =>
    isNullOrUndefinedOrEmpty(generalAppName) &&
    isNullOrUndefinedOrEmpty(generalProjectName) &&
    isNullOrUndefinedOrEmpty(selectedTeam) &&
    isNullOrUndefinedOrEmpty(selectedMembers);

  const isAnyGeneralTabDetailEmpty = () =>
    isNullOrUndefinedOrEmpty(generalAppName) ||
    isNullOrUndefinedOrEmpty(generalProjectName) ||
    isNullOrUndefinedOrEmpty(selectedTeam) ||
    isNullOrUndefinedOrEmpty(selectedMembers);

  const disableResetButton = () => (!isEmpty(getProjectDetailsById) && isValid()) || areGeneralTabDetailsEmpty();

  const disableUpdateButton = () =>
    !isEmpty(getProjectDetailsById) ? isValid() || isAnyGeneralTabDetailEmpty() : isAnyGeneralTabDetailEmpty();

  return (
    <Spin spinning={projectLoading} tip="Loading" className="spinner-center">
      <Row justify="space-between" align="middle" className={styles["main_Header"]}>
        <Col>
          <div className={styles["main-Header-Text"]}>Project Details</div>
        </Col>
        <Col>
          <Space>
            <SaveResetButtonV2
              submitLabel={"Update"}
              resetLabel={"Reset"}
              submitDisable={disableUpdateButton()}
              resetDisable={disableResetButton()}
              handleSubmit={updateGeneralSettingsData}
              handleReset={resetGeneralSettingsData}
            />
          </Space>
        </Col>
      </Row>
      <Row justify="center" className={styles["page_Scroll"]}>
        <Col xs={{ span: 24 }} lg={{ span: 14 }} className={styles["project_Details"]}>
          <Row gutter={50} justify="center">
            <Col span={20}>
              <div className={styles["header_Title"]}>Basic Details</div>
              <CommonInputFieldV2
                label="App Name"
                name="appName"
                placeholder="Please Enter App Name"
                value={generalTabDetails.appName}
                handleChange={(e) => {
                  dispatch(
                    changeGeneralTabData({
                      ...generalTabDetails,
                      appName: e.target.value,
                    })
                  );
                }}
                labelBackgroundClass={"label_Background"}
                required={true}
                autoFocus
              />
              <CommonInputFieldV2
                label="Project Name"
                name="projectName"
                placeholder="For Example: Development Or QA"
                value={generalTabDetails.projectName}
                handleChange={(e) => {
                  dispatch(
                    changeGeneralTabData({
                      ...generalTabDetails,
                      projectName: e.target.value,
                    })
                  );
                }}
                labelBackgroundClass={"label_Background"}
                required={true}
              />
              <CommonInputFieldV2
                label="Project Description"
                name="projectDescription"
                placeholder="Insert Project Description."
                value={generalTabDetails.projectDes}
                handleChange={(e) => {
                  dispatch(
                    changeGeneralTabData({
                      ...generalTabDetails,
                      projectDes: e.target.value,
                    })
                  );
                }}
                labelBackgroundClass={"label_Background"}
                required={true}
                type="textarea"
              />
            </Col>
          </Row>
          <Divider className={styles["divider"]} />
          <Row gutter={50} justify="center">
            <Col span={20} className="text">
              <div className={styles["header_Title"]}>Team & Members</div>
              <CommonSelectBoxV2
                label="Team"
                labelBackgroundClass={"label_Background"}
                required={true}
                options={teamOfMembersOfChildren}
                value={
                  myTeamsList && myTeamsList?.some((i) => i?.teamId === generalTabDetails.selectedTeam)
                    ? generalTabDetails.selectedTeam
                    : undefined
                }
                placeholder="Select Team"
                handleChange={(value) => {
                  dispatch(
                    changeGeneralTabData({
                      ...generalTabDetails,
                      selectedTeam: value,
                    })
                  );
                }}
              />
              <CommonInputFieldV2
                label="Platform"
                name="platform"
                placeholder="Enter Platform Name"
                value={(generalTabDetails.platform && generalTabDetails.platform.name) || "ANDROID"}
                labelBackgroundClass={"label_Background"}
                required={true}
                disabled={true}
              />
              <CommonSelectBoxV2
                label="Members"
                labelBackgroundClass={"label_Background"}
                required={true}
                options={membersOfChildren}
                value={generalTabDetails.selectedMembers && generalTabDetails.selectedMembers.map((i) => i)}
                placeholder={ADD_MEMBERS.project}
                handleChange={(e) => {
                  dispatch(
                    changeGeneralTabData({
                      ...generalTabDetails,
                      selectedMembers: e,
                    })
                  );
                }}
                mode="multiple"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default GeneralTabV2;
