import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Tooltip } from "antd";
import {
  GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT,
  STEP,
  STEP_ITEMS,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  TOOLTIP_BG_COLOR,
} from "../../../Constants";
import {
  ACTION_ICON_COLOR,
  APPLICATION_STRING_ICON_NEW,
  CREATE_ICON,
  DEVICE_STRING_ICON,
  DISABLE_COLOR,
  DISABLE_ICON_COLOR,
  ICON_AI,
  ICON_CLOSE,
  ICON_CONDITIONAL_STEP,
  ICON_MORE_OPTIONS,
  ICON_ROBOT_STEP,
  IMPORT_STEP_ICON,
  PREVIEW_RUN_ICON,
  WHITE_COLOR,
  YELLOW_COLOR,
} from "../../../Constants/SvgConstants";
import {
  ADD_CONDITIONAL_STEP,
  ADD_STEP,
  APPLICATION_STRING,
  APPLICATION_STRING_IN_BLOCKS,
  APPLICATION_STRING_IN_SCENARIO,
  BUILD_TEST_DATA_SET,
  DEVICE_STRING,
  DEVICE_STRING_IN_BLOCKS,
  DEVICE_STRING_IN_SCENARIO,
  GENERATE_STEP,
  IMPORT_STEP_IN_SCENARIO,
  IMPORT_STEP_IN_TEST_BLOCK,
  PREVIEW_RUN,
} from "../../../Constants/TooltipConstants";
import SvgLoader from "../../../Util/SvgLoader";
import { setRecTestScenarioIdForPreview } from "../../../actions/RecTestScenarioAction";
import {
  addConditionToStep,
  addRecTestStep,
  hideAIGenerateStepsRow,
  showGenerateStepsDebugFromAIModal,
  showImportRecTestStepModal,
  stopGenerateStepsFromAIModal,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import {
  setApplicationAssigneeDetail,
  setDeviceAssigneeDetail,
  setTestDataSetAssigneeDetail,
} from "../../../actions/TestDataSet/TestDataSetCommonAction";
import PreviewRunDrawer from "../../RecTestScenario/PreviewRunDrawer";
import GenerateScenarioStepsDebugModal from "../../RecTestStep/ScenarioHeader/ScenarioHeaderOptions/GenerateScenarioStepsDebugModal/GenerateScenarioStepsDebugModal";
import PreviewSettingModal from "../../RecTestStep/ScenarioHeader/ScenarioHeaderOptions/PreviewSettingModal/PreviewSettingModal";
import CommonConfirmationModalV2 from "../CommonConfirmationModalV2/CommonConfirmationModalV2";
import CustomButtonV2 from "../CustomButtonV2/CustomButtonV2";
import VerticalLine from "../VerticalLine";
import ButtonActionsV2 from "./ButtonActionsV2";
import PlayActionsV2 from "./PlayActionsV2";
import TestDataProfileButtonV2 from "./TestDataProfileButtonV2";
import styles from "./EditTestHeaderOptionsV2.module.scss";

export const addConditionalStepMenu = (selectedRecStepForOperation, dispatch) => {
  let menus = [];
  menus.push(
    selectedRecStepForOperation?.recTestStepProperty?.isStepHasPrecondition === 0 &&
      selectedRecStepForOperation?.recTestStepProperty?.isLoopStep === 0 && (
        <>
          <Menu.Item
            key={"SubMenu-1"}
            onClick={() =>
              dispatch(addConditionToStep(selectedRecStepForOperation.tag, STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT))
            }
          >
            When to Run this Step By Verifying Element
          </Menu.Item>
          <Menu.Item
            key={"SubMenu-2"}
            onClick={() =>
              dispatch(
                addConditionToStep(selectedRecStepForOperation.tag, STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_TEST_DATA)
              )
            }
          >
            When to Run this Step By Verifying Test Data
          </Menu.Item>
        </>
      ),
    selectedRecStepForOperation?.recTestStepProperty?.isStepHasPrecondition === 0 &&
      selectedRecStepForOperation?.recTestStepProperty?.isLoopStep === 0 && (
        <Menu.Item
          key={"SubMenu-3"}
          onClick={() => dispatch(addConditionToStep(selectedRecStepForOperation.tag, STEP_ITEMS.REPEAT_TILL))}
        >
          Repeat Steps Till
        </Menu.Item>
      )
  );
  return <Menu className={styles.actionsDropdown}>{menus}</Menu>;
};

const EditTestHeaderOptionsV2 = (props) => {
  const dispatch = useDispatch();
  const { disabled, projectId, orgId, onBackClick } = props;

  const {
    selectedRecTestScenarioName,
    selectedRecTestScenarioId,
    isPreviewRunning,
    selectedDeviceByUdid,
    previewRunNumber,
    testBlockStep,
  } = useSelector((state) => state.RecTestStepReducer);
  const {
    stepType,
    scenarioObjective,
    scenarioGenerateInstructions,
    selectedModelType,
    requestingPerformRecTestStepAction,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { applicationStringAssigneeDetail, testDataSetAssigneeDetail, deviceStringAssigneeDetail } = useSelector(
    (state) => state.TestDataSetReducer
  );
  const [openPreviewRunDrawer, setOpenPreviewRunDrawer] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const { isStepHasPrecondition, isLoopStep } =
    testBlockStep && testBlockStep?.recTestStepProperty ? testBlockStep?.recTestStepProperty : {};

  const scenarioStepType = useMemo(() => stepType === TEST_SCENARIOS, [stepType]);

  const addOrImportDisabled = useMemo(
    () =>
      disabled ||
      isPreviewRunning ||
      requestingPerformRecTestStepAction ||
      testDataSetAssigneeDetail ||
      deviceStringAssigneeDetail ||
      applicationStringAssigneeDetail,
    [
      disabled,
      isPreviewRunning,
      requestingPerformRecTestStepAction,
      testDataSetAssigneeDetail,
      deviceStringAssigneeDetail,
      applicationStringAssigneeDetail,
    ]
  );

  const importToolTipTitle = useMemo(
    () => (scenarioStepType ? IMPORT_STEP_IN_SCENARIO : IMPORT_STEP_IN_TEST_BLOCK),
    [scenarioStepType]
  );

  const previewRunOrBuildTestDataSetDisable = useMemo(
    () =>
      isPreviewRunning ||
      testDataSetAssigneeDetail ||
      disabled ||
      applicationStringAssigneeDetail ||
      deviceStringAssigneeDetail ||
      requestingPerformRecTestStepAction,
    [
      isPreviewRunning,
      testDataSetAssigneeDetail,
      disabled,
      applicationStringAssigneeDetail,
      deviceStringAssigneeDetail,
      requestingPerformRecTestStepAction,
    ]
  );

  const conditionDisabled = useMemo(
    () =>
      disabled ||
      testDataSetAssigneeDetail ||
      applicationStringAssigneeDetail ||
      requestingPerformRecTestStepAction ||
      deviceStringAssigneeDetail,
    [
      disabled,
      testDataSetAssigneeDetail,
      applicationStringAssigneeDetail,
      requestingPerformRecTestStepAction,
      deviceStringAssigneeDetail,
    ]
  );

  const isConditionStep = useMemo(
    () => stepType === TEST_STEP_BLOCKS && isStepHasPrecondition === 0 && isLoopStep === 0,
    [stepType, isStepHasPrecondition, isLoopStep]
  );

  const handlePreviewRunDrawer = useCallback(() => {
    setOpenPreviewRunDrawer(true);
    dispatch(setRecTestScenarioIdForPreview(selectedRecTestScenarioId));
  }, [dispatch, selectedRecTestScenarioId]);

  const handleApplicationStringDetail = useCallback(() => {
    if (scenarioStepType) {
      if (!previewRunOrBuildTestDataSetDisable) {
        dispatch(setApplicationAssigneeDetail({ id: selectedRecTestScenarioId, name: selectedRecTestScenarioName }));
      }
    } else {
      if (!applicationStringAssigneeDetail && !isPreviewRunning && !addOrImportDisabled) {
        dispatch(setApplicationAssigneeDetail(testBlockStep));
      }
    }
  }, [
    dispatch,
    previewRunOrBuildTestDataSetDisable,
    selectedRecTestScenarioId,
    selectedRecTestScenarioName,
    testBlockStep,
    scenarioStepType,
    applicationStringAssigneeDetail,
    isPreviewRunning,
    addOrImportDisabled,
  ]);

  const handleDeviceStringDetail = useCallback(() => {
    if (scenarioStepType) {
      if (!previewRunOrBuildTestDataSetDisable) {
        dispatch(setDeviceAssigneeDetail({ id: selectedRecTestScenarioId, name: selectedRecTestScenarioName }));
      }
    } else {
      if (!applicationStringAssigneeDetail && !isPreviewRunning && !addOrImportDisabled) {
        dispatch(setDeviceAssigneeDetail(testBlockStep));
      }
    }
  }, [
    dispatch,
    previewRunOrBuildTestDataSetDisable,
    selectedRecTestScenarioId,
    selectedRecTestScenarioName,
    testBlockStep,
    scenarioStepType,
    applicationStringAssigneeDetail,
    isPreviewRunning,
    addOrImportDisabled,
  ]);

  const moreOptionsMenu = () => {
    let menus = [];
    menus.push(
      <>
        <Menu.Item
          key={"SubMenu-1"}
          onClick={() =>
            stepType === TEST_SCENARIOS
              ? !previewRunOrBuildTestDataSetDisable &&
                dispatch(
                  setTestDataSetAssigneeDetail({ id: selectedRecTestScenarioId, name: selectedRecTestScenarioName })
                )
              : !testDataSetAssigneeDetail &&
                !isPreviewRunning &&
                !applicationStringAssigneeDetail &&
                !addOrImportDisabled &&
                dispatch(setTestDataSetAssigneeDetail(testBlockStep))
          }
          disabled={previewRunOrBuildTestDataSetDisable}
        >
          {BUILD_TEST_DATA_SET}
        </Menu.Item>
        <Tooltip
          title={stepType === TEST_SCENARIOS ? APPLICATION_STRING_IN_SCENARIO : APPLICATION_STRING_IN_BLOCKS}
          color={TOOLTIP_BG_COLOR}
          placement="left"
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
        >
          <Menu.Item
            key={"SubMenu-2"}
            onClick={() => handleApplicationStringDetail()}
            disabled={previewRunOrBuildTestDataSetDisable}
          >
            {APPLICATION_STRING}
          </Menu.Item>
        </Tooltip>
        <Tooltip
          title={stepType === TEST_SCENARIOS ? DEVICE_STRING_IN_SCENARIO : DEVICE_STRING_IN_BLOCKS}
          color={TOOLTIP_BG_COLOR}
          placement="left"
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
        >
          <Menu.Item
            key={"SubMenu-3"}
            onClick={() => handleDeviceStringDetail()}
            disabled={previewRunOrBuildTestDataSetDisable}
          >
            {DEVICE_STRING}
          </Menu.Item>
        </Tooltip>
      </>
    );
    return <Menu className={styles.actionsDropdown}>{menus}</Menu>;
  };

  return (
    <>
      <div className={styles["header_Options"]}>
        <VerticalLine className={styles["border_Right"]} />
        <CustomButtonV2
          buttonLabel={ADD_STEP}
          handleClick={() => !addOrImportDisabled && dispatch(addRecTestStep(undefined))}
          buttonIcon={CREATE_ICON}
          disabled={addOrImportDisabled}
          iconColor={addOrImportDisabled ? DISABLE_COLOR : WHITE_COLOR}
          className="common_Green_Button"
        />
        <ButtonActionsV2
          classForButton="buttonForActionStep"
          toolTipTitle={importToolTipTitle}
          handleClick={() =>
            !addOrImportDisabled && dispatch(showImportRecTestStepModal(STEP, projectId, "", true, undefined))
          }
          disabled={addOrImportDisabled}
          imageName={IMPORT_STEP_ICON}
        />
        {isConditionStep && (
          <ButtonActionsV2
            classForButton="buttonForActionStep"
            toolTipTitle={ADD_CONDITIONAL_STEP}
            handleClick={() => addConditionalStepMenu(testBlockStep, dispatch)}
            disabled={conditionDisabled}
            imageName={ICON_CONDITIONAL_STEP}
            dropDownIcon
          />
        )}
        <Tooltip title={GENERATE_STEP} color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
          <div className={styles["buttonForActionStep"]}>
            <div onClick={() => !previewRunOrBuildTestDataSetDisable && dispatch(showGenerateStepsDebugFromAIModal())}>
              <SvgLoader
                iconName={ICON_ROBOT_STEP}
                iconColor={previewRunOrBuildTestDataSetDisable ? DISABLE_ICON_COLOR : ACTION_ICON_COLOR}
                width={"1.625rem"}
                height={"1.625rem"}
                disabled={previewRunOrBuildTestDataSetDisable}
              />
            </div>
          </div>
        </Tooltip>
        <VerticalLine className={styles["border_Right"]} />
        {(!previewRunNumber || isPreviewRunning) && (
          <div className={styles["show_Test_Data_Details"]}>
            <TestDataProfileButtonV2
              disabled={previewRunOrBuildTestDataSetDisable}
              previewRunOrBuildTestDataSetDisable={previewRunOrBuildTestDataSetDisable}
              addOrImportDisabled={addOrImportDisabled}
            />
            <ButtonActionsV2
              classForButton="buttonForActionStep"
              toolTipTitle={scenarioStepType ? APPLICATION_STRING_IN_SCENARIO : APPLICATION_STRING_IN_BLOCKS}
              handleClick={() => handleApplicationStringDetail()}
              disabled={previewRunOrBuildTestDataSetDisable}
              imageName={APPLICATION_STRING_ICON_NEW}
            />
            <ButtonActionsV2
              classForButton="buttonForActionStep"
              toolTipTitle={scenarioStepType ? DEVICE_STRING_IN_SCENARIO : DEVICE_STRING_IN_BLOCKS}
              handleClick={() => handleDeviceStringDetail()}
              disabled={previewRunOrBuildTestDataSetDisable}
              imageName={DEVICE_STRING_ICON}
            />
            <VerticalLine className={styles["border_Right"]} />
          </div>
        )}
        <PlayActionsV2
          orgId={orgId}
          projectId={projectId}
          disabled={disabled}
          requestingPerformRecTestStepAction={requestingPerformRecTestStepAction}
          previewRunOrBuildTestDataSetDisable={previewRunOrBuildTestDataSetDisable}
        />
        {scenarioStepType && (
          <ButtonActionsV2
            classForButton="buttonForActionStep"
            toolTipTitle={PREVIEW_RUN}
            disabled={previewRunOrBuildTestDataSetDisable}
            handleClick={() => !previewRunOrBuildTestDataSetDisable && handlePreviewRunDrawer()}
            imageName={PREVIEW_RUN_ICON}
          />
        )}
        <div className={styles["more_Options_Icon"]}>
          <ButtonActionsV2
            classForButton="buttonForActionStep"
            toolTipTitle={"More Options"}
            handleClick={() => moreOptionsMenu()}
            imageName={ICON_MORE_OPTIONS}
            dropDownIcon
          />
        </div>
      </div>
      <SvgLoader iconName={ICON_CLOSE} handleClick={onBackClick} width="2.1rem" height="2.1rem" />
      <PreviewRunDrawer
        openPreviewRunDrawer={openPreviewRunDrawer}
        setOpenPreviewRunDrawer={setOpenPreviewRunDrawer}
        recTestScenarioName={selectedRecTestScenarioName}
      />
      <PreviewSettingModal
        recTestScenarioName={selectedRecTestScenarioName}
        selectedRecTestScenarioId={selectedRecTestScenarioId}
        testBlockStep={testBlockStep}
      />
      {/* <GenerateScenarioStepsModal
        recTestScenarioName={selectedRecTestScenarioName}
        selectedRecTestScenarioId={selectedRecTestScenarioId}
      /> */}

      <GenerateScenarioStepsDebugModal
        recTestScenarioName={selectedRecTestScenarioName}
        selectedRecTestScenarioId={selectedRecTestScenarioId}
      />
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(
            stopGenerateStepsFromAIModal({
              objective: scenarioObjective,
              rules: scenarioGenerateInstructions,
              deviceId: selectedDeviceByUdid?.targetUniqueId,
              modelType: selectedModelType,
            })
          );
          dispatch(hideAIGenerateStepsRow());
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT}
        modalTitle="Stop AI Generate Steps"
        okText="Confirm"
        isDeleteModal={true}
      />
    </>
  );
};

export default EditTestHeaderOptionsV2;
