import React from "react";
import { checkNotUndefinedAndNull } from "../../../../../Util";
import ShowTooltipV2 from "../../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import styles from "./TestPlanResultColumnsV2.module.scss";

const TestPlanDetailsColumnV2 = ({ row }) => {
  return (
    <>
      <div className={styles["test_Plan_Name_Label"]}>
        <span className={styles["label"]}>Name: </span>
        <span>
          <ShowTooltipV2 text={row?.name} className={styles["test_Plan_Name"]} />
        </span>
      </div>
      {checkNotUndefinedAndNull(row?.testCoveragePerDevice) && (
        <div>
          <span className={styles["label"]}>Test Details: </span>
          {Object.values(row?.testCoveragePerDevice)?.map((i, index) => (
            <>
              <span className={styles["label-name"]} key={index}>
                {" "}
                Scenarios: {i?.scenarioCount}, Steps: {i?.steps?.stepsCount}, Screenshots: {i?.steps?.screenshotCount}
              </span>
            </>
          ))}
        </div>
      )}
      <div>
        <span className={styles["label"]}>Test Data Profile:</span>{" "}
        <span className={styles["label-name"]}> {row?.selectedTestDataProfileName}</span>
      </div>
    </>
  );
};

export default TestPlanDetailsColumnV2;
