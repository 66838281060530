import React, { useCallback, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Select, Space, Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import { APPLY_FOR, EXECUTION_JOB, TOOLTIP_BG_COLOR } from "../../../../Constants";
import { CREATE_ICON, DISABLE_COLOR, SILVER_GRAY, TRASH_ICON, WHITE_COLOR } from "../../../../Constants/SvgConstants";
import SvgLoader from "../../../../Util/SvgLoader";
import CommonSelectBoxV2 from "../../../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import CustomButtonV2 from "../../../CommonComponents/CustomButtonV2/CustomButtonV2";
import TableComponentV2 from "../../TableComponent/TableComponentV2";
import styles from "./PermissionDrawerV2.module.scss";

const { Option } = Select;
const ApplyForMemberListV2 = (props) => {
  const {
    searchText,
    setSearchText,
    notShowExecutionCheck,
    ownerEmailId,
    applyFor,
    setApplyFor,
    removeFor,
    setRemoveFor,
    setApplyForOption,
    applyForOption,
    setIsAddNew,
    isAddNew,
  } = props;

  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { memberList } = useSelector((state) => state.CommonReducer);
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  const isDisable = (emailId, isAdmin) => emailId === ownerEmailId || isAdmin;

  const addForApplyMember = useCallback(() => {
    setIsAddNew(true);
    const membersListFilter = memberList?.map((i) => i?.email);
    const ApplyMembers = membersListFilter?.filter((i) => !applyFor?.some((j) => j?.email === i));
    setApplyForOption(ApplyMembers);
  }, [memberList, applyFor]);

  const handleViewCheck = useCallback(
    (e, i) => {
      const { name, checked } = e.target;
      const handleViewObj = {
        ...applyFor[i],
        [name]: checked ? 1 : 0,
        isEdit: 0,
        isDelete: 0,
        isExec: 0,
      };
      const handleViewArray = applyFor.map((item, index) => (index === i ? handleViewObj : item));
      setApplyFor(handleViewArray);
    },
    [applyFor]
  );

  const handleApplyMembersSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleApplyMembersReset = (clearFilters) => {
    clearFilters({
      confirm: true,
      closeDropdown: true,
    });
    setSearchText("");
    setSearchedColumn("");
  };

  const handleAdminCheck = (e, i) => {
    const { name, checked } = e.target;
    let handleAdminObj = {
      ...applyFor[i],
      [name]: checked ? 1 : 0,
      isView: 1,
      isEdit: 1,
      isDelete: 1,
      isExec: 1,
    };
    let handleAdminArray = applyFor?.map((i) => (i?.userId === handleAdminObj?.userId ? handleAdminObj : i));
    setApplyFor(handleAdminArray);
  };

  const handleSelectNewApplyMemberList = (e) => {
    setIsAddNew(false);
    const MemberUserId = memberList?.find((i) => i?.email === e)?.userId;
    const isChecked = MemberUserId === loggedInUserDetails?.userID || e === ownerEmailId ? 1 : 0;
    setApplyFor([
      ...applyFor,
      {
        userId: MemberUserId,
        email: e,
        isAdmin: isChecked,
        isView: isChecked,
        isEdit: isChecked,
        isDelete: isChecked,
        isExec: isChecked,
        shouldAddOrRemove: 1,
      },
    ]);
    const validApplyMember = removeFor?.filter((i) => i?.userId !== MemberUserId);
    setRemoveFor(validApplyMember);
  };

  const getApplyColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleApplyMembersSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleApplyMembersSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleApplyMembersReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered && "#1890ff" }} />,
    onFilter: (value, record) => {
      return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleCheckBox = (row, index, name, onChangeFunc, isDisabledFunc) => (
    <Checkbox
      className="selection-checkBox"
      checked={row[name]}
      name={name}
      onChange={(e) => onChangeFunc(e, index)}
      disabled={
        name === "isAdmin"
          ? ownerEmailId === row?.email || loggedInUserDetails?.userEmail === row?.email
          : isDisabledFunc(row)
      }
    />
  );
  const handleDeleteMember = useCallback(
    (row) => {
      const applyForForMembers = applyFor.filter((item) => item !== row);
      setApplyFor(applyForForMembers);
    },
    [applyFor]
  );

  const handleCheck = useCallback(
    (e, i) => {
      const { name, checked } = e.target;
      const handleCheckObj = { ...applyFor[i], [name]: checked ? 1 : 0, isView: 1 };
      const handleCheckArray = applyFor.map((item, index) => (index === i ? handleCheckObj : item));
      setApplyFor(handleCheckArray);
    },
    [applyFor]
  );

  const applyMembersColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getApplyColumnSearchProps("email"),
      width: 300,
    },
    { title: "Admin", dataIndex: "admin" },
    { title: "View", dataIndex: "view" },
    { title: "Edit", dataIndex: "edit" },
    { title: "Delete", dataIndex: "delete" },
    {
      title: "Execution",
      dataIndex: "execution",
      hidden: notShowExecutionCheck,
    },
    { title: "", dataIndex: "action" },
  ]?.filter((item) => !item.hidden);

  const isOddRow = (index) => index % 2 !== 0;

  const ApplyMemberListDetails = applyFor && [
    ...applyFor?.map((row, j) => ({
      key: row?.userId,
      email: ownerEmailId === row?.email ? row?.email + ` (Owner)` : row?.email,
      admin: handleCheckBox(row, j, "isAdmin", handleAdminCheck),
      view: handleCheckBox(row, j, "isView", handleViewCheck, (row) => isDisable(row?.email, row?.isAdmin)),
      edit: handleCheckBox(row, j, "isEdit", handleCheck, (row) => isDisable(row?.email, row?.isAdmin)),
      delete: handleCheckBox(row, j, "isDelete", handleCheck, (row) => isDisable(row?.email, row?.isAdmin)),
      execution: handleCheckBox(row, j, "isExec", handleCheck, (row) => isDisable(row?.email, row?.isAdmin)),
      action: (
        <Tooltip title="Delete" color={TOOLTIP_BG_COLOR}>
          <div>
            <SvgLoader iconName={TRASH_ICON} iconColor={SILVER_GRAY}  handleClick={(e) => handleDeleteMember(row)} width={35} height={25} />
          </div>
        </Tooltip>
      ),
    })),
    !!isAddNew && {
      email: (
        <CommonSelectBoxV2
          labelBackgroundClass={isOddRow(applyFor.length) ? styles["label_Background"] : undefined}
          label="Members"
          options={
            <>
              {applyForOption &&
                applyForOption?.map((i, k) => {
                  return (
                    <Option value={i} key={k}>
                      {i}
                    </Option>
                  );
                })}
            </>
          }
          placeholder="Select Members"
          handleChange={(e) => handleSelectNewApplyMemberList(e)}
        />
      ),
    },
  ];

  return (
    <>
      <div className={styles["members-list-details"]}>
        <div className={styles["table-header-text"]}>{APPLY_FOR}</div>
        <CustomButtonV2
          iconColor={memberList?.length === applyFor?.length || !isEmpty(searchText) ? DISABLE_COLOR : WHITE_COLOR}
          buttonIcon={CREATE_ICON}
          buttonLabel={"Add"}
          handleClick={() => addForApplyMember()}
          className={styles["add_Button"]}
          disabled={memberList?.length === applyFor?.length || !isEmpty(searchText)}
        />
      </div>
      <div className={styles["table-content"]}>
        <TableComponentV2
          className="table__wrapper"
          columns={applyMembersColumns}
          data={ApplyMemberListDetails?.filter(Boolean)}
          isScrollable={true}
          isExecution={stepType === EXECUTION_JOB ? true : false}
        />
      </div>
    </>
  );
};

export default ApplyForMemberListV2;