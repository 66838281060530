import React, { useState } from "react";
import { Tooltip } from "antd";
import { TEST_DATA_SET_NAME } from "../../../../../../../Constants/TooltipConstants";
import styles from "../AllTestDataSetV2.module.scss";
import { useSelector } from "react-redux";
import { TEST_PLAN, TEST_SCENARIOS, TEST_STEP_BLOCKS, TOOLTIP_BG_COLOR } from "../../../../../../../Constants";

const TestDataDetailsColumnV2 = ({ testDataSet }) => {
  const { id, name, createdBy, updatedBy } = testDataSet || {};
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const tooltipTitle = () => {
    const tooltipMap = {
      [TEST_PLAN]: "Default Execution Profile of Scenario",
      [TEST_STEP_BLOCKS]: TEST_DATA_SET_NAME,
      [TEST_SCENARIOS]: TEST_DATA_SET_NAME,
    };
    return tooltipMap[stepType] || undefined;
  };

  return (
    <div>
      {!id ? (
        <Tooltip title={tooltipTitle} color={TOOLTIP_BG_COLOR} visible={tooltipVisible} placement="topLeft">
          <div className={styles["test-data-name"]}>
            <span
              className={`${styles["test-Data-Set-Title"]} cursor__pointer`}
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            >
              <span className={styles["data_Set_Title"]}>{name}</span>
            </span>
          </div>
        </Tooltip>
      ) : (
        <div>
          <span className={styles["data_Set_Title"]}>{name}</span>
        </div>
      )}
      {createdBy && (
        <div>
          <span className={styles["label"]}>Created By: </span>
          <span className={styles["label-name"]}>{createdBy}</span>
        </div>
      )}
      {updatedBy && (
        <div>
          <span className={styles["label"]}>Updated By: </span>
          <span className={styles["label-name"]}>{updatedBy}</span>
        </div>
      )}
    </div>
  );
};

export default TestDataDetailsColumnV2;
