import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { ACTION_NOT_VIEW, BUILD_TEST_SCENARIO, TOOLTIP_BG_COLOR, defaultDateTimeFormat } from "../../../Constants";
import { displayUTCDateFormatWithToolTip, getPermissionType, permissionTypeIcon } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import { editScenario } from "../../../actions/TestPlanAction";
import SelectionCheckBoxV2 from "../../CommonComponents/SelectionCheckBoxV2/SelectionCheckBoxV2";
import ShowTooltipV2 from "../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import styles from "../RecTestScenarioV2.module.scss";

const ScenarioInfoColumnV2 = ({
  row,
  selectAllScenario,
  setSelectAllScenario,
  isScenarioLoadModel,
  projectId,
  navigateToEditBuildScenario,
}) => {
  const dispatch = useDispatch();
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  //Destructure row object
  const {
    allowDelete,
    isPrivate,
    recTestScenarioRights,
    name,
    createdBy,
    lastUpdatedBy,
    lastUpdatedOn,
    stepsWithTestData,
    currentAndTotalTestDataProfile,
  } = row ? row : {};

  const handleOnClick = () => {
    navigateToEditBuildScenario(BUILD_TEST_SCENARIO);
    dispatch(editScenario(row, projectId));
  };

  return (
    <div className={`${allowDelete === ACTION_NOT_VIEW && styles["table-details"]}  display__flex ml-5`}>
      {!isScenarioLoadModel && (
        <SelectionCheckBoxV2 row={row} selectAllData={selectAllScenario} setSelectAllData={setSelectAllScenario} />
      )}
      <div
        className={`${allowDelete === ACTION_NOT_VIEW && styles["permission_Icons_And_Name"]} ${
          styles["icon-and-details"]
        }`}
      >
        <div className={styles["permission-tooltip"]}>
          <Tooltip title={getPermissionType(isPrivate, recTestScenarioRights?.data)} color={TOOLTIP_BG_COLOR}>
            <div>
              <SvgLoader iconName={permissionTypeIcon(getPermissionType(isPrivate, recTestScenarioRights?.data))} />
            </div>
          </Tooltip>
        </div>
        <div className={styles["test_Scenario_Info_Details"]}>
          <ShowTooltipV2 text={name} className={styles["step-name"]} handleOnClick={() => handleOnClick()} />
          {loggedInUserDetails?.userEmail !== row?.createdByEmail && (
            <div>
              <span className={styles["label"]}> Created By: </span>{" "}
              <span className={styles["label-name"]}>{createdBy || "NA"}</span>
            </div>
          )}
          <div>
            <span className={styles["label"]}>Last Updated By: </span>{" "}
            <span className={styles["label-name"]}>
              {lastUpdatedBy || "NA"} On{" "}
              {lastUpdatedOn ? displayUTCDateFormatWithToolTip(lastUpdatedOn, defaultDateTimeFormat) : "NA"}
            </span>
          </div>

          {stepsWithTestData && (
            <div>
              <span className={styles["label"]}>Steps with TestData:</span>
              {stepsWithTestData}
            </div>
          )}
          {currentAndTotalTestDataProfile && (
            <div>
              <span className={styles.label}>Current TestData Profile & Total TestData Keys in Profile:</span>
              {currentAndTotalTestDataProfile}
            </div>
          )}
          <div>
            <span className={styles["label"]}>Test Data Profile:</span>{" "}
            <span className={styles["label-name"]}>
              {" "}
              {row?.testDataProfile && row?.testDataProfile?.name ? row?.testDataProfile?.name : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScenarioInfoColumnV2;
