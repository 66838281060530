import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tabs } from "antd";
import { KEYS, PROFILES, SYSTEM_KEYS } from "../../../Constants";
import { fetchTestDataByProjectId } from "../../../actions/TestDataAction";
import {
  changeTestDataSetView,
  setTestDataSetAssigneeDetail,
} from "../../../actions/TestDataSet/TestDataSetCommonAction";
import { fetchSystemKeyByProjectId } from "../../../actions/TestDataSetAction";
import SystemKeyTestDataSetListV2 from "./SystemKeyTestDataSetListV2";
import SystemKeyTestDataV2 from "./SystemKeyTestDataV2";

const { TabPane } = Tabs;

const SystemKeysV2 = ({ projectId }) => {
  const dispatch = useDispatch();
  const [getActiveKey, setActiveKey] = useState(KEYS);

  useEffect(() => {
    dispatch(changeTestDataSetView(SYSTEM_KEYS));
    dispatch(setTestDataSetAssigneeDetail({ id: projectId }));

    return () => {
      dispatch(setTestDataSetAssigneeDetail(undefined));
    };
  }, []);

  const handleOnChange = (e) => {
    setActiveKey(e);
    if (e === KEYS) {
      dispatch(fetchTestDataByProjectId(projectId));
    } else {
      dispatch(fetchSystemKeyByProjectId());
    }
  };

  return (
    <Tabs defaultActiveKey={KEYS} activeKey={getActiveKey} onChange={(e) => handleOnChange(e)}>
      <TabPane tab={"Keys"} key={KEYS}>
        <SystemKeyTestDataV2 projectId={projectId} />
      </TabPane>
      <TabPane tab={"Profiles"} key={PROFILES}>
        <SystemKeyTestDataSetListV2 projectId={projectId} stepType={SYSTEM_KEYS} />
      </TabPane>
    </Tabs>
  );
};

export default SystemKeysV2;
