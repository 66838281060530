import { Spin, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAIStepTestData,
  selectOutputTestData,
  selectOutputTestDataForRecElement,
  selectTestData,
  updateReferenceTestDataForVerification,
  updateVerificationKey,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { loadEditTestDataDetail, visibleTestDataDrawer } from "../../../../actions/TestDataAction";
import { AI_TARGET_ELEMENT, splitValue, TEST_SCENARIOS, TEST_STEP_BLOCKS, TOOLTIP_BG_COLOR } from "../../../../Constants";
import { ICON_EDIT_NEW, SELECT_ICON } from "../../../../Constants/SvgConstants";
import SvgLoader from "../../../../Util/SvgLoader";
import ShowTooltipV2 from "../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import TableComponentV2 from "../../../CoreComponents/TableComponent/TableComponentV2";
import styles from "./ManageTestDataV2.module.scss";

const TestDataDetailsV2 = (props) => {
  const dispatch = useDispatch();
  const { isSkipStep, data, setOpenAddTestDataModal } = props;

  const { testDataList, fetchingTestDataList, stepType } = useSelector((props) => props.RecTestStepCommonViewReducer);
  const { openTestDataSetDrawerByTabName, tagForOpenDrawer, guidForOpenDrawer, verificationDataForTestDataDrawer } =
    useSelector((props) => props.TestDataReducer);

  let verifyElementPropertyValue =
    data?.recTestStepProperty?.recStepVerifyElementProperties &&
    data?.recTestStepProperty?.recStepVerifyElementProperties.length > 0
      ? data?.recTestStepProperty?.recStepVerifyElementProperties[0]
      : [];

  const handleSelectTestData = (v) => {
    const setTestData = {
      OPERATION: selectTestData(tagForOpenDrawer, v?.id, v?.value),
      AI_TARGET_ELEMENT: selectAIStepTestData(tagForOpenDrawer, v?.id, v?.value),
      ELEMENTS: selectOutputTestDataForRecElement(
        tagForOpenDrawer,
        guidForOpenDrawer,
        v ? v?.id + splitValue + v?.name + splitValue + v?.value : undefined
      ),
      POST_OPERATION_SETTINGS: selectOutputTestData(tagForOpenDrawer, v?.id),
      VERIFICATION_FOR_TEST_DATA: updateReferenceTestDataForVerification(
        verificationDataForTestDataDrawer?.key,
        verificationDataForTestDataDrawer?.uuid,
        tagForOpenDrawer,
        v,
        guidForOpenDrawer
      ),
      VERIFY_TEST_DATA: updateVerificationKey(
        v?.id,
        verificationDataForTestDataDrawer?.uuid,
        tagForOpenDrawer,
        guidForOpenDrawer,
        testDataList,
        verifyElementPropertyValue?.value
      ),
    };
    return dispatch(setTestData[openTestDataSetDrawerByTabName]);
  };

  const tableDetails = (data, label) => {
    return (
      <div>
        {label && <span className={styles["label"]}>{label}: </span>}{" "}
        <span className={styles["label-name"]}>{data}</span>
      </div>
    );
  };

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      width: 400,
    },
    {
      title: "Value",
      dataIndex: "value",
      width: 200,
    },
    {
      title: "Used in Scenarios",
      dataIndex: "usedInScenario",
      width: 200,
    },
    {
      title: "Used in Test Blocks",
      dataIndex: "usedInSteps",
      width: 200,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "right",
    },
  ];

  const fetchTestDataList = () => {
    let data = [];
    if (testDataList) {
      testDataList?.forEach((row) => {
        if (stepType === TEST_STEP_BLOCKS || (stepType === TEST_SCENARIOS && row.scope !== "Test Block")) {
          data.push({
            key: (
              <>
                <ShowTooltipV2 text={row.name || "-"} className={styles["testDataName"]} />
                {tableDetails(row?.scope, "Scope")}
              </>
            ),
            value: (
              <ShowTooltipV2 text={row.value || "-"} className={`${styles["testDataName"]} ${styles["label-name"]}`} />
            ),

            usedInScenario: <>{tableDetails(row?.usedInScenarios)}</>,
            usedInSteps: <>{tableDetails(row?.usedInSteps)}</>,
            actions:
              !isSkipStep &&
              (stepType === TEST_STEP_BLOCKS || (stepType === TEST_SCENARIOS && row.scope !== "Test Block")) ? (
                <div className={styles["action-icon"]}>
                  <Tooltip title="Select" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
                    <div className={styles["test--Data-Action__Buttons"]}>
                      <SvgLoader
                        iconName={SELECT_ICON}
                        handleClick={() => {
                          handleSelectTestData(row);
                          dispatch(visibleTestDataDrawer());
                        }}
                      />
                    </div>
                  </Tooltip>{" "}
                  <Tooltip title="Edit" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
                    <div className={styles["test--Data-Action__Buttons"]}>
                      <SvgLoader
                        iconName={ICON_EDIT_NEW}
                        handleClick={() => {
                          dispatch(loadEditTestDataDetail(row.id));
                          setOpenAddTestDataModal(true);
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
              ) : (
                ""
              ),
          });
        }
      });
    }
    return data;
  };
  return (
    <>
      <Spin spinning={fetchingTestDataList}>
        <div className={styles["table_Details"]}>
          <TableComponentV2 columns={columns} data={fetchTestDataList()} className="table__wrapper" />
        </div>
      </Spin>
    </>
  );
};

export default TestDataDetailsV2;
