import isEmpty from "lodash/isEmpty";
import { Checkbox, Divider, Spin } from "antd";
import { default as React, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ACTION_VIEW,
  All_RECORDS,
  CUSTOM,
  MY_TEST_BLOCKS,
  OWNED,
  PRIVATE,
  PUBLIC,
  SHARED,
  SHARED_WITH_ME,
  TEST_BLOCK_DESIGNER,
} from "../../../Constants";
import { removeSelectedIds, selectAllData } from "../../../Util";
import { deleteTestBlock } from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import {
  addTestBlockStep,
  changeTestBlockListFilter,
  editTestStepBlocks,
  getListOfBlocksByProjectId,
  saveTestStepBlockRights,
  setSelectedTestStepBlocks,
} from "../../../actions/TestBlocksAction";
import { setDeviceAssigneeDetail } from "../../../actions/TestDataSet/TestDataSetCommonAction";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import CommonHeaderV2 from "../../CommonComponents/CommonHeaderV2/CommonHeaderV2";
import HeaderButtonsGroupV2 from "../../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import PaginationComponentV2 from "../../CoreComponents/PaginationComponent/PaginationComponentV2";
import PermissionDrawerV2 from "../../CoreComponents/PermissionDrawer/PermissionDrawerV2/PermissionDrawerV2";
import CommonSearchV2 from "../../CoreComponents/SearchComponent/CommonSearchV2";
import TableComponentV2 from "../../CoreComponents/TableComponent/TableComponentV2";
import ImportTestStepActionsModal from "../../RecTestStep/ScenarioHeader/ScenarioSubHeader/ImportTestStepActionsModal/ImportTestStepActionsModal";
import TestBlockActionsColumnV2 from "./TestBlockDetailsColumnsV2/TestBlockActionsColumnV2";
import TestBlockInfoColumnV2 from "./TestBlockDetailsColumnsV2/TestBlockInfoColumnV2";
import TestBlockStepDetailsColumnV2 from "./TestBlockDetailsColumnsV2/TestBlockStepDetailsColumnV2";
import UsedInScenarioColumnV2 from "./TestBlockDetailsColumnsV2/UsedInScenarioColumnV2";
import styles from "./AllTestBlockStepListV2.module.scss";

const AllTestBlockStepListV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId, isTestStepBlocksLoadModel = false, isTestStepBlocksForImport = false, currentActionTab } = props;

  const { fetchingTestBlockList, testBlockListFilter } = useSelector((state) => state.TestBlockReducer);
  const { requestingDeleteRecTestStep, listOfBlocksByProjectId } = useSelector((state) => state.RecTestStepReducer);
  const { importRecTestStepModalVisible, stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const [selectAllTestStepBlocks, setSelectAllTestStepBlocks] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const [openPermissionDrawer, setOpenPermissionDrawer] = useState(false);
  const [permissionTestStepBlock, setPermissionTestStepBlock] = useState(null);
  const [members, setMembers] = useState([]);
  const [previousPermission, setPreviousPermission] = useState([]);
  const [openDeleteTestBlockModal, setOpenDeleteTestBlockModal] = useState({ visible: false, data: {} });
  const [openCreateTestBlockModal, setOpenCreateTestBlockModal] = useState(false);

  const filterPermission = [
    { id: OWNED, value: MY_TEST_BLOCKS },
    { id: SHARED, value: SHARED_WITH_ME },
    { id: "", value: All_RECORDS },
  ];

  //destructure object
  const { data: dataOfTestBlocks } = listOfBlocksByProjectId ? listOfBlocksByProjectId : {};

  useEffect(() => {
    if (permissionTestStepBlock?.recTestStepRights) {
      setMembers([...permissionTestStepBlock?.recTestStepRights]);
    }
  }, [permissionTestStepBlock]);

  useEffect(() => {
    !importRecTestStepModalVisible &&
      dispatch(getListOfBlocksByProjectId(projectId, 1, "", testBlockListFilter, importRecTestStepModalVisible));
  }, [projectId]);

  useEffect(() => {
    setSelectAllTestStepBlocks([]);
    setSearchKeyword([]);
  }, [importRecTestStepModalVisible]);

  const showCheckBoxInTableHeader = useMemo(
    () =>
      isEmpty(selectAllTestStepBlocks) &&
      !isTestStepBlocksLoadModel &&
      !isEmpty(dataOfTestBlocks) &&
      dataOfTestBlocks?.some((i) => i?.allowDelete === ACTION_VIEW),
    [selectAllTestStepBlocks, isTestStepBlocksLoadModel, dataOfTestBlocks]
  );

  const columns = [
    {
      title: (
        <div className={showCheckBoxInTableHeader ? styles["checkBox-in-header"] : styles["table-header"]}>
          {showCheckBoxInTableHeader && (
            <span className={styles["header-checkBox"]}>
              <Checkbox
                className={"selection-checkBox mr-26"}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllTestStepBlocks([
                      ...selectAllTestStepBlocks,
                      ...(
                        dataOfTestBlocks &&
                        dataOfTestBlocks?.filter(
                          (i) => i?.allowDelete === ACTION_VIEW && !selectAllTestStepBlocks.includes(i?.id) && i?.id
                        )
                      )?.map((i) => i?.allowDelete === ACTION_VIEW && i.id),
                    ]);
                  } else {
                    setSelectAllTestStepBlocks(removeSelectedIds(selectAllTestStepBlocks, dataOfTestBlocks));
                  }
                }}
              />
            </span>
          )}
          Test Block Info
        </div>
      ),
      dataIndex: "testBlockInfo",
      width: 500,
    },
    {
      title: "Step Details",
      dataIndex: "stepDetails",
      width: 200,
    },
    {
      title: "Used In Scenarios",
      dataIndex: "usedInScenarios",
      width: 300,
    },
    { title: "Actions", dataIndex: "actions", align: "right", hidden: isTestStepBlocksLoadModel },
  ]?.filter((item) => !item.hidden);

  const deleteConfirm = (recTestStep) => {
    setOpenDeleteTestBlockModal({ visible: true, data: { ...recTestStep } });
  };

  const handleActionChange = (actionName, data) => {
    setPermissionTestStepBlock(() => ({ ...data }));
    switch (actionName) {
      case "deviceStrings":
        dispatch(setDeviceAssigneeDetail(data));
        props.navigateToEditBuildScenario(TEST_BLOCK_DESIGNER);
        dispatch(editTestStepBlocks(data, projectId));
        break;
      case "delete":
        deleteConfirm(data);
        break;
      case PRIVATE:
        savePermission(PRIVATE, data);
        break;
      case PUBLIC:
        savePermission(PUBLIC, data);
        break;
      case CUSTOM:
        setOpenPermissionDrawer(true);
        break;
      default:
        break;
    }
  };

  const callPaginationAPI = (pageNumber) => {
    if (listOfBlocksByProjectId?.currentPage !== pageNumber) {
      dispatch(
        getListOfBlocksByProjectId(
          projectId,
          pageNumber,
          searchKeyword,
          testBlockListFilter,
          importRecTestStepModalVisible
        )
      );
    }
  };

  const savePermission = (Value, recTestStepData, customData) => {
    const result =
      customData &&
      customData?.map((i) => ({
        userId: i?.userId,
        isEdit: i?.isEdit,
        isDelete: i?.isDelete,
        isView: i?.isView,
        isAdmin: i?.isAdmin,
        shouldAddOrRemove: i?.shouldAddOrRemove,
      }));
    let obj = {
      recTestStepId: recTestStepData?.id,
      isPrivate: Value === PRIVATE || Value === CUSTOM ? 1 : 0,
      listOfMembers: Value === CUSTOM && !isEmpty(result) ? result : [],
    };
    dispatch(saveTestStepBlockRights(obj, projectId, testBlockListFilter));
    setOpenPermissionDrawer(false);
    setMembers([]);
  };

  const setPermissionData = () => {
    if (permissionTestStepBlock?.isPrivate && !isEmpty(permissionTestStepBlock?.recTestStepRights)) {
      setPreviousPermission(permissionTestStepBlock?.recTestStepRights);
      setMembers([...permissionTestStepBlock?.recTestStepRights]);
    } else {
      setMembers([]);
      setPreviousPermission([]);
    }
  };

  const handleFilters = (e) => {
    dispatch(changeTestBlockListFilter(e, projectId, searchKeyword));
  };

  const testStepBlockDetails = dataOfTestBlocks?.map((row, index) => ({
    key: row?.id,
    testBlockInfo: (
      <TestBlockInfoColumnV2
        row={row}
        projectId={projectId}
        selectAllScenario={selectAllTestStepBlocks}
        setSelectAllScenario={setSelectAllTestStepBlocks}
        isTestStepBlocksLoadModel={isTestStepBlocksLoadModel}
        navigateToEditBuildScenario={props.navigateToEditBuildScenario}
        isTestStepBlocksForImport
      />
    ),

    stepDetails: <TestBlockStepDetailsColumnV2 row={row} />,
    usedInScenarios: <UsedInScenarioColumnV2 row={row} index={index} />,
    actions: !isTestStepBlocksLoadModel && (
      <TestBlockActionsColumnV2
        row={row}
        navigateToEditBuildScenario={props.navigateToEditBuildScenario}
        handleActionChange={handleActionChange}
        projectId={projectId}
      />
    ),
  }));

  const generateModalProps = () => {
    if (openCreateTestBlockModal) {
      return {
        handleOk: () => {
          dispatch(addTestBlockStep(searchKeyword, props.navigateToEditBuildScenario));
          setOpenCreateTestBlockModal(false);
        },
        handleCancel: () => setOpenCreateTestBlockModal(false),
        modalOpen: openCreateTestBlockModal,
        modalContent: "Are you sure you want to create new Test Block?",
        modalTitle: "Confirm Test Block Creation",
        okText: "Create",
      };
    } else {
      return {
        handleOk: () => {
          dispatch(
            deleteTestBlock(
              openDeleteTestBlockModal?.data?.id ? [openDeleteTestBlockModal?.data?.id] : selectAllTestStepBlocks,
              searchKeyword,
              setSelectAllTestStepBlocks
            )
          );
          setOpenDeleteTestBlockModal({ visible: false, data: {} });
        },
        handleCancel: () => setOpenDeleteTestBlockModal({ visible: false, data: {} }),
        modalOpen: openDeleteTestBlockModal?.visible,
        modalContent: `Are you sure you want to delete ${
          selectAllTestStepBlocks && selectAllTestStepBlocks.length > 1 ? "these items?" : "this item?"
        } This action cannot be undone.`,
        modalTitle: "Confirm Deletion",
        okText: "Delete",
        isDeleteModal: true,
      };
    }
  };

  return (
    <Spin
      spinning={!importRecTestStepModalVisible && (fetchingTestBlockList || requestingDeleteRecTestStep)}
      tip="Loading"
      className={!importRecTestStepModalVisible && !isTestStepBlocksLoadModel ? `${styles.spinnerCenter}` : ""}
    >
      <div>
        {!isTestStepBlocksLoadModel && (
          <CommonHeaderV2
            headerText={"Test Blocks"}
            label={"Build Test Block"}
            handleOk={() => setOpenCreateTestBlockModal(true)}
          />
        )}
        <div className={styles["sub-header"]}>
          {!isTestStepBlocksForImport && (
            <HeaderButtonsGroupV2
              data={filterPermission}
              activeButton={testBlockListFilter?.id}
              onClick={(e) => handleFilters(e)}
            />
          )}
          {!isTestStepBlocksForImport && (
            <CommonSearchV2
              searchDetails={(searchWord) => {
                dispatch(getListOfBlocksByProjectId(props.projectId, 1, searchWord, testBlockListFilter));
              }}
              placeHolder={isTestStepBlocksForImport ? "Search TestBlockSteps" : "Search By Test Blocks Name"}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              importSteps={isTestStepBlocksForImport ? true : false}
            />
          )}
        </div>
        <div>
          <Divider className="m-0" />
          <div className="table_Details_scroll">
            <div className={`${!isTestStepBlocksForImport && "table_Details__wrapper"}`}>
              {!isTestStepBlocksLoadModel && !isEmpty(selectAllTestStepBlocks) && (
                <div className={styles["select--all-box"]}>
                  {selectAllData(selectAllTestStepBlocks, setSelectAllTestStepBlocks, dataOfTestBlocks, deleteConfirm)}
                </div>
              )}
              {isTestStepBlocksForImport ? (
                !isEmpty(dataOfTestBlocks) && (
                  <>
                    {dataOfTestBlocks?.map((recTestStep, index) => (
                      <ImportTestStepActionsModal
                        recTestStep={recTestStep}
                        key={index}
                        index={index}
                        projectId={projectId}
                        stepType={stepType}
                        currentActionTab={currentActionTab}
                        name={recTestStep.name}
                      />
                    ))}
                  </>
                )
              ) : (
                <div>
                  <TableComponentV2
                    columns={columns}
                    data={testStepBlockDetails}
                    selected={selectAllTestStepBlocks}
                    className={`table__wrapper ${!isEmpty(selectAllTestStepBlocks) && " hide_column_name"}`}
                    handleSelectRow={(selectedRow) => ({
                      onClick: () => {
                        let data = listOfBlocksByProjectId && dataOfTestBlocks?.find((i) => i?.id === selectedRow?.key);
                        dispatch(
                          setSelectedTestStepBlocks({
                            id: data.id,
                            name: data.recTestStepProperty?.stepName,
                            tag: data.tag,
                            testRailTestCaseId: data.testRailTestCaseId,
                            testRailTestSuiteId: data.testRailTestSuiteId,
                            platform: data.platform,
                            recTestStepProperty: data.recTestStepProperty,
                          })
                        );
                      },
                    })}
                    selectedRowId={[props.selectedTestBlocksId]}
                  />
                </div>
              )}
              <div className={"pagination_Section"}>
                {!isEmpty(dataOfTestBlocks) && !isTestStepBlocksLoadModel && (
                  <PaginationComponentV2
                    currentPageItems={listOfBlocksByProjectId?.pageItems}
                    totalItems={listOfBlocksByProjectId?.totalItems}
                    currentPage={listOfBlocksByProjectId?.currentPage}
                    totalPage={listOfBlocksByProjectId?.totalCount}
                    callPaginationAPI={callPaginationAPI}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PermissionDrawerV2
        openPermissionDrawer={openPermissionDrawer}
        onClose={() => setOpenPermissionDrawer(false)}
        permissionData={permissionTestStepBlock}
        savePermission={savePermission}
        members={members}
        setMembers={setMembers}
        previousPermission={previousPermission}
        setPermissionData={setPermissionData}
        ownerEmailId={permissionTestStepBlock?.createdByEmail}
        notShowExecutionCheck={true}
        drawerTitle={"Test Block"}
      />
      {(openDeleteTestBlockModal?.visible || openCreateTestBlockModal) && (
        <CommonConfirmationModalV2 {...generateModalProps()} />
      )}
    </Spin>
  );
};
export default AllTestBlockStepListV2;
