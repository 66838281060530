import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Select, Space, Spin } from "antd";
import { Link } from "react-router-dom";
import { getChildComponentByTypeAndParentId } from "../../actions/CommonActions";
import { goToDeviceDetailListScreen } from "../../actions/OrgDeviceAction";
import { goToProjects } from "../../actions/ProjectsAction";
import { cancelLogin, loginLocalDeviceServer, selectedOrg } from "../../actions/ServerAction";
import { getOrgList } from "../../actions/TeamsAction";
import { DEVICE_DETAILS, xpressComponentType } from "../../Constants";
import { LOGO_XPRESS } from "../../Constants/SvgConstants";
import history from "../../history";
import SvgLoader from "../../Util/SvgLoader";
import CommonSelectBoxV2 from "../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import VerticalLine from "../CommonComponents/VerticalLine";
import HeaderComponent from "../Header/Header";
import styles from "./LocalDeviceServerV2.module.scss";

const { Option } = Select;

const LocalDeviceServerV2 = (props) => {
  const dispatch = useDispatch();
  let { orgList } = useSelector((state) => state.TeamsReducer);
  let { selectedOrgId, loginStatusLoading, successLoginInLocalDeviceServer } = useSelector(
    (state) => state.ServerReducer
  );

  useEffect(() => {
    dispatch(getOrgList());
    dispatch(
      getChildComponentByTypeAndParentId({
        componentType: xpressComponentType.menu,
        parentId: props.route.componentId,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Spin spinning={loginStatusLoading} className="spinner-center">
        {!successLoginInLocalDeviceServer ? (
          <>
            <HeaderComponent
              showHumbergerMenu={true}
              buttonState={DEVICE_DETAILS}
              organizationId={orgList[0]?.organization?.id}
            />{" "}
            <div className={styles["localDeviceServer-header"]}>
              <div className={styles["localDeviceServer-title"]}>Local Device Server</div>
            </div>
            <div className={styles["dropdown-container"]}>
              <div className={styles["dropdown"]}>
                <CommonSelectBoxV2
                  label="Organization"
                  labelBackgroundClass="label_Background"
                  options={orgList?.map((i, k) => (
                    <Option value={i.organization.id} key={k}>
                      {i.organization.name}
                    </Option>
                  ))}
                  value={selectedOrgId}
                  placeholder="Select Organization"
                  handleChange={(value) => dispatch(selectedOrg(value))}
                />
                <div className={styles["button-container"]}>
                  <Space size={10}>
                    <Button type="default" className={styles["cancel_Btn"]} onClick={() => dispatch(cancelLogin())}>
                      <span>Cancel</span>
                    </Button>
                    <Button
                      type="primary"
                      className={styles["continue_Btn"]}
                      onClick={() => dispatch(loginLocalDeviceServer())}
                      disabled={selectedOrgId === null}
                    >
                      <span>Continue</span>
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles["container"]}>
            <div className={styles["header"]}>
              <div className={styles["logo"]}>
                <SvgLoader iconName={LOGO_XPRESS} width={100} height={30} />
                <VerticalLine className={styles["border_Right"]} />

                <div className={styles["check_Icon"]}>
                  <FontAwesomeIcon icon={faCheck} />
                </div>
                <span className={styles["login_Text"]}>Authentication Successful</span>
              </div>
            </div>

            <div className={styles["content"]}>
              <div className={styles["section"]}>
                <h2 className={styles["section_Title"]}>1. Start Local Device Server</h2>
                <div className={styles["list"]}>
                  <div>1. Open the XpressLabsAgent App</div>
                  <div>2. Navigate to Settings</div>
                  <div>3. Choose appropriate settings and click Update</div>
                  <div>4. Start Device Server</div>
                </div>
              </div>

              <div className={styles["section"]}>
                <h2 className={styles["sectionTitle"]}>2. Assign and Use Device</h2>
                <ul className={styles["bullet_List"]}>
                  <li>
                    If you've connected a new device, you need to register it in{" "}
                    <Link
                      to="#"
                      className={styles["link"]}
                      onClick={(e) => {
                        history.push(`/${selectedOrgId}/ServerManagement`);
                        dispatch(getOrgList());
                      }}
                    >
                      Device Server Management
                    </Link>
                    .
                  </li>
                  <li>
                    If the device is already registered, you can add it to a project from the{" "}
                    <Link
                      to="#"
                      className={styles["link"]}
                      onClick={(e) => {
                        dispatch(goToDeviceDetailListScreen(selectedOrgId));
                        dispatch(getOrgList());
                      }}
                    >
                      Devices List
                    </Link>
                    .
                  </li>
                  <li>
                    Once both steps are done, go to the{" "}
                    <Link
                      to="#"
                      className={styles["link"]}
                      onClick={(e) => {
                        dispatch(goToProjects(selectedOrgId));
                        dispatch(getOrgList());
                      }}
                    >
                      Projects
                    </Link>{" "}
                    page to start using your devices.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </Spin>
    </>
  );
};

export default LocalDeviceServerV2;
