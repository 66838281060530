import React, { Fragment, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactJson from "react-json-view";
import { faCamera, faClock, faRecycle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Input, Row, Tooltip, Popover } from "antd";
import {
  ELEMENT_ACTION,
  REC_TEST_STEP_PREVIEW_STATUS_TYPE,
  TEST_SCENARIOS,
  TOOLTIP_BG_COLOR,
} from "../../../../Constants";
import {
  ACTION_ICON_COLOR,
  CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP,
  DEEP_BLUE_COLOR,
  IGNORE_FAILURE_AND_CONTINUE_EXECUTION_ICON,
  OPEN_COLLAPSE_ICON_FOR_PARENT_STEP,
} from "../../../../Constants/SvgConstants";
import {
  blockCollapseAction,
  disableStep,
  getRecTestStepStatusWithImage,
  getStepActionIcon,
  getStepActionIconTooltip,
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import { selectAllRecScenarioStepIds } from "../../../../actions/RecTestScenarioAction";
import { changeRecTestStepActionName } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../actions/RecTestStepAction";
import VerticalLine from "../../../CommonComponents/VerticalLine";
import HoverActionsOnRecTestStepV2 from "../HoverActionsOnRecTestStepV2/HoverActionsOnRecTestStepV2";
import iconAI from "../../../../images/iconAi.png";
import styles from "./RecTestStepActionDetailV2.module.scss";

const RecTestStepActionPanelHeaderV2 = ({
  data,
  disabled,
  disabledStep,
  stepId,
  projectId,
  isTestBlock,
  isDisableStep,
  parentSkipStep,
  setDragOverOnCollapse,
  handleDragLeave,
  setMenuVisibleInActions,
  menuVisibleInActions,
  showToggle,
  stepNo,
  selectedStep,
}) => {
  const dispatch = useDispatch();
  const { isPreviewRunning, selectedRectTestScenarioStepId } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecStepTag, stepType, expandedStepTags, expandedStepAssocIds, isLoadingForAIStep } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  //Destructure of data object
  const {
    isSkipStep,
    recTestScenarioRecTestStepAssocId: scenarioAssocIdOfData,
    id: idOfData,
    tag: tagOfData,
    orderId,
  } = data;
  const {
    continueAfterFail,
    action,
    uniqueByIndex,
    stepName,
    retryCount,
    pauseTime,
    isCaptureScreenshot,
    captureScreenShotDelayTime,
  } = data && data?.recTestStepProperty ? data?.recTestStepProperty : {};

  const derivedValues = useMemo(() => {
    const isSelected =
      stepType === TEST_SCENARIOS
        ? selectedRectTestScenarioStepId?.includes(scenarioAssocIdOfData)
        : selectedRectTestScenarioStepId?.includes(idOfData);
    const selectedRectTestStep = !isSelected && selectedStep;
    const selectedAction =
      stepType === TEST_SCENARIOS && scenarioAssocIdOfData ? [...expandedStepAssocIds] : [...expandedStepTags];
    const activeCollapse = selectedAction?.includes(
      stepType === TEST_SCENARIOS && scenarioAssocIdOfData ? scenarioAssocIdOfData : tagOfData
    );
    const stylesRecTestStepOrderName = selectedStep
      ? styles["selectChildStyledStepOrder"]
      : isDisableStep
      ? styles["childDisableStepOrder"]
      : styles["childStyledStepOrder"];

    const shouldDisplayIcon =
      continueAfterFail === 1 || isCaptureScreenshot === 1 || pauseTime !== 0 || retryCount !== 0;
    return {
      selectedRectTestStep,
      activeCollapse,
      stylesRecTestStepOrderName,
      shouldDisplayIcon,
    };
  }, [
    stepType,
    scenarioAssocIdOfData,
    idOfData,
    tagOfData,
    selectedRectTestScenarioStepId,
    expandedStepAssocIds,
    expandedStepTags,
    isDisableStep,
    selectedStep,
    continueAfterFail,
    isCaptureScreenshot,
    pauseTime,
    retryCount,
  ]);

  const { selectedRectTestStep, activeCollapse, stylesRecTestStepOrderName, shouldDisplayIcon } = derivedValues;

  const handleMultiSelectStepId = useCallback(
    (e) => {
      if (
        (e.ctrlKey || e.metaKey) &&
        !isDisableStep &&
        !unSavedRecStepTag &&
        isLoadingForAIStep === null &&
        !isPreviewRunning
      ) {
        e.stopPropagation();
        dispatch(
          selectAllRecScenarioStepIds(stepType === TEST_SCENARIOS ? data?.recTestScenarioRecTestStepAssocId : data?.id)
        );
      } else {
        if (
          !selectedRectTestScenarioStepId?.includes(
            stepType === TEST_SCENARIOS ? data?.recTestScenarioRecTestStepAssocId : data?.id
          )
        ) {
          dispatch(selectRecTestSteps({ ...data, parentSkipStep }));
        }
      }
    },
    [
      isPreviewRunning,
      isLoadingForAIStep,
      dispatch,
      isDisableStep,
      unSavedRecStepTag,
      stepType,
      data,
      parentSkipStep,
      selectedRectTestScenarioStepId,
    ]
  );

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col className={styles["recTestStep"]}>
          <div className={styles["recTestStepTextInput"]}>
            <Tooltip color={TOOLTIP_BG_COLOR} title={stepId?.length > 6 && `${stepId}.${orderId}`}>
              <div
                className={`${!isDisableStep && "onHoverActionOrderNameBorder"} ${stylesRecTestStepOrderName} ${
                  (selectedRectTestScenarioStepId?.includes(
                    stepType === TEST_SCENARIOS ? data?.recTestScenarioRecTestStepAssocId : data?.id
                  ) &&
                    "order_path_blue") ||
                  (selectedStep && "selected-step-or-action ")
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMultiSelectStepId(e);
                }}
              >
                <span className={`recTestStepName ${selectedRectTestStep ? "color__white" : "color__darkGrayColor"}`}>
                  {orderId}
                </span>
              </div>
            </Tooltip>
            <div>
              <div
                className={`collapseIconOnHover ${styles["collapseIcon"]} ${isDisableStep && "collapseIconBgColor"}`}
              >
                <SvgLoader
                  iconName={activeCollapse ? OPEN_COLLAPSE_ICON_FOR_PARENT_STEP : CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP}
                />
              </div>
            </div>

            {data.recTestStepProperty.isAIStep === 1 && data.recTestStepProperty.aiGeneratedStep && (
              <div className={styles["actionIcon"]}>
                <Popover
                  overlayStyle={{ width: "60%" }}
                  content={
                    <>
                      <div className={styles["json_Data"]}>
                        <ReactJson src={JSON.parse(data.recTestStepProperty.aiGeneratedStep)} />
                      </div>
                    </>
                  }
                  title="AI Generated Step"
                  placement="bottom"
                  showArrow={false}
                >
                  <span>
                    <img src={iconAI} alt="iconAI" />
                  </span>
                </Popover>
              </div>
            )}
            <div className={styles["actionIcon"]}>
              <Tooltip title={getStepActionIconTooltip(data?.recTestStepProperty?.action)} color={TOOLTIP_BG_COLOR}>
                {uniqueByIndex && uniqueByIndex > 0 ? (
                  <Fragment>
                    <SvgLoader iconName={getStepActionIcon(action)} width="1.38rem" height="1.75rem" />
                    {action === ELEMENT_ACTION.TAP_BY_INDEX && (
                      <span className={styles["styledTapByIndexValue"]} title="Tap on index value">
                        {uniqueByIndex}
                      </span>
                    )}
                  </Fragment>
                ) : (
                  <div>
                    <SvgLoader
                      iconName={getStepActionIcon(action)}
                      width="1.38rem"
                      height="1.75rem"
                      iconColor={selectedStep ? DEEP_BLUE_COLOR : ACTION_ICON_COLOR}
                    />
                  </div>
                )}
              </Tooltip>
            </div>
            <Input
              className={styles["inputStyle"]}
              collapsible={"false"}
              type="text"
              placeholder="Provide a title/description for the action"
              value={stepName}
              disabled={
                disabled ||
                parentSkipStep ||
                isSkipStep === 1 ||
                (stepType === TEST_SCENARIOS && isTestBlock === 1) ||
                isPreviewRunning
              }
              onChange={(e) => dispatch(changeRecTestStepActionName(e.target.value, tagOfData))}
              onClick={(e) => {
                blockCollapseAction(e);
                dispatch(selectRecTestSteps(data));
              }}
              onDragOver={() => {
                setDragOverOnCollapse(true);
              }}
              onDragLeave={handleDragLeave}
            />
          </div>
          {!isDisableStep && unSavedRecStepTag === undefined && !isPreviewRunning && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                dispatch(selectRecTestSteps(data));
              }}
              className={!selectedStep && "showActionsIconOnHover"}
            >
              <HoverActionsOnRecTestStepV2
                recTestStep={data}
                showToggle={showToggle}
                parentSkipStep={disabledStep}
                disabled={disabled}
                projectId={projectId}
                stepId={stepId}
                stepNo={stepNo}
                menuVisible={menuVisibleInActions}
                setMenuVisible={setMenuVisibleInActions}
                ignoreFailureDisable={disabled || parentSkipStep}
              />
            </div>
          )}
          {(shouldDisplayIcon || isDisableStep || data?.previewStatus) && (
            <div className={styles["switchOrPreviewResult"]}>
              {shouldDisplayIcon && (
                <div className={styles["displayIcon"]}>
                  {continueAfterFail === 1 && (
                    <Tooltip title="Ignore Failure & continue execution" color={TOOLTIP_BG_COLOR} placement="topRight">
                      <span className={styles["stylesCaptureScreenshot"]}>
                        <SvgLoader iconName={IGNORE_FAILURE_AND_CONTINUE_EXECUTION_ICON} />
                      </span>
                    </Tooltip>
                  )}
                  {isCaptureScreenshot === 1 && (
                    <Tooltip
                      color={TOOLTIP_BG_COLOR}
                      title={"Capture screenshot after " + captureScreenShotDelayTime + " milliseconds"}
                      placement="topRight"
                    >
                      <span className={styles["stylesCaptureScreenshot"]}>
                        <FontAwesomeIcon icon={faCamera} />
                      </span>
                    </Tooltip>
                  )}
                  {pauseTime !== 0 && (
                    <Tooltip
                      color={TOOLTIP_BG_COLOR}
                      title={"Delay Execution for " + pauseTime + " milliseconds"}
                      placement="topRight"
                    >
                      <span className={styles["stylesCaptureScreenshot"]}>
                        <FontAwesomeIcon icon={faClock} />
                      </span>
                    </Tooltip>
                  )}
                  {retryCount !== 0 && (
                    <Tooltip color={TOOLTIP_BG_COLOR} title={"Retry Count: " + retryCount} placement="topRight">
                      <span className={styles["stylesCaptureScreenshot"]}>
                        <FontAwesomeIcon icon={faRecycle} />
                      </span>
                    </Tooltip>
                  )}
                </div>
              )}
              {(data?.previewStatus || isDisableStep) && (
                <>
                  <div className={styles["previewIcon"]}>
                    {getRecTestStepStatusWithImage(
                      data?.previewStatus === REC_TEST_STEP_PREVIEW_STATUS_TYPE.CONTINUE_AFTER_FAILED &&
                        data?.preconditionResult === REC_TEST_STEP_PREVIEW_STATUS_TYPE.CONTINUE_AFTER_FAILED
                        ? REC_TEST_STEP_PREVIEW_STATUS_TYPE.PRECONDITION_FAILED
                        : data?.previewStatus
                    )}
                  </div>
                  {data?.previewStatus && <VerticalLine className={styles["borderRight"]} />}
                  {isDisableStep && (
                    <div className={styles["disabledTextOrSwitch"]}>
                      <Tooltip
                        color={TOOLTIP_BG_COLOR}
                        title={!parentSkipStep && (isSkipStep === 1 ? "Enable" : "Disable")}
                      >
                        {disableStep(
                          isTestBlock,
                          unSavedRecStepTag,
                          parentSkipStep,
                          disabledStep,
                          data,
                          dispatch,
                          isPreviewRunning,
                          stepType,
                          "disable_Switch",
                          isLoadingForAIStep
                        )}
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default RecTestStepActionPanelHeaderV2;
