import React, { useState } from "react";
import { Dropdown, Spin, Tooltip } from "antd";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import { ACTION_ICON_COLOR, DISABLE_ICON_COLOR } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import styles from "./EditTestHeaderOptionsV2.module.scss";

const ButtonActionsV2 = (props) => {
  const { classForButton, handleClick, disabled, toolTipTitle, spinner, imageName, dropDownIcon, imageColor } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <Tooltip
      title={toolTipTitle}
      color={TOOLTIP_BG_COLOR}
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
      onClick={() => setTooltipVisible(false)}
      visible={tooltipVisible}
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
    >
      <div className={styles[classForButton]}>
        {spinner ? (
          <Spin spinning={spinner} className={styles["spinner"]}></Spin>
        ) : dropDownIcon ? (
          <Dropdown overlay={handleClick} trigger={["click"]} disabled={disabled}>
            <div>
              <SvgLoader
                iconName={imageName}
                iconColor={disabled ? DISABLE_ICON_COLOR : ACTION_ICON_COLOR}
                width="1.87rem"
                height="1.87rem"
                disabled={disabled}
                handleClick={() => setTooltipVisible(false)}
              />
            </div>
          </Dropdown>
        ) : (
          <div onClick={handleClick}>
            <SvgLoader
              iconName={imageName}
              iconColor={disabled ? DISABLE_ICON_COLOR : imageColor ? imageColor : ACTION_ICON_COLOR}
              width="1.87rem"
              height="1.87rem"
              disabled={disabled}
              handleClick={() => setTooltipVisible(false)}
            />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default ButtonActionsV2;