import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { ACTION_NOT_VIEW, ACTION_VIEW, APP_BUILD_UPLOAD_STATUS, TOOLTIP_BG_COLOR } from "../../../../Constants";
import { getPermissionType, permissionTypeIcon } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import ShowTooltipV2 from "../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import styles from "./AppBuildListColumnsV2.module.scss";
import { pushUploadBuild } from "../../../../actions/Recording";

const BuildDetailsColumnsV2 = (props) => {
  const dispatch = useDispatch();
  const {
    row,
    executionTab,
    testJobDetail,
    setSelectAllBuild,
    selectAllBuild,
    fetchAppBuildDataById,
    changeTestJobData,
    devicePushBuild,
  } = props;

  const { pushUploadBuildId } = useSelector((state) => state.RecordingReducer);

  const buildSelectionCheckboxType = (row) => {
    return (
      <span>
        {![APP_BUILD_UPLOAD_STATUS.IN_PROGRESS, APP_BUILD_UPLOAD_STATUS.PENDING].includes(row?.uploadStatus) &&
          row.allowDelete === ACTION_VIEW && (
            <Checkbox
              className="selection-checkBox mr-26"
              checked={selectAllBuild && selectAllBuild?.map((i) => i?.id)?.includes(row?.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  testJobDetail &&
                    dispatch(
                      changeTestJobData({
                        ...testJobDetail,
                        buildDetail: { ...row },
                      })
                    );
                  setSelectAllBuild([
                    ...selectAllBuild,
                    {
                      id: row?.id,
                      folderPath: row?.folderPath,
                      fileKey: row?.fileKey,
                      awsDeviceFarmProjectId: row?.awsDeviceProjectId,
                    },
                  ]);
                } else {
                  setSelectAllBuild(selectAllBuild && selectAllBuild?.filter((i) => i?.id !== row?.id));
                }
              }}
            />
          )}
      </span>
    );
  };
  
  const buildSelectionRadioType = (row) => {
    return (
      <span className="mr-10">
        {![APP_BUILD_UPLOAD_STATUS.IN_PROGRESS, APP_BUILD_UPLOAD_STATUS.PENDING].includes(row?.uploadStatus) && (
          <input
            className="vertical-align-middle h-17 w-18 cursor-pointer"
            type="radio"
            name="radioGroup"
            value={row?.id}
            checked={
              devicePushBuild
                ? row?.id === pushUploadBuildId
                : (selectAllBuild && selectAllBuild?.map((i) => i?.id)?.includes(row?.id)) ||
                  testJobDetail?.buildDetail?.id === row?.id
            }
            onChange={(e) => {
              if (e.target.checked) {
                if (devicePushBuild) {
                  dispatch(pushUploadBuild(row?.id));
                } else {
                  testJobDetail &&
                    dispatch(
                      changeTestJobData({
                        ...testJobDetail,
                        buildDetail: { ...row },
                        isUseLatestBuild: 0,
                      })
                    );
                }
                setSelectAllBuild([
                  {
                    id: row?.id,
                    folderPath: row?.folderPath,
                    fileKey: row?.fileKey,
                    awsDeviceFarmProjectId: row?.awsDeviceProjectId,
                  },
                ]);
              } else {
                if (devicePushBuild) {
                  dispatch(pushUploadBuild(null));
                }
                setSelectAllBuild(selectAllBuild && selectAllBuild?.filter((i) => i?.id !== row?.id));
              }
            }}
          />
        )}
      </span>
    );
  };

  return (
    <div
      className={`${
        !executionTab &&
        !devicePushBuild &&
        ([APP_BUILD_UPLOAD_STATUS.IN_PROGRESS, APP_BUILD_UPLOAD_STATUS.PENDING].includes(row?.uploadStatus) ||
          row.allowDelete === ACTION_NOT_VIEW) &&
        styles["table-details"]
      }  display__flex ml-5`}
    >
      {executionTab || devicePushBuild ? buildSelectionRadioType(row) : buildSelectionCheckboxType(row)}

      <div
        className={`${
          ([APP_BUILD_UPLOAD_STATUS.IN_PROGRESS, APP_BUILD_UPLOAD_STATUS.PENDING].includes(row?.uploadStatus) ||
            row.allowDelete === ACTION_NOT_VIEW) &&
          styles["permission_Icons_And_Name"]
        } ${styles["icon-and-details"]}`}
      >
        <div className={styles["permission-tooltip"]}>
          <Tooltip title={getPermissionType(row?.isPrivate, row?.tesPlanRights?.data)} color={TOOLTIP_BG_COLOR}>
            <div>
              <SvgLoader iconName={permissionTypeIcon(getPermissionType(row?.isPrivate, row?.tesPlanRights?.data))} />
            </div>
          </Tooltip>
        </div>
        <div className={styles["test_Plan_Info_Details"]}>
          <ShowTooltipV2
            text={row?.displayName}
            className={styles["step-name"]}
            handleOnClick={() => row?.uploadStatus === "Done" && fetchAppBuildDataById(row?.id)}
          />
          <div>
            <span className={styles["label"]}>Version: </span>{" "}
            <span className={styles["label-name"]}>{row?.buildVersion}</span>
          </div>
          <div>
            <span className={styles["label"]}>Type: </span>{" "}
            <span className={styles["label-name"]}>{row?.buildType}</span>
          </div>

          {!isEmpty(row?.buildLinkedTo) && (
            <div className={styles["build_Linked_Label"]}>
              <span>Uploaded on</span>
              <div className={styles["build_Link"]}>
                {row?.buildLinkedTo?.map((i, index) => (
                  <span key={i.id || index}>
                    {index !== 0 && ", "}
                    <span className={styles["link"]}>{i?.name}</span>
                  </span>
                ))}
              </div>
              <span>Farm</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuildDetailsColumnsV2;
