import { Button, Modal, Row, Spin } from "antd";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBuildDetails, pushAnUploadBuild, pushBuild, pushUploadBuild } from "../../../actions/Recording";
import { INITIAL_APP_BUILD_DATA, SUCCESS_STATUS } from "../../../Constants";
import { handleSaveBuild, isNotNullAndNotEmptyAndNotUndefined, isNotNullBuildDetails } from "../../../Util";
import AddAppBuildV2 from "../../BuildManagementV2/AddAppBuildV2/AddAppBuildV2";
import AppBuildListV2 from "../../BuildManagementV2/AppBuildListV2/AppBuildListV2";
import HeaderButtonsGroupV2 from "../../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import styles from "./DevicePushBuildModalV2.module.scss";

const filterPermission = [
  { id: "USE AN UPLOAD BUILD", value: "Use Uploaded Build" },
  { id: "UPLOAD BUILD", value: "Install Build from Local System" },
];

const DevicePushBuildModalV2 = (props) => {
  const { projectId, hidePushBuildModal, visible, targetUniqueId } = props;
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [saveBuild, setSaveBuild] = useState(false);
  const [buildId, setBuildId] = useState("");
  const [activeFilter, setActiveFilter] = useState({ id: "USE AN UPLOAD BUILD", value: "Use Uploaded Build" });

  const [appBuildData, setAppBuildData] = useState(INITIAL_APP_BUILD_DATA);
  const {
    pushBuildRequest,
    pushBuildResponse,
    appBuildDetailsById,
    sharedWithFarmInAddAppBuild,
    uploadAndSaveBuildRequest,
    pushUploadBuildId,
  } = useSelector((state) => state.RecordingReducer);
  const { deviceFarmList } = useSelector((state) => state.ServerListReducer);

  useEffect(() => {
    setBuildId("");
  }, [props.projectId]);

  useEffect(() => {
    if (visible) {
      dispatch(getAllBuildDetails(false, searchKeyword, projectId));
      setSaveBuild(false);
      setActiveFilter({ id: "USE AN UPLOAD BUILD", value: "Use Uploaded Build" })
    }
  }, [visible]);

  const handleOnClose = () => {
    hidePushBuildModal();
    setSearchKeyword(null);
    dispatch(pushUploadBuild(null));
    setAppBuildData(INITIAL_APP_BUILD_DATA);
  };

  useEffect(() => {
    if (pushBuildResponse === SUCCESS_STATUS) {
      setSearchKeyword(null);
      dispatch(pushUploadBuild(null));
    }
  }, [pushBuildResponse]);

  const disableUploadButton = () => {
    if (isNotNullAndNotEmptyAndNotUndefined(appBuildDetailsById)) {
      return isEqual(appBuildData, appBuildDetailsById) || isNotNullBuildDetails(appBuildData);
    } else {
      return isNotNullBuildDetails(appBuildData);
    }
  };
  const selectedFarms = [];

  if (deviceFarmList && deviceFarmList.length > 0) {
    deviceFarmList.map((v, k) => {
      return selectedFarms.push(v.id);
    });
  }

  return (
    <Modal
      width="70%"
      title={<span className={styles["modal_Title"]}>Install Build</span>}
      visible={visible}
      destroyOnClose
      onCancel={() => {
        if (!(pushBuildRequest || uploadAndSaveBuildRequest)) {
          handleOnClose();
        }
      }}
      closable={!pushBuildRequest && !uploadAndSaveBuildRequest}
      maskClosable={!pushBuildRequest && !uploadAndSaveBuildRequest}
      confirmLoading={pushBuildRequest || uploadAndSaveBuildRequest}
      loading={pushBuildRequest || uploadAndSaveBuildRequest}
      className="importStepsOrActionsModal device_Push_Build_Modal"
      footer={[
        <div className="modal-Content-Btn">
          <Button
            key="submit"
            className={`modal_Buttons modal_Cancel_Btn modal-Button-Width`}
            disabled={pushBuildRequest || uploadAndSaveBuildRequest}
            onClick={() => {
              if (!(pushBuildRequest || uploadAndSaveBuildRequest)) {
                handleOnClose();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            className={`modal_Buttons modal-Ok-Btn`}
            onClick={() =>
              activeFilter?.id === "USE AN UPLOAD BUILD"
                ? dispatch(pushBuild([targetUniqueId], pushUploadBuildId))
                : saveBuild === true
                ? handleSaveBuild(
                    selectedFarms,
                    sharedWithFarmInAddAppBuild,
                    selectedFarms,
                    buildId,
                    appBuildData,
                    dispatch,
                    true,
                    targetUniqueId,
                    setAppBuildData
                  )
                : dispatch(pushAnUploadBuild(appBuildData))
            }
            disabled={activeFilter?.id === "USE AN UPLOAD BUILD" ? pushUploadBuildId == null : disableUploadButton()}
            loading={pushBuildRequest || uploadAndSaveBuildRequest}
          >
            Push Build
          </Button>
        </div>,
      ]}
    >
      <HeaderButtonsGroupV2
        data={filterPermission}
        activeButton={activeFilter?.id}
        onClick={(e) => setActiveFilter(e)}
      />

      {activeFilter?.id === "USE AN UPLOAD BUILD" && (
        <div className={styles["build_Details"]}>
          <div className={styles.pageScroll}>
            <AppBuildListV2 setBuildId={setBuildId} projectId={projectId} devicePushBuild={true} />
          </div>
        </div>
      )}
      {activeFilter?.id === "UPLOAD BUILD" && (
        <Spin spinning={uploadAndSaveBuildRequest || pushBuildRequest} className="spinner-center">
          <AddAppBuildV2
            buildId={buildId}
            projectId={props.projectId}
            devicePushBuild={true}
            saveBuild={saveBuild}
            setSaveBuild={setSaveBuild}
            appBuildData={appBuildData}
            setAppBuildData={setAppBuildData}
          />
        </Spin>
      )}
    </Modal>
  );
};

export default DevicePushBuildModalV2;
