import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Tooltip } from "antd";
import { hideDeleteUserModal, removeMemberFromTeam, showDeleteUserModal } from "../../../actions/TeamsAction";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import { SILVER_GRAY, TRASH_ICON } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import TableComponentV2 from "../../CoreComponents/TableComponent/TableComponentV2";
import styles from "./MembersByTeamV2.module.scss";

const MembersByTeamV2 = (props) => {
  const dispatch = useDispatch();
  const { myTeamMembers, removeUserRights } = props;
  const { teamNameOfProject, deleteUserId, deleteUserName, deleteUserModalVisible } = useSelector(
    (state) => state.ProjectsReducer
  );

  const columns = [
    {
      title: "Member",
      dataIndex: "member",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
    },
  ];
  const testPlanDetails =
    myTeamMembers &&
    myTeamMembers?.map((row) => ({
      key: row?.id,
      member: (
        <>
          <Avatar className="avatar-font" style={{ backgroundColor: "#FCC7C7" }}>
            {row?.userName
              .split(" ")
              .map((i) => i[0])
              .join("")}
          </Avatar>
          <titleName className={styles["member_Name"]}>{row?.userName}</titleName>
        </>
      ),
      action: (
        <Tooltip
          title={removeUserRights && row?.isTL === 0 ? "Remove User" : "This user can not be removed"}
          color={TOOLTIP_BG_COLOR}
        >
          <div>
            <SvgLoader
              iconName={TRASH_ICON}
              iconColor={removeUserRights && row?.isTL === 0 ? SILVER_GRAY : "#a5a5a5"}
              handleClick={(e) =>
                removeUserRights && row?.isTL === 0 && dispatch(showDeleteUserModal(row.userId, row.userName))
              }
              disabled={!removeUserRights || row?.isTL === 1}
            />
          </div>
        </Tooltip>
      ),
    }));

  return (
    <div className={styles["members_By_Team_Details"]}>
      <div className="table_Details__wrapper">
        <TableComponentV2 columns={columns} data={testPlanDetails} className="table__wrapper" />
      </div>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(
            removeMemberFromTeam({
              userId: deleteUserId,
              teamId: parseInt(localStorage.getItem("teamId")),
            })
          );
          dispatch(hideDeleteUserModal());
        }}
        handleCancel={() => dispatch(hideDeleteUserModal())}
        modalOpen={deleteUserModalVisible}
        modalTitle={"Confirm Removal"}
        modalContent={`Are you sure you want to remove ${deleteUserName} from ${teamNameOfProject} team?`}
        okText={"Remove"}
        isDeleteModal={true}
      />
    </div>
  );
};

export default MembersByTeamV2;
