import { default as React, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faYandexInternational } from "@fortawesome/free-brands-svg-icons";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Popover, Tooltip, Typography } from "antd";
import { TEST_SCENARIOS, TOOLTIP_BG_COLOR } from "../../../../Constants";
import {
  CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP,
  CONDITION_DELETE_IMAGE,
  ICON_AI,
  ICON_PERFORM_SAVE,
  OPEN_COLLAPSE_ICON_FOR_PARENT_STEP,
  RESET_ICON,
  SAVE_ICON,
} from "../../../../Constants/SvgConstants";
import {
  getRecTestStepStatusWithImage,
  isCurrentRecTestStepDisable,
  isNotNullAndNotEmptyAndNotUndefined,
  isStepHaveLoopStep,
  isStepHavePrecondition,
  suggestedPreConditionName,
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  showDeleteLoopConditionModalVisible,
  showDeleteRecTestStepPreConditionConfirmationModal,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import PerformRecTestStepActionV2 from "../PerformRecTestStepActionV2/PerformRecTestStepActionV2";
import RecTestStepLoopConditionV2 from "./RecTestStepLoopConditionV2";
import RecTestStepPreConditionV2 from "./RecTestStepPreConditionV2";
import styles from "./RecTestStepConditionV2.module.scss";
import ReactJson from "react-json-view";

const { Panel } = Collapse;
const { Paragraph } = Typography;

export const getConditionalIcon = (recTestStep) => {
  if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
    return (
      <span className="mr-5 font-color-orange">
        <FontAwesomeIcon icon={faYandexInternational} />
      </span>
    );
  } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
    return (
      <span className="mr-5 font-color-orange">
        <FontAwesomeIcon icon={faReply} />
      </span>
    );
  }
};

const renderPreconditionText = (recTestStep, tooltipText, truncated, setTruncated) => {
  return (
    <span className="display__flex">
      {getConditionalIcon(recTestStep)}
      <span className={styles["truncated_Text"]}>
        <Tooltip title={truncated && tooltipText} color={TOOLTIP_BG_COLOR}>
          <Paragraph
            ellipsis={{
              symbol: <></>,
              rows: 1,
              expandable: true,
              onEllipsis: (ellipsis) => setTruncated(ellipsis),
            }}
            className={`${styles["step-name"]} ${truncated && "cursor__pointer"}`}
          >
            {tooltipText}
          </Paragraph>
        </Tooltip>
      </span>
    </span>
  );
};

const getHeaderText = (recTestStep, testBlockStep, truncated, setTruncated) => {
  const preCondition = `${suggestedPreConditionName(recTestStep)} ${
    recTestStep?.recTestStepProperty?.recStepVerifyElementProperties[0]?.key
  }`;

  let text = `Run this group step if ${preCondition}`;
  let testBlockCondition = `Run this block if ${preCondition}`;

  if (recTestStep.tag === testBlockStep?.tag) {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      if (recTestStep.recTestStepProperty?.recStepVerifyElementProperties?.some((i) => i?.type === "VerifyTestData")) {
        return (
          <span>
            {getConditionalIcon(recTestStep)}
            When to Run this Step
          </span>
        );
      } else {
        return renderPreconditionText(recTestStep, testBlockCondition, truncated, setTruncated);
      }
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return (
        <span>
          {getConditionalIcon(recTestStep)}
          Repeat Block till
        </span>
      );
    }
  } else {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      if (recTestStep.recTestStepProperty?.recStepVerifyElementProperties?.some((i) => i?.type === "VerifyTestData")) {
        return (
          <span>
            {getConditionalIcon(recTestStep)}
            When to Run this Step
          </span>
        );
      } else {
        return renderPreconditionText(recTestStep, text, truncated, setTruncated);
      }
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return (
        <span>
          {getConditionalIcon(recTestStep)}
          Repeat Steps till
        </span>
      );
    }
  }
};

const getTooltipText = (recTestStep, testBlockStep) => {
  if (recTestStep.tag === testBlockStep?.tag) {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      return <span>Remove When to Run this Block</span>;
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return <span>Remove Repeat Block till Condition</span>;
    }
  } else {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      return <span>Remove When to Run this Step</span>;
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return <span>Remove Repeat Till Condition</span>;
    }
  }
};

const RecTestStepConditionV2 = (props) => {
  const {
    recTestStep,
    disableAction,
    isSkipStep,
    isDeviceLoaded,
    isTestBlock,
    recTestSteps,
    setDragOverOnCollapse,
    projectId,
  } = props;
  const dispatch = useDispatch();
  const { isPreviewRunning, testBlockStep } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecStepTag, stepType, isLoadingForAIStep, isDeviceInteractionSelectMode } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  const [truncated, setTruncated] = useState(false);

  //Destructure of recTestStep object
  const { isTestBlock: testBlockInRecTestStep, preconditionResult } = recTestStep;
  const { isStepHasPrecondition, isLoopStep } =
    recTestStep && recTestStep.recTestStepProperty ? recTestStep.recTestStepProperty : {};

  let disabled = useMemo(
    () =>
      isLoadingForAIStep !== null ||
      isDeviceInteractionSelectMode ||
      isCurrentRecTestStepDisable(unSavedRecStepTag, recTestStep, disableAction) ||
      isPreviewRunning ||
      (stepType === TEST_SCENARIOS && isTestBlock),
    [
      unSavedRecStepTag,
      recTestStep,
      disableAction,
      isPreviewRunning,
      stepType,
      isTestBlock,
      isLoadingForAIStep,
      isDeviceInteractionSelectMode,
    ]
  );

  let disabledDeleteIcon = useMemo(
    () =>
      disabled ||
      (stepType === TEST_SCENARIOS &&
        testBlockInRecTestStep === 1 &&
        (isStepHasPrecondition === 1 || isLoopStep === 1)),
    [disabled, stepType, isStepHasPrecondition, isLoopStep, testBlockInRecTestStep]
  );

  //className of action collapse
  let recTestStepCollapseClassNames = `rec_Test_Step_Collapse ${
    !isSkipStep && "recTestStepCollapseBorder"
  } collapseStepOrderBorder stepCollapseBorder recTestStepSteps `;
  if (unSavedRecStepTag === recTestStep?.tag && !isDeviceInteractionSelectMode) {
    recTestStepCollapseClassNames += "unsavedStepBorder ";
  }
  let isConditionStepOpen =
    (isStepHavePrecondition(recTestStep) || isStepHaveLoopStep(recTestStep)) &&
    !recTestStep.recTestStepProperty?.recStepVerifyElementProperties?.[0]?.id;

  return isStepHasPrecondition === 1 || isLoopStep === 1 ? (
    <div
      onDragOver={(event) => {
        event.stopPropagation();
        setDragOverOnCollapse(false);
      }}
    >
      <Collapse
        className={(recTestStepCollapseClassNames += isSkipStep ? " disable_bg_color" : " bg__white")}
        expandIconPosition="start"
        defaultActiveKey={isConditionStepOpen ? 0 : 1}
        expandIcon={({ isActive }) => (
          <div className={`collapseIconOnHover ${styles["collapseIcon"]} ${isSkipStep && "collapseIconBgColor"}`}>
            <SvgLoader iconName={isActive ? OPEN_COLLAPSE_ICON_FOR_PARENT_STEP : CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP} />
          </div>
        )}
      >
        <Panel
          showArrow={true}
          header={
            <div className="display__flex align_Items__Center">
              {recTestStep.recTestStepProperty.isAIStep == 1 ? (
                <span className="align-self-center">
                  <Popover
                    overlayStyle={{ width: "60%" }}
                    content={
                      <div>
                        <ReactJson src={JSON.parse(recTestStep.recTestStepProperty?.aiGeneratedStep)} />
                      </div>
                    }
                    title="AI Generated Step"
                  >
                    <span>
                      <SvgLoader iconName={ICON_AI} infoIcon={true} />
                    </span>
                  </Popover>
                </span>
              ) : (
                ""
              )}
              <div className={styles["header_Title"]}>
                <span className={styles["conditionText"]}>
                  {getHeaderText(recTestStep, testBlockStep, truncated, setTruncated)}
                </span>
              </div>
            </div>
          }
          extra={
            <div className={styles["previewOrDeleteIcon"]}>
              {unSavedRecStepTag === recTestStep?.tag && !isDeviceInteractionSelectMode && (
                <PerformRecTestStepActionV2
                  action={recTestStep?.recTestStepProperty?.action}
                  data={recTestStep}
                  recTestStepId={recTestStep?.id}
                  recTestStepTag={recTestStep?.tag}
                  disabled={
                    disabled ||
                    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== recTestStep?.tag)
                  }
                  isDeviceLoaded={isDeviceLoaded}
                  projectId={projectId}
                  isPreviewRunning={isPreviewRunning}
                  saveIcon={SAVE_ICON}
                  performSaveIcon={ICON_PERFORM_SAVE}
                  resetIcon={RESET_ICON}
                />
              )}
              <div className={unSavedRecStepTag !== recTestStep?.tag && "showRecTestStepActionsIconOnHover"}>
                <Tooltip
                  color={TOOLTIP_BG_COLOR}
                  title={getTooltipText(recTestStep, testBlockStep)}
                  placement="topRight"
                >
                  {!isPreviewRunning && (
                    <div>
                      <SvgLoader
                        disabled={disabledDeleteIcon}
                        iconName={CONDITION_DELETE_IMAGE}
                        handleClick={() =>
                          !(disabled || (stepType === TEST_SCENARIOS && recTestStep.isTestBlock === 1))
                            ? isLoopStep === 1
                              ? dispatch(showDeleteLoopConditionModalVisible(recTestStep, recTestStep.tag))
                              : isStepHasPrecondition === 1
                              ? dispatch(
                                  showDeleteRecTestStepPreConditionConfirmationModal(
                                    recTestStep,
                                    recTestStep?.recTestStepProperty?.id
                                  )
                                )
                              : ""
                            : ""
                        }
                        width="2rem"
                        height="2rem"
                      />
                    </div>
                  )}
                </Tooltip>
              </div>
              <div className={styles["preview_Result"]}>
                {getRecTestStepStatusWithImage(preconditionResult, isPreviewRunning)}
              </div>
            </div>
          }
        >
          {isStepHasPrecondition === 1 && (
            <RecTestStepPreConditionV2
              recTestStep={recTestStep}
              disabled={disabled}
              isDeviceLoaded={isDeviceLoaded}
              recTestSteps={recTestSteps}
              projectId={props.projectId}
              isTestBlock={isTestBlock}
            />
          )}
          {isLoopStep === 1 && isStepHasPrecondition === 0 && (
            <RecTestStepLoopConditionV2
              isSkipStep={isSkipStep}
              isTestBlock={isTestBlock}
              recTestStep={recTestStep}
              disabled={disabled}
              unSavedRecStepTag={unSavedRecStepTag}
              isDeviceLoaded={isDeviceLoaded}
              recTestSteps={recTestSteps}
              projectId={props.projectId}
            />
          )}
        </Panel>
      </Collapse>
    </div>
  ) : (
    ""
  );
};

export default RecTestStepConditionV2;
