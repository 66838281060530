import React from "react";
import isEmpty from "lodash/isEmpty";
import ViewDefaultTestDataListViewV2 from "./ViewDefaultTestDataListViewV2";

const ViewTestBlockDefaultTestDataSetV2 = ({ title, stepType, defaultTestData, refTestDataList,testBlockInTestScenario }) => {
  return (
    <>
      {defaultTestData && !isEmpty(defaultTestData?.testDataValues) &&
        <ViewDefaultTestDataListViewV2
          testDataValues={defaultTestData.testDataValues}
          title={title
              + " [Steps using Test Data: " +  defaultTestData?.stepCount
              + " Unique Test Data Keys: " + defaultTestData?.uniqueTestDataCount + "]"}
          stepType={stepType}
          refTestDataList={refTestDataList ? refTestDataList : []}
          testBlockInTestScenario={testBlockInTestScenario}
        />
      }
    </>
  );
};

export default ViewTestBlockDefaultTestDataSetV2;