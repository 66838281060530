import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Dropdown, Menu, Row } from "antd";
import { goToDeviceDetailListScreen } from "../../../actions/OrgDeviceAction";
import { goToProjects } from "../../../actions/ProjectsAction";
import { getOrgList, goToTeams } from "../../../actions/TeamsAction";
import {
  ADMIN_DEVICE_SERVER_MANAGEMENT,
  DEVICE_DETAILS,
  PROJECT,
  SERVER_MANAGEMENT,
  TEAMS,
  USAGE_REPORT,
  dateHourMinuteFormat,
} from "../../../Constants";
import {
  DEVICE_LIST_MENU_ICON,
  EXECUTION_REPORT_MENU_ICON,
  LINK_ICON,
  MENU_BUTTON,
  PROJECT_MENU_ICON,
  SERVER_MANAGEMENT_MENU_ICON,
  TEAM_MENU_ICON,
} from "../../../Constants/SvgConstants";
import generatedGitInfo from "../../../generatedGitInfo.json";
import history from "../../../history";
import { getFormateDateAndTime } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import OrganizationButtonV2 from "../../OrganizationV2/OrganizationButtonV2/OrganizationButtonV2";
import styles from "./MenuOptionsV2.module.scss";

// Reusable MenuItem Component
const MenuItem = ({ icon, label, isActive, onClick }) => (
  <div className={`${styles["menu_list"]} ${isActive ? styles["menu_list_Active"] : ""}`} onClick={onClick}>
    <SvgLoader iconName={icon} iconColor={isActive ? "white" : "black"} />
    <div className={`${styles["menu_Item_Label"]} ${isActive ? styles["menu_Item_Active_Label"] : ""}`}>{label}</div>
  </div>
);

const MenuOptionsV2 = ({ buttonState, organizationId, isPreviewRunning }) => {
  const dispatch = useDispatch();
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  const isNotXpressLabsEmail = loggedInUserDetails?.userEmail?.split('@')[1]?.toLowerCase() !== 'xpress-labs.com';

  // Menu items array
  const menuItems = [
    {
      icon: PROJECT_MENU_ICON,
      label: "Projects",
      isActive: buttonState === PROJECT,
      onClick: () => {
        dispatch(goToProjects(organizationId));
        dispatch(getOrgList());
      },
    },
    {
      icon: TEAM_MENU_ICON,
      label: "Teams",
      isActive: buttonState === TEAMS,
      onClick: () => {
        dispatch(goToTeams(organizationId));
        dispatch(getOrgList());
      },
    },
    {
      icon: DEVICE_LIST_MENU_ICON,
      label: "Device List",
      isActive: buttonState === DEVICE_DETAILS,
      onClick: () => {
        dispatch(goToDeviceDetailListScreen(organizationId));
        dispatch(getOrgList());
      },
    },
    {
      icon: EXECUTION_REPORT_MENU_ICON,
      label: "Execution Report",
      isActive: buttonState === USAGE_REPORT,
      onClick: () => {
        history.push(`/${organizationId}/UsageReport`);
        dispatch(getOrgList());
      },
    },
    {
      icon: SERVER_MANAGEMENT_MENU_ICON,
      label: SERVER_MANAGEMENT,
      isActive: buttonState === SERVER_MANAGEMENT,
      onClick: () => {
        history.push(`/${organizationId}/ServerManagement`);
        dispatch(getOrgList());
      },
    },
    {
      icon: SERVER_MANAGEMENT_MENU_ICON,
      label: ADMIN_DEVICE_SERVER_MANAGEMENT,
      isActive: buttonState === ADMIN_DEVICE_SERVER_MANAGEMENT,
      onClick: () => {
        history.push(`/${organizationId}/admin/ServerManagement`);
        dispatch(getOrgList());
      },
      hideMenu: isNotXpressLabsEmail,
    },
  ];

  const recentItems = [
    { label: "Scenario", content: "Scenario 23 (Search result check)" },
    { label: "Execution Report", content: "Test job 23" },
  ];

  const menu = (
    <div className={"menu_details"}>
      <Menu>
        <Menu.Item>
          <Row gutter={30} className="padding__20">
            <Col lg={12} xs={24} className={styles["custom-col"]}>
              <h4>Organization</h4>
              <OrganizationButtonV2
                key="OrganizationButton"
                buttonState={buttonState}
                organizationId={organizationId}
              />
            </Col>
            <Col lg={6} xs={12} className={`${styles["xs-my-30"]} ${styles["custom-col-menu"]}`}>
              <h4>Menu</h4>
              <div className={styles["menu_Items"]}>
                {menuItems.map(
                  (item, index) =>
                    !item?.hideMenu && (
                      <MenuItem
                        key={index}
                        icon={item.icon}
                        label={item.label}
                        isActive={item.isActive}
                        onClick={item.onClick}
                      />
                    )
                )}
              </div>
            </Col>
            <Col lg={6} xs={12} className={`${styles["xs-my-30"]} ${styles["custom-col-recent"]}`}>
              <h4>Recent (TBD)</h4>
              {recentItems.map((item, index) => (
                <div key={index} className={styles["recent-list"]}>
                  <div className={styles["recent_Label_Icon"]}>
                    {item.label} <SvgLoader iconName={LINK_ICON} />
                  </div>
                  <span>{item.content}</span>
                </div>
              ))}
            </Col>
          </Row>
          <Row justify="space-between" className={styles["menu_Footer"]}>
            <span>version 2.0</span>
            <div>
              <span>Commit date: </span>
              {getFormateDateAndTime(generatedGitInfo?.buildDate, dateHourMinuteFormat)}
            </div>
            <div>
              <span>Commit id: </span> {generatedGitInfo.gitCommitHash}
            </div>
          </Row>
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      className={isPreviewRunning ? "cursor-not-allowed menuIcon" : styles["menu_Icon"]}
      disabled={isPreviewRunning}
    >
      <div>
        <SvgLoader iconName={MENU_BUTTON} width="1.4rem" height="1.4rem" disabled={isPreviewRunning} />
      </div>
    </Dropdown>
  );
};

export default MenuOptionsV2;
