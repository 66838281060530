import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { ACTION_VIEW, BUILD_TEST_PLAN, CUSTOM, PRIVATE, PUBLIC, TOOLTIP_BG_COLOR } from "../../../../Constants";
import { ICON_BUILD_TEST_DATA_SET_NEW, ICON_EDIT_NEW, TRENDS_ICON_NEW } from "../../../../Constants/SvgConstants";
import { BUILD_TEST_DATA_SET } from "../../../../Constants/TooltipConstants";
import SvgLoader from "../../../../Util/SvgLoader";
import { setTestDataSetAssigneeDetail } from "../../../../actions/TestDataSet/TestDataSetCommonAction";
import { fetchTestDataSetByProfileId } from "../../../../actions/TestDataSet/TestDataSetRedirectAction";
import { showTestDataSetSelectionModal } from "../../../../actions/TestDataSetAction";
import { changeTestPlanDetails, changeTestPlanTab, getRunInsight } from "../../../../actions/TestPlanAction";
import ActionComponentV2 from "../../../CoreComponents/ActionComponentV2/ActionComponentV2";
import styles from "./TestPlanDetailsColumnsV2.module.scss";

const TestPlanTableActionsColumnV2 = (props) => {
  const { row, testPlanTab, testJobDetail, handleActionChange, setSelectedTestPlan } = props;
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  const dispatch = useDispatch();

  const handleAction = (row) => {
    let arr = [];
    if (row.allowDelete === ACTION_VIEW) {
      arr.push({ title: "Delete", name: "delete" });
    }
    if (loggedInUserDetails?.userEmail === row?.createdBy || row?.allowAdmin === ACTION_VIEW) {
      arr.push({
        title: "Permissions",
        name: "permissions",
        children: [
          ((row?.isPrivate !== 1 && loggedInUserDetails?.userEmail === row?.createdBy) ||
            (row?.isPrivate === 1 &&
              !isEmpty(row?.tesPlanRights?.data) &&
              loggedInUserDetails?.userEmail === row?.createdBy)) && {
            title: "Make Private",
            name: PRIVATE,
          },
          row?.isPrivate !== 0 && {
            title: "Make Public",
            name: PUBLIC,
          },
          {
            title: "Custom",
            name: CUSTOM,
          },
        ],
      });
    }
    return arr;
  };

  return (
    <>
      {testPlanTab ? (
        <Button
          type="primary"
          className={styles["test_Plan_Button"]}
          disabled={testJobDetail.testPlan ? testJobDetail?.testPlan?.id !== row?.id : false}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedTestPlan(row);
            dispatch(fetchTestDataSetByProfileId(testJobDetail?.testPlan?.id, "", 1, false,"",true));
            dispatch(showTestDataSetSelectionModal(row));
          }}
        >
          Test Data
        </Button>
      ) : (
        <div className={styles["action-icon"]}>
          {row.allowEdit === ACTION_VIEW && (
            <Tooltip title="Edit" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div className={styles["test--Plan-Action__Buttons"]}>
                <SvgLoader
                  iconName={ICON_EDIT_NEW}
                  handleClick={() => {
                    dispatch(changeTestPlanDetails(row));
                    dispatch(changeTestPlanTab(BUILD_TEST_PLAN));
                  }}
                />
              </div>
            </Tooltip>
          )}
          <Tooltip title={BUILD_TEST_DATA_SET} color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div className={styles["test--Plan-Action__Buttons"]}>
              <SvgLoader
                iconName={ICON_BUILD_TEST_DATA_SET_NEW}
                handleClick={() => {
                  dispatch(setTestDataSetAssigneeDetail(row));
                  dispatch(changeTestPlanDetails(row));
                }}
              />
            </div>
          </Tooltip>
          <Tooltip title="Test Plan Trends" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div className={styles["test--Plan-Action__Buttons"]}>
              <SvgLoader
                iconName={TRENDS_ICON_NEW}
                handleClick={() => {
                  dispatch(getRunInsight(row, moment(new Date()).subtract(2, "days"), moment(new Date())));
                }}
              />
            </div>
          </Tooltip>
          {(row.allowDelete === ACTION_VIEW || row.allowAdmin === ACTION_VIEW) && (
            <ActionComponentV2 actions={handleAction(row)} data={row} handleActionChange={handleActionChange} />
          )}
        </div>
      )}
    </>
  );
};

export default TestPlanTableActionsColumnV2;
