import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Select, Spin, Tooltip } from "antd";
import { isEqual } from "lodash";
import { resetApplicationStringsData } from "../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  addApplicationStringLocaleAndValue,
  addApplicationStrings,
  addApplicationStringsFormVisible,
  changeApplicationStringName,
  changeApplicationStringValue,
  deleteApplicationStringLocale,
  getListOfLocalesForApplicationStrings,
  selectApplicationStringLocale,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { CONFIRM_MODAL_CONTENT, TOOLTIP_BG_COLOR } from "../../../Constants";
import { CREATE_ICON, SILVER_GRAY, TRASH_ICON, WHITE_COLOR } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import CommonInputFieldV2 from "../../CommonComponents/CommonInputFieldV2/CommonInputFieldV2";
import CommonSelectBoxV2 from "../../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import CustomButtonV2 from "../../CommonComponents/CustomButtonV2/CustomButtonV2";
import SaveResetButtonV2 from "../../CoreComponents/SaveResetButtonV2/SaveResetButtonV2";
import styles from "./ApplicationStringsV2.module.scss";

const EditOrAddApplicationStringsV2 = () => {
  const dispatch = useDispatch();
  const {
    getListOfLocales,
    applicationStringName,
    applicationStringValue,
    applicationStringIsImport,
    selectLocaleOfApplicationString,
    applicationStringLoader,
    editApplicationStringData,
    previous_editApplicationStringData,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const addDisableForEditApplicationString = editApplicationStringData?.every(
    (item) => !Object.values(item).some((value) => value === "")
  );

  const enableSaveApplicationStringButton = editApplicationStringData
    ? !isEqual(editApplicationStringData, previous_editApplicationStringData) && addDisableForEditApplicationString
    : applicationStringName !== undefined &&
      applicationStringValue !== undefined &&
      selectLocaleOfApplicationString !== undefined;

  const backButton = editApplicationStringData
    ? !isEqual(editApplicationStringData, previous_editApplicationStringData)
    : applicationStringName !== undefined ||
      applicationStringValue !== undefined ||
      selectLocaleOfApplicationString !== undefined;

  const onBackClick = () => {
    if (backButton) {
      setOpenConfirmModal(true);
    } else {
      dispatch(addApplicationStringsFormVisible(false));
    }
  };

  useEffect(() => {
    dispatch(getListOfLocalesForApplicationStrings());
  }, [dispatch]);
  
  return (
    <>
      <Spin spinning={applicationStringLoader} tip="Loading">
        <Row justify="space-between" align="middle" className={styles["main_Header"]}>
          <Col className={styles["main-Header-Text"]}>
            {`${editApplicationStringData ? "Edit" : "Add"} Application Strings`}
          </Col>
          <Col>
            <SaveResetButtonV2
              submitLabel={editApplicationStringData ? "Update" : "Add"}
              resetLabel={"Cancel"}
              submitDisable={!enableSaveApplicationStringButton}
              resetDisable={false}
              handleSubmit={() =>
                dispatch(
                  addApplicationStrings(
                    applicationStringName,
                    applicationStringValue,
                    selectLocaleOfApplicationString,
                    editApplicationStringData ? editApplicationStringData : [],
                    applicationStringIsImport
                  )
                )
              }
              handleReset={onBackClick}
            />
          </Col>
        </Row>

        <Row className={styles.content} justify="center">
          <Col xs={24} lg={9} className={styles.text}>
            <div className={styles["key_Name_Add_Icon"]}>
              <Col xs={24}>
                <CommonInputFieldV2
                  label="Key Name"
                  placeholder="Application String Name"
                  name="keyName"
                  value={applicationStringName}
                  handleChange={(e) => {
                    dispatch(changeApplicationStringName(e.target.value));
                  }}
                  disabled={editApplicationStringData}
                  autoFocus
                  labelBackgroundClass={"label_Background"}
                />
              </Col>
              {editApplicationStringData && (
                <Tooltip title={"Add Locale and value"} color={TOOLTIP_BG_COLOR}>
                  <div>
                    <CustomButtonV2
                      iconColor={WHITE_COLOR}
                      buttonIcon={CREATE_ICON}
                      handleClick={() =>
                        addDisableForEditApplicationString && dispatch(addApplicationStringLocaleAndValue())
                      }
                      className="common_Green_Button"
                      disabled={!addDisableForEditApplicationString}
                    />
                  </div>
                </Tooltip>
              )}
            </div>

            {editApplicationStringData ? (
              <Col xs={24}>
                {editApplicationStringData?.map((row) => {
                  return (
                    <div className={styles["key_Name_Add_Icon"]} key={row.id}>
                      <Col xs={6}>
                        <CommonSelectBoxV2
                          label="Locale"
                          labelBackgroundClass={"label_Background"}
                          options={
                            getListOfLocales &&
                            getListOfLocales?.map((i) => <Select.Option value={i?.locales}>{i?.locales}</Select.Option>)
                          }
                          value={row?.locale}
                          placeholder="Select Application String Locale"
                          handleChange={(e) => {
                            dispatch(selectApplicationStringLocale(e, row?.id));
                          }}
                        />
                      </Col>
                      <Col xs={18}>
                        <CommonInputFieldV2
                          label="Value"
                          placeholder="Application String Value"
                          name="value"
                          value={row?.value}
                          handleChange={(e) => {
                            dispatch(changeApplicationStringValue(e.target.value, row?.id));
                          }}
                          disabled={editApplicationStringData}
                          labelBackgroundClass={"label_Background"}
                        />
                      </Col>

                      {editApplicationStringData.length > 1 && (
                        <Tooltip title="Remove Application Locale and Value" color={TOOLTIP_BG_COLOR}>
                          <div className={styles['delete_Icon']}>
                            <SvgLoader
                              iconName={TRASH_ICON}
                              iconColor={SILVER_GRAY}
                              handleClick={() => dispatch(deleteApplicationStringLocale(row?.id))}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  );
                })}
              </Col>
            ) : (
              <Col>
                <CommonInputFieldV2
                  label="Value"
                  placeholder="Application String Value"
                  name="value"
                  value={applicationStringValue}
                  handleChange={(e) => {
                    dispatch(changeApplicationStringValue(e.target.value));
                  }}
                  disabled={editApplicationStringData}
                  labelBackgroundClass={"label_Background"}
                />
                <CommonSelectBoxV2
                  label="Locale"
                  labelBackgroundClass={"label_Background"}
                  options={
                    getListOfLocales &&
                    getListOfLocales?.map((i) => <Select.Option value={i?.locales}>{i?.locales}</Select.Option>)
                  }
                  value={selectLocaleOfApplicationString}
                  placeholder="Select Application String Locale"
                  handleChange={(e) => {
                    dispatch(selectApplicationStringLocale(e));
                  }}
                />
              </Col>
            )}
          </Col>
        </Row>
      </Spin>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(addApplicationStringsFormVisible(false));
          dispatch(resetApplicationStringsData());
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle={"Discard Changes"}
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default EditOrAddApplicationStringsV2;
