import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTestJobData } from "../../../../actions/TestJobActions";
import styles from "./TestPlanDetailsColumnsV2.module.scss";

const TestPlanSelectionRadioTypeV2 = ({ row, testJobDetail }) => {
  const dispatch = useDispatch();
  const { testPlanDetailData } = useSelector((state) => state.TestPlanReducer);

  useEffect(() => {
    if (testJobDetail?.testPlan?.id === 0 && testPlanDetailData.length > 0) {
      dispatch(
        changeTestJobData({
          ...testJobDetail,
          testPlan: {
            id: testPlanDetailData[0].id,
            name: testPlanDetailData[0].name,
          },
          testDataSet: {
            id: testPlanDetailData[0].testDataProfile?.id,
            name: testPlanDetailData[0].testDataProfile?.name,
          },
        })
      );
    }
  }, [testPlanDetailData, testJobDetail, dispatch]);

  const handleChange = (e) => {
    e.stopPropagation();
  
    const testDataSet = row.testDataProfile?.id === 0
      ? { name: "Default" }
      : { id: row.testDataProfile?.id, name: row.testDataProfile?.name };
  
    dispatch(
      changeTestJobData({
        ...testJobDetail,
        testPlan: { id: row.id, name: row.name },
        testDataSet,
      })
    );
  };

  const isChecked = testJobDetail?.testPlan?.id === row?.id || "";

  return (
    <div>
      <span className={styles["marginRight"]}>
        <input
          className={styles["radioButtons"]}
          type="radio"
          name="radioGroup"
          value={row?.id}
          checked={isChecked}
          onChange={handleChange}
        />
      </span>
    </div>
  );
};

export default TestPlanSelectionRadioTypeV2;