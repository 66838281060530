import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { resetAllRecScenarioStepIds, selectAllRecScenarioStepIds } from "../../../actions/RecTestScenarioAction";
import { deleteRecTestStep } from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../actions/RecTestStepAction";
import { RESET_DEVICE_SCREEN_SOURCE, TOOLTIP_BG_COLOR } from "../../../Constants";
import { STEP_DELETE_ICON } from "../../../Constants/SvgConstants";
import { hasLengthGreaterThanOne } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "./TestBlockAndScenarioHeaderV2.module.scss";

const RecTestStepSelectionBoxV2 = ({ disabled }) => {
  const dispatch = useDispatch();
  const { selectedRectTestScenarioStepId, recTestStepList, isPreviewRunning } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { isLoadingForAIStep, requestingPerformRecTestStepAction } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const { applicationStringAssigneeDetail, testDataSetAssigneeDetail, deviceStringAssigneeDetail } = useSelector(
    (state) => state.TestDataSetReducer
  );

  const [openDeleteStepsModal, setOpenDeleteStepsModal] = useState(false);

  const indeterminate = useMemo(() => {
    return (
      selectedRectTestScenarioStepId?.length > 0 &&
      !recTestStepList?.every((i) =>
        selectedRectTestScenarioStepId?.includes(i?.recTestStepId || i.recTestScenarioRecTestStepAssocId || i?.id)
      )
    );
  }, [selectedRectTestScenarioStepId, recTestStepList]);

  const isCheckBoxChecked = useMemo(() => {
    if (isEmpty(selectedRectTestScenarioStepId)) {
      return false;
    }
    return recTestStepList
      ?.filter((j) => j?.isSkipStep === 0)
      ?.every((i) =>
        selectedRectTestScenarioStepId?.includes(i?.recTestStepId || i.recTestScenarioRecTestStepAssocId || i?.id)
      );
  }, [selectedRectTestScenarioStepId, recTestStepList]);

  const isDisableCheckBox = useMemo(
    () =>
      disabled ||
      isPreviewRunning ||
      requestingPerformRecTestStepAction ||
      testDataSetAssigneeDetail ||
      deviceStringAssigneeDetail ||
      applicationStringAssigneeDetail ||
      isLoadingForAIStep !== null,
    [
      isLoadingForAIStep,
      disabled,
      isPreviewRunning,
      requestingPerformRecTestStepAction,
      testDataSetAssigneeDetail,
      deviceStringAssigneeDetail,
      applicationStringAssigneeDetail,
    ]
  );

  const allSelectSteps = useCallback(
    (e) => {
      if (e.target.checked) {
        dispatch(selectAllRecScenarioStepIds("", "", recTestStepList));
      } else {
        dispatch(resetAllRecScenarioStepIds());
        dispatch(selectRecTestSteps(recTestStepList[0]));
      }
    },
    [dispatch, recTestStepList]
  );

  return (
    <>
      <div className={styles["select_Box_Delete_Icon"]}>
        <div className={styles["selectRecTestStepCheckBox"]}>
          <Tooltip
            title={`${selectedRectTestScenarioStepId?.length} selected`}
            color={TOOLTIP_BG_COLOR}
            overlayClassName={isEmpty(selectedRectTestScenarioStepId) ? styles["hidden_Tooltip"] : ""}
          >
            <Checkbox
              checked={isCheckBoxChecked}
              onChange={(e) => allSelectSteps(e)}
              indeterminate={indeterminate}
              disabled={isDisableCheckBox}
            />
          </Tooltip>
        </div>
        {!isEmpty(selectedRectTestScenarioStepId) && (
          <Tooltip
            color={TOOLTIP_BG_COLOR}
            title={`Delete ${selectedRectTestScenarioStepId?.length} ${
              hasLengthGreaterThanOne(selectedRectTestScenarioStepId) ? "items" : "item"
            }`}
          >
            <div className={styles["deleteInHeader"]}>
              <SvgLoader
                iconName={STEP_DELETE_ICON}
                iconColor={isDisableCheckBox ? "#6f7283" : "#FF188F"}
                handleClick={() => {
                  !isDisableCheckBox && setOpenDeleteStepsModal(true);
                }}
                disabled={isDisableCheckBox}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch({ type: RESET_DEVICE_SCREEN_SOURCE });
          dispatch(deleteRecTestStep());
          setOpenDeleteStepsModal(false);
        }}
        handleCancel={() => setOpenDeleteStepsModal(false)}
        modalOpen={openDeleteStepsModal}
        modalContent={`Are you sure you want to delete ${
          hasLengthGreaterThanOne(selectedRectTestScenarioStepId) ? "these items?" : "this item?"
        } `}
        modalTitle={"Confirm Deletion"}
        okText={"Delete"}
        isDeleteModal={true}
      />
    </>
  );
};

export default RecTestStepSelectionBoxV2;
