import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Menu, Row, Space } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/CommonActions";
import * as actions from "../../actions/HomeAction";
import * as recTestStepCommonActions from "../../actions/RecTestStep/RecTestStepCommonAction";
import * as recordingActions from "../../actions/Recording";
import * as serverListActions from "../../actions/ServerListAction";
import * as homeActions from "../../actions/HomeAction";
import * as testPlanAction from "../../actions/TestPlanAction";
import * as testJobAction from "../../actions/TestJobActions";

import { BACK_TO_TOP_ICON } from "../../CdnImagePath";
import {
  ADMIN_DEVICE_SERVER_MANAGEMENT,
  ALL_BUILDS,
  ALL_JOBS,
  ALL_TEST_PLANS,
  APP_MANAGER,
  AWS_DEVICE_FARM,
  CONFIRM_MODAL_CONTENT,
  DEVICE_SESSION,
  EXECUTION_JOB,
  GENERAL,
  LAMBDA_TEST,
  POOL_MANAGEMENTS,
  PROJECT_STRINGS,
  PROXY_STATUS,
  REMOTE_DEVICE,
  REPORT_MANAGER,
  S3_CONFIGURATION,
  SAUCELABS,
  SERVER_MANAGEMENT,
  SETTINGS,
  TEST_DATA_SET_MANAGEMENT,
  TEST_PLAN,
  TEST_RAILS_CONFIG,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
} from "../../Constants";
import XpressGuideV01 from "../../Documents/XpressGuideV01.pdf";
import { checkNotUndefinedAndNull } from "../../Util";
import history from "../../history";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import DeviceFarmView from "../DeviceFarmList/DeviceFarmView";
import GoToScreen from "../GoToScreen/GoToScreen";
import AWSDeviceFarmManagerV2 from "../ProjectSettingsV2/AWSDeviceFarmV2/AWSDeviceFarmManagerV2";
import GeneralTabV2 from "../ProjectSettingsV2/GeneralTabV2";
import ProjectSettings from "../ProjectSettings/ProjectSettings";
import S3Configuration from "../ProjectSettings/S3Configuration";
import TestRailsConfigV2 from "../ProjectSettingsV2/TestRailsConfigV2";
import SauceLabsV2 from "../ProjectSettingsV2/SauceLabsV2";
import ProjectBreadcrumbsDetails from "../Projects/ProjectBreadcrumbDetails/ProjectBreadcrumbDetails";
import ReportManagerV2 from "../ReportManagerV2/ReportManagerV2";
import ServerManager from "../ServerManagement/ServerManager";
import TestDataSetManagement from "../TestDataSetManagement/TestDataSetManagement";
import TestDataSetManagementV2 from "../TestDataSetManagementV2/TestDataSetManagementV2";
import TestPlanManager from "../TestPlan/TestPlanManager/TestPlanManager";
import TestPlanResultDetails from "../TestPlan/TestPlanResultDetails/TestPlanResultDetails";
import MenuOptionsV2 from "./MenuOptionsV2/MenuOptionsV2";
import UserIconOptions from "./UserIconOptions";
import SvgLoader from "../../Util/SvgLoader";
import { HELP_ICON, LOGO } from "../../Constants/SvgConstants";
import PoolManagement from "../FarmManagement/PoolManagement/PoolManagement";
import ProjectStrings from "../ProjectStrings/ProjectStrings";
import ProjectStringsV2 from "../ProjectStringsV2/ProjectStringsV2";
import TestBlockV2 from "../TestBlockV2/TestBlockDetailsV2/TestBlockV2";
import RecTestScenarioV2 from "../RecTestScenarioV2/RecTestScenarioV2";
import DeviceSelectionForRecordingV2 from "../DeviceViewV2/DeviceSelectionForRecordingV2/DeviceSelectionForRecordingV2";
import ShowDeviceViewButtonV2 from "../DeviceViewV2/ShowDeviceViewButtonV2";
import ExecutionManagerV2 from "../ExecutionManagerV2/ExecutionManagerV2";
import BuildManagerV2 from "../BuildManagementV2/BuildManagerV2";
import LambdaTestV2 from "../ProjectSettingsV2/LambdaTestV2";
import CommonConfirmationModalV2 from "../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "./Header.module.scss";

library.add(faBars);
library.add(faUserCircle);
library.add(faSignOutAlt);
class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    const showDeviceView = !localStorage.getItem("showDeviceView")
      ? localStorage.setItem("showDeviceView", true)
      : localStorage.getItem("showDeviceView") === "true";
    this.state = {
      color: props.color,
      showScrollButton: false,
      runNumber: this.props.runNumber,
      buttonState: props.buttonState,
      currentMenuOptionsSelected: !this.props.runNumber
        ? this.props.currentMenuOptionsSelected
        : "TestPlanResultDetails",
      projectId: this.props.projectId,
      orgId: this.props.organizationId,
      handleSession: false,
      showDeviceView,
      openConfirmModal: null,
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.idleTimer = null;
  }
  componentDidMount() {
    if (this.state.projectId) {
      this.props.serverListActions.getDeviceFarmList({ projectId: this.state.projectId });
    }
    this.showDeviceView = !localStorage.getItem("showDeviceView")
      ? localStorage.setItem("showDeviceView", true)
      : localStorage.getItem("showDeviceView") === "true";
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        this.setState({ showScrollButton: true });
      } else {
        this.setState({ showScrollButton: false });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.projectId !== this.state.projectId) {
      this.setState({ projectId: nextProps.projectId });
      this.props.serverListActions.getDeviceFarmList({ projectId: nextProps.projectId });
    }
  }

  handleLogout(e) {
    if (window.location.href.includes("/device/")) {
      if (this.props.RecordingReducer.proxyStatus === PROXY_STATUS.STARTED) {
        this.props.recordingActions.stopProxy({
          targetUniqueId: this.props.targetUniqueId,
          serverId: this.props.serverId,
          platformName: this.props.platform,
          deviceFarmId: this.props.deviceFarmId,
        });
      }

      if (this.props.actions.logStarted()) {
        const { forceStopLogs } = this.props.actions;
        if (!forceStopLogs(this.props.serverId, this.props.targetUniqueId)) {
          e.preventDefault();
        } else {
          history.push("/farms/" + (this.props.deviceFarmId ? this.props.deviceFarmId : ""));
          if (this.props.platform === "I" || this.props.platform === "A") {
            this.props.actions.stopIOSScreenStreaming(this.props.serverId, this.props.targetUniqueId);
          }
          e.preventDefault();
        }
      } else {
        history.push("/farms/" + (this.props.deviceFarmId ? this.props.deviceFarmId : ""));
        if (this.props.platform === "I" || this.props.platform === "A") {
          this.props.actions.stopIOSScreenStreaming(this.props.serverId, this.props.targetUniqueId);
        }
        e.preventDefault();
      }
    }
    this.props.commonActions.logout();
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  handleConfirm = (url, tab) => {
    history.push(url);
    !(
      this.props.RecTestStepReducer?.isPreviewRunning || this.props.RecTestStepReducer?.requestingForPreview?.length > 0
    ) && this.setState({ currentMenuOptionsSelected: tab });
  };

  handleMenuChange = (tab) => {
    const { orgId } = this.state;
    const {
      projectId,
      recTestStepCommonActions,
      RecordingReducer,
      RecTestStepCommonViewReducer,
      TestPlanReducer,
      TestJobReducer,
      testPlanAction,
      testJobAction,
      recordingActions,
    } = this.props;

    const url = `/${orgId}/project/${projectId}/${tab}`;
    const { resetPreviewSettings } = recTestStepCommonActions;

    resetPreviewSettings();

    const isRecording = RecordingReducer.recordingState === "RECORDING";
    const hasUnsavedChanges =
      RecTestStepCommonViewReducer.unSavedRecStepTag ||
      TestPlanReducer.unSavedTestPlanChanges ||
      TestJobReducer.unSavedBuildJobChanges ||
      RecordingReducer.unSavedAppBuildChanges;

    if ((isRecording || hasUnsavedChanges) && tab !== this.state.currentMenuOptionsSelected) {
      this.setState({ openConfirmModal: { url, tab } });
    } else {
      this.handleConfirm(url, tab);
      if (tab !== this.state.currentMenuOptionsSelected) {
        testPlanAction.changeTestPlanTab(ALL_TEST_PLANS);
        testJobAction.changeExecutionManagerTabAndSubTab(ALL_JOBS);
        recordingActions.changeAppManagerTab(ALL_BUILDS);
      }
    }
  };

  setShowDeviceView = () => {
    this.setState((prevState) => {
      const newShowDeviceView = !prevState.showDeviceView;
      localStorage.setItem("showDeviceView", newShowDeviceView.toString());
      return { showDeviceView: newShowDeviceView };
    });
  };

  handleCloseSession = (url) => {
    history.push(url);
    if (this.props.RecTestStepReducer.selectedDeviceByUdid !== undefined) {
      this.props.homeActions.stopScreenStreaming(
        this.props.RecTestStepReducer.selectedDeviceByUdid?.autoServerDetail?.id,
        this.props.RecTestStepReducer.selectedDeviceByUdid?.targetUniqueId
      );
    }
  };

  handleClick = () => {
    const url = `/${this.state.orgId}/project/`;
    if (
      this.props.RecordingReducer.recordingState === "RECORDING" ||
      this.props.RecTestStepCommonViewReducer.unSavedRecStepTag
    ) {
      this.setState({ openConfirmModal: { url } });
    } else {
      history.push(url);
      this.handleCloseSession(url);
    }
  };

  handleDispatchesBasedOnMenuOption = (menuOption) => {
    switch (menuOption) {
      case TEST_PLAN:
        this.props.testPlanAction.changeTestPlanTab(ALL_TEST_PLANS);
        this.props.testPlanAction.editTestPlan(false);
        break;
      case EXECUTION_JOB:
        this.props.testJobAction.ediBuildJob(false);
        this.props.testJobAction.changeExecutionManagerTabAndSubTab(ALL_JOBS);
        break;
      case APP_MANAGER:
        this.props.recordingActions.editAppBuild(false);
        this.props.recordingActions.changeAppManagerTab(ALL_BUILDS);
        break;
      default:
        break;
    }
  };

  render() {
    let disableForPreview =
      this.props.RecTestStepReducer?.isPreviewRunning ||
      this.props.RecTestStepReducer?.requestingForPreview?.length > 0 ||
      this.props.RecTestStepCommonViewReducer.isLoadingForAIStep !== null;

    return (
      <React.Fragment>
        <header>
          <Row className={styles.mainHeader}>
            <Col>
              <Row>
                <Col className={styles.logo}>
                  <SvgLoader iconName={LOGO} width="2.85rem" height="2.90rem" handleClick={() => this.handleClick()} />
                </Col>
                <Col>
                  <Row className={styles.logo__section}>
                    <Col>
                      {checkNotUndefinedAndNull(this.props.projectDetails) && (
                        <ProjectBreadcrumbsDetails
                          setSelectProjectName={this.props.setSelectProjectName}
                          getProjectByOrgId={this.props.getProjectByOrgId}
                          teamNameOfProject={this.props.teamNameOfProject}
                          projectDetails={this.props.projectDetails}
                          organizationId={this.props.organizationId}
                          disableOptions={disableForPreview}
                          currentMenuOptionsSelected={this.state.currentMenuOptionsSelected}
                          setCurrentMenuOptionsSelected={this.handleMenuChange}
                          runNumber={this?.props?.runNumber}
                          projectId={this.state.projectId}
                        />
                      )}
                    </Col>
                    {this.state.projectId && (
                      <Col>
                        <Menu
                          className={styles.mainMenu}
                          mode="horizontal"
                          defaultSelectedKeys={[this.state.runNumber ? "" : this.state.currentMenuOptionsSelected]}
                          selectedKeys={[this.state.currentMenuOptionsSelected]}
                        >
                          <Menu.Item
                            className={styles.menuItems}
                            key={TEST_STEP_BLOCKS}
                            onClick={() => this.handleMenuChange(TEST_STEP_BLOCKS)}
                            disabled={disableForPreview}
                          >
                            Test Blocks
                          </Menu.Item>

                          <Menu.Item
                            className={styles.menuItems}
                            key={TEST_SCENARIOS}
                            onClick={() => this.handleMenuChange(TEST_SCENARIOS)}
                            disabled={disableForPreview}
                          >
                            Test Scenarios
                          </Menu.Item>

                          <Menu.Item
                            className={styles.menuItems}
                            key={TEST_PLAN}
                            onClick={() => this.handleMenuChange(TEST_PLAN)}
                            disabled={disableForPreview}
                          >
                            Test Plans
                          </Menu.Item>

                          <Menu.Item
                            className={styles.menuItems}
                            key={EXECUTION_JOB}
                            onClick={() => this.handleMenuChange(EXECUTION_JOB)}
                            disabled={disableForPreview}
                          >
                            Execution Jobs
                          </Menu.Item>

                          <Menu.Item
                            className={styles.menuItems}
                            key={REPORT_MANAGER}
                            onClick={() => this.handleMenuChange(REPORT_MANAGER)}
                            disabled={disableForPreview}
                          >
                            Test Reports
                          </Menu.Item>

                          <Menu.Item
                            className={styles.menuItems}
                            key={APP_MANAGER}
                            onClick={() => this.handleMenuChange(APP_MANAGER)}
                            disabled={disableForPreview}
                          >
                            App Builds
                          </Menu.Item>

                          <Menu.Item
                            className={styles.menuItems}
                            key={POOL_MANAGEMENTS}
                            onClick={() => this.handleMenuChange(POOL_MANAGEMENTS)}
                            disabled={disableForPreview}
                          >
                            Manage Device Pools
                          </Menu.Item>

                          <Menu.Item
                            className={styles.menuItems}
                            key={TEST_DATA_SET_MANAGEMENT}
                            onClick={() => this.handleMenuChange(TEST_DATA_SET_MANAGEMENT)}
                            disabled={disableForPreview}
                          >
                            Project Test Data
                          </Menu.Item>

                          <Menu.Item
                            className={styles.menuItems}
                            key={PROJECT_STRINGS}
                            onClick={() => this.handleMenuChange(PROJECT_STRINGS)}
                            disabled={disableForPreview}
                          >
                            Project Strings
                          </Menu.Item>

                          <Menu.Item
                            className={styles.menuItems}
                            key={GENERAL}
                            onClick={() => this.handleMenuChange(GENERAL)}
                            disabled={disableForPreview}
                          >
                            Project Settings
                          </Menu.Item>
                          <Menu.SubMenu key="SubMenu" title="Integrations" disabled={disableForPreview}>
                            <Menu.Item key={AWS_DEVICE_FARM} onClick={() => this.handleMenuChange(AWS_DEVICE_FARM)}>
                              AWS Device Farm
                            </Menu.Item>
                            <Menu.Item key={TEST_RAILS_CONFIG} onClick={() => this.handleMenuChange(TEST_RAILS_CONFIG)}>
                              Testrail
                            </Menu.Item>
                            <Menu.Item key={SAUCELABS} onClick={() => this.handleMenuChange(SAUCELABS)}>
                              Saucelabs
                            </Menu.Item>
                            <Menu.Item key={LAMBDA_TEST} onClick={() => this.handleMenuChange(LAMBDA_TEST)}>
                              LambdaTest
                            </Menu.Item>
                          </Menu.SubMenu>
                        </Menu>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <div className={styles.menuOptions}>
                <a target="_blank" rel="noopener noreferrer" href={!disableForPreview && XpressGuideV01}>
                  <SvgLoader iconName={HELP_ICON} width="1.875rem" height="1.875rem" disabled={disableForPreview} />
                </a>
                {!this.props.hideUserTag && (
                  <UserIconOptions handleLogout={this.handleLogout} isPreviewRunning={disableForPreview} />
                )}
                {this.props.showHumbergerMenu && (
                  <MenuOptionsV2
                    buttonState={this.props.buttonState}
                    organizationId={this.props.organizationId}
                    routeList={this.props.routeList}
                    deviceFarmId={this.props.deviceFarmId}
                    isPreviewRunning={disableForPreview}
                  />
                )}
              </div>
            </Col>
          </Row>
        </header>
        {this.props.RecTestStepReducer?.selectedDeviceByUdid && this.props.RecordingReducer.deviceLoaded && (
          <div>
            <CountdownTimer
              timeoutAction={() => {
                this.props.recordingActions.stopAppiumStreaming(
                  this.props.RecTestStepReducer?.selectedDeviceByUdid &&
                    this.props.RecTestStepReducer?.selectedDeviceByUdid["autoServerDetail"].id,
                  this.props.RecTestStepReducer?.selectedDeviceByUdid &&
                    this.props.RecTestStepReducer?.selectedDeviceByUdid["targetUniqueId"],
                  true,
                  this.state.projectId
                );
              }}
              sessionTimeoutAt={DEVICE_SESSION}
              idleTimer={this.idleTimer}
              setIdleTimer={(value) => (this.idleTimer = value)}
            />
          </div>
        )}

        {this.state.projectId && (
          <Col span={24} className={styles.container}>
            <Col xs={24} sm={24} md={24} lg={24} xl={7} onFocus={() => this.props.recordingActions.disableKeyboard()}>
              <DeviceSelectionForRecordingV2
                projectId={this.state.projectId}
                organizationId={this.state.orgId}
                handleSession={this.state.handleSession}
                setShowDeviceView={this.setShowDeviceView}
                showDeviceView={this.state.showDeviceView}
                idleTimer={this.idleTimer}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={!this.state.showDeviceView ? 24 : 24}
              lg={!this.state.showDeviceView ? 24 : 24}
              xl={!this.state.showDeviceView ? 24 : 17}
              onFocus={() => this.props.recordingActions.disableKeyboard()}
            >
              <div className="position__relative bg__light_Blue">
                {!this.state.showDeviceView && (
                  <div className={styles["show_Device_View_Button"]}>
                    <ShowDeviceViewButtonV2
                      showDeviceView={this.state.showDeviceView}
                      setShowDeviceView={this.setShowDeviceView}
                    />
                  </div>
                )}
                {this.state.currentMenuOptionsSelected === TEST_STEP_BLOCKS && (
                  <TestBlockV2
                    isForModal={true}
                    isProjectModal={true}
                    projectId={this.state.projectId}
                    organizationId={this.state.orgId}
                    childComponentList={this.props.CommonReducer.childComponentList}
                    handleSession={this.state.handleSession}
                    disableOptions={disableForPreview}
                    currentMenuOptionsSelected={this.state.currentMenuOptionsSelected}
                  />
                )}

                {this.state.currentMenuOptionsSelected === TEST_SCENARIOS && (
                  <RecTestScenarioV2
                    isForModal={true}
                    isProjectModal={true}
                    projectId={this.state.projectId}
                    organizationId={this.state.orgId}
                    childComponentList={this.props.CommonReducer.childComponentList}
                    handleSession={this.state.handleSession}
                    disableOptions={disableForPreview}
                    currentMenuOptionsSelected={this.state.currentMenuOptionsSelected}
                  />
                )}

                {this.state.currentMenuOptionsSelected === GENERAL && (
                  <GeneralTabV2 projectId={this.state.projectId} orgId={this.state.orgId} />
                )}
                {this.state.currentMenuOptionsSelected === S3_CONFIGURATION && (
                  <S3Configuration projectId={this.state.projectId} />
                )}
                {this.state.currentMenuOptionsSelected === AWS_DEVICE_FARM && (
                  <AWSDeviceFarmManagerV2 projectId={this.state.projectId} />
                )}
                {this.state.currentMenuOptionsSelected === TEST_RAILS_CONFIG && (
                  <TestRailsConfigV2 projectId={this.state.projectId} />
                )}
                {this.state.currentMenuOptionsSelected === SAUCELABS && (
                  <SauceLabsV2 projectId={this.state.projectId} />
                )}
                {this.state.currentMenuOptionsSelected === LAMBDA_TEST && (
                  <LambdaTestV2 projectId={this.state.projectId} />
                )}

                {this.state.currentMenuOptionsSelected === TEST_PLAN && (
                  <TestPlanManager
                    projectId={this.state.projectId}
                    setCurrentMenuOptionsSelected={this.handleMenuChange}
                    orgId={this.state.orgId}
                  />
                )}
                {this.state.currentMenuOptionsSelected === REPORT_MANAGER && (
                  <ReportManagerV2 projectId={this.state.projectId} orgId={this.state.orgId} />
                )}

                {this.state.currentMenuOptionsSelected === EXECUTION_JOB && (
                  <ExecutionManagerV2
                    projectId={this.state.projectId}
                    orgId={this.state.orgId}
                    currentMenuOptionsSelected={this.state.currentMenuOptionsSelected}
                    setCurrentMenuOptionsSelected={this.handleMenuChange}
                    showDeviceView={this.state.showDeviceView}
                  />
                )}
                {this.state.currentMenuOptionsSelected === APP_MANAGER && (
                  <BuildManagerV2 projectId={this.state.projectId} orgId={this.state.orgId} />
                )}
                {this.state.currentMenuOptionsSelected === REMOTE_DEVICE && (
                  <DeviceFarmView
                    projectId={this.state.projectId}
                    orgId={this.state.orgId}
                    childComponentList={this.props.CommonReducer.childComponentList}
                    deviceFarmList={this.props.ServerListReducer.deviceFarmList}
                  />
                )}
                {this.state.currentMenuOptionsSelected === POOL_MANAGEMENTS && (
                  <PoolManagement
                    projectId={this.state.projectId}
                    orgId={this.state.orgId}
                    showDeviceView={this.state.showDeviceView}
                  />
                )}
                {this.state.currentMenuOptionsSelected === TEST_DATA_SET_MANAGEMENT && (
                  <TestDataSetManagementV2 projectId={this.state.projectId} orgId={this.state.orgId} />
                )}
                {this.state.currentMenuOptionsSelected === PROJECT_STRINGS && (
                  <ProjectStringsV2 projectId={this.state.projectId} orgId={this.state.orgId} />
                )}
                {this.state.currentMenuOptionsSelected === SETTINGS && (
                  <ProjectSettings projectId={this.state.projectId} orgId={this.state.orgId} />
                )}
                {this.state.currentMenuOptionsSelected === SERVER_MANAGEMENT &&
                  this.state.currentMenuOptionsSelected === ADMIN_DEVICE_SERVER_MANAGEMENT && (
                    <ServerManager projectId={this.state.projectId} orgId={this.state.orgId} />
                  )}
                {this.state.currentMenuOptionsSelected === "TestPlanResultDetails" && (
                  <TestPlanResultDetails
                    projectId={this.state.projectId}
                    orgId={this.state.orgId}
                    runNumber={this.props.runNumber}
                  />
                )}
                {this.props.text}
                {this.props.showCloseButton ? (
                  <GoToScreen
                    screenRoute={"/farms/" + this.props.deviceFarmId}
                    tipmessage="Back To Home"
                    targetUniqueId={this.props.targetUniqueId}
                    serverId={this.props.serverId}
                    deviceFarmId={this.props.deviceFarmId}
                    platform={this.props.platform}
                    toolTipPlacement="left"
                  />
                ) : null}
                {this.state.showScrollButton && (
                  <Button className="backToTop" onClick={this.scrollToTop.bind(this)}>
                    <div className="display-flex gap-10">
                      <img src={BACK_TO_TOP_ICON} alt="backToTop" />
                      <span>Back to top</span>
                    </div>
                  </Button>
                )}
              </div>
            </Col>
          </Col>
        )}
        <CommonConfirmationModalV2
          handleOk={() => {
            this.handleConfirm(this.state.openConfirmModal?.url, this.state.openConfirmModal?.tab);
            this.props.recordingActions.recordingStop();
            this.props.recordingActions.resetElement();
            this.props.recTestStepCommonActions.resetEditRecTestStep();
            // Call the function with the current menu option
            this.handleDispatchesBasedOnMenuOption(this.state.currentMenuOptionsSelected);

            this.setState({ openConfirmModal: null });
          }}
          handleCancel={() => this.setState({ openConfirmModal: null })}
          modalOpen={!!this.state.openConfirmModal}
          modalContent={CONFIRM_MODAL_CONTENT}
          modalTitle={"Discard Changes"}
          okText={"Confirm"}
          isDeleteModal={true}
        />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    recordingActions: bindActionCreators(recordingActions, dispatch),
    recTestStepCommonActions: bindActionCreators(recTestStepCommonActions, dispatch),
    serverListActions: bindActionCreators(serverListActions, dispatch),
    homeActions: bindActionCreators(homeActions, dispatch),
    testPlanAction: bindActionCreators(testPlanAction, dispatch),
    testJobAction: bindActionCreators(testJobAction, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    HomeReducer: state.HomeReducer,
    CommonReducer: state.CommonReducer,
    RecordingReducer: state.RecordingReducer,
    RecTestStepCommonViewReducer: state.RecTestStepCommonViewReducer,
    RecTestStepReducer: state.RecTestStepReducer,
    ServerListReducer: state.ServerListReducer,
    ProjectsReducer: state.ProjectsReducer,
    TestPlanReducer: state.TestPlanReducer,
    TestJobReducer: state.TestJobReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
