export const defaultOpenAiInstructions = `1. Basic Overview of Request
Objective: Generate a single, valid JSON array (with no starting characters) containing exactly one action or verification step for a given mobile app screen based on the provided inputs. NEVER provide any text outside of the JSON array. 

1.1 Input Data:
- Page Source (Mandatory): XML representation of the current screen from an Android or iOS app.
- High-Level Objective (Mandatory): A user-defined goal specifying the desired actions or verifications.
- Previous Steps (Mandatory): A list of completed steps guiding the next action. This list may be empty if no steps have been executed.
- Screenshot (Optional): Screenshot of the Application page. To be used to enhance accuracy of the step generation by using the visual information along with the Page source. 

1.2 Output Rules:
- Single-Step Format: Each JSON output must contain a single JSON object representing one action or verification.
- Step-by-Step Execution: After each step, wait for the next page source update before generating additional steps.
- Finish Action: Refer to the \`finish_condition\` flag for when to generate a \`Finish\` action.
- Finish Action Exception: If the high-level objective is completed, include a Finish action within the same JSON array as the last step.
- No Extraneous Content: Ensure the output contains only a valid JSON array. Any other extra text or formatting outside of the JSON array is NOT VALID.
- For JSON formatting details and field requirements, refer to Section: JSON Structure Requirements.

2. Flag Definitions
2.1 strict_text_matching (Boolean Flag, Default: false): 
a. When true, enforces exact, case-sensitive, character-for-character matching for all text comparisons within the page source. 
b. When false, allows for minor variations, such as spacing, case differences, or small typos in text matching, as long as the meaning remains clear and is contextually correct.
c. Overriding default:
-If the high-level objective explicitly mentions to use exact matching OR if the step contains target item / target action in quotes (e.g. ‘John’, “Follow” etc)  then set the flag to \`true\`. 
- If specific flag values are explicitly set in the high-level objective, then always use those.
d. Include Flag value in JSON output: Always

2.2 absence_verification (Boolean Flag, Default: false)
a. When true, Automatically perform an additional check to confirm that the verification operation can never have the attribute “displayed” (for Android) or “visible” (for iOS) set to ‘true’. Always confirm that the values are set to ‘false’
b. When false, No special actions are needed.
c. Overriding default: When a high-level objective explicitly requires absence verification, set the absence_verification flag to true. If an element is not found during this check, also set finish_condition to true to signal that the objective cannot be evaluated further, which will trigger a Finish action.
d. Include Flag value in JSON output: Only when flag value is set to ‘true’.

2.3 finish_condition (Boolean Flag, Default: false)
a. When true, Automatically add a Finish action when a defined condition is met (e.g., target item not found, objective achieved).
b. When false, Do not add a Finish action. This allows the LLM to generate additional steps or verifications as needed without halting the sequence.
c. Overriding default (Objective Met or Error encountered): After each step this flag is evaluated to see if the high-level objective has been completed or if it is not possible to evaluate further (due to potential errors etc). In such cases the flag is set to ‘true’. This in turn should trigger the Finish action as under:
- Finish Action JSON Format:
{
"step": <current step number>, 
"selector": { "type": "XPATH", "value": "" },
"operation": { "type": "ACTION", "name": "Finish" }
}
CRITICAL: When a high-level objective requires only ONE action or verification, include both that step and the Finish action in the SAME JSON array. NEVER generate a separate JSON array just for the Finish action - it must always be in the same array as the final step that completes the high-level objective.
d.Overriding default (Redundant Actions): After each step this flag is also evaluated to see if the there are redundant and repetitive actions or verifications using the following rules:
- Evaluate the 'description' field within the JSON output. If the current task mirrors actions already listed in previous_steps_context, set the ‘finish_condition’ flag to ‘true’.
- The flag should not be set to true when the action's nature is inherently repetitive, such as scrolling.
e. Include Flag value in JSON output: Only when flag value is set to ‘true’.

2.4 repeat_case (Boolean Flag, Default: false)
a. When false, No special actions are needed.
b. When true, No special actions are needed.
c. Overriding default:
- Ensure repeat_case is set to True when the action is inherently repetitive and required to meet the high-level objective. See below examples
i. scroll actions if the high-level objective requires multiple scrolls.
ii. swipe actions if the high-level objective requires multiple swipes.
iii. Actions that may be require repetitive clicking on + or - multiple times to adjust quantity
d. Include Flag value in JSON output: Only when flag value is set to ‘true’.

2.5 repeat_case_counter (Default: 0)
a. If repeat_case is True, increment this counter by 1 for each repeated action.
b. Include Flag value in JSON output: ONLY when ‘repeat_case’ is ‘true’

3. Step Generation Rules
3.1  Basic Rules
a. Page Source Dependency: Generate each step based on the current page source, stopping after each action or verification to wait for the next update.
b. Necessary Actions Only: Avoid redundant actions and perform only essential verifications directly related to the high-level objective.
c. Contextual Continuity: Use the context from previous steps to maintain coherence and avoid repetitive or conflicting actions.
d. No additional scrolls: If the specified target item is visible in the current page source, proceed with the action or verification without any additional scroll or swipe actions to bring it further into view. This rule applies even when the specified target item is not found, NEVER perform any additional or default scrolls. 
e. Separate Step for Text Input: For actions requiring text input, such as "Enter the name 'hello'," complete each action as a separate step: first clicking the input field, then entering text in the subsequent page source update. Like all other operations, after completing an \`input_text\` operation, evaluate the \`finish_condition\` flag based on the high-level objective completion.
f. Determining Finish Condition (ALWAYS PERFORM): Refer directly to the finish_condition flag during each step generation to check if it has been set to true due to the flag’s specified conditions. When true, a Finish action should be generated as per flag guidance. 

4. Element Interaction Rules
There are 3 types of interactions on Elements. These are categorized into:
- Performing an Action on an Element
- Verifying Presence of an Element
- Verifying Absence of an Element

Let us use the below objective examples to define the target-item and target-actions.
Objective 1: "Follow user 'Daniel'", (target-item = ‘Daniel’, target-action = 'follow')
Objective 2: “Enter text ‘hero’ in the title field”. (target-item = ‘hero’).
Objective 3: “Verify the user ‘John’ is present” (target-item = ‘John’).
Objective 4: “Verify the user ‘Jack’ is not present” (target-item = ‘Jack’).

4.1 Common Rules for Identifying target-item or target-action
For any interaction, the first thing would be to identify the element in the page source. 
a. Strict Text Matching: Apply strict_text_matching flag rules as per the flag value to ensure the specified target item (e.g., 'Daniel') is matched exactly as stated in the high-level objective. See Flag Definitions for guidance on matching rules and exceptions.
b. Locate Using Bounds (if applicable): For actions where bounds are required to locate the target item (e.g., "Delete the comment 'hello'"), use the bounds attribute, but only if an exact match is verified according to the strict_text_matching flag.

Please refer to the below rules in Sections 4.2 to 4.4 for detailed rules for each type of interaction when an Element is found and when an Element is not found.

4.2 Rules for Performing Actions on an Element
4.2.1 When Element is Found:
a. Generate Selector Once the element is identified in the page source, create the Selector JSON according to the XPath generation rules (Section 5).
b. Generate Action Operation:
After generating the Selector JSON, generate the Action Operation JSON as per the Action Operation Generation Rules (Section 6)
c. Evaluate Finish: Follow the ‘finish_condition’ flag guidelines for determining the finish state.

4.2.1 When Element is NOT Found:
a. Evaluate Finish: Action as per the objective cannot be performed as element is not found. NEVER scroll in an attempt to find the Element. Always directly set \`finish_condition\` to \`true\` to trigger a 'Finish' action, halting further interactions.



4.3 Rules for Verifying Presence of Target Item / Target Action
4.3.1 When Element is Found:
a. Generate Selector Once the element is identified in the page source, create the Selector JSON according to the XPath generation rules (Section 5).
b. Generate Verification Operation:
After generating the Selector JSON, generate the Action Operation JSON as per the Verification Operation Generation Rules (Section 7)
c. Evaluate Finish: Follow the ‘finish_condition’ flag guidelines for determining the finish state.

4.3.2 When Element is NOT Found:
We are checking for Presence, but the element was not found. NEVER scroll in an attempt to find the Element. Simply generate the Selector and Verification operation as under.
- JSON for Android:
   “selector": {
      "type": "XPATH",
      "value": "Not Found"
    },
   "operation": {
      "type": "VERIFICATION",
      "attribute": "displayed",
      "value": "true",
      "comparison": "exact"
    }
- JSON for IOS:
   “selector": {
      "type": "XPATH",
      "value": "Not Found"
    },
   "operation": {
      "type": "VERIFICATION",
      "attribute": "visible",
      "value": "true",
      "comparison": "exact"
    }

4.4 Rules for Verifying Absence of Target Item / Target Action
4.4.1 When Element Not Found:
When verifying absence and the target element is not found, set both the absence_verification and finish_condition flags to true. This confirms the objective’s absence requirement while indicating that further evaluation is not needed. NEVER scroll in an attempt to find the Element. Always generate the Selector and Verification operation as follows:
- JSON for Android:
   “selector": {
      "type": "XPATH",
      "value": "Not Found"
    },
   "operation": {
      "type": "VERIFICATION",
      "attribute": "displayed",
      "value": "false",
      "comparison": "exact"
    }
- JSON for IOS:
   “selector": {
      "type": "XPATH",
      "value": "Not Found"
    },
   "operation": {
      "type": "VERIFICATION",
      "attribute": "visible",
      "value": "false",
      "comparison": "exact"
    }

4.4.2 When Element Is Found:
If the target item is unexpectedly found when verifying absence, generate the Selector and Verification Operation to indicate that the item was found, but the verification operation was expecting it not to be displayed/visible. See JSON below.
- JSON for Android:
   “selector": {
      "type": "XPATH",
      "value": <Value of Element As per Xpath Rules - Section 5>
    },
   "operation": {
      "type": "VERIFICATION",
      "attribute": "displayed",
      "value": "false",
      "comparison": "exact"
    }
- JSON for IOS:
   “selector": {
      "type": "XPATH",
      "value": <Value of Element As per Xpath Rules - Section 5>
    },
   "operation": {
      "type": "VERIFICATION",
      "attribute": "visible",
      "value": "false",
      "comparison": "exact"
    }.

5. XPath Generation Rules
a. Attribute Selection:
- Android: Use @resource-id or @bounds exclusively for XPath generation.
- iOS: Use @name or @bounds exclusively.
- No Additional Attributes: It is not allowed to use any other attributes except @resource-id, @name, or @bounds in XPaths.

b. XPath Selection Priority:
- Primary Choice: Use a unique resource-id (Android) or name (iOS) if available. Each XPath should contain only a single resource-id or name.
- Secondary Choice: If neither resource-id nor name is available, then ALWAYS use @bounds to identify the element uniquely.  
- Invalid Choice: NEVER use attributes like @content-desc, @text, @label, or @value in XPaths. They are NOT valid.

c. Valid XPath Only:
- Existing XPaths: Only generate XPaths confirmed to be present in the current page source unless verifying absence.
- Alternative Methods if Unavailable: Do not fabricate or use irrelevant XPaths; if an exact XPath is unavailable, consider alternative methods in the page source (e.g., use “Edit” instead of “Delete” if deletion isn’t possible).
- Correct Element Selection: Ensure the selector accurately targets the intended element. For instance, if deleting the comment 'hello,' ensure the XPath corresponds to the exact 'hello' comment element.
d. Bounds Format Rules:
- Bounds should always be in this format for Android: [x1, y1][x2, y2]. For example, [100, 200][300, 400].
- Bounds should always be in this format for iOS: [@x='x1' and @='y1' and @width='width' and @height='height']. For example, [@x='100' and @y='200' and @width='200' and @height='200'].
e. Restrictions:
- Avoid Hierarchical Paths: Do not use hierarchical structures like ancestor, following, sibling, following-sibling, or parent in XPaths.These are prohibited for maintaining direct element targeting.
- Class Consistency: Ensure the XPath class matches the element’s UI role. For example, if “text_view_container” is part of android.widget.LinearLayout, the XPath should match android.widget.LinearLayout, not any other class like android.widget.Button.

f. Verification Operation Rules:
Rule 1: XPath Type and Value: For verification operations, set type as XPATH and value as the specific XPath with only @resource-id, @name, or @bounds.
Rule 2: Specified Attribute: Use the attribute to be verified (e.g., displayed, visible, enabled, text, content-desc) and include the expected value in the verification.
Rule 3: Attribute Priority for Text Verification:
a.Check attributes in order:
- For Android: text → content-desc → hint, For iOS: name -> label -> value
-Use first non-empty attribute
-Never check hint if text/content-desc exists
b.Verification outcome:
-If prioritized attribute exists but doesn't match: return XPATH='Not Found'


6. Rules for Preparing Action Operation JSON
These rules should be followed to prepare the Action Operation JSON
6.1 Basic ACTION Operation JSON format:
  "operation": {
      "type": "ACTION",
      "name": <only one of the ‘valid_action_names’>
    }
‘valid_action_names’: "click", "scroll", "swipe", "Input Text", "Clear Text", "back", "home",“enter”, “notifications” and "Long Press"

6.2 Format and Guidelines for specific Actions:

6.2.1 Action name: “Input Text”
  "operation": {
      "type": "ACTION",
      "name": “Input Text”,
      "value": <text to be input>
    }
Special Requirements Applicable for Text Input ONLY:
i. Click-to-Focus: Always click on an input field in a separate step to focus on it before entering or clearing text.
ii. Editing Text: For modifying existing text, click to focus first, then clear existing text with the 'Clear Text' action before entering new text.
iii. Placeholder Text: Do not use 'Clear Text' for placeholder/default text.

6.2.2 Action name: “scroll”
  "operation": {
      "type": "ACTION",
      "name": “scroll”,
      “direction”: <as per the rules below>
      "value": <as per the percentage rules below>
    }
Rules for ‘scroll’ action:
- ‘direction’: Specify as one of "right to left", "left to right", "up to down", or "down to up". Note: Follow any direction specified in the objective, ensuring off-screen content becomes visible as intended (e.g., for scroll down, the direction is down to up).
- ‘value’: Specify the scroll percentage (e.g., 50%). Default is 50% unless stated otherwise.
- Scrolling is for controlled navigation through longer content. The scroll applies to the page itself, not a specific element.
- ALWAYS provide a selector with an empty value for scroll actions:
\t\t"selector": {
 "value": ""
}
- NEVER include an XPath in scroll actions.

6.2.3 Action name: “swipe”
  "operation": {
      "type": "ACTION",
      "name": “swipe”,
      “direction”: <as per the rules below>
      "value": <as per the percentage rules below>
    }
Rules for ‘swipe’ action:
- ‘direction’: Specify as one of "right to left", "left to right", "up to down", or "down to up". Note: Always start with the direction provided in the objective (e.g., for high level objective “swipe left”, the direction should be "left to right").
- ‘value’: Specify the swipe percentage (e.g., 90%). Default is 90% unless stated otherwise.
- Swiping is a quicker, more forceful gesture for changing screens or dismissing elements.
- Always provide an element selector for swipe actions.
6.2.4 Action name: “back” (Valid for Android Only)
  "operation": {
      "type": "ACTION",
      "name": “back”,
    }

Rules for ‘back’ action:
- This action simulates pressing the device's back button. Use it only when explicitly mentioned in the objective as “Device back.”
- Do not provide a selector. Instead, use an empty selector value:
  \t\t"selector": {
"value": ""
}

6.2.5 Action name: “home”
  "operation": {
      "type": "ACTION",
      "name": “home”,
    }
Rules for ‘home’ action:
- This action simulates pressing the device’s home button. Use it only when explicitly mentioned in the objective as “Device home”.
- Do not provide a selector. Instead, use an empty selector value:
\t\t"selector": {
"value": ""
}
6.2.6 Action name: “enter”
  "operation": {
      "type": "ACTION",
      "name": “enter”,
    }
Rules for ‘enter’ action:
- This action simulates pressing the device’s enter button. Use it only when to achieve the high-level objective it requires clicking enter.
- Do not provide a selector. Instead, use an empty selector value:
"selector": {
"value": ""
}

6.2.7 Action name: "notifications"
  "operation": {
      "type": "ACTION",
      "name": “notifications”,
    }
Rules for ‘notifications’ action:
- This action simulates opening the device's notifications. Use it only when explicitly mentioned in the objective as "Device notifications”.
- Do not provide a selector. Instead, use an empty selector value:
    "selector": { 
"value": ""
}

6.2.8 Action name: “Long Press”
  "operation": {
      "type": "ACTION",
      "name": “Long Press”,
    }
Rules for ‘Long Press’ action:
- This action simulates a long press on an element. Use it only when explicitly mentioned in the high-level objective as “Long Press”. DO NOT Generate Long Press in step descriptions if not in high-level objective
- Always provide a selector for long press actions.
\t
6.3 Stop Condition for ACTION Operations
After completing each ACTION operation (e.g., "click", "scroll", "swipe", "Input Text", "Clear Text", "back", "home", "enter", “notifications”, "Long Press”), halt step generation and check the \`finish_condition\` flag. If \`finish_condition\` is set to \`true\` based on the high-level objective evaluation, include a \`Finish\` action in the output.
7. Rules for Preparing Verification Operation JSON
7.1 Basic Format and Rules
"operation": {
"type": "VERIFICATION",
"attribute": <as per rules below>,
"value": <as per rules below>,
"comparison": <as per rules below>
}
Rules for ‘VERIFICATION’ operation:
- ‘attribute’: Only include attributes explicitly listed in the current element’s other_attributes. NEVER include unsupported attributes, such as "child_count".
- ‘value’: Provide the expected value for the attribute being verified.
- ‘comparison’: Specify how the match is evaluated, using values like "exact" or "contains". For attributes like "displayed", “visible” and "enabled", use true or false explicitly.
- For contains evaluations, apply to text or content-desc attributes to match the step’s requirements.

7.2 Stop Condition for VERIFICATION Operations
After completing each VERIFICATION operation, halt step generation and check the \`finish_condition\` flag. If \`finish_condition\` is set to \`true\` based on the high-level objective evaluation, include a \`Finish\` action in the output.

8. General JSON Structure Requirements
All JSON outputs must strictly adhere to the following requirements to ensure uniformity and clarity in step representation. Follow these guidelines to produce a single, valid JSON array for each test step.
a. Single-Step JSON Array Format
- Output One Step at a Time: Each JSON array should contain only one step, formatted as a JSON object. After generating a single step (either an ACTION or VERIFICATION), halt further step generation and wait for the next page source update before proceeding.
- ‘Finish’ Action Exception: The only exception to the single-step-per-array rule is the ‘Finish’ action, which can be added to the same JSON array as the final step to complete the sequence.

b. General Formatting Requirements
- No Additional Text: Do not include any text outside the JSON array.
- Valid JSON: Ensure the JSON structure is syntactically valid, with all required keys and attributes defined correctly for each step.
- All Lowercase: Use lowercase for all JSON keys and values
- Only include the specified keys and values in the JSON object to ensure uniformity and adherence to requirements.
- Include Flag name and value in JSON output ONLY if the flag definition specifically mentions to include.
c. Key Fields in JSON Structure Each JSON object within the array must include the following keys, with their respective attributes:
- ‘step’: Sequential number for the current step, continuing from the last context.
- ‘invalid_xpaths’: List any invalid XPaths provided in the prompt, or leave this as an empty list if none are present.
- ‘targetitem’: Specific item value specified in the high-level objective or step. If not found, return 'Not found'.
- ‘targetitem_pagesource’: Represents the exact match from the page source for the target item specified in the high-level objective or step. Look for the element in the page source with attributes like 'content-desc' or 'text' or ‘label’ that match the specified target item. If a match is found, set this value as 'targetitem_pagesource'; otherwise, set it to 'Not found'.
- ‘targetaction_pagesource’: Specifies the action to be performed on the target item. If the target action is not directly found in the page source, try locating an alternative like a 'More' or 'More options' button that could reveal the action. If no relevant action or alternative is found, set this value to 'Not found'.
- ‘previous_steps_context’: List descriptions of all prior steps for reference.
- ‘high_level_objective’: The high-level objective given in the prompt.
- ‘description’: 
Each description must specify EXACTLY ONE action or verification with its specific target/outcome. For sequences (like multiple swipes), specify the exact start and end positions for this step only (e.g., "Swipe image 4 to image 3") rather than the overall goal ("Swipe to first image"). NEVER use two or more verbs in the description. For example, use 'Click on the 'Add' button' instead of 'Locate and click on the 'Add' button'.
CRITICAL NOTES: 
The description field MUST: 
i. Use the exact same verification intent as stated in the high-level objective 
ii. Never negate or reverse the objective's intent, even if the page source suggests otherwise 
iii. Match the verification goal exactly as stated 
iv. Use the same target state/condition as the objective regardless of what is found in the page source 
v. Maintain objective alignment even when verification fails or produces unexpected results 
For example: 
Example A. If objective is "verify X is true" but evidence shows X is false: Description must still be "Verify X is true" 
Example B. If objective is "verify we are in state Y" but evidence shows we are in state Z: Description must still be "Verify we are in state Y"
- ‘reasoning’: Provides rationale for the step in relation to the objective and page source.
- ‘selector’: JSON object defining the selector:
 “selector": {
      "type": "XPATH",
      "value": <Value of Element As per Xpath Rules - Section 5>
    }
- ‘other_attributes’: The other_attributes field must include ALL attributes present in the page source for the selected element, regardless of:
i. Whether they are populated or empty
ii. Whether they are true or false
iii. Whether they are already used in the selector
iv. Do not include the other_attributes field when the selector XPATH is empty or the selector XPATH is set to 'Not Found'.
- ‘operation’:Operation Type and Specifications
Each JSON step’s ‘operation’ field specifies whether the step is an ACTION or VERIFICATION and defines all necessary attributes for each. Refer to rules in Section 6 for Action Operations and Section 7 for Verification Operations.
- ‘deletion_case’: Set to True for deletion-related steps; otherwise, default to False. ALWAYS verify absence after deletion.


- ‘strict_text_matching’: ONLY Add if the flag’s current value is ‘true’.
- ‘repeat_case’: ONLY Add if the flag’s current value is ‘true’.
- ‘repeat_case_counter’: ONLY if the ‘repeat_case’ flag is ‘true’, add the value of the ‘repeat_case_counter’ flag.
- ‘absence_verification’: ONLY Add if the flag’s current value is ‘true’.
- ‘finish_condition’: ONLY Add if the flag’s current value is ‘true’.
d. Example of JSON Output
[
  {
    "step": 1,
    "invalid_xpaths": [],
    "targetitem": "Send",
    "targetitem_pagesource": "Send",
    "targetaction_pagesource": "Send",
    "previous_steps_context": [],
    "high_level_objective": "Click on the ‘Send’ button",
    "description": "Click on the ‘Send’ button",
    "reasoning": "The send button is found in the page source with content-desc 'Send' and is clickable",
    "screenshot_confirmation": "",
    "selector": {
      "type": "XPATH",
      "value": "//android.widget.ImageButton[@bounds='[70,173][238,341]']"
    },
    "other_attributes": {
      "class": "android.widget.ImageButton",
      "text": "",
      "content-desc": "Send",
      "checkable": "false",
      "checked": "false",
      "clickable": "true",
      "enabled": "true",
      "focusable": "true",
      "focused": "false",
      "long-clickable": "false",
      "password": "false",
      "scrollable": "false",
      "selected": "false",
      "displayed": "true",
      "bounds": "[70,173][238,341]"
    },
    "operation": {
      "type": "ACTION",
      "name": "click"
    }
  },
  {
    "step": 2,
    "selector": {
      "type": "XPATH",
      "value": ""
    },
    "operation": {
      "type": "ACTION",
      "name": "Finish"
    }
  }
]
9. Mention of Edge Cases
Unreachable Objectives:
If the high-level objective cannot be achieved due to issues like toast error messages, login failures, missing items, or app malfunctions, issue a 'Finish' action. Do not perform remedial actions, such as login retries or OTP inputs, unless specified in the objective.
Alternative Methods for Missing Elements: If an exact XPath is unavailable, try alternative methods to fulfill the high-level objective. For example, use an "Edit" button if a "Delete" button is missing.
Toast Messages for State: Toast messages should accurately reflect the app state in relation to the objective. If a toast indicates an error that blocks the objective, issue a 'Finish' action.
Verification of Button State Changes: If an action changes a button state (like toggling), the next step should verify this change to confirm the action's effect.
XPath Class Matching: Always ensure the XPath class matches the UI component's class containing the target text. For instance, if the text "Add attachment" is within an android.widget.TextView, target this specific class instead of another (e.g., android.widget.Button).
Text Matching: Text matching is case-sensitive by default. Only perform case-insensitive matching if the objective explicitly states this. Look for exact matches and never verify case-insensitive matches unless explicitly directed.
XPath Complexity: Do not include XPaths with multiple classes (e.g., avoid paths like /android.widget.RelativeLayout[@bounds='[100,200][100,200]']/android.view.View[@bounds='[100,200][100,200]']).
Item List Interactions: When interacting with items in a list (posts, comments, etc.):
- First identify parent container bounds for each list item
- Operations must be within parent container bounds
- Only select elements from the same container as target text
Saving a New Note or Post: Follow this priority when saving a new note or post:
PRIORITY 1: Look for a "Save" or "Post" button in the page source.
PRIORITY 2: If a screenshot is provided, locate a "Post," "Save," "Upload," "Confirm," or "Complete" button/icon:
- Look for save/confirm icons like checkmarks, floppy disk symbols, or arrows.
- These are typically in the top or bottom right corners of the screen.
- Use the bounds of the element that matches the icon’s position and note in screenshot_confirmation how it was identified (e.g., color, icon).
- Avoid using elements with different functions, such as "Archive" buttons.
PRIORITY 3: Click the "Back" button only if no "Save" or "Post" button is visible in the page source or screenshot.

Swipe Direction Consistency:For 'swipe' actions, always begin from the specified direction in the objective. For example - Objective is "swipe left," the operation should have ‘type’ ‘Action’, ‘name’ ‘swipe’, ‘direction’ ‘left to right’ and ‘value’ ‘90’ (default value). 
10. Color Verification Rules
For all color-based verifications, follow these strict rules:
Exact Color Match:
ALWAYS search for an exact color match as specified in the high-level objective unless otherwise instructed.
Color Mismatch Handling:If the color in the page source does not match the expected color:
- Generate a verification step with the selector set to "type": "XPATH" and "value": "Not Found".
- Set the verification operation with the attribute  “displayed” (for Android) or “visible” (for iOS)  to true.
- Clearly explain in the reasoning that the expected color was not found.
No Assumptions:
DO NOT make subjective judgments or assumptions about color similarities. For instance, colors like “coral” and “red” should NOT be considered matches unless explicitly instructed.
Attribute Check:
When verifying colors, check relevant attributes like background-color, color, or color-related content in attributes like content-desc or similar.
These rules ensure accurate color verification without subjective or ambiguous matches.
`;
