import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { TEST_SCENARIOS, TOOLTIP_BG_COLOR } from "../../../../../../../Constants";
import { ICON_EDIT_NEW, ICON_VIEW, SILVER_GRAY, TRASH_ICON } from "../../../../../../../Constants/SvgConstants";
import { DELETE, EDIT_TEST_DATA_VALUES, VIEW_STEPS } from "../../../../../../../Constants/TooltipConstants";
import SvgLoader from "../../../../../../../Util/SvgLoader";
import {
  fetchTestDataSetByProfileId,
  getTestDataSetById,
} from "../../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import { deleteTestDataSet, getTestDataUsedByRecTestScenarioId } from "../../../../../../../actions/TestDataSetAction";
import CommonConfirmationModalV2 from "../../../../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "../AllTestDataSetV2.module.scss";

const TestDataSetActionsColumnV2 = (props) => {
  const { testDataSet, stepType, setIsEditTestDataSet, setIsViewTestData } = props;
  const dispatch = useDispatch();

  const { testDataSetAssigneeDetail } = useSelector((state) => state.TestDataSetReducer);
  const [openConfirmModal, setOpenConfirmModal] = useState(null);

  return (
    <>
      <div className={styles["test-data-set-actions"]}>
        {testDataSet?.id && (
          <span className={styles["margin-5"]}>
            <Tooltip color={TOOLTIP_BG_COLOR} title={EDIT_TEST_DATA_VALUES} mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div>
                <SvgLoader
                  iconName={ICON_EDIT_NEW}
                  handleClick={() => {
                    stepType === TEST_SCENARIOS && dispatch(getTestDataUsedByRecTestScenarioId());
                    dispatch(getTestDataSetById(testDataSet?.id));
                    setIsEditTestDataSet(true);
                  }}
                />
              </div>
            </Tooltip>
          </span>
        )}
        {(testDataSet?.id && (
          <span className={styles["margin-5"]}>
            <Tooltip color={TOOLTIP_BG_COLOR} title={DELETE} mouseEnterDelay={0} mouseLeaveDelay={0}>
              <div>
                <SvgLoader
                  iconName={TRASH_ICON}
                  iconColor={SILVER_GRAY}
                  handleClick={() => {
                    setOpenConfirmModal(testDataSet.id);
                  }}
                />
              </div>
            </Tooltip>
          </span>
        )) || (
          <span className={styles["margin-5"]}>
            <Tooltip
              color={TOOLTIP_BG_COLOR}
              title={VIEW_STEPS}
              placement="topRight"
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
            >
              <div>
                <SvgLoader
                  iconName={ICON_VIEW}
                  handleClick={() => {
                    setIsViewTestData(true);
                    dispatch(fetchTestDataSetByProfileId(testDataSetAssigneeDetail?.id, "", 1, false));
                  }}
                />
              </div>
            </Tooltip>
          </span>
        )}
      </div>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(deleteTestDataSet(openConfirmModal, stepType));
          setOpenConfirmModal(null);
        }}
        handleCancel={() => setOpenConfirmModal(null)}
        modalOpen={!!openConfirmModal}
        modalTitle={"Confirm Deletion"}
        modalContent={"Are you sure you want to delete this item?"}
        okText={"Delete"}
        isDeleteModal={true}
      />
    </>
  );
};

export default TestDataSetActionsColumnV2;
