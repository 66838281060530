import React, { useCallback, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Input, Row, Spin } from "antd";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { BUILD_TEST_PLAN, GET_TEST_PLAN_BY_ID, SUCCESS_TYPE } from "../../../../../Constants";
import { DRAG_SCENARIO_IMG } from "../../../../../Constants/SvgConstants";
import { isArrayNotUndefinedAndNotNull, showNotification } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import { getByProjectIdAndNotAssocWithTestPlan } from "../../../../../actions/RecTestScenarioAction";
import { getTestPlanByTestPlanId } from "../../../../../actions/TestPlanAction";
import CommonSearchV2 from "../../../../CoreComponents/SearchComponent/CommonSearchV2";
import TestScenarioDataV2 from "./TestScenarioDataV2";
import styles from "./BuildTestPlanV2.module.scss";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  width: "100%",
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,
  borderRadius: 5,
  boxShadow: "0px 4px 6px rgba(120, 120, 120, 0.05)",
  ...draggableStyle,
});

const getListStyle = () => ({
  width: "100%",
  background: "#FFFFFF",
  border: "1px solid #d6d6d6",
  borderRadius: "10px",
  height: "calc(100vh - 140px)",
});

const BuildNewTestPlanV2 = (props) => {
  const dispatch = useDispatch();
  const {
    projectId,
    setSaveTestPlan,
    saveTestPlan,
    setTestPlanData,
    testPlanData,
    searchScenario,
    setSearchScenario,
    resetSearchInput,
    isLoading,
    setIsLoading,
  } = props;
  const { testPlanDetails, getTestPlanById, selectedTestPlanTab } = useSelector((state) => state.TestPlanReducer);
  const { recTestScenarioListByProjectId } = useSelector((state) => state.RecTestScenarioReducer);

  useEffect(() => {
    dispatch(getTestPlanByTestPlanId(testPlanDetails ? testPlanDetails?.id : 0));
  }, [testPlanDetails]);

  useEffect(() => {
    if (selectedTestPlanTab === BUILD_TEST_PLAN) {
      dispatch(
        getByProjectIdAndNotAssocWithTestPlan(projectId, "", testPlanDetails ? testPlanDetails?.id : 0, setIsLoading)
      );
    }
  }, [testPlanDetails, selectedTestPlanTab]);

  useEffect(() => {
    if (selectedTestPlanTab) {
      setTestPlanData([[], []]);
      if (selectedTestPlanTab !== BUILD_TEST_PLAN) {
        dispatch({ type: GET_TEST_PLAN_BY_ID, getTestPlanById: [] });
      }
    }
  }, [selectedTestPlanTab]);

  useEffect(() => {
    if (isArrayNotUndefinedAndNotNull(recTestScenarioListByProjectId)) {
      let testPlanDataObj = getTestPlanById;
      const listOfScenarios = recTestScenarioListByProjectId.filter(
        (value) => !testPlanDataObj.map((temp) => temp.name).includes(value.name)
      );
      setTestPlanData([listOfScenarios, testPlanDataObj]);
    }
  }, [recTestScenarioListByProjectId, getTestPlanById, setTestPlanData]);

  useEffect(() => {
    if (testPlanData && !isEmpty(testPlanData[1])) {
      setSaveTestPlan({
        ...saveTestPlan,
        testScenario: testPlanData[0],
        testPlanList: testPlanData[1],
      });
    }
  }, [testPlanData]);

  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      const sInd = +source.droppableId;
      const dInd = +destination.droppableId;

      if (sInd === dInd) {
        // Reorder within the same list
        const items = reorder(testPlanData[sInd], source.index, destination.index);
        const newState = [...testPlanData];
        newState[sInd] = items;
        setTestPlanData(newState);
      } else {
        let sourceList = testPlanData[sInd];
        if (sInd === 0 && searchScenario) {
          sourceList = sourceList.filter((item) => item.name.toLowerCase().includes(searchScenario.toLowerCase()));
        }

        // Move item
        const result = move(sourceList, testPlanData[dInd], source, destination);
        const newState = [...testPlanData];

        const droppedItemName = testPlanData[sInd][source.index].name;
        if (searchScenario) {
          const remainingItems = testPlanData[sInd].filter((item) => !sourceList.map((i) => i.id).includes(item.id));
          newState[sInd] = [...remainingItems, ...result[sInd]];

          if (sInd === 1 && !droppedItemName.includes(searchScenario) && !searchScenario.includes(droppedItemName)) {
            showNotification(SUCCESS_TYPE, `Scenario "${droppedItemName}" removed from the Test Plan.`, true);
          }
        } else {
          newState[sInd] = result[sInd];
        }
        newState[dInd] = result[dInd];
        setTestPlanData(newState);
      }
    },
    [testPlanData, searchScenario, setTestPlanData]
  );
  const getEmptyDataContent = (text) => (
    <>
      <SvgLoader iconName={DRAG_SCENARIO_IMG} />
      <span className={styles["empty_Data_Text"]}>{text}</span>
    </>
  );

  const showEmptyDataImage = (ind) => {
    if (isEmpty(testPlanData[0]) && !isEmpty(testPlanData[1]) && ind === 0) {
      return getEmptyDataContent("Drag Scenarios here to remove them from the Test Plan.");
    } else if (isEmpty(testPlanData[0]) && isEmpty(testPlanData[1]) && ind === 0) {
      return getEmptyDataContent("No scenarios were found in this project");
    } else if (!isEmpty(testPlanData[0]) && isEmpty(testPlanData[1]) && ind === 1) {
      return getEmptyDataContent("Drag Scenarios here to add them to the Test Plan.");
    } else {
      return getEmptyDataContent("We couldn't find any results matching your search.");
    }
  };

  const renderDraggableItems = (items) => {
    return items?.map((item, index) => (
      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <TestScenarioDataV2 scenarioData={item} />
          </div>
        )}
      </Draggable>
    ));
  };

  return (
    <Spin spinning={isLoading} tip="Loading" className={styles["spinner_Center"]}>
      <Row className={styles["test-plan-row"]}>
        <Col span={24} className={styles["drag-drop-box"]}>
          <DragDropContext onDragEnd={onDragEnd}>
            {testPlanData?.map((ele, ind) => {
              const filteredItems = ele.filter((item) =>
                item.name.toLowerCase().includes(searchScenario.toLowerCase())
              );
              return (
                <Droppable key={ind} droppableId={`${ind}`}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      <Row>
                        {ind === 0 && (
                          <Row className={styles["all_Scenarios_Header"]}>
                            <Col span={10}>
                              <div className={styles["all_Scenario_Text"]}>All Scenarios</div>
                            </Col>
                            <Col span={14}>
                              {!resetSearchInput && (
                                <CommonSearchV2
                                  searchDetails={(searchWord) => setSearchScenario(searchWord)}
                                  placeHolder="Search Scenario"
                                  searchKeyword={searchScenario}
                                  setSearchKeyword={setSearchScenario}
                                  className={styles["search_Test_Scenario"]}
                                  setAutoWidth
                                  autoFocus={false}
                                />
                              )}
                            </Col>
                          </Row>
                        )}
                        {ind === 1 && (
                          <Row className={styles["test_Plan_Header"]}>
                            <Col span={10}>
                              <div className={styles["test_Plan-Title"]}>
                                Test Plan<span className="text-danger">*</span>
                              </div>
                            </Col>
                            <Col span={14}>
                              <Input
                                placeholder="Please Enter Test Plan Name"
                                className={`common_Search_Box ${
                                  !isEmpty(ele) && saveTestPlan?.testPlanName === "" && styles["border-red"]
                                }`}
                                onChange={(e) => setSaveTestPlan({ ...saveTestPlan, testPlanName: e.target.value })}
                                value={saveTestPlan?.testPlanName}
                                autoFocus
                              />
                            </Col>
                          </Row>
                        )}
                      </Row>

                      {!isLoading && (
                        <Row
                          className={`${
                            isEmpty(ele) || (ind === 0 && isEmpty(filteredItems))
                              ? styles["empty_Data_Container"]
                              : styles["draggable-row"]
                          }`}
                        >
                          {(isEmpty(ele) || (ind === 0 && isEmpty(filteredItems))) && (
                            <Row justify="center" align="middle" className={styles["empty_Row"]}>
                              {showEmptyDataImage(ind)}
                            </Row>
                          )}
                          {ind === 0
                            ? renderDraggableItems(
                                ele.filter((item) => item.name.toLowerCase().includes(searchScenario.toLowerCase()))
                              )
                            : renderDraggableItems(ele)}
                        </Row>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              );
            })}
          </DragDropContext>
        </Col>
      </Row>
    </Spin>
  );
};

export default BuildNewTestPlanV2;
