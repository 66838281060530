import { Button, Col, Drawer, Radio, Row, Select, Space, Tabs, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_ICON_GRAY } from "../../../../../CdnImagePath";
import { EXECUTION_JOB, TEST_SCENARIOS, TOOLTIP_BG_COLOR, splitValue } from "../../../../../Constants";
import {
  closePreviewSettingDrawer,
  getListOfLocalesForApplicationStrings,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { fetchTestDataSetByProfileId } from "../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import { scheduleNow } from "../../../../../actions/TestJobActions";
import styles from "./JobSettingModal.module.scss";
const JobSettingModal = (props) => {
  const { selectedRecTestScenarioId, testBlockStep, testPlanId, setCurrentMenuOptionsSelected, testJobId } = props;
  const dispatch = useDispatch();
  const { previewSettingDrawerVisible, stepType, getListOfLocales } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const { testDataSetList } = useSelector((state) => state.TestDataSetReducer);
  const [jobCustomTestDataSet, setJobCustomTestDataSet] = useState(undefined);
  const [jobAppLocale, setJobAppLocale] = useState(undefined);
  useEffect(() => {
    if (previewSettingDrawerVisible) {
      if (stepType === EXECUTION_JOB && testPlanId) {
        dispatch(fetchTestDataSetByProfileId(testPlanId,"",1,true,false));
      } else if (stepType === TEST_SCENARIOS && selectedRecTestScenarioId) {
        dispatch(fetchTestDataSetByProfileId(selectedRecTestScenarioId,"",1,true,false));
      } else if(testBlockStep?.id){
        dispatch(fetchTestDataSetByProfileId(testBlockStep?.id,"",1,true,false));
      }
      dispatch(getListOfLocalesForApplicationStrings());
    }
  }, [previewSettingDrawerVisible]);
  useEffect(() => {
    if (testDataSetList && jobCustomTestDataSet === undefined) {
      let defaultTestDataList = testDataSetList && testDataSetList.filter((item) => item.isDefault === 1);
      setJobCustomTestDataSet({
        id: defaultTestDataList.length > 0 ? defaultTestDataList[0]?.id : undefined,
        name: defaultTestDataList.length > 0 ? defaultTestDataList[0]?.name : undefined,
      });
    }
  }, [testDataSetList]);
  return (
    <Drawer
      title={
        <div className="display-flex justify-space-between">
          <span>Preview Settings</span>
          <span>
            <img
              src={CLOSE_ICON_GRAY}
              alt="close"
              className="cursor-pointer"
              onClick={() => {
                dispatch(closePreviewSettingDrawer());
              }}
            />
          </span>
        </div>
      }
      placement="right"
      closable={false}
      width={700}
      onClose={() => {
        dispatch(closePreviewSettingDrawer());
      }}
      visible={previewSettingDrawerVisible}
      bodyStyle={{ padding: 15 }}>
      <div>
        <Row>
          <Col span={24}>
            <div>Profile Selected: {jobCustomTestDataSet ? jobCustomTestDataSet?.name : ""}</div>
            <div>App Locale Selected: {jobAppLocale ? jobAppLocale : ""}</div>
            <div className="text-align-right">
              <Button
                size="large"
                type="primary"
                className={styles.button}
                disabled={jobCustomTestDataSet === undefined && jobAppLocale === undefined}
                onClick={() => {
                  dispatch(
                    scheduleNow(
                      {
                        testJobId: testJobId,
                        isManual: 1,
                        testDataSetId: jobCustomTestDataSet?.id ===undefined && jobCustomTestDataSet?.name?-1: jobCustomTestDataSet?.id ,
                        appLocale: jobAppLocale,
                      },
                      setCurrentMenuOptionsSelected
                    )
                  );
                  dispatch(closePreviewSettingDrawer())
                }}>
                Run
              </Button>
            </div>
          </Col>
        </Row>
        <Tabs defaultActiveKey="TEST DATA PROFILE">
          <Tabs.TabPane tab="TEST DATA PROFILE" key={"TEST DATA PROFILE"}>
            <Row>
              <Col span={24}>
                <div className={styles.headerTitle}>Set Test Data Profile for Run</div>
              </Col>
              <Col span={24} className="text-align-right">
                <Button
                  type="text"
                  className="resetButton"
                  onClick={() => {
                    // setJobCustomTestDataSet(undefined);
                    let defaultTestDataList = testDataSetList && testDataSetList.filter((item) => item.isDefault === 1);
                    setJobCustomTestDataSet({
                      id: defaultTestDataList.length > 0 ? defaultTestDataList[0]?.id : undefined,
                      name: defaultTestDataList.length > 0 ? defaultTestDataList[0]?.name : undefined,
                    });
                  }}>
                  Reset
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Radio.Group
                  onChange={(e) => {
                    setJobCustomTestDataSet({
                      id: e.target.value.split(splitValue)[0] && e.target.value.split(splitValue)[0]!="undefined"?e.target.value.split(splitValue)[0]:undefined,
                      name: e.target.value.split(splitValue)[1],
                    });
                  }}
                  value={jobCustomTestDataSet?.id + splitValue + jobCustomTestDataSet?.name}>
                  <Space direction="vertical">
                    {testDataSetList && (
                      <Radio key={0} value={undefined + splitValue + testDataSetList[0].name}>
                        <Tooltip title="Currently selected Execution Profile of Test Plan" color={TOOLTIP_BG_COLOR}>
                          <span>{testDataSetList[0].name}</span>
                        </Tooltip>
                      </Radio>
                    )}
                    <div className={styles.headerTitle}>Custom Profiles</div>
                    {testDataSetList && testDataSetList.length > 1
                      ? testDataSetList.map((value) => {
                          return (
                            value.id && (
                              <Radio key={value.id} value={value.id + splitValue + value.name}>
                                {value.name}
                              </Radio>
                            )
                          );
                        })
                      : "No Data"}
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="APP LOCALE" key={"APP LOCALE"}>
            <Row>
              <Col span={24} className="text-align-right">
                <Button
                  type="text"
                  className="resetButton"
                  onClick={() => {
                    setJobAppLocale(undefined);
                  }}>
                  Reset{" "}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className={styles.headerTitle}>Set Application Locale for Run</div>
              </Col>
              <Col span={24}>
                <div>
                  <Select
                    showSearch={true}
                    className="setAppLocalValue"
                    value={jobAppLocale}
                    placeholder="Select Locale"
                    onChange={(e) => {
                      setJobAppLocale(e);
                    }}>
                    {getListOfLocales &&
                      getListOfLocales?.map((i) => (
                        <Select.Option key={i?.locales} value={i?.locales}>
                          {i?.locales}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Drawer>
  );
};

export default JobSettingModal;
