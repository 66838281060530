import { Tabs } from "antd";
import React, { useState } from "react";
import { APPLICATION_STRINGS, DEVICE_STRINGS } from "../../Constants";
import { useDispatch } from "react-redux";
import { fetchSystemKeyByProjectId } from "../../actions/TestDataSetAction";
import ApplicationStringsV2 from "./ApplicationStringsV2/ApplicationStringsV2";
import DeviceStringsV2 from "./DeviceStringsV2/DeviceStringsV2";
const { TabPane } = Tabs;

const ProjectStringsV2 = (props) => {
  const { projectId } = props;
  const [getActiveKey, setActiveKey] = useState(APPLICATION_STRINGS);
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    setActiveKey(e);
    dispatch(fetchSystemKeyByProjectId());
  };
  return (
    <div className="deviceTab">
      <Tabs defaultActiveKey={APPLICATION_STRINGS} activeKey={getActiveKey} onChange={(e) => handleOnChange(e)}>
        <TabPane tab={"Application Strings"} key={APPLICATION_STRINGS}>
          <ApplicationStringsV2 projectId={projectId} />
        </TabPane>
        <TabPane tab={"Device Strings"} key={DEVICE_STRINGS}>
          <DeviceStringsV2 projectId={projectId} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ProjectStringsV2;
