import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Select, Space, Spin } from "antd";
import { isEmpty } from "lodash";
import { DEVICE_EXECUTION_FARM } from "../../Constants";
import { disableValidateCredential, isResetDisableForCloudTesting } from "../../Util";
import {
  getExternalFarmConfig,
  getExternalFarmDataCenterList,
  handleDisableButton,
  setExternalFarmValidation,
  updateExternalFarmDetail,
  validateExternalFarmCredentials,
} from "../../actions/ProjectSettingsActions";
import CommonInputFieldV2 from "../CommonComponents/CommonInputFieldV2/CommonInputFieldV2";
import CommonSelectBoxV2 from "../CommonComponents/CommonSelectBoxV2/CommonSelectBoxV2";
import ErrorComponent from "../CoreComponents/ErrorComponent/ErrorComponent";
import SaveResetButtonV2 from "../CoreComponents/SaveResetButtonV2/SaveResetButtonV2";
import styles from "./ProjectSettingsV2.module.scss";

const initialSauceLabsDetails = {
  userName: "",
  accessKey: "",
  dataCenter: "",
};

const { Option } = Select;
const SauceLabsV2 = (props) => {
  const { projectId } = props;
  const dispatch = useDispatch();
  const {
    externalFarmDataCenterList,
    projectLoading,
    externalFarmDetails,
    externalFarmLoading,
    isDisabled,
    externalFarmName,
    isValidateExternalFarmCredentials,
  } = useSelector((state) => state.ProjectSettingsReducer);
  const [sauceLabsDetails, setSauceLabsDetails] = useState(initialSauceLabsDetails);
  const [error, setError] = useState(false);

  const { userName, accessKey, dataCenter } = sauceLabsDetails || {};
  const {
    userName: externalUserName,
    accessKey: externalAccessKey,
    dataCenter: externalDataCenter,
  } = externalFarmDetails || {};

  useEffect(() => {
    dispatch(getExternalFarmDataCenterList(DEVICE_EXECUTION_FARM.SAUCELABS));
    dispatch(getExternalFarmConfig(projectId, DEVICE_EXECUTION_FARM.SAUCELABS));
    setError(false);
  }, [projectId]);

  useEffect(() => {
    if (!isEmpty(externalFarmDetails) && externalFarmName === DEVICE_EXECUTION_FARM.SAUCELABS) {
      setSauceLabsDetails({
        userName: externalUserName,
        accessKey: externalAccessKey,
        dataCenter: externalDataCenter,
      });
      dispatch(handleDisableButton(true));
    }
  }, [externalFarmDetails]);

  useEffect(() => {
    if (isValidateExternalFarmCredentials) {
      dispatch(handleDisableButton(false));
    } else {
      dispatch(handleDisableButton(true));
    }
  }, [isValidateExternalFarmCredentials]);

  useEffect(() => {
    if (projectLoading) {
      dispatch(handleDisableButton(true));
    }
  }, [projectLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSauceLabsDetails({ ...sauceLabsDetails, [name]: value });
    dispatch(setExternalFarmValidation(false));
    setError(false);
  };

  const handleDataCenterChange = (e) => {
    setSauceLabsDetails({ ...sauceLabsDetails, dataCenter: e });
    setError(false);
  };

  let dataCenterList = [];
  if (!isEmpty(externalFarmDataCenterList)) {
    externalFarmDataCenterList?.map((data) => {
      dataCenterList.push(<Option key={data?.value}>{data?.key}</Option>);
    });

    const saveSauceLabsDetail = () => {
      if (sauceLabsDetails && isValidateExternalFarmCredentials) {
        dispatch(
          updateExternalFarmDetail({
            ...sauceLabsDetails,
            id: externalFarmDetails ? externalFarmDetails.id : 0,
            project: { id: parseInt(projectId) },
            farmName: DEVICE_EXECUTION_FARM.SAUCELABS,
          })
        );
        dispatch(handleDisableButton(true));
      }
    };

    const resetSauceLabsDetails = () => {
      if (!isEmpty(externalFarmDetails)) {
        setSauceLabsDetails({
          userName: externalUserName,
          accessKey: externalAccessKey,
          dataCenter: externalDataCenter,
        });
      } else {
        setSauceLabsDetails(initialSauceLabsDetails);
      }
      dispatch(setExternalFarmValidation(false));
      setError(false);
      dispatch(handleDisableButton(true));
    };

    const validateCredentials = () => {
      dispatch(
        validateExternalFarmCredentials(userName, accessKey, dataCenter, DEVICE_EXECUTION_FARM.SAUCELABS, setError)
      );
    };

    const inputFields = [
      {
        label: "Username",
        name: "userName",
        placeholder: "Please Enter Username",
        value: userName,
        autoFocus: true,
      },
      {
        label: "Access Key",
        name: "accessKey",
        placeholder: "Please Enter Access Key",
        value: accessKey,
      },
    ];

    return (
      <Spin spinning={projectLoading || externalFarmLoading} tip="Loading">
        <Row justify="space-between" align="middle" className={styles["main_Header"]}>
          <Col>
            <div className={styles["main-Header-Text"]}>SauceLabs</div>
          </Col>
          <Col>
            <Space>
              <SaveResetButtonV2
                submitLabel={!isEmpty(sauceLabsDetails) ? "Update" : "Save"}
                resetLabel={"Reset"}
                submitDisable={isDisabled}
                resetDisable={isResetDisableForCloudTesting(sauceLabsDetails, externalFarmDetails)}
                handleSubmit={saveSauceLabsDetail}
                handleReset={resetSauceLabsDetails}
              />
            </Space>
          </Col>
        </Row>
        <Row justify="center" className={styles["page_Scroll"]}>
          <Col xs={{ span: 24 }} lg={{ span: 14 }} className={styles["project_Details"]}>
            <Row gutter={50} justify="center">
              <Col span={20}>
                <div className={styles["header_Title"]}>Basic Details</div>
                {inputFields.map((field, index) => (
                  <CommonInputFieldV2
                    key={index}
                    label={field.label}
                    name={field.name}
                    placeholder={field.placeholder}
                    value={field.value}
                    handleChange={handleChange}
                    labelBackgroundClass="label_Background"
                    required={true}
                    autoFocus={field.autoFocus}
                  />
                ))}
                <CommonSelectBoxV2
                  label="Data Center"
                  labelBackgroundClass={"label_Background"}
                  options={dataCenterList}
                  value={dataCenter || undefined}
                  placeholder="Select Data Center"
                  handleChange={(e) => handleDataCenterChange(e)}
                  required={true}
                />
                <Button
                  className={styles["Search_And_validate_Btn"]}
                  onClick={() => validateCredentials()}
                  disabled={disableValidateCredential(sauceLabsDetails, externalFarmDetails)}
                >
                  Validate credentials
                </Button>
                {error && (
                  <div className="mt-10">
                    <ErrorComponent errorMessage="Please check credentials are invalid." />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
};

export default SauceLabsV2;
