import { Col, Row, Space } from "antd";
import React, { useCallback } from "react";
import { BACK_BUTTON } from "../../../../../../CdnImagePath";
import { TEST_PLAN, TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../../../../Constants";
import { confirmModal } from "../../../../../../Util";
import BackButton from "../../../../../CoreComponents/BackButtonComponent/BackButton";
import styles from "./ViewTestDataSetListV2.module.scss";

const ViewTestDataSetHeaderV2 = (props) => {
  const { setIsEditTestDataSet, isDefaultView, editTestDataSetName, selectedTestPlanName, stepType } = props;

  const onBackClick = useCallback(() => {
    if (!isDefaultView) {
      confirmModal(() => setIsEditTestDataSet(false));
    } else {
      setIsEditTestDataSet(false);
    }
  }, [isDefaultView, setIsEditTestDataSet]);

  return (
    <Row align="middle" className={styles["TestDataSetHeaderRow"]}>
      <Col className={styles["TestDataSetHeaderCol"]}>
        {stepType !== TEST_PLAN && (
          <div>
            <img src={BACK_BUTTON} alt="Back" onClick={onBackClick} className={styles["backIcon"]} />
          </div>
        )}
        <div className={styles["container"]}>
          <label className={styles["profile_Name_Label"]}>Profile Name: </label>
          <span className={styles["test_Data_Set_Name"]}>{editTestDataSetName}</span>
          {stepType && selectedTestPlanName && (
            <span>
              (
              {(stepType === TEST_SCENARIOS && "Test Scenario:") ||
                (stepType === TEST_STEP_BLOCKS && "Test Block:") ||
                "Test Plan:"}{" "}
              {selectedTestPlanName})
            </span>
          )}
        </div>
      </Col>
      <Col>
        <Space>
          {stepType === TEST_PLAN && (
            <div className="closeIcon">
              <BackButton handleBack={onBackClick} isBlack={true} />
            </div>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default ViewTestDataSetHeaderV2;
