import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "antd";
import { changeTestJobData } from "../../../../../actions/TestJobActions";
import TestJobCriteriaWrapV2 from "./TestJobCriteriaWrapV2";
import styles from "./JobCriteriaV2.module.scss";

const JobCriteriaV2 = () => {
  const dispatch = useDispatch();
  const { testJobDetail, testJobErrorDetail } = useSelector((state) => state.TestJobReducer);

  return (
    <>
      <div className={styles["job_Name_And_Input"]}>
        <div className={styles["job_Name_Title"]}>
          Job Name:<span className={styles["red_Text"]}>*</span>
        </div>
        <div className={styles["job_Input_Box"]}>
          <Input
            className={`common_Search_Box  ${testJobErrorDetail["name"] && styles["border_Color_Red"]}`}
            onChange={(e) =>
              dispatch(
                changeTestJobData({
                  ...testJobDetail,
                  name: e.target.value,
                })
              )
            }
            placeholder="Please Enter Job Name"
            value={testJobDetail?.name}
            autoFocus
          />
        </div>
      </div>
      <TestJobCriteriaWrapV2 />
    </>
  );
};

export default JobCriteriaV2;

export const InfoDataNotification = () => {
  return (
    <span className="font-color-orange float-left font-size-20">
      <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
    </span>
  );
};
