import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Tabs, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import {
  PROJECT_KEYS,
  SYSTEM_KEY_WARNING_CONTENT,
  TEST_DATA,
  TOOLTIP_BG_COLOR,
} from "../../Constants";
import { fetchProjectKeysData, fetchTestDataByProjectId } from "../../actions/TestDataAction";
import CommonConfirmationModalV2 from "../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import ProjectKeys from "./ProjectKeys/ProjectKeys";
import SystemKeys from "./SystemKeys/SystemKeys";
import styles from "./TestDataSetManagement.module.scss";

const { TabPane } = Tabs;
const TestDataSetManagement = (props) => {
  const { projectId } = props;
  const [getActiveKey, setActiveKey] = useState(PROJECT_KEYS);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    setActiveKey(e);
    if (e === TEST_DATA) {
      dispatch(fetchTestDataByProjectId(props.projectId));
    } else {
      dispatch(fetchProjectKeysData(projectId));
    }
  };

  return (
    <div className="deviceTab">
      <Tabs defaultActiveKey={PROJECT_KEYS} activeKey={getActiveKey} onChange={(e) => handleOnChange(e)}>
        <TabPane tab={"Project Keys"} key={PROJECT_KEYS}>
          <ProjectKeys projectId={projectId} />
        </TabPane>
        <TabPane
          tab={
            <span className={styles["tab_Name_And_Icon"]}>
              System Keys
              <Tooltip title={"Learn more about System Keys"} color={TOOLTIP_BG_COLOR}>
                <div className={styles['info_Icon']}>
                  <InfoCircleFilled onClick={() => setOpenConfirmModal(true)} />
                </div>
              </Tooltip>
            </span>
          }
          key={TEST_DATA}
        >
          <SystemKeys projectId={projectId} />
        </TabPane>
      </Tabs>
      <CommonConfirmationModalV2
        handleOk={() => {
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={SYSTEM_KEY_WARNING_CONTENT}
        modalTitle={"SYSTEM KEYS HAVE LIMITED USE"}
        okText={"Ok"}
        hideCancelButton={true}
      />
    </div>
  );
};

export default TestDataSetManagement;
