import React from "react";
import { DatePicker, Input, TimePicker } from "antd";
import styles from "./CommonInputFieldV2.module.scss";
const { TextArea } = Input;

const CommonInputFieldV2 = (props) => {
  const {
    label,
    placeholder,
    name,
    value,
    handleChange,
    required = false,
    type = "input",
    disabledDate,
    autoFocus = false,
    labelBackgroundClass,
    disabled = false,
  } = props;
  return (
    <div className={styles["custom_Input_Wrapper"]}>
      <div
        className={`${styles["custom_Input_Label"]} ${
          labelBackgroundClass ? labelBackgroundClass : ""
        } ${disabled && styles["disable_Input_Label"]}`}
      >
        {label}
        {required && <span className={styles["field_Required"]}>*</span>}
      </div>
      {type === "input" && (
        <Input
          className={styles["modal_Input"]}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={handleChange}
          autoComplete="off"
          autoFocus={autoFocus}
          disabled={disabled}
        />
      )}
      {type === "textarea" && (
        <TextArea
          className={styles["modal_TextArea"]}
          rows={4}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      )}
      {type === "datepicker" && (
        <DatePicker
          className={styles["modal_Date_Picker_Box"]}
          placeholder={placeholder}
          disabledDate={disabledDate}
          onChange={handleChange}
          value={value || null}
        />
      )}
      {type === "timepicker" && (
        <TimePicker
          className={styles["modal_Date_Picker_Box"]}
          placeholder={placeholder}
          onChange={handleChange}
          value={value || null}
        />
      )}
    </div>
  );
};

export default CommonInputFieldV2;
