import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { isEmpty } from "lodash";
import { DEVICE_LOADED, TEST_SCENARIOS, TOOLTIP_BG_COLOR } from "../../../Constants";
import { INVITE_MEMBER_ICON, PROJECT_AND_TEAM_ICON, SEND_ICON } from "../../../Constants/SvgConstants";
import { showTagOfPlatform } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import { myProjects } from "../../../actions/ProjectsAction";
import { resetDeviceFarmList, resetDeviceList } from "../../../actions/ServerListAction";
import history from "../../../history";
import InviteMemberModalV2 from "../../CommonComponents/InviteMemberV2/InviteMemberModalV2";
import ProjectOrTeamMembersV2 from "../../CommonComponents/ProjectOrTeamMembersV2/ProjectOrTeamMembersV2";
import PaginationComponentV2 from "../../CoreComponents/PaginationComponent/PaginationComponentV2";
import TableComponentV2 from "../../CoreComponents/TableComponent/TableComponentV2";
import styles from "./ProjectDetailsV2.module.scss";

const columns = [
  {
    title: "Project",
    dataIndex: "project",
    width: 500,
  },
  {
    title: "Operating System",
    dataIndex: "os",
    width: 300,
  },
  {
    title: "Team Name",
    dataIndex: "teamName",
    width: 250,
  },
  {
    title: "Members",
    dataIndex: "members",
    width: 300,
  },
  {
    title: "Actions",
    dataIndex: "action",
    width: 300,
  },
];

const ProjectCardsV2 = (props) => {
  const dispatch = useDispatch();
  const { filter, orgId, teamNameOfProject } = props;

  const { myProjectsList, currentProjectListPage, totalProjectListPage, totalItemsForProject } = useSelector(
    (state) => state.ProjectsReducer
  );
  const [modalStatus, setModalStatus] = useState(false);
  const [projectId, setProjectId] = useState(null);

  const goInsideProject = useCallback(
    (projectId, orgId) => {
      localStorage.setItem("projectId", projectId);
      const url = `/${orgId}/project/${projectId}/${TEST_SCENARIOS}`;
      history.push(url);
      dispatch(resetDeviceFarmList());
      dispatch(resetDeviceList());
      dispatch({ type: DEVICE_LOADED, isLoaded: false });
    },
    [dispatch, orgId, projectId]
  );

  const modalClosed = () => {
    setModalStatus(false);
  };

  const projectData = myProjectsList?.map((row) => {
    const { id, projectName, appName, buildType, platform, projectMembers, teamName, projectId } = row;
    return {
      key: id,
      project: (
        <div>
          <div className={styles["projects-icon-and-name"]}>
            <span>
              <SvgLoader iconName={PROJECT_AND_TEAM_ICON} defaultCursor />
            </span>
            <span className={styles["project-Name"]} onClick={() => goInsideProject(projectId, orgId)}>
              {projectName}
            </span>
          </div>
          {appName && (
            <div>
              <span className={styles["labels"]}>App Name: </span>{" "}
              <span className={styles["labels-details"]}>{appName}</span>
            </div>
          )}
          {buildType && (
            <div>
              <span className={styles["labels"]}>Type: </span>{" "}
              <span className={styles["labels-details"]}>{buildType}</span>
            </div>
          )}
        </div>
      ),
      os: showTagOfPlatform(platform),
      members: <ProjectOrTeamMembersV2 members={projectMembers} />,
      teamName: <span className={styles["team-name"]}>{teamName ? teamName : teamNameOfProject}</span>,
      action: (
        <div className="display__flex gap-10">
          <Tooltip
            title="Invite members to the Project"
            color={TOOLTIP_BG_COLOR}
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
          >
            <div>
              <SvgLoader
                iconName={INVITE_MEMBER_ICON}
                handleClick={(e) => {
                  e.stopPropagation();
                  setModalStatus(true);
                  setProjectId(projectId);
                }}
              />
            </div>
          </Tooltip>
          <Tooltip title="Open Project" color={TOOLTIP_BG_COLOR} mouseEnterDelay={0} mouseLeaveDelay={0}>
            <div>
              <SvgLoader
                iconName={SEND_ICON}
                handleClick={() => {
                  goInsideProject(projectId, orgId);
                }}
              />
            </div>
          </Tooltip>
        </div>
      ),
    };
  });

  return (
    <>
      <div className="table_Details__wrapper">
        <TableComponentV2 columns={columns} data={projectData} className="table__wrapper" />
        {!isEmpty(myProjectsList) && !teamNameOfProject && (
          <div className={styles["pagination-section"]}>
            <PaginationComponentV2
              currentPageItems={totalProjectListPage}
              currentPage={currentProjectListPage}
              totalPage={totalProjectListPage}
              totalItems={totalItemsForProject}
              callPaginationAPI={(pageNumber) => {
                pageNumber !== currentProjectListPage && dispatch(myProjects(orgId, pageNumber, "", filter));
              }}
            />
          </div>
        )}
      </div>

      <InviteMemberModalV2 modalClosed={modalClosed} modalStatus={modalStatus} projectId={projectId} />
    </>
  );
};

export default ProjectCardsV2;
