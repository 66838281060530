import React, { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { splitValue, STEP, TEST_SCENARIOS, TOOLTIP_BG_COLOR } from "../../../../Constants";
import { isSubStep, showActionStep, showSubStep } from "../../../../Util";
import VerticalLine from "../../../CommonComponents/VerticalLine";
import RecTestStepAddActionsV2 from "../../StepInnerComponents/RecTestStepListHeaderV2/StepActionsComponents/RecTestStepAddActionsV2";
import RecTestStepPlayActionsV2 from "../../StepInnerComponents/RecTestStepListHeaderV2/StepActionsComponents/RecTestStepPlayActionsV2";
import RecTestStepSubStepsV2 from "../../StepInnerComponents/RecTestStepListHeaderV2/StepActionsComponents/RecTestStepSubStepsV2";
import RecTestStepMoreActionsV2 from "./RecTestStepMoreActionsV2/RecTestStepMoreActionsV2";
import iconAI from "../../../../images/iconAi.png";
import disableIconAI from "../../../../images/disableIconAI.png";
import {
  captureExpandedSteps,
  showGenerateStepsFromAIHeader,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { Tooltip } from "antd";
import { GENERATE_STEP } from "../../../../Constants/TooltipConstants";
import styles from "./HoverActionsOnRecTestStepV2.module.scss";

const HoverActionsOnRecTestStepV2 = (props) => {
  const {
    recTestStep,
    childStepDisabled,
    parentSkipStep,
    disabled,
    projectId,
    stepId,
    setMenuVisible,
    menuVisible,
    ignoreFailureDisable,
    actionMenuVisible,
    setActionMenuVisible,
    generalActionMenuVisible,
    setGeneralActionMenuVisible,
    stepNo,
  } = props;
  const { stepType, hiddenAIGenerateRecTestStep } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { selectedScenarioPlatform } = useSelector((state) => state.RecTestStepReducer);

  //Destructure of recTestStep object
  const { isTestBlock, parentRecTestStepId, recTestSteps } = recTestStep ? recTestStep : {};
  const { action } = recTestStep && recTestStep?.recTestStepProperty ? recTestStep?.recTestStepProperty : {};

  const memorizedValue = useMemo(() => {
    const isForEmptyStep = recTestSteps?.length === 0 && action === STEP;
    const subActionStep = recTestSteps?.some((i) => i?.recTestStepProperty?.action !== STEP) ? true : false;
    const actionStep = showActionStep(recTestStep, subActionStep);
    const isAddSubSteps = showSubStep(recTestStep, actionStep) && !subActionStep;
    const isSubHeaderActions = subActionStep || isForEmptyStep;
    const isTestBlockInTestScenario = stepType === TEST_SCENARIOS && isTestBlock === 1;
    const isSubTestBlockInTestScenario = stepType === TEST_SCENARIOS && parentRecTestStepId;
    const importStep =
      recTestSteps?.some((i) => i?.recTestStepProperty?.action === STEP) || (isEmpty(recTestSteps) ? true : false);
    const disableAIGenerateIcon = !hiddenAIGenerateRecTestStep?.includes(recTestStep.id);
    const disabledIcons = !hiddenAIGenerateRecTestStep?.includes(recTestStep.id);
    return {
      isForEmptyStep,
      subActionStep,
      actionStep,
      isAddSubSteps,
      isSubHeaderActions,
      isTestBlockInTestScenario,
      isSubTestBlockInTestScenario,
      importStep,
      disableAIGenerateIcon,
      disabledIcons,
    };
  }, [recTestStep, stepType, action, recTestSteps, isTestBlock, parentRecTestStepId, hiddenAIGenerateRecTestStep]);

  const {
    isForEmptyStep,
    subActionStep,
    actionStep,
    isAddSubSteps,
    isSubHeaderActions,
    isTestBlockInTestScenario,
    isSubTestBlockInTestScenario,
    importStep,
    disableAIGenerateIcon,
    disabledIcons,
  } = memorizedValue;
  const dispatch = useDispatch();

  const handleAiIconClick = () => {
    if (!disableAIGenerateIcon) {
      dispatch(showGenerateStepsFromAIHeader(STEP, recTestStep));
    }
    dispatch(
      captureExpandedSteps(
        recTestStep?.tag,
        recTestStep?.recTestScenarioRecTestStepAssocId
          ? recTestStep?.recTestScenarioRecTestStepAssocId
          : recTestStep?.testBlockRecTestScenarioRecTestStepAssocId + splitValue + recTestStep?.tag,
        true
      )
    );
  };
  return (
    <div className={`${styles["hoverActionIcons"]} ${stepNo > 3 ? "hide_in_mobile" : ""}`}>
      <>
        {isAddSubSteps && stepId?.length < 7 && !isTestBlockInTestScenario && (
          <RecTestStepSubStepsV2
            projectId={projectId}
            stepType={stepType}
            recTestStep={recTestStep}
            disabled={disabled || disabledIcons}
            recTestStepOnHover
          />
        )}
        {isSubHeaderActions && stepId?.length < 7 && !isTestBlockInTestScenario && (
          <RecTestStepAddActionsV2
            disabled={disabled || disabledIcons}
            projectId={projectId}
            actionStep={actionStep}
            platform={selectedScenarioPlatform}
            recTestStep={recTestStep}
            actionMenuVisible={actionMenuVisible}
            setActionMenuVisible={setActionMenuVisible}
            generalActionMenuVisible={generalActionMenuVisible}
            setGeneralActionMenuVisible={setGeneralActionMenuVisible}
            recTestStepOnHover
          />
        )}
        {!isSubTestBlockInTestScenario && (
          <>
            <RecTestStepPlayActionsV2
              disabled={disabled}
              projectId={projectId}
              recTestStep={recTestStep}
              recTestStepOnHover
            />
            <RecTestStepMoreActionsV2
              recTestStep={recTestStep}
              projectId={projectId}
              actionStep={actionStep}
              childStepDisabled={childStepDisabled}
              parentSkipStep={parentSkipStep}
              stepName={stepId}
              isAddSubSteps={isAddSubSteps}
              isSubHeaderActions={isSubHeaderActions}
              isForEmptyStep={isForEmptyStep}
              subActionStep={subActionStep}
              setMenuVisible={setMenuVisible}
              isTestBlockInTestScenario={isTestBlockInTestScenario}
              menuVisible={menuVisible}
              ignoreFailureDisable={ignoreFailureDisable}
              platform={selectedScenarioPlatform}
              importStep={importStep}
            />
            <VerticalLine className={styles["borderRight"]} />
          </>
        )}
        {isSubStep([recTestStep]) && (
          <>
            <Tooltip title={GENERATE_STEP} color={TOOLTIP_BG_COLOR}>
              <div
                className={`${styles["buttonForAIStep"]} ${disableAIGenerateIcon && styles["disableButtonForAIStep"]}`}
              >
                <div onClick={() => handleAiIconClick()}>
                  <img src={disableAIGenerateIcon ? disableIconAI : iconAI} alt="iconAI" />
                </div>
              </div>
            </Tooltip>
            <VerticalLine className={styles["borderRight"]} />
          </>
        )}
      </>
    </div>
  );
};

export default HoverActionsOnRecTestStepV2;
