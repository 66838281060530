import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Upload } from "antd";
import { UPLOAD_ICON } from "../../../CdnImagePath";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../Util";
import { removeApplicationStringsXml } from "../../../actions/ApplicationStrings";
import styles from "./UploadFilesV2.module.scss";

const UploadFilesV2 = (props) => {
  const {
    setAddNewBuild,
    addNewBuild,
    existingBuildName,
    setFileList,
    labelName,
    placeholder,
    setUploadBuildFile,
    acceptFileTypes,
    index,
    setSelectStringsIndex,
    selectStringsIndex,
    replaceApkFile,
    devicePushBuild,
  } = props;

  const dispatch = useDispatch();

  const [dragOver, setDragOver] = useState(false);

  const sanitizeFileName = (fileName) => {
    return fileName.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_\-.]/g, "");
  };
  const uploadProps = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const sanitizedFile = new File([file], sanitizeFileName(file.name), { type: file.type });
      changeBuildFileName(sanitizedFile);
      return false;
    },
    accept: acceptFileTypes,
    multiple: false,
  };

  const changeBuildFileName = (file) => {
    if (file) {
      setFileList([file]);
      setUploadBuildFile && setUploadBuildFile(file, file.name);
    }
  };

  const addFile =
    addNewBuild || selectStringsIndex?.includes(index) || !isNotNullAndNotEmptyAndNotUndefined(existingBuildName);

  return (
    <>
      <div className={`${addFile && styles["custom_Input_Wrapper"]}`}>
        {labelName && (
          <div
            className={`${addFile ? styles["custom_Input_Label"] : styles["upload_Label"]} ${
              devicePushBuild ? styles["input_Label_Modal"] : ""
            }`}
          >
            <div>
              {labelName}
              <span className={styles["field_Required"]}>*</span>
            </div>
          </div>
        )}

        {!addFile && (
          <div className="display-flex mb-5">
            <span className="font-size-14 font-weight-500 font-color-darkBlue">{existingBuildName}</span>
            <span className="font-lightBlue cursor-pointer ml-20">
              <Tooltip color={TOOLTIP_BG_COLOR} title="Upload another file">
                <img
                  src={UPLOAD_ICON}
                  alt="upload"
                  onClick={() => {
                    setSelectStringsIndex && setSelectStringsIndex([...selectStringsIndex, index]);
                    selectStringsIndex ? selectStringsIndex?.includes(index) && setAddNewBuild(true) : replaceApkFile();
                    selectStringsIndex && dispatch(removeApplicationStringsXml(index));
                  }}
                />
              </Tooltip>
            </span>
          </div>
        )}

        {addFile && (
          <div className={`${styles["upload_Box"]} ${dragOver ? styles["drag_Over_Text"] : ""}`}>
            <Upload {...uploadProps} maxCount={1} className="upload_Files">
              <FontAwesomeIcon icon="cloud-upload-alt" className="mr-5" />
              {placeholder}
            </Upload>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadFilesV2;
