import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Select, Spin, Tooltip } from "antd";
import { recordingStart, recordingStop, resetElement } from "../../../../../actions/Recording";
import { editRecTestStep, resetEditRecTestStep } from "../../../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  changeScenarioObjective,
  deviceInteractionSelectMode,
  fetchGenerateStepStatus,
  generateScenarioSteps,
  showGenerateStepsFromAIHeader,
  stopGenerateStepsFromAIModal,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../../actions/RecTestStepAction";
import { CLOSE_ICON_GRAY } from "../../../../../CdnImagePath";
import {
  AI_SCENARIO_GENERATE_STEP_TYPE,
  DEVICE_INTERACTION_MODE,
  GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT,
  STEP,
  TOOLTIP_BG_COLOR,
} from "../../../../../Constants";
import { checkNotUndefinedAndNullAndBlank } from "../../../../../Util";
import CommonConfirmationModalV2 from "../../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "./GenerateScenarioStepsModal.module.scss";

const GenerateScenarioStepsHeaderV2 = ({ recTestStep, selectedRecTestScenarioId, handleClose }) => {
  const dispatch = useDispatch();
  const {
    scenarioGenerateInstructions,
    selectedModelType,
    scenarioObject,
    isLoadingForAIStep,
    unSavedRecStepTag,
    isDeviceInteractionSelectMode,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { selectedDeviceByUdid, isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { selectedElement, hoveredElement, deviceInteractionMode } = useSelector((state) => state.RecordingReducer);

  const [inputValue, setInputValue] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (scenarioObject[recTestStep?.id]) {
      setInputValue(scenarioObject[recTestStep?.id]);
    }
  }, [recTestStep?.id, scenarioObject, inputValue]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    dispatch(changeScenarioObjective(value, recTestStep?.id));
    dispatch(recordingStop());
  };

  useEffect(() => {
    if (isLoadingForAIStep === recTestStep?.id) {
      const intervalId = setInterval(() => {
        dispatch(
          fetchGenerateStepStatus({
            objective: inputValue,
            rules: scenarioGenerateInstructions,
            deviceId: selectedDeviceByUdid?.targetUniqueId,
            modelType: selectedModelType,
          })
        );
      }, 4000);
      return () => {
        clearInterval(intervalId);
        setInputValue("");
      };
    }
  }, [isLoadingForAIStep, recTestStep]);

  const handleStop = () => {
    dispatch(
      stopGenerateStepsFromAIModal({
        objective: inputValue,
        rules: scenarioGenerateInstructions,
        deviceId: selectedDeviceByUdid?.targetUniqueId,
        recTestScenarioId: selectedRecTestScenarioId,
        modelType: selectedModelType,
      })
    );
    setInputValue("");
    setOpenConfirmModal(false);
  };

  const handleClick = () => {
    if (!isPreviewRunning) {
      if (isLoadingForAIStep === recTestStep?.id) {
        setOpenConfirmModal(true);
      } else {
        handleClose();
      }
    }
  };

  const handleStart = () => {
    dispatch(recordingStop());
    dispatch(showGenerateStepsFromAIHeader(STEP, recTestStep));
    dispatch(
      generateScenarioSteps(
        {
          objective: inputValue,
          rules: scenarioGenerateInstructions,
          deviceId: selectedDeviceByUdid?.targetUniqueId,
          modelType: selectedModelType,
        },
        recTestStep
      )
    );
  };

  const disableInspectorButton =
    !selectedDeviceByUdid ||
    isPreviewRunning ||
    isLoadingForAIStep !== null ||
    (unSavedRecStepTag && unSavedRecStepTag !== recTestStep?.tag);

  const disable =
    isLoadingForAIStep !== null || (unSavedRecStepTag && unSavedRecStepTag !== recTestStep?.tag) || isPreviewRunning;

  const getHoveredOrSelectedElementText = (hoveredOrSelectedElement) => {
    let text = "";
    if (hoveredOrSelectedElement?.attributes) {
      if (checkNotUndefinedAndNullAndBlank(hoveredOrSelectedElement.attributes.text)) {
        text = hoveredOrSelectedElement.attributes.text;
      } else if (checkNotUndefinedAndNullAndBlank(hoveredOrSelectedElement.attributes["content-desc"])) {
        text = hoveredOrSelectedElement.attributes["content-desc"];
      }
    }
    return text;
  };

  const onHoverElementAttribute = getHoveredOrSelectedElementText(hoveredElement);
  const onSelectedElementAttribute = getHoveredOrSelectedElementText(selectedElement);

  return (
    <div className={styles["AI_Generate_Header"]}>
      <div className={styles["AI_Generate_Header_Details"]}>
        <div>Generate New Steps with AI</div>
        <div className={styles["close_Icon"]}>
          <img
            src={CLOSE_ICON_GRAY}
            alt="close"
            className={isPreviewRunning ? "cursor__not_allowed" : "cursor__pointer"}
            onClick={handleClick}
          />
        </div>
      </div>
      <div className={styles["AI_Generate_Container"]}>
        <div className={styles["label"]}>Test Objective: </div>
        <Input
          className={styles["AI_Objective_Input"]}
          placeholder="Eg:'Create a post with the text 'Exploring the new park in our neighborhood!' and verify that the post has been created successfully.'"
          onChange={handleInputChange}
          value={inputValue}
          autoFocus
          onFocus={() => dispatch(selectRecTestSteps(recTestStep))}
          disabled={disable}
        />
        <Select className={styles["ai_objective_select"]} placeholder="Generate Steps" disabled={disable}>
          <Select.Option value={AI_SCENARIO_GENERATE_STEP_TYPE.APPEND_WITH_EXISTING_STEPS}>
            Save current steps and append
          </Select.Option>
          <Select.Option value={AI_SCENARIO_GENERATE_STEP_TYPE.CLEAR_EXISTING_STEPS_AND_START}>
            Clear and regenerate
          </Select.Option>
        </Select>
        {isLoadingForAIStep === recTestStep?.id ? (
          <Button
            type="primary"
            size="large"
            className={styles["start_Or_Stop_Btn"]}
            onClick={() => setOpenConfirmModal(true)}
          >
            <div className={styles["stop_Generate_Step"]}>
              <Spin className="stop_Ai_Generate_spinner" />
              <span>Stop</span>
            </div>
          </Button>
        ) : (
          <Tooltip
            title={!selectedDeviceByUdid && "Please load a Device to start generating steps"}
            color={TOOLTIP_BG_COLOR}
            overlayClassName={selectedDeviceByUdid ? styles["hidden_Tooltip"] : ""}
          >
            <div>
              <Button
                type="primary"
                size="large"
                className={styles["start_Or_Stop_Btn"]}
                disabled={!selectedDeviceByUdid || !inputValue || isPreviewRunning}
                onClick={handleStart}
              >
                Start
              </Button>
            </div>
          </Tooltip>
        )}
        {deviceInteractionMode === DEVICE_INTERACTION_MODE.SELECT && isDeviceInteractionSelectMode ? (
          <div>
            <Button
              type="primary"
              size="large"
              className={styles["inspector_Button"]}
              disabled={disableInspectorButton}
              onClick={() => {
                dispatch(resetEditRecTestStep());
                dispatch(resetElement());
                dispatch(recordingStop());
                dispatch(deviceInteractionSelectMode());
              }}
            >
              Stop Inspecting
            </Button>
          </div>
        ) : (
          <Tooltip
            title="Hover over the Elements on the Device to view their suggested text. Using this text in the Test Objective helps improve accuracy of the Step generation."
            color={TOOLTIP_BG_COLOR}
          >
            <div>
              <Button
                type="primary"
                size="large"
                className={styles["inspector_Button"]}
                disabled={disableInspectorButton}
                onClick={() => {
                  dispatch(recordingStart());
                  dispatch(editRecTestStep(recTestStep?.tag));
                  dispatch(selectRecTestSteps(recTestStep));
                  dispatch(deviceInteractionSelectMode());
                }}
              >
                Inspector
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
      {(onSelectedElementAttribute || onHoverElementAttribute) && unSavedRecStepTag === recTestStep?.tag && (
        <div>
          Suggested: <span className={"ml-5"}></span>
          {onSelectedElementAttribute ? onSelectedElementAttribute : onHoverElementAttribute}
        </div>
      )}
      <CommonConfirmationModalV2
        handleOk={handleStop}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT}
        modalTitle="Stop AI Generate Steps"
        okText="Abort"
        isDeleteModal
      />
    </div>
  );
};

export default GenerateScenarioStepsHeaderV2;
