import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Tooltip } from "antd";
import { isEmpty } from "lodash";
import {
  deleteTestData,
  fetchProjectKeysData,
  fetchTestDataByProjectId,
  loadEditProjectStringDetail,
  loadEditTestDataDetail,
  showAddProjectKeysForm,
  showAddTestDataForm,
} from "../../actions/TestDataAction";
import { TOOLTIP_BG_COLOR } from "../../Constants";
import { CREATE_ICON, ICON_EDIT_NEW, SILVER_GRAY, TRASH_ICON, WHITE_COLOR } from "../../Constants/SvgConstants";
import SvgLoader from "../../Util/SvgLoader";
import CommonConfirmationModalV2 from "../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import CustomButtonV2 from "../CommonComponents/CustomButtonV2/CustomButtonV2";
import PaginationComponentV2 from "../CoreComponents/PaginationComponent/PaginationComponentV2";
import CommonSearchV2 from "../CoreComponents/SearchComponent/CommonSearchV2";
import TableComponentV2 from "../CoreComponents/TableComponent/TableComponentV2";
import styles from "./TestDataSetManagementV2.module.scss";

const ProjectTestDataV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId, projectKeys } = props;

  const { fetchingTestDataList } = useSelector((props) => props.TestDataReducer);
  const { testDataList, totalTestDataCounts, currentTestDataPage, totalTestDataPageCount, testDataPageItems } =
    useSelector((props) => props.RecTestStepCommonViewReducer);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(null);

  useEffect(() => {
    if (projectKeys) {
      dispatch(fetchProjectKeysData(projectId));
    } else {
      dispatch(fetchTestDataByProjectId(projectId, 1, ""));
    }
  }, [projectId]);

  const TestDataSetActions = (v) => {
    return (
      <div className={styles["action_Icons"]}>
        <Tooltip color={TOOLTIP_BG_COLOR} title="Edit" mouseEnterDelay={0} mouseLeaveDelay={0}>
          <div className={styles["test_Data_action__buttons"]}>
            <SvgLoader
              iconName={ICON_EDIT_NEW}
              handleClick={() =>
                projectKeys ? dispatch(loadEditProjectStringDetail(v.id)) : dispatch(loadEditTestDataDetail(v.id))
              }
            />
          </div>
        </Tooltip>
        <Tooltip color={TOOLTIP_BG_COLOR} title="Delete" mouseEnterDelay={0} mouseLeaveDelay={0}>
          <div className={styles["test_Data_action__buttons"]}>
            <SvgLoader iconName={TRASH_ICON} iconColor={SILVER_GRAY} handleClick={() => setOpenConfirmModal(v.id)} />
          </div>
        </Tooltip>
      </div>
    );
  };

  const handleDispatch = (actionForProjectKeys, actionForTestData, ...args) => {
    if (projectKeys) {
      dispatch(actionForProjectKeys(...args));
    } else {
      dispatch(actionForTestData(...args));
    }
  };

  const searchDetails = (searchText) => {
    handleDispatch(fetchProjectKeysData, fetchTestDataByProjectId, projectId, searchText, 1);
  };

  const callPaginationAPI = (pageNumber) => {
    handleDispatch(fetchProjectKeysData, fetchTestDataByProjectId, projectId, "", pageNumber);
  };

  const addTestData = () => {
    handleDispatch(showAddProjectKeysForm, showAddTestDataForm, projectId);
  };

  const columns = [
    {
      title: projectKeys ? "Key Name" : "Name",
      dataIndex: "testDataDetails",
    },
    {
      title: "Scope",
      dataIndex: "scope",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Default Value",
      dataIndex: "defaultValue",
      width: 350,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
    },
  ];

  const renderUserInfo = (label, value) =>
    value && (
      <div>
        <span className={styles["label"]}>{label}: </span>
        <span className={styles["label-name"]}>{value}</span>
      </div>
    );

  const testDataListNew =
    !isEmpty(testDataList) &&
    testDataList.map((row) => ({
      key: row?.id,
      testDataDetails: (
        <div>
          <div>
            {!projectKeys && <span className={styles["label"]}>Data Name: </span>}
            <span className={styles["data_Set_Title"]}>{row?.name}</span>
          </div>
          {renderUserInfo("Created By", row?.createdBy)}
          {renderUserInfo("Updated By", row?.updatedBy)}
        </div>
      ),
      scope: <span className={styles["label-name"]}>{row?.scope}</span>,
      type: <span className={styles["label-name"]}>{row?.type}</span>,
      defaultValue: <span className={`word-break-all ${styles["label-name"]}`}>{row?.value}</span>,
      action: TestDataSetActions(row),
    }));

  return (
    <div className={`${projectKeys ? styles["page_Scroll"] : styles["system_Key_Page_Scroll"]}`}>
      <div className={styles["project_Test_Data_Header"]}>
        <CommonSearchV2
          placeHolder="Search by Data name"
          searchDetails={(searchText) => searchDetails(searchText)}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
        />
        <CustomButtonV2
          iconColor={WHITE_COLOR}
          buttonIcon={CREATE_ICON}
          buttonLabel={`${projectKeys ? "Project" : "System"} Keys`}
          handleClick={() => addTestData()}
          className={styles["add_keys_Btn"]}
        />
      </div>
      <>
        <div className="table_Details__wrapper">
          <Spin spinning={fetchingTestDataList} tip="Loading" className="spinner-center">
            <TableComponentV2 className="table__wrapper" data={testDataListNew} columns={columns} />
          </Spin>
          {!isEmpty(testDataList) && (
            <div className="pagination_Section">
              <PaginationComponentV2
                currentPageItems={testDataPageItems}
                totalItems={totalTestDataCounts}
                currentPage={currentTestDataPage}
                totalPage={totalTestDataPageCount}
                callPaginationAPI={callPaginationAPI}
              />
            </div>
          )}
        </div>
      </>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(deleteTestData(openConfirmModal, projectKeys ? false : true, "", projectKeys, true));
          setOpenConfirmModal(null);
        }}
        handleCancel={() => setOpenConfirmModal(null)}
        modalOpen={!!openConfirmModal}
        modalTitle={"Confirm Deletion"}
        modalContent={"Are you sure you want to delete this item?"}
        okText={"Delete"}
        isDeleteModal={true}
        modalNote="Note: Test Data set attached with this Test Data will be removed."
      />
    </div>
  );
};

export default ProjectTestDataV2;
