import { faCheckCircle, faExclamationCircle, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Empty, Row, Tabs, Tooltip } from "antd";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { INVALID_ICON } from "../../../CdnImagePath";
import {
  TEST_PLAN_RESULT_TYPE,
  TOOLTIP_BG_COLOR,
  VERIFICATIONS,
  verificationExistenceDisplayName,
} from "../../../Constants";
import { CommonCol, DataCol, StyledCol } from "./ElementDetails/ActualElements";
import ErrorInvestigationDetailsTab from "./ElementDetails/ErrorInvestigationDetailsTab";
import ExecutionInfo from "./ElementDetails/ExecutionInfo";
import ImageEvidenceTab from "./ElementDetails/ImageEvidenceTab";
import RepeatSteps from "./ElementDetails/RepeatSteps";
import RetryDetailsTab from "./ElementDetails/RetryDetailsTab";
import SvgLoader from "../../../Util/SvgLoader";
import { DISABLE_ELEMENT_ICON, LIGHT_GRAY_COLOR } from "../../../Constants/SvgConstants";

const { TabPane } = Tabs;
export const StyledDiv = styled.div`
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
  text-align: center;
`;
export const StyledInnerTabs = styled(Tabs)`
  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-tab {
    width: calc(100% - 8px);
    text-align: center;
  }
`;
const elementFound = () => {
  return;
};

const elementNotFound = () => <img src={INVALID_ICON} alt="Invalid" />;

const TestPlanResultStepElementDetails = (props) => {
  const {
    outputTestDataName,
    outputTestDataValue,
    testDataName,
    testDataValue,
    testDataCustomValue,
    selectedElementImageUrl,
    selectorType,
    retryCounts,
    retryIndex,
    recordedStepImageLink,
    failureImage,
    failurePageSourceLink,
    result,
    continueAfterFail,
    retrySteps,
    preconditionResult,
    testStepFailDetails,
    testExecutionErrorInvestigationDetails,
    action,
    repeatSteps,
    testExecutionElementDetails,
    text,
    data,
    isStepHasPreCondition,
  } = props;
  let passedTestExecutionElementDetails = [];
  let failedTestExecutionElementDetails = [];
  let notAttemptedTestExecutionElementDetails = [];
  testExecutionElementDetails &&
    testExecutionElementDetails.forEach((item) => {
      if (item.state === "FOUND") {
        passedTestExecutionElementDetails.push(item);
      } else if (item.state === "NOT_FOUND") {
        failedTestExecutionElementDetails.push(item);
      } else if (item.state === null) {
        notAttemptedTestExecutionElementDetails.push(item);
      }
    });
  passedTestExecutionElementDetails = [
    ...passedTestExecutionElementDetails,
    ...failedTestExecutionElementDetails,
    ...notAttemptedTestExecutionElementDetails,
  ];
  return (
    passedTestExecutionElementDetails && (
      <div>
        {data?.aiStepObjective && (
          <Row>
            <Col><b>Ai Step Objective:</b></Col>
            <Col className="ml-10">{data?.aiStepObjective}</Col>
          </Row>
        )}
        {(
          <>
            <Col span={24} className="text-align-left mt-10">
              <StyledDiv className={"font-weight-600"}>
                Execution by
              </StyledDiv>
            </Col>
            <CommonCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                Execution
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                Result
              </DataCol>
            </CommonCol>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
               AI Execution
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
              {data?.aiStepExecutionStatus?data?.aiStepExecutionStatus:"Not Executed"}
              </DataCol>
            </StyledCol>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                Manual Element Selection
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
              {data?.manualElementExecutionStatus?data?.manualElementExecutionStatus:"Not Executed"}
              </DataCol>
            </StyledCol>
          </>
        )}
        {data?.testStepVerifyElementProperties &&
          data?.testStepVerifyElementProperties.length > 0 &&
          data?.action === VERIFICATIONS && (
            <Col span={24} className="text-align-left">
              <div>
                <span className={"font-weight-600"}>Verify: </span>
                    <span>
                      {"Element " + verificationExistenceDisplayName[data?.testStepVerifyElementProperties[0].key]}
                    </span>
                  </div>
                <div>
                  <span className={"font-weight-600"}>Element Matching Criteria: </span>
                  <span>
                    {data?.elementOperator}
                  </span>
                </div>
            </Col>
          )}
        {testDataName && (
          <>
            <Col span={24} className="text-align-left">
              <StyledDiv className={"font-weight-600"}>
                Test Data Used in Step
              </StyledDiv>
            </Col>
            <CommonCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                Key
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                Value(s)
              </DataCol>
            </CommonCol>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {testDataName}
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                <div>Default : {testDataValue}</div>
                <div>Custom Value : {testDataCustomValue}</div>
              </DataCol>
            </StyledCol>
          </>
        )}
        {outputTestDataName && (
          <>
            <Col span={24} className="text-align-left mt-10">
              <StyledDiv className={"font-weight-600"}>
                Test Data Saved ( After Step execution)
              </StyledDiv>
            </Col>
            <CommonCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                Key
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                Value(s)
              </DataCol>
            </CommonCol>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {outputTestDataName}
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {outputTestDataValue}
              </DataCol>
            </StyledCol>
          </>
        )}
        {!repeatSteps && !retrySteps && (
          <Tabs defaultActiveKey="1" tabPosition={"top"}>
            {passedTestExecutionElementDetails.map(
              (testExecutionElementDetail, k) => {
                return testExecutionElementDetail.isActive === 1 && (
                  <TabPane
                    key={k + 1}
                    tab={
                      <span>
                        {testExecutionElementDetail?.name}
                        {testExecutionElementDetail.isActive === 1 ? (
                          ![
                            TEST_PLAN_RESULT_TYPE.SKIPPED,
                            TEST_PLAN_RESULT_TYPE.ABORTED,
                          ].includes(result) ? (
                            testExecutionElementDetail.state === "FOUND" ? (
                              <Tooltip
                                color={TOOLTIP_BG_COLOR}
                                title="Element found!"
                              >
                                <FontAwesomeIcon
                                  className="vertical-align-top"
                                  icon={faCheckCircle}
                                  color="#12bf24"
                                />
                              </Tooltip>
                            ) : testExecutionElementDetail.state ===
                              "NOT_FOUND" ? (
                              <Tooltip
                                color={TOOLTIP_BG_COLOR}
                                title="Element not found on page!"
                              >
                                <FontAwesomeIcon
                                  className="vertical-align-top"
                                  icon={faWindowClose}
                                  color="#ffcb32"
                                />
                              </Tooltip>
                            ) : (
                              testExecutionElementDetail.state !== null && (
                                <Tooltip
                                  color={TOOLTIP_BG_COLOR}
                                  title="Another Element has matched! Did not attempt to find this Element."
                                >
                                  <FontAwesomeIcon
                                    className="vertical-align-top"
                                    icon={faExclamationCircle}
                                    color="#1890ff"
                                  />
                                </Tooltip>
                              )
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          <Tooltip
                            color={TOOLTIP_BG_COLOR}
                            title="Element is Disabled!"
                          >
                            <div>
                              <SvgLoader
                                iconName={DISABLE_ELEMENT_ICON}
                                iconColor={LIGHT_GRAY_COLOR}
                                width="18.333px"
                                height="18.333px"
                              />
                            </div>
                          </Tooltip>
                        )}
                      </span>
                    }
                  >
                    <Tabs
                      className={retrySteps ? "tabsRetry" : "tabs"}
                      defaultActiveKey="1"
                    >
                      <TabPane
                        className="ant-tabs-tab"
                        tab="Image Evidence"
                        key="1"
                        style={{ height: "auto" }}
                      >
                        <ImageEvidenceTab
                          testExecutionElementDetail={
                            testExecutionElementDetail
                          }
                          recordedStepImageLink={recordedStepImageLink}
                          failureImage={failureImage}
                          result={result}
                          continueAfterFail={continueAfterFail}
                          preconditionResult={data?.preconditionResult}
                          aiGeneratedRecordedStep={data?.aiGeneratedRecordedStep}
                          aiGeneratedRecordedPagesource={data?.aiGeneratedRecordedPagesource}
                          aiGeneratedRecordedScreenshot={data?.aiGeneratedRecordedScreenshot}
                          isAiStep={data.isAiStep}
                        />
                      </TabPane>

                      <TabPane
                        className="ant-tabs-tab"
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        tab="Execution Info"
                        key="2"
                      >
                        <ExecutionInfo
                          outputTestDataName={data.outputTestDataName}
                          outputTestDataValue={data.outputTestDataValue}
                          testExecutionElementDetail={
                            testExecutionElementDetail
                          }
                          result={result}
                          testDataName={testDataName}
                          testDataValue={testDataValue}
                          testDataCustomValue={testDataCustomValue}
                          selectedElementImageUrl={selectedElementImageUrl}
                          selectorType={selectorType}
                          retryCounts={retryCounts}
                          retryIndex={retryIndex}
                          testStepFailDetails={testStepFailDetails}
                          failurePageSourceLink={failurePageSourceLink}
                          action={action ? action : data?.action}
                          aiGeneratedStep={data?.aiGeneratedStep}
                          aiGeneratedRecordedStep={data?.aiGeneratedRecordedStep}
                          aiGeneratedRecordedPagesource={data?.aiGeneratedRecordedPagesource}
                          text={text}
                          isStepHasPreCondition={
                            isStepHasPreCondition
                              ? isStepHasPreCondition
                              : data?.isStepHasPreCondition
                          }
                        />
                      </TabPane>

                      {!preconditionResult && result === "Failed" && (
                        <TabPane
                          className="ant-tabs-tab"
                          xs={24}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          tab="Error Investigation"
                          key="5"
                        >
                          <ErrorInvestigationDetailsTab
                            errorInvestigationDetails={
                              testExecutionErrorInvestigationDetails
                            }
                          />
                        </TabPane>
                      )}
                    </Tabs>
                  </TabPane>
                );
              }
            )}
          </Tabs>
        )}
        {passedTestExecutionElementDetails.length==0 && data.isAiStep==1 && 
              <>
                <Row className="w-100 mt-10">
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="font-weight-600">Recorded Image</span>
                    <div>
                      {data?.aiGeneratedRecordedScreenshot ? (
                        <img src={data?.aiGeneratedRecordedScreenshot} className="recorded_img" alt=""></img>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <span className="font-weight-600">Execution Image</span>
                    <div>
                      {data?.failureScreenshotLink ? (
                        <img src={data?.failureScreenshotLink} alt="" height="500" width="300"></img>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </Col>
              </Row>
              <Row className="w-100 mt-10">
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {data?.aiGeneratedRecordedPagesource && 
                      <a href={data?.aiGeneratedRecordedPagesource} target="_scenariolog">
                        Recorded Pagesource
                    </a>
                    }
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {data?.failurePagesourceLink && <a href={data?.failurePagesourceLink} target="_scenariolog">
                        Failure Pagesource
                    </a>
                    }
                  </Col>
              </Row>
              <Row className="w-100 mt-10">
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {data?.aiGeneratedRecordedStep && 
                      <div onClick={()=>{
                        var file = new Blob([data?.aiGeneratedRecordedStep], { type: 'text/json' });
                        var fileURL = URL.createObjectURL(file);
                        let tab= window.open(fileURL,"_blank")
                      }}>
                        Recorded AI Step
                      </div>
                    }
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {data?.aiGeneratedStep &&   
                    <div onClick={()=>{
                        var file = new Blob([data?.aiGeneratedStep], { type: 'text/json' });
                        var fileURL = URL.createObjectURL(file);
                        let tab= window.open(fileURL,"_blank")
                      }}>
                        Executed AI Step
                      </div>
                    }
                  </Col>
              </Row>
            </>
            }

        {!_.isEmpty(repeatSteps) && <RepeatSteps {...props} data={data} />}
        {!_.isEmpty(retrySteps) && <RetryDetailsTab {...props} data={data} />}
      </div>
    )
  );
};
export default TestPlanResultStepElementDetails;
